import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import type { AlgorithmSummary, CommonAlgorithm } from 'web/apis/swaggers/swagger-docs';
import { automationSearchTextState } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/automation-search';
import CommonAutomationCardItem from 'web/templates/Automation/containers/Main/CommonAutomationCardItem/CommonAutomationCardItem';
import ConsultationAlgorithmList from 'web/templates/Automation/containers/Main/CounselAutomationContent/components/ConsultationAlgorithmList';
import CounselAutomationTitle from 'web/templates/Automation/containers/Main/CounselAutomationContent/components/CounselAutomationTitle';

interface CounselAutomationContentProps {
  consultationAlgorithms?: AlgorithmSummary[];
  commonAlgorithm?: CommonAlgorithm;
}

export default function CounselAutomationContent({
  consultationAlgorithms,
  commonAlgorithm,
}: CounselAutomationContentProps) {
  const automationSearchText = useAtomValue(automationSearchTextState);
  const [viewAll, setViewAll] = useState(false);

  const onClickViewAllCards = () => {
    setViewAll(true);
  };

  const onClickViewUnder9Cards = () => {
    setViewAll(false);
  };

  const slicedConsultationAlgorithms = useMemo(() => {
    if (!consultationAlgorithms) return [];
    return consultationAlgorithms.slice(0, 9);
  }, [consultationAlgorithms]);

  const restConsultationAlgorithms = useMemo(() => {
    if (!consultationAlgorithms) {
      return [];
    }
    return consultationAlgorithms.length > 9 ? consultationAlgorithms.slice(9) : [];
  }, [consultationAlgorithms]);

  if (!consultationAlgorithms?.length && automationSearchText?.length) {
    return (
      <div>
        <CounselAutomationTitle />
        <div className='col-span-3 h-[200px] flex-w-full-center rounded-r16 bg-[#E5EEF8]'>
          <NoData
            iconProps={{
              name: 'warning',
              size: 48,
              color: 'white600',
            }}
            title='검색 결과가 없습니다.'
            subTitle='단어의 철자가 정확한지 확인해 주세요.'
          />
        </div>
      </div>
    );
  }

  if (!consultationAlgorithms?.length) {
    return (
      <div>
        <CounselAutomationTitle />
        <div className='grid grid-cols-3 gap-x-20 gap-y-16 rounded-r16 bg-[#E5EEF8] p-10'>
          {!automationSearchText && <CommonAutomationCardItem commonAlgorithm={commonAlgorithm} />}
          <div className='col-span-3 h-[200px] flex-w-full-center rounded-r16 bg-[#E5EEF8]'>
            <NoData
              iconProps={{
                name: 'warning',
                size: 48,
                color: 'white600',
              }}
              title='표시할 내용이 없습니다.'
              subTitle='등록된 자동화가 여기에 표시됩니다.'
              className='justify-center'
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <CounselAutomationTitle />
      <div>
        <div className='grid grid-cols-3 gap-x-20 gap-y-16 rounded-r16 bg-[#E5EEF8] p-10'>
          {!automationSearchText && <CommonAutomationCardItem commonAlgorithm={commonAlgorithm} />}
          <ConsultationAlgorithmList
            slicedConsultationAlgorithms={slicedConsultationAlgorithms}
            restConsultationAlgorithms={restConsultationAlgorithms}
            viewAll={viewAll}
          />
        </div>
        {consultationAlgorithms.length > 9 && (
          <button
            type='button'
            className='mx-auto flex w-fit items-center gap-2 rounded-b-r16 border-white600 border-x border-b bg-white50 pt-2 pr-8 pb-6 pl-20'
            onClick={viewAll ? onClickViewUnder9Cards : onClickViewAllCards}>
            <div className='text-Header14'>{viewAll ? '숨기기' : '더보기'}</div>
            <Icon name={viewAll ? 'chevron-up' : 'chevron-down'} size={28} color='black500' />
          </button>
        )}
      </div>
    </div>
  );
}
