import { algorithmModeState as counselAlgorithmModeState } from '@templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useAtomValue } from 'jotai/index';
import { useFormContext } from 'react-hook-form';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { useCounselAutomationResetAll } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-counsel-automation-reset-all';
import { useUnsavedChangesWarning } from 'web/templates/Automation/containers/shared/hooks/use-unsaved-changes-warning';

export default function RegisterCounselAutomationDialogHeader() {
  const {
    formState: { isDirty, defaultValues },
    getValues,
    reset,
    watch,
  } = useFormContext<CounselAutomationAPIFormValues>();

  const counselAlgorithmMode = useAtomValue(counselAlgorithmModeState);

  const { resetAll } = useCounselAutomationResetAll({
    reset,
  });

  const handleConfirmLeave = () => {
    modalService.pop();
    overlayPageService.popById(OVERLAY_PAGE_ID['register-counsel-automation-dialog']);
  };

  const handleCancelLeave = () => {
    modalService.popById('unsaved-changes-warning');
  };

  const { showWarning } = useUnsavedChangesWarning<CounselAutomationAPIFormValues>({
    formMethods: {
      isDirty,
      defaultValues: defaultValues as CounselAutomationAPIFormValues,
      getValues,
      watch,
    },
    onReset: resetAll,
    onConfirm: handleConfirmLeave,
    onCancel: handleCancelLeave,
  });

  return (
    <div className='flex w-full items-center justify-between bg-white50 px-20 pt-20 pb-8'>
      <div className='w-fit text-Header16 text-black700'>
        {`상담자동화 ${counselAlgorithmMode.mode === 'CREATE' || counselAlgorithmMode.mode === 'COPY' ? '등록' : '수정'}`}
      </div>
      <button type='button' onClick={showWarning} className='text-Body14 text-black700'>
        닫기
      </button>
    </div>
  );
}
