import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';
import { chattingRequestState } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/states/chatting-request';
import { useChatInput } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/hooks/use-chat-input';
import { ROOM_TYPE_MAP } from 'web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import { useChatMessageContext } from '../../../../ChatMessageContext';
import { TEXT_TYPE_GROUP } from '../../../../Message/Message';

export default function BottomError({
  isError,
  handleRemove,
}: { isError: boolean; handleRemove: () => void }) {
  const resetReq = useResetAtom(chattingRequestState);

  const { index, setResMessages, type, content, error, isInResMessages } = useChatMessageContext();
  const { sendText } = useChatInput();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isError) {
      if (isInResMessages) {
        if (type && !(type === TEXT_TYPE_GROUP[0])) {
          toastService.errorMsg({
            text: '전송에 실패하였습니다. 다시 시도해 주세요.',
          });

          setResMessages((prev) => {
            if (prev) {
              const newResMessages = [...prev];
              newResMessages.splice(index, 1);
              return newResMessages;
            }
            return prev;
          });
        }
      }
    }
  }, [isError]);

  const handleResend = async () => {
    sendText(content);
    handleRemove();
  };

  useEffect(() => {
    return () => {
      resetReq();
    };
  }, [resetReq]);

  const isSendOverOneThousandStringToKakao =
    error &&
    error.type === ROOM_TYPE_MAP.kakao &&
    error.rawErrorMessage === 'IllegalArgumentException. message length is limited in length 1000.';

  return (
    <div className='flex justify-end gap-6'>
      {!isSendOverOneThousandStringToKakao && (
        <div className='flex cursor-pointer' onClick={handleResend}>
          <div className='px-6 text-Body11 text-red500'>재전송</div>
          <Icon name='replay' color='red500' size={16} />
        </div>
      )}
      <div>
        <div className='cursor-pointer px-6 text-Body11' onClick={handleRemove}>
          삭제
        </div>
      </div>
    </div>
  );
}
