import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import TextArea from 'afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import { useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { selectAtom } from 'jotai/utils';
import type React from 'react';

const selectedAtom = selectAtom(hospitalInfoState, (hospital) => hospital.initialChats);

export default function AutoGreetingMessageInput() {
  const message = useAtomValue(selectedAtom);
  const setHospitalInfo = useSetAtom(hospitalInfoState);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHospitalInfo((prev) => ({
      ...prev,
      initialChats: prev.initialChats?.map((message, index) =>
        index === 0 ? { ...message, content: e.target.value } : message,
      ),
    }));
  };

  return (
    <div className='flex flex-col gap-20'>
      <TextArea
        className='mt-10'
        placeholder='내용을 입력해 주세요.'
        height={276}
        width={'100%'}
        maxLength={950}
        onChange={handleChange}
        isShowBottom={false}
        value={message?.[0]?.content || ''}
      />
      <div className='flex items-center gap-10'>
        <span className='text-Header12 text-black500'>치환문자</span>
        <HoverTooltip position='topRight' text={'병원명이 표시됩니다. 예) 애프터닥의원'}>
          <span className='rounded-r6 bg-white200 px-6 py-3 text-Body12 text-black500'>
            {'{{병원명}}'}
          </span>
        </HoverTooltip>
        <HoverTooltip position='topRight' text={'고객명이 표시됩니다. 예) 홍길동'}>
          <span className='rounded-r6 bg-white200 px-6 py-3 text-Body12 text-black500'>
            {'{{고객명}}'}
          </span>
        </HoverTooltip>
      </div>
    </div>
  );
}
