import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useAtom, useAtomValue } from 'jotai';
import { isEditDisabledByKakaoAlimTalkState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/states/is-edit-disabled-by-kakao-alim-talk';
import { selectedMessageIdState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';

export const useKakaoCheckWhetherEditOrNot = () => {
  const [isEditDisabled, setIsEditDisabled] = useAtom(isEditDisabledByKakaoAlimTalkState);

  const originMessages = useAtomValue(originMessagesState);
  const selectedMessageId = useAtomValue(selectedMessageIdState);

  const hasApprovedTemplate = originMessages.find(
    (message) => message.id === selectedMessageId,
  )?.hasApprovedAlimtalkTemplate;

  const handleFormClick = (e?: React.MouseEvent, successCallback?: () => void) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!isEditDisabled) {
      return;
    }

    return modalService.defaultWarning({
      id: 'kakao-alimtalk-warning',
      title: '카카오 알림톡 승인된 메시지입니다.',
      contents:
        '내용을 수정하실 경우 알림톡 승인이 거부될 수 있습니다.\n승인이 완료되기 전까지 애프터닥 앱에 가입한 사람들에게만 수정된 메시지가 전달됩니다.\n내용을 계속 수정하시려면 “네” 버튼을 클릭해주세요.',
      onConfirm: () => {
        modalService.popById('kakao-alimtalk-warning');
        setIsEditDisabled(false);
        successCallback?.();
      },
    });
  };

  return {
    handleFormClick,
    showOverlay: hasApprovedTemplate && isEditDisabled,
  };
};
