import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';

interface RegisterButtonProps {
  disabled?: boolean;
  onClick: () => void;
  className?: string;
}

export default function RegisterButton({ disabled, onClick, className }: RegisterButtonProps) {
  return (
    <div className={customTwMerge('w-full', className)}>
      <ContainedButton className='w-full' disabled={disabled} onClick={onClick}>
        등록
      </ContainedButton>
    </div>
  );
}
