import dayjs from 'dayjs';

export const getMonthRange = (date: Date) => {
  // 입력받은 날짜의 월말
  const endDate = dayjs(date).endOf('month');

  // 5개월 전의 월초 (현재 월 포함 총 6개월)
  const startDate = endDate.subtract(5, 'month').startOf('month');

  return {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  };
};
