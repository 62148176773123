import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { PatientSavingRequest } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useResetTable } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-reset-table/use-reset-table';

const postPatientsInfo = async (params: PatientSavingRequest) => {
  const response = await apiClient.v3.apiPatientsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useSaveUser = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const { resetTable, revertTableData } = useResetTable();

  const postSaveFinalUserInfos = useMutation({
    mutationFn: postPatientsInfo,
    onMutate: () => {
      fullDimmedLoadingService.on();
    },
    onSuccess: async (data) => {
      // 테이블 초기화
      resetTable({
        isFinalSave: true,
      });

      // 쿼리 무효화
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElListOrSearch] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElCount, { hospitalID }] }),
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged],
        }),
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }],
        }),
      ]);

      await revertTableData();

      if (!data.failCount && !data.successCount) {
        toastService.errorMsg({
          text: '업데이트할 고객정보가 없습니다.',
        });
      }

      if (!data.failCount && data.successCount > 0) {
        toastService.successMsg({ text: '고객 정보를 업데이트 했습니다.' });
      }

      if (data.failCount > 0) {
        toastService.errorMsg({
          text: `${data.failCount}명의 고객 정보 업데이트를 실패했습니다. 입력된 정보를 다시 확인해주세요`,
        });
      }
    },
    onError: (error) => {
      toastService.errorMsg({
        text: '일시적인 오류가 발생하여 업데이트에 실패하였습니다. 다시 시도해 주세요.',
      });
      console.error('error', error);
    },
    onSettled: () => {
      fullDimmedLoadingService.off();
    },
  });

  const handleSaveUserInfoFinally = async () => {
    await postSaveFinalUserInfos.mutateAsync({
      hospitalID,
    });
  };

  return {
    handleSaveUserInfoFinally,
  };
};
