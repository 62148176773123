import { useChatMessageContext } from '../ChatMessageContext';
import { MESSAGE_TYPE_CODE } from '../constants/message';
import AlgorithmOldVideoMessage from './AlgorithmFileMessage';
import AlgorythmSurveyMessage from './AlgorythmSuveyMessage';
import AutomationMarketingMessage from './AutomationMarketingMessage';
import EventMessage from './EventMessage';
import FileMessage from './FileMessage';
import HaftercareEventMessage from './HaftercareEventMessage';
import HaftercareMessage from './HaftercareMessage';
import ImageMessage from './ImageMessage';
import MarketingImageMessage from './MarketingImageMessage';
import MockingImageMessage from './MockingImageMessage';
import NoticeMessage from './NoticeMessage';
import OldHomecareMessage from './OldHomecareMessage';
import OldVideoMessage from './OldVideoMessage';
import ReservationMessage from './ReservationMessage';
import SystemContentMessage from './SystemContentMessage';
import TextContentMessage from './TextContentMessage/TextContentMessage';
import VideoMessage from './VideoMessage';

export const TEXT_TYPE_GROUP = [100, 20100, 12100, 11100, 610, MESSAGE_TYPE_CODE.TEXT_MOCKING];
export const IMAGE_TYPE_GROUP = [300, 12300];
export const FILE_TYPE_GROUP = [500, MESSAGE_TYPE_CODE.FILE_MOCKING];

export default function Message() {
  const { type } = useChatMessageContext();
  if (type === undefined) return <div>undefined type</div>;

  if (TEXT_TYPE_GROUP.includes(type)) {
    return <TextContentMessage />;
  }
  if (IMAGE_TYPE_GROUP.includes(type)) {
    return <ImageMessage />;
  }
  if (FILE_TYPE_GROUP.includes(type)) {
    return <FileMessage />;
  }

  switch (type) {
    case 12410:
      return <VideoMessage />;
    case 11400:
      return <AlgorithmOldVideoMessage />;
    case 400:
    case 12400:
      return <OldVideoMessage />;
    case 600:
      return <ReservationMessage />;
    case 801:
      return <NoticeMessage />;
    case 700:
    case 701:
    case 11700:
    case 12700:
      return <EventMessage />;
    case 710:
    case 11710:
    case 11500: //11710으로 대체되면서 없어질 예정
      return <HaftercareEventMessage />;
    case 711:
      return <HaftercareMessage />;
    case 410:
    case 11410:
      return <OldHomecareMessage />;
    case 13900:
    case 13910:
      return <AlgorythmSurveyMessage />;
    case 20300:
      return <MarketingImageMessage />;
    case 11300: //이 메시지는 애프터닥터에서만, 오른쪽 메시지에서만 사용되는 메시지입니다.
      return <AutomationMarketingMessage />;
    case MESSAGE_TYPE_CODE.IMAGE_MOCKING:
      return <MockingImageMessage />;
    case 40000:
      return <SystemContentMessage />;
    default:
      return <div>{type} unknown type</div>;
  }
}
