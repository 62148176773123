import ActivePeriodDatePicker from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/ExposurePeriodSelector/components/ActivePeriodDatePicker';
import { selectedContentEditDateErrorAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import {
  RadioGroup,
  type ValueType,
} from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import { useAtom } from 'jotai';
import { memo, useEffect } from 'react';

interface ExposurePeriodSelectorProps {
  isLimitedTime: boolean;
  startDate: string;
  endDate: string;
  onHandleIsLimitedTimeValue: (value: boolean) => void;
  onHandleStartDateValue: (value: string) => void;
  onHandleEndDateValue: (value: string) => void;
}

const ExposurePeriodSelector = memo(
  ({
    isLimitedTime,
    startDate,
    endDate,
    onHandleIsLimitedTimeValue,
    onHandleStartDateValue,
    onHandleEndDateValue,
  }: ExposurePeriodSelectorProps) => {
    const [dateInputError, setDateInputError] = useAtom(selectedContentEditDateErrorAtom);

    useEffect(() => {
      setDateInputError(undefined);
    }, [startDate, endDate, isLimitedTime]);

    const handleChange = (value: ValueType) => {
      const newValue = value === 'limited';
      onHandleIsLimitedTimeValue(newValue);
    };

    return (
      <div className='mt-10 flex w-full flex-col gap-10'>
        <div className='flex min-h-[120px] gap-28 py-6'>
          <LabelText width={72} className='flex h-32 items-center'>
            노출기간
          </LabelText>
          <div className='flex flex-col gap-10'>
            <div className='flex flex-col gap-4'>
              <RadioGroup
                name='isLimitedTime'
                value={isLimitedTime ? 'limited' : 'not-limited'}
                onChange={handleChange}>
                <Radio id='not-limited' label='미설정' value={'not-limited'} />
                <Radio id='limited' label='설정 (설정한 기간에만 노출합니다.)' value={'limited'} />
              </RadioGroup>
            </div>
            <div className='ml-34 flex flex-col gap-4'>
              <div className='flex-center gap-10'>
                <ActivePeriodDatePicker
                  type='start'
                  isLimitedTime={isLimitedTime}
                  targetDate={startDate}
                  onHandleChangeDate={onHandleStartDateValue}
                />
                <span className='text-Header12 text-black500'>~</span>
                <ActivePeriodDatePicker
                  type='end'
                  isLimitedTime={isLimitedTime}
                  targetDate={endDate}
                  onHandleChangeDate={onHandleEndDateValue}
                />
              </div>
              {!!dateInputError && (
                <span className='text-Body10 text-red500'>{dateInputError}</span>
              )}
            </div>
          </div>
        </div>
        <Divider type='line' />
      </div>
    );
  },
);

export default ExposurePeriodSelector;
