import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { type ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import type {
  ApiServiceSettingsElCountriesData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import { KOREA_COUNTRY_CODE_ID } from 'web/shared/constants/country-code-id';
import { GENDER_DISPLAY, VISIT_DISPLAY } from 'web/shared/constants/options';
import useUnTaggedTablecellInteraction, {
  type TableCellRefs,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/components/UnTaggedCustomerManagementEditableTableBody/hooks/use-untagged-customer-management-cell-interaction';
import type { UnTaggedCustomerManagementAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/types/table';
import MultipleTagsSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/MultipleTagsSelectBoxTagDropdownForTable/MultipleTagsSelectBoxTagDropdownForTable';
import SingleTagSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/SingleTagSelectBoxTagDropdownForTable/SingleTagSelectBoxTagDropdownForTable';
import { COLUMN_WIDTHS } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/constants/width';
import './UnTaggedCustomerManagementEditableTableBody.scss';

type UnTaggedCustomerManagementEditableTableBodyProps = {
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTagsList: ApiTreatmentTagsElData['data']['treatmentTags'];
  visibleRows: UnTaggedCustomerManagementAPIFormValues;
};

type SelectedCell = {
  row: number;
  col: number;
};

type FocusedCell = {
  row: number | null;
  col: number | null;
};

const DisabledTableCell = ({
  value,
  placeholder,
  className,
}: { value?: string | null; placeholder?: string; className?: string }) => (
  <td className={customTwMerge('border-line-table border-y border-r bg-disabled', className)}>
    <input
      type='text'
      placeholder={placeholder}
      disabled={true}
      value={value || ''}
      className={customTwMerge(
        'w-full bg-disabled px-12 py-11 text-Body12 placeholder-white600 outline-none placeholder:text-white600',
      )}
    />
  </td>
);

const renderIsSelected = (rowIndex: number, colIndex: number, selectedCell: SelectedCell) => {
  return selectedCell.row === rowIndex && selectedCell.col === colIndex;
};

const renderIsFocused = (rowIndex: number, colIndex: number, focusedCell: FocusedCell) => {
  return focusedCell.row === rowIndex && focusedCell.col === colIndex;
};

const getTableCellProps = (
  rowIndex: number,
  colIndex: number,
  selectedCell: SelectedCell,
  onClick: () => void,
  className?: string,
) => ({
  className: `group border-line-table border-y border-r text-Body12 hover:bg-blueLight ${
    renderIsSelected(rowIndex, colIndex, selectedCell)
      ? '-outline-offset-1 bg-blue50 outline outline-1 outline-blue500'
      : ''
  } ${className}`,
  onClick,
});

const TableCell = ({
  rowIndex,
  colIndex,
  selectedCell,
  handleCellClick,
  children,
  className,
}: {
  rowIndex: number;
  colIndex: number;
  selectedCell: SelectedCell;
  handleCellClick: () => void;
  children: ReactNode;
  className?: string;
}) => (
  <td {...getTableCellProps(rowIndex, colIndex, selectedCell, handleCellClick, className)}>
    {children}
  </td>
);

export default function UnTaggedCustomerManagementEditableTableBody({
  countriesList,
  treatmentTagsList,
  visibleRows,
}: UnTaggedCustomerManagementEditableTableBodyProps) {
  const { control, setValue } = useFormContext<{
    rows: UnTaggedCustomerManagementAPIFormValues;
  }>();

  const inputRefs = useRef<TableCellRefs>([]);
  const setInputRef = useCallback(
    (rowIndex: number, colIndex: number, element: HTMLInputElement | HTMLDivElement | null) => {
      if (!inputRefs.current[rowIndex]) {
        inputRefs.current[rowIndex] = [];
      }
      inputRefs.current[rowIndex][colIndex] = element;
    },
    [],
  );

  const rows = useWatch({
    name: 'rows',
    control: control,
  });

  const {
    selectedCell,
    focusedCell,
    handleCellClick,
    resetFocusedCell,
    handleChangeFocusedCell,
    handleChangeSelectedCell,
  } = useUnTaggedTablecellInteraction({
    maxRows: rows.length,
  });

  const columnsToRender = useMemo(
    () => [
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
    ],
    [],
  );

  const activeColumns = useMemo(
    () =>
      columnsToRender
        .map((col, index) => (col.visible ? index : null))
        .filter((index) => index !== null),
    [columnsToRender],
  );

  const countriesOptions = useMemo(
    () =>
      countriesList
        .map((item) => ({
          name: item.koreanCountryName ?? '',
          id: item.countryId,
        }))
        .filter((country) => country.id !== KOREA_COUNTRY_CODE_ID),
    [countriesList],
  );

  const treatmentTagsOptions = useMemo(
    () =>
      treatmentTagsList?.map((tag) => ({
        name: tag.name,
        id: tag.tagId,
        color: tag.color,
      })) ?? [],
    [treatmentTagsList],
  );

  useEffect(() => {
    // 선택된 셀이 없을 경우
    if (selectedCell.row === null || selectedCell.col === null) return;

    // 선택된 셀과 포커스된 셀이 일치할 때 포커스 설정
    const inputElement = inputRefs.current[selectedCell.row]?.[selectedCell.col];

    if (inputElement) {
      if (focusedCell.row === selectedCell.row && focusedCell.col === selectedCell.col) {
        inputElement.classList.remove('hidden-caret');
        inputElement.focus();
      } else {
        inputElement.classList.add('hidden-caret');
        inputElement.blur();
      }
    }
  }, [selectedCell, focusedCell, inputRefs]);

  return (
    <>
      <colgroup>
        <col className='w-[320px]' />
        <col className='w-[140px]' />
        <col className='w-[120px]' />
        <col className='w-[130px]' />
        <col className='w-[130px]' />
        <col className='w-[90px]' />
        <col className='min-w-[178px]' />
        <col className='w-[160px]' />
      </colgroup>
      <tbody>
        {visibleRows.map((row, rowIndex) => {
          const {
            name,
            chartNumber,
            isFirstVisit,
            phoneNumber,
            birthdate,
            nationalityId,
            treatmentTagIds,
            gender,
            patientId,
          } = row;

          const order = rowIndex + 1;

          return (
            <tr key={patientId}>
              {activeColumns.map((colIndex, index) => {
                const rowOrder = order ?? 0;

                switch (colIndex) {
                  case 0:
                    return (
                      <DisabledTableCell
                        key={`name-${rowOrder - 1}`}
                        value={name}
                        placeholder='예) 홍길동'
                        className={`w-[${COLUMN_WIDTHS.name}]`}
                      />
                    );

                  case 1:
                    return (
                      <DisabledTableCell
                        key={`chartNumber-${rowOrder - 1}`}
                        value={chartNumber}
                        placeholder='예) 00000'
                        className={`w-[${COLUMN_WIDTHS.chartNumber}]`}
                      />
                    );

                  case 2:
                    return (
                      <DisabledTableCell
                        key={`isFirstVisit-${rowOrder - 1}`}
                        placeholder='선택'
                        value={
                          isFirstVisit !== undefined && isFirstVisit !== null
                            ? isFirstVisit
                              ? VISIT_DISPLAY.FIRST_VISIT
                              : VISIT_DISPLAY.REVISIT
                            : null
                        }
                      />
                    );

                  // case 3:
                  //   return (
                  //     <DisabledTableCell
                  //       key={`countryCode-${rowOrder - 1}`}
                  //       placeholder='선택'
                  //       value={
                  //         countryCode !== undefined && countryCode !== null
                  //           ? `${countryCode.koreanCountryName}(+${countryCode.internationalDialingCodes})`
                  //           : '선택'
                  //       }
                  //     />
                  //   );

                  case 3:
                    return (
                      <DisabledTableCell
                        key={`phoneNumber-${rowOrder - 1}`}
                        value={phoneNumber}
                        placeholder='예) 01012345678'
                        className={`w-[${COLUMN_WIDTHS.phoneNumber}]`}
                      />
                    );

                  case 4:
                    return (
                      <DisabledTableCell
                        key={`birthdate-${rowOrder - 1}`}
                        value={birthdate}
                        placeholder='예) YYYY-MM-DD'
                        className={`w-[${COLUMN_WIDTHS.birthdate}]`}
                      />
                    );

                  case 5:
                    return (
                      <DisabledTableCell
                        key={`gender-${rowOrder - 1}`}
                        placeholder='선택'
                        value={gender !== undefined ? GENDER_DISPLAY[gender] : null}
                      />
                    );

                  case 6:
                    return (
                      <TableCell
                        key={`treatmentTagIds-${rowOrder - 1}`}
                        handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                        rowIndex={rowOrder - 1}
                        selectedCell={selectedCell}
                        colIndex={index}>
                        <MultipleTagsSelectBoxTagDropdownForTable
                          key={`treatmentTagIds-${rowOrder - 1}`}
                          value={
                            treatmentTagIds
                              ? treatmentTagsOptions.filter((tag) =>
                                  treatmentTagIds.includes(tag.id),
                                )
                              : null
                          }
                          ref={(el) => setInputRef(rowOrder - 1, index, el)}
                          isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                          isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                          placeholder={'예) 도수치료, 리프팅'}
                          onRemoveTag={() => {
                            handleChangeFocusedCell(rowOrder - 1, index);
                            handleChangeSelectedCell(rowOrder - 1, index);
                          }}
                          tagOptions={treatmentTagsOptions}
                          onSelectedTagsChange={(tags) => {
                            setValue(
                              `rows.${rowOrder - 1}.treatmentTagIds`,
                              tags.map((tag) => tag.id),
                              { shouldDirty: true },
                            );
                          }}
                          handleClickOutside={resetFocusedCell}
                        />
                      </TableCell>
                    );

                  case 7:
                    return (
                      <TableCell
                        key={`nationalityId-${rowOrder - 1}`}
                        handleCellClick={() => handleCellClick(rowOrder - 1, index)}
                        rowIndex={rowOrder - 1}
                        selectedCell={selectedCell}
                        colIndex={index}
                        className='w-[160px]'>
                        <SingleTagSelectBoxTagDropdownForTable
                          key={`nationalityId-${rowOrder - 1}`}
                          value={
                            nationalityId
                              ? (countriesOptions.find((item) => item.id === nationalityId) ?? null)
                              : null
                          }
                          width={140}
                          onRemoveTag={() => {
                            handleChangeFocusedCell(rowOrder - 1, index);
                            handleChangeSelectedCell(rowOrder - 1, index);
                          }}
                          ref={(el) => setInputRef(rowOrder - 1, index, el)}
                          placeholder='선택'
                          isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                          isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                          tagOptions={countriesOptions}
                          onSelectedTagChange={(tag) => {
                            if (tag?.id) {
                              setValue(`rows.${rowOrder - 1}.nationalityId`, tag.id, {
                                shouldDirty: true,
                              });
                            } else {
                              setValue(`rows.${rowOrder - 1}.nationalityId`, null, {
                                shouldDirty: true,
                              });
                            }
                          }}
                          handleClickOutside={resetFocusedCell}
                        />
                      </TableCell>
                    );

                  default:
                    return null;
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  );
}
