import ParticipantsChip from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/components/ChattingListItem/components/ParticipantsChip';
import type { ActiveDropdown } from '@templates/CustomerChat/components/ChattingList/types/active-dropdown';
import { useParsedChatRoomData } from '@templates/CustomerChat/components/ChattingList/use-parsed-chat-room-data';
import Badge from 'afterdoc-design-system/components/Atoms/Badge/Badge';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { contextMenuService } from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.service';
import { PositionType } from 'afterdoc-design-system/components/Molecules/ContextMenu/ContextMenu.type';
import dayjs from 'dayjs';
import { type MouseEvent, memo, useCallback, useMemo, useRef } from 'react';
import ParentDropdown from '../../../../../ParentDropDown/ParentDropdown';
import { displayTextContent } from '../../../../functions/displayTextContent';
import type { TempChatRoomHServiceLastMessageUser } from '../../../../hooks/use-chatting-list';
import type { HandleSelectedChatRoomParmas } from '../../ChattingListBody';

interface ChattingListItemAdvanceProps {
  chatRoom: TempChatRoomHServiceLastMessageUser;
  isSelectedItem: boolean;
  activeDropdown: ActiveDropdown | null;
  onHandleDropdown: (activeDropdown: ActiveDropdown, isActive: boolean) => void;
  onHandleSelectChatRoom: (params: HandleSelectedChatRoomParmas) => void;
}

function ChattingListItem({
  chatRoom,
  isSelectedItem,
  activeDropdown,
  onHandleDropdown,
  onHandleSelectChatRoom,
}: ChattingListItemAdvanceProps) {
  const {
    id,
    realTitle,
    counselorID,
    type,
    counselors,
    chatRoomParentHServiceID,
    patientIDInChattingListUserID,
    lastMessageUpdatedAt,
    isBadge,
    isColorAfterDocIcon,
    isKakaoIcon,
    isGrayAfterDocIcon,
    isLeft,
    isTranslated,
    translatedContent,
    content,
    chatRoomHserviceID,
  } = useParsedChatRoomData(chatRoom);
  const chattingListItemRef = useRef<HTMLDivElement | null>(null);

  const isDropdownVisible = useCallback(
    (key: ActiveDropdown['key']) => {
      return activeDropdown?.key === key && activeDropdown.chatRoomID === id;
    },
    [activeDropdown, id],
  );

  const dropdwonDataParams = { id, isColorAfterDocIcon, isKakaoIcon, isGrayAfterDocIcon };

  const isChildDropdownVisible = isDropdownVisible('children');

  const previewMessage = useMemo(() => {
    const textContent = isLeft || !isTranslated ? content : translatedContent;

    const displayContent = displayTextContent(type);

    return displayContent || textContent || '';
  }, [isLeft, isTranslated, translatedContent, content, type]);

  const formatedUpdatedAt = useMemo(() => {
    const updatedAt = dayjs(lastMessageUpdatedAt);
    const isToday = updatedAt.isSame(dayjs(), 'day');
    return isToday ? updatedAt.format('HH:mm') : updatedAt.format('YYYY-MM-DD');
  }, [lastMessageUpdatedAt]);

  const currentItemIconSet = useMemo(() => {
    return {
      isColorAfterDocIcon,
      isGrayAfterDocIcon,
      isKakaoIcon,
      isPendingService: false,
    };
  }, [isColorAfterDocIcon, isGrayAfterDocIcon, isKakaoIcon]);

  const handleLayerContextMenu = (e: MouseEvent, id?: string) => {
    contextMenuService.push(
      e,
      <ParentDropdown
        selectedName={realTitle}
        selectedIconSet={currentItemIconSet}
        inChattingListchatRoomID={id}
        inChattingListpatientID={chatRoomParentHServiceID}
        inChattingCounselors={counselors}
        handleToggle={() => {
          contextMenuService.popById('chatting-list-item-context-menu');
        }}
        isReservationDisabled={!isColorAfterDocIcon && !isGrayAfterDocIcon}
        dropdownDataParams={dropdwonDataParams}
      />,
      {
        positionType: PositionType.MOUSE_EVENT,
        xOffset: 0,
        yOffset: 0,
        customId: 'chatting-list-item-context-menu',
      },
    );
  };

  return (
    <div
      key={id}
      className={`relative cursor-pointer ${isSelectedItem ? 'bg-blue50' : ''}`}
      onClick={() =>
        onHandleSelectChatRoom({
          chatRoomID: id,
          chatRoomParentHServiceID,
          patientIDInChattingListUserID,
          chatRoomHserviceID,
        })
      }>
      <div
        className='flex h-86 flex-col gap-2 px-20 py-12'
        ref={chattingListItemRef}
        onContextMenu={(e) => handleLayerContextMenu(e, id)}>
        <div className='flex h-24 flex-shrink-0 gap-10'>
          <div className='flex h-18 flex-grow items-center gap-8 overflow-hidden'>
            <span className='overflow-hidden text-ellipsis whitespace-nowrap text-Header14 text-black700'>
              {realTitle}
            </span>
            <div className='flex gap-4'>
              {isColorAfterDocIcon && <Icon name='afterdoc' />}
              {isGrayAfterDocIcon && <Icon name='afterdoc-non' />}
              {isKakaoIcon && <Icon name='kakaotalk' />}
            </div>
          </div>
          <div className='flex flex-shrink-0'>
            <Badge isBadge={isSelectedItem ? false : isBadge}>
              <ParticipantsChip
                title={realTitle}
                isNextDropdownOpen={isChildDropdownVisible}
                counselorID={counselorID}
                onHandleDropdown={onHandleDropdown}
                eachChattingRoomID={id}
                counselors={counselors}
              />
            </Badge>
          </div>
        </div>
        <div className='flex h-36 flex-row justify-between'>
          <span className='line-clamp-2 w-[280px] text-Body12 text-black200'>{previewMessage}</span>
          <span className='flex content-end items-end text-Caption9 text-black200'>
            {formatedUpdatedAt}
          </span>
        </div>
      </div>
      {isSelectedItem && <div className='absolute top-0 bottom-0 left-0 w-2 bg-blue500' />}
    </div>
  );
}

export default memo(ChattingListItem);
