import { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import _BaseTag, { type BaseTagProps } from './shared/_BaseTag';

export interface DashedTagProps extends Omit<BaseTagProps, 'textStyle'> {}

export default function DashedTag({
  children,
  tagSize = 'small',
  bgColor = Color.white50,
  borderColor = Color.white600,
  borderWidth = 1,
  textColor,
  iconProps,
  onClick,
  className,
  style,
}: DashedTagProps) {
  return (
    <_BaseTag
      tagSize={tagSize}
      bgColor={bgColor}
      textColor={textColor}
      iconProps={iconProps}
      onClick={onClick}
      borderWidth={borderWidth}
      borderColor={borderColor}
      className={customTwMerge(
        'border-dashed',
        'pl-8',
        tagSize === 'small' ? 'py-2' : 'py-0',
        iconProps ? 'pr-6' : 'pr-8',
        className,
      )}
      style={style}>
      {children}
    </_BaseTag>
  );
}
