import * as React from "react";
import type { SVGProps } from "react";
const SvgBackground = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <g className="background_svg__ql-fill background_svg__ql-color-label">
      <path d="M6 6.868V6H5v1h.942zM4 4h1v1H4zM6.817 5H6v1h.38zM2 6h1v1H2zM3 5h1v1H3zM4 7h1v1H4zM4 11.439V11H3v1h.755zM2 12h1v1H2zM2 9h1v1H2zM2 15h1v1H2zM4.63 10H4v1h.192zM3 8h1v1H3zM10.832 4.2l.168.382V4h-.292a2 2 0 0 1 .124.2M7 4.582l.168-.382a2 2 0 0 1 .124-.2H7zM8 13h-.317l-.351.8a2 2 0 0 1-.124.2H8zM12 2h1v1h-1zM11 3h1v1h-1zM9 3H8v.282A2 2 0 0 1 9 3M2 3h1v1H2zM6 2h1v1H6zM3 2h1v1H3zM5 3h1v1H5zM9 2h1v1H9zM15 14h1v1h-1zM13.447 10.174l.022.051.003.007.336.768H14v-1h-.63zM13 7h1v1h-1zM15 5h1v1h-1zM14 6h1v1h-1zM15 8h1v1h-1zM14 9h1v1h-1zM3.775 14H3v1h1v-.686A2 2 0 0 1 3.775 14M14 3h1v1h-1zM12 6.868V6h-.38zM15 2h1v1h-1zM12 5h1v1h-1zM13 4h1v1h-1zM12.933 9H13V8h-.505zM9 14h1v1H9zM8 15h1v1H8zM6 14.926V15h1v-.684a2 2 0 0 1-1 .61M5 15h1v1H5zM10.668 13.8l-.351-.8H10v1h.792a2 2 0 0 1-.124-.2M11 15h1v1h-1zM14.332 12.2a2 2 0 0 1 .166.8H15v-1h-.755ZM14 15h1v1h-1zM15 11h1v1h-1z" />
    </g>
    <path
      d="M5.5 13 9 5l3.5 8M11.63 11H6.38"
      className="background_svg__ql-stroke"
    />
  </svg>
);
export default SvgBackground;
