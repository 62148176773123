import { useQueryClient } from '@tanstack/react-query';
import { USER_TYPE_CODE } from '@templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { useUpdateBookMark } from '@templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-update-book-mark';
import { selectedMessageIDState } from '@templates/CustomerChat/components/SupportBot/states/selected-message-id';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';
import { displayTextContent } from '../../ChattingList/functions/displayTextContent';

interface ChatIsBookmarkListItemProps {
  data: ChatFileAndContentIDs;
}

export default function ChatIsBookmarkListItem({ data }: ChatIsBookmarkListItemProps) {
  const queryClient = useQueryClient();

  const [selectedMessageID, setSelectedMessageID] = useAtom(selectedMessageIDState);

  const { updateBookMarkAction } = useUpdateBookMark();

  const {
    _id: messageID,
    content,
    sentAt,
    userName,
    userRealName,
    userType,
    type,
    isTranslated,
    translatedContent,
  } = data;

  const formattedSentAt = useMemo(() => {
    const sentDate = dayjs(sentAt);
    return sentDate.isValid() ? sentDate.format('YYYY-MM-DD HH:mm') : '';
  }, [sentAt]);

  const displayName = useMemo(
    () => `${formattedSentAt} ${userRealName || userName || '이름없음'}`,
    [formattedSentAt, userRealName, userName],
  );

  const previewMessage = useMemo(() => {
    const isLeft = userType === USER_TYPE_CODE.PATIENT;
    const textContent = isLeft || !isTranslated ? content : translatedContent;

    const displayContent = displayTextContent(type);

    return displayContent || textContent || '';
  }, [data]);

  const handleSelectIsBookmarkChat = () => {
    selectedMessageID === messageID &&
      queryClient.resetQueries({
        queryKey: [QUERY_KEY.apiChatElFindId, { _id: selectedMessageID }],
      });

    setSelectedMessageID(messageID);
  };

  const handleBookmarkRemove = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    updateBookMarkAction(messageID, false);
  };

  return (
    <div
      key={messageID}
      className='flex flex-center cursor-pointer gap-10 rounded-r16 border border-white600 p-12'
      onClick={handleSelectIsBookmarkChat}>
      <div className='flex flex-grow flex-col overflow-hidden'>
        <p className='line-clamp-1 h-20 w-full text-Body12 text-black500'>{previewMessage}</p>
        <p className='h-16 text-Body11 text-black200'>{displayName}</p>
      </div>
      <ContainedButton
        btnColor='secondary'
        buttonSize='small'
        className='h-22 w-50 flex-shrink-0 rounded-r6'
        onClick={(e) => handleBookmarkRemove(e)}>
        해제
      </ContainedButton>
    </div>
  );
}
