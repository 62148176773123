import * as React from "react";
import type { SVGProps } from "react";
const SvgSettingActivated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#333"
      fillRule="evenodd"
      d="M9.73 21.58c.04.24.25.42.5.42h4c.25 0 .46-.18.5-.42l.37-2.65c.63-.25 1.17-.59 1.69-.98l2.49 1c.22.09.49 0 .61-.22l2-3.46c.13-.22.07-.49-.12-.64L19.66 13l.07-1-.07-1 2.11-1.63c.19-.15.25-.42.12-.64l-2-3.46c-.12-.22-.39-.31-.61-.22l-2.49 1c-.52-.39-1.06-.73-1.69-.98l-.37-2.65a.506.506 0 0 0-.5-.42h-4c-.25 0-.46.18-.5.42l-.37 2.65c-.63.25-1.17.59-1.69.98l-2.49-1c-.22-.09-.49 0-.61.22l-2 3.46a.493.493 0 0 0 .12.64L4.8 11l-.07 1 .07.97-2.11 1.66a.493.493 0 0 0-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1.01c.52.4 1.06.74 1.69.99zM12 8a4 4 0 1 1 0 8 4 4 0 0 1 0-8"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSettingActivated;
