import { selectedContentTextErrorAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import AfterDocTextEditor from 'afterdoc-text-editor';
import { useAtom } from 'jotai/index';
import { useRef } from 'react';

interface ContentAfterDocTextEditorProps {
  content?: string;
  onHandleContentValue: (value: string) => void;
}

export default function ContentAfterDocTextEditor({
  content,
  onHandleContentValue,
}: ContentAfterDocTextEditorProps) {
  const [contentImageError, setContentImageError] = useAtom(selectedContentTextErrorAtom);
  const isFirstRender = useRef(true);

  const handleChange = (value: string) => {
    if (!isFirstRender.current) {
      onHandleContentValue(value);
      setContentImageError(undefined);
    } else {
      isFirstRender.current = false;
    }
  };

  return (
    <div className='flex w-full flex-col gap-4'>
      <div>
        <AfterDocTextEditor height={350} value={content} onChange={handleChange} />
      </div>
      {!!contentImageError && <span className='text-Body10 text-red500'>{contentImageError}</span>}
    </div>
  );
}
