import { SHARED_UTILS } from '@shared-utils/utils';
import {
  findUserWithALCustomerNumber,
  findUserWithCustomerName,
  findUserWithCustomerNumber,
  findUserWithPhoneNumber,
  updateUserWithALCustomerNumber,
} from 'web/apis/almighty-integration/api-client';
import { apiClient } from 'web/apis/instances/api-client';
import type {
  ApiPatientsElDetailData,
  ApiPatientsElDetailParams,
} from 'web/apis/swaggers/swagger-docs';
import {
  parseAfterDocData,
  parseAfterDocDatas,
  parseSmartDoctorData,
  parseSmartDoctorDatas,
} from './parser';
import type {
  ALIntegrationUserData,
  ALResponse,
  SmartdoctorCustomerResponse,
  responseDataDuplicatedCustomerNumber,
} from './type';

export const getPatientDetailInfo = async (params: ApiPatientsElDetailParams) => {
  return new Promise((resolve, reject) => {
    try {
      apiClient.v3.apiPatientsElDetail(params).then((response) => {
        const data = SHARED_UTILS.api.checkApiResponse(response.data);
        const result = parseAfterDocData({ data } as ApiPatientsElDetailData);
        resolve(result);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCustomerUsingName = async (name: string) => {
  return new Promise<ALIntegrationUserData[]>((resolve, reject) => {
    try {
      findUserWithCustomerName(name).then((response) => {
        const data = parseSmartDoctorDatas(response);
        if (data === undefined) {
          reject();
          return;
        }
        resolve(data);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCustomerUsingPhone = async (phone: string) => {
  return new Promise<ALIntegrationUserData[]>((resolve, reject) => {
    try {
      findUserWithPhoneNumber(phone).then((response) => {
        const data = parseSmartDoctorDatas(response);
        if (data === undefined) {
          reject();
          return;
        }
        resolve(data);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCustomerUsingCustomerNumbers = async (customerNumbers: string[]) => {
  return new Promise<ALIntegrationUserData[]>((resolve, reject) => {
    try {
      const promises = [];
      for (const number of customerNumbers) {
        const promise = findUserWithCustomerNumber(number);
        promises.push(promise);
      }
      Promise.all(promises).then((results: ALResponse<SmartdoctorCustomerResponse>[]) => {
        const array: ALIntegrationUserData[] = [];
        for (const result of results) {
          const data = parseSmartDoctorData(result);
          if (data === undefined) continue;
          array.push(data);
        }
        resolve(array);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const getCustomerUsingCustomerNumber = async (customerNumber: string) => {
  return new Promise<ALIntegrationUserData>((resolve, reject) => {
    try {
      findUserWithCustomerNumber(customerNumber).then((response) => {
        const data = parseSmartDoctorData(response);
        if (data === undefined) {
          reject();
          return;
        }
        resolve(data);
      });
    } catch (err) {
      reject(err);
    }
  });
};
export const getDuplicatedCustomerNumber = async (customerNumber: string, patientId: string) => {
  return new Promise<ALIntegrationUserData[]>((resolve, reject) => {
    try {
      const hospitalIDString = localStorage.getItem('selectedHospitalID') as string;
      if (hospitalIDString === undefined) return;
      const hospitalID = JSON.parse(hospitalIDString);
      findUserWithALCustomerNumber(hospitalID ?? '', customerNumber).then((r) => {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const response = r as Record<string, any>;
        if (response.data.code !== 0) {
          console.error(response);
          return;
        }
        const result = response.data.data as Array<responseDataDuplicatedCustomerNumber>;
        const datas = parseAfterDocDatas(result);
        const array = datas.filter((u) => u._id !== patientId);
        resolve(array);
      });
    } catch (err) {
      reject(err);
    }
  });
};

export const updateHService = async (
  patientId: string,
  ALCustomerNumber: string | null,
  ALCustomerNumbers: (string | undefined)[] | undefined,
) => {
  return new Promise((resolve, reject) => {
    try {
      updateUserWithALCustomerNumber(patientId, ALCustomerNumber, ALCustomerNumbers).then(
        (response) => {
          if (response.data.code !== 0) {
            reject(response);
            return;
          }
          const data = response.data.data;
          resolve(data);
        },
      );
    } catch (err) {
      reject(err);
    }
  });
};
