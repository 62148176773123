import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useDataEventBusHandler } from 'web/apis/push/data-push/hooks/use-data-event-bus-handler';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { DataNotificationPayload } from 'web/shared/utils/event-bus';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { GET_APPROVAL_REQUEST_BASED_QUERY } from './use-get-approval-chatroom';

export const useApprovalChatroomPushHandler = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  if (!chatRoomID) return;

  const queryClient = useQueryClient();

  const query = useMemo(() => {
    return {
      chatRoomID,
      ...GET_APPROVAL_REQUEST_BASED_QUERY,
    };
  }, [chatRoomID]);

  const handleNotification = useCallback(
    async (payload: DataNotificationPayload) => {
      const { subCategory, data } = payload.data;
      if (
        subCategory === 'PM_JoinChatRoom_Responder' ||
        subCategory === 'PM_JoinChatRoom_Requestor'
      ) {
        const targetChatRoomID = JSON.parse(data as string).chatRoomID;
        if (targetChatRoomID === chatRoomID) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEY.apiApprovalChatroomElFind, query],
          });
        }
      }
    },
    [chatRoomID, query, queryClient.invalidateQueries],
  );

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_ServiceNotice_h'],
        handler: handleNotification,
      },
    ],
    [handleNotification],
  );

  useDataEventBusHandler(notificationConfigs);
};
