import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useTemporaryPatientsLock } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { clickedPanelIndexState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';

export const useOpenLeaveWithoutSavingModal = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const [customerTableDisplayMode, setCustomerTableDisplayMode] = useAtom(
    customerTableDisplayModeState,
  );
  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);

  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();

  const handleChangeDisplayMode = useCallback(
    (mode?: Parameters<typeof setCustomerTableDisplayMode>[0]) => {
      setCustomerTableDisplayMode(mode ?? 'VIEWER');
    },
    [setCustomerTableDisplayMode],
  );

  const openLeaveWithoutSavingModal = useCallback(
    ({
      successCb,
      settledCb,
      cancelCb,
    }: {
      successCb?: () => void;
      settledCb?: () => void;
      cancelCb?: () => void;
    } = {}) => {
      const onRequestLock = async () => {
        const latestLockData = await getPatientsIsLocked({ hospitalID });

        if (!latestLockData) return;

        await patchRequestLockMutation.mutateAsync({
          hospitalID,
          isLock: false,
          version: latestLockData.version,
        });
      };

      if (clickedPanelIndex === 0 && customerTableDisplayMode === 'EDITOR') {
        modalService.defaultWarning({
          id: 'leave-without-saving',
          title: '저장하지 않고 나가시겠어요?',
          contents: '페이지 이탈 시 저장하지 않은 정보를 잃게 됩니다.',
          onConfirm: async () => {
            await onRequestLock();

            if (successCb) {
              await successCb();
            }

            modalService.popById('leave-without-saving');
            handleChangeDisplayMode('VIEWER');
          },
          onCancel: () => {
            cancelCb?.();
            modalService.popById('leave-without-saving');
          },
        });
        return;
      }
      return settledCb?.();
    },
    [
      clickedPanelIndex,
      customerTableDisplayMode,
      getPatientsIsLocked,
      patchRequestLockMutation,
      hospitalID,
      handleChangeDisplayMode,
    ],
  );

  return { openLeaveWithoutSavingModal };
};
