import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Loading from 'afterdoc-design-system/assets/lotties/loading.webp';
import type { CSSProperties } from 'react';

export interface BaseLoadingProps {
  width?: number;
  height?: number;
  style?: CSSProperties;
  className?: string;
}

export default function BaseLoading({
  width = 45,
  height = 45,
  style,
  className,
}: BaseLoadingProps) {
  return (
    <div
      className={customTwMerge('flex-center flex-shrink-0 select-none overflow-hidden', className)}
      style={{
        width: SHARED_UTILS.css.getCssSizeValue(width),
        height: SHARED_UTILS.css.getCssSizeValue(height),
        ...style,
      }}>
      <img
        src={Loading}
        alt='loading'
        className='h-full w-full object-contain'
        style={{
          minWidth: SHARED_UTILS.css.getCssSizeValue(width),
          minHeight: SHARED_UTILS.css.getCssSizeValue(height),
        }}
      />
    </div>
  );
}
