import DOMPurify from 'dompurify';

/**
 * 외부 HTML을 에디터 형식에 맞게 정규화하는 함수
 */
function normalizeExternalHTML(doc: Document) {
  // DOMPurify 설정
  const config = {
    ALLOWED_TAGS: [
      'p',
      'ol',
      'ul',
      'li',
      'br', // 기본 구조
      'strong',
      'u', // underline
      'strike',
      's',
      'del', // strike
      'em',
      'i', // italic
      'h1',
      'h2',
      'h3', // headers
      'img', // image
      'blockquote', // blockquote
      'hr', // divider
      'a', // links
    ],
    ALLOWED_ATTR: ['data-list', 'src', 'alt', 'href', 'target', 'rel', 'class'],
    RETURN_DOM: true,
    RETURN_DOM_FRAGMENT: true,
    FORBID_TAGS: ['style', 'script'],
    FORBID_ATTR: ['style', 'class'],
    ADD_TAGS: ['p'],
    FORCE_BODY: true,
    WHOLE_DOCUMENT: true,

    // 커스텀 후처리 훅
    HOOKS: {
      beforeSanitizeElements(node: Element) {
        // Google Docs 스타일 span 처리
        if (node.tagName?.toLowerCase() === 'span') {
          // span 태그가 스타일만 가지고 있고 다른 의미적 마크업이 없는 경우
          const hasOnlyStyle = node
            .getAttributeNames()
            .every((attr) => attr === 'style' || attr.startsWith('data-'));

          if (hasOnlyStyle) {
            // span을 제거하고 내용물만 유지
            const fragment = doc.createDocumentFragment();
            while (node.firstChild) {
              fragment.appendChild(node.firstChild);
            }
            node.parentNode?.replaceChild(fragment, node);
            return fragment;
          }
        }

        // 기본 태그 변환
        const tagMap: Record<string, string> = {
          span: 'p',
          div: 'p',
          article: 'p',
          section: 'p',
          main: 'p',
          aside: 'p',
          i: 'em',
          s: 'strike',
          del: 'strike',
        };

        const newTag = tagMap[node.tagName?.toLowerCase()];
        if (newTag) {
          const newNode = doc.createElement(newTag);
          newNode.innerHTML = node.innerHTML;
          return newNode;
        }

        // 스타일 기반 서식 변환
        if (node instanceof HTMLElement) {
          const style = window.getComputedStyle(node);

          // bold 변환
          if (style.fontWeight === 'bold' || Number.parseInt(style.fontWeight) >= 700) {
            const strong = doc.createElement('strong');
            strong.innerHTML = node.innerHTML;
            return strong;
          }

          // italic 변환
          if (style.fontStyle === 'italic') {
            const em = doc.createElement('em');
            em.innerHTML = node.innerHTML;
            return em;
          }

          // underline 변환
          if (style.textDecoration?.includes('underline')) {
            const u = doc.createElement('u');
            u.innerHTML = node.innerHTML;
            return u;
          }

          // strike 변환
          if (style.textDecoration?.includes('line-through')) {
            const strike = doc.createElement('strike');
            strike.innerHTML = node.innerHTML;
            return strike;
          }
        }

        return node;
      },
      afterSanitizeElements(node: Element) {
        // 리스트 처리
        if (node.tagName?.toLowerCase() === 'li') {
          const parent = node.parentElement;
          if (parent?.tagName?.toLowerCase() === 'ul') {
            node.setAttribute('data-list', 'bullet');
          } else if (parent?.tagName?.toLowerCase() === 'ol') {
            node.setAttribute('data-list', 'ordered');
          }
        }

        // 이미지 처리
        if (node.tagName?.toLowerCase() === 'img') {
          // base64 이미지 처리 또는 외부 이미지 URL 유지
          const src = node.getAttribute('src');
          if (src) {
            node.setAttribute('src', src);
            if (!node.getAttribute('alt')) {
              node.setAttribute('alt', 'image');
            }
          } else {
            node.remove();
            return null;
          }
        }

        // blockquote 처리
        if (node.tagName?.toLowerCase() === 'blockquote') {
          if (!node.textContent?.trim()) {
            node.innerHTML = '<p><br></p>';
          }
        }

        // 빈 태그 처리
        if (!node.textContent?.trim() && !node.querySelector('br, img, hr')) {
          if (node.tagName?.toLowerCase() === 'p') {
            node.innerHTML = '<br>';
          }
        }

        // 연속된 br 태그 처리
        if (node.tagName?.toLowerCase() === 'p') {
          const brs = node.getElementsByTagName('br');
          if (brs.length > 1) {
            node.innerHTML = '<br>';
          }
        }

        return node;
      },
    },
  };
  try {
    // HTML 정규화 처리
    const cleanFragment = DOMPurify.sanitize(doc.body.innerHTML, config);

    // 결과를 다시 document에 적용
    if (typeof cleanFragment === 'string') {
      doc.body.innerHTML = cleanFragment;
    } else {
      doc.body.innerHTML = '';
      doc.body.appendChild(cleanFragment as DocumentFragment);
    }

    // 최종 후처리
    convertListStructure(doc);
    validateDocument(doc);
  } catch (error) {
    console.error('HTML normalization failed:', error);
    doc.body.innerHTML = '<p><br></p>';
  }
}

/**
 * ul/ol 구조를 에디터 형식에 맞게 변환
 */
function convertListStructure(doc: Document) {
  try {
    // ul을 ol로 변환
    const uls = doc.getElementsByTagName('ul');
    while (uls.length > 0) {
      const ul = uls[0];
      const ol = doc.createElement('ol');
      ol.innerHTML = ul.innerHTML;
      const lis = ol.getElementsByTagName('li');
      for (const li of lis) {
        li.setAttribute('data-list', 'bullet');
      }
      ul.parentNode?.replaceChild(ol, ul);
    }

    // ol 내부 li 처리
    const ols = doc.getElementsByTagName('ol');
    for (const ol of ols) {
      const lis = ol.getElementsByTagName('li');
      for (const li of lis) {
        if (!li.hasAttribute('data-list')) {
          li.setAttribute('data-list', 'ordered');
        }
      }
    }
  } catch (error) {
    console.error('List structure conversion failed:', error);
  }
}

/**
 * 문서 구조 유효성 검사
 */
function validateDocument(doc: Document) {
  // 루트 레벨에 텍스트 노드가 있는 경우 p 태그로 감싸기
  const bodyChildNodes = Array.from(doc.body.childNodes);
  for (const node of bodyChildNodes) {
    if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
      const p = doc.createElement('p');
      p.textContent = node.textContent;
      node.parentNode?.replaceChild(p, node);
    }
  }

  // 빈 문서인 경우 기본 p 태그 추가
  if (!doc.body.children.length) {
    doc.body.innerHTML = '<p><br></p>';
  }

  // 마지막 노드가 빈 p 태그가 아닌 경우 추가
  const lastChild = doc.body.lastElementChild;
  if (!lastChild || lastChild.tagName.toLowerCase() !== 'p' || !lastChild.querySelector('br')) {
    const p = doc.createElement('p');
    p.innerHTML = '<br>';
    doc.body.appendChild(p);
  }
}

export default normalizeExternalHTML;
