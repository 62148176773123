import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtomValue } from 'jotai';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

const NO_DATA = 'No data';

const getReservationToday = async (
  params: Parameters<typeof apiClient.v3.apiPatientsElTemporaryV2ReservationToday>[0],
) => {
  const response = await apiClient.v3.apiPatientsElTemporaryV2ReservationToday(params);

  return response;
};

export const useLoadReservationHistory = () => {
  const abortController = useRef<AbortController | null>(null);

  const { hospitalID } = useSelectedHospitalInfo();
  const { getValues, setValue } = useFormContext<{
    rows: CustomerManagementTemporaryAPIFormValues;
  }>();

  const resetState = () => {
    fullDimmedLoadingService.off();
    toastService.close('loading-reservation-history');
  };

  const selectedCell = useAtomValue(selectedCellState);
  const focusedCell = useAtomValue(focusedCellState);

  const getStartingRowIndex = () => {
    // 선택된 셀이나 포커스된 셀의 행 인덱스를 반환
    if (selectedCell.row !== null) return selectedCell.row;
    if (focusedCell.row !== null) return focusedCell.row;
    return 0; // 둘 다 없으면 첫 번째 행부터 시작
  };

  const loadReservationHistory = async (): Promise<void> => {
    try {
      const {
        data: { data },
      } = await getReservationToday({
        hospitalID,
      });

      if (!data) {
        return Promise.reject(new Error(NO_DATA));
      }

      const reservationHistoryData = data.map((item) => ({
        name: item.name,
        phoneNumber: item.phone ? String(item.phone).replace(/[^0-9]/g, '') : null,
        birthdate: item.birthdate,
        chartNumber: item.chartNumber,
        isFirstVisit: item.isFirstVisit,
        gender: item.gender,
      }));

      const startingRowIndex = getStartingRowIndex();
      const existingRows = getValues('rows') || [];

      const filteredExistingRows = existingRows.filter((row) => {
        const { hospitalID, order, ...otherFields } = row;

        // otherFields의 값들 중 하나라도 유효한 데이터가 있는지 확인
        return Object.values(otherFields).some(
          (v) =>
            v !== null &&
            v !== undefined &&
            !(Array.isArray(v) && v.length === 0) &&
            !(typeof v === 'string' && v.trim() === ''),
        );
      });

      // 새로운 데이터 포맷팅 - order 값을 기존 순서 유지
      const updatedData: CustomerManagementTemporaryAPIFormValues = reservationHistoryData?.map(
        ({ name, phoneNumber, birthdate, chartNumber, isFirstVisit, gender }, index) => ({
          // 기존 order 값을 유지하면서 새로운 데이터 추가
          order:
            filteredExistingRows[startingRowIndex + index]?.order || startingRowIndex + index + 1,
          hospitalID,
          patientId: null,
          name,
          phoneNumber,
          birthdate,
          chartNumber,
          gender: gender as 'MALE' | 'FEMALE',
          isFirstVisit,
          nationalityId: null,
          treatmentTagIds: [],
        }),
      );

      // 전체 데이터의 길이 계산 (기존 데이터 + 새로운 데이터)
      const totalLength = Math.max(
        startingRowIndex + updatedData.length,
        filteredExistingRows.length,
      );

      // 50의 배수로 필요한 전체 행 수 계산
      const requiredTotalRows = Math.ceil(totalLength / 50) * 50;

      // 새로운 데이터의 order 값 조정
      const adjustedUpdatedData = updatedData.map((item, index) => ({
        ...item,
        order: startingRowIndex + index + 1,
      }));

      // 기존 데이터를 order 기준으로 정렬하고 빈 공간 채우기
      const sortedExistingRows = [...filteredExistingRows].sort(
        (a, b) => (a.order || 0) - (b.order || 0),
      );

      // 최종 데이터 배열 생성
      const finalData: CustomerManagementTemporaryAPIFormValues = [];

      // 1부터 requiredTotalRows까지 순회하면서 데이터 채우기
      for (let i = 1; i <= requiredTotalRows; i++) {
        // 현재 순서에 해당하는 데이터 찾기
        if (i >= startingRowIndex + 1 && i < startingRowIndex + updatedData.length + 1) {
          // 새로운 데이터 영역
          finalData.push(adjustedUpdatedData[i - startingRowIndex - 1]);
        } else {
          // 기존 데이터나 빈 행
          const existingRow = sortedExistingRows.find((row) => row.order === i);
          if (existingRow) {
            finalData.push(existingRow);
          } else {
            finalData.push({
              order: i,
              hospitalID,
              patientId: null,
              name: null,
              chartNumber: null,
              isFirstVisit: null,
              phoneNumber: null,
              birthdate: null,
              gender: null,
              treatmentTagIds: [],
              nationalityId: null,
            });
          }
        }
      }

      if (finalData.length > 0) {
        setValue('rows', finalData as CustomerManagementTemporaryAPIFormValues);

        return Promise.resolve();
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onLoadReservationHistory = () => {
    const LOADING_DELAY = 300;
    let loadingTimer: NodeJS.Timeout | null = null;

    loadingTimer = setTimeout(() => {
      toastService.loadingMsg({
        id: 'loading-reservation-history',
        text: '예약 내역을 불러오는 중입니다. 잠시만 기다려주세요.',
        rightButton: {
          text: '취소하기',
          onClick: () => {
            if (abortController.current) {
              abortController.current.abort();
            }
            resetState();
          },
        },
      });
    }, LOADING_DELAY);

    loadReservationHistory()
      .then(() => {
        // 먼저 로딩 메시지를 닫고
        if (loadingTimer) {
          clearTimeout(loadingTimer);
        }
        toastService.close('loading-reservation-history');

        // 약간의 딜레이 후 성공 메시지 표시
        setTimeout(() => {
          toastService.successMsg({
            text: '예약 내역을 불러왔습니다.',
          });
        }, 100);
      })
      .catch((error) => {
        console.error('예약 내역 로드 실패:', error);
        if (loadingTimer) {
          clearTimeout(loadingTimer);
        }
        toastService.close('loading-reservation-history');

        setTimeout(() => {
          if (error.message === NO_DATA) {
            toastService.errorMsg({
              text: '불러올 예약 내역이 없습니다.',
            });
          } else {
            toastService.errorMsg({
              text: '예약 내역을 불러오는 중 오류가 발생했습니다.',
            });
          }
        }, 100);
      });
  };

  return {
    onLoadReservationHistory,
  };
};
