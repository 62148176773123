import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useCallback, useEffect, useRef, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { v4 as uuidv4 } from 'uuid';
import useFileDownloadWithProgress from 'web/shared/hooks/files/use-file-download-with-progress';
import { extractFileExtention } from 'web/shared/utils/fileUpload/functions/extract-file-extention';
import { extractFileName } from 'web/shared/utils/fileUpload/functions/extract-file-name';
import ViewerChild from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/Message/components/ViewerChild';
import './ImageViewer.scss';

export interface ImageObject {
  id?: string;
  url: string;
  fileName?: string;
  sentAt?: string;
  name?: string;
  jobType?: string;
}

export interface ImageViewerProps {
  id?: string;
  imageUrls: string[];
  imageObjects?: ImageObject[];
  onClose: (e?: MouseEvent) => void;
  selectedIndex?: number;
  proxyCallback?: (url: string) => Promise<Response>;
}

export default function ImageViewer({
  selectedIndex = 0,
  imageUrls,
  onClose,
  imageObjects,
  proxyCallback,
}: ImageViewerProps) {
  const galleryRef = useRef<ImageGallery>(null);
  const imageViewerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [scale, setScale] = useState(1);
  const [canZoomIn, setCanZoomIn] = useState(true);
  const [canZoomOut, setCanZoomOut] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(selectedIndex); // 현재 인덱스를 상태로 관리

  const { startDownload } = useFileDownloadWithProgress(proxyCallback);

  const handleDownload = () => {
    const currentIndex = galleryRef.current?.getCurrentIndex();

    if (currentIndex === undefined) return;

    const imageUrl = imageUrls[currentIndex];

    const fileName = extractFileName(imageUrl) ?? `image-${uuidv4()}`;
    const fileExtention = extractFileExtention(imageUrl);

    startDownload(imageUrl, fileName, [
      { name: 'Images', extensions: fileExtention ? [fileExtention] : ['jpg', 'png', 'jpeg'] },
    ]);
  };

  const handleZoomIn = (scale: number) => {
    setScale((prevScale) => {
      const newScale = Math.min(prevScale + scale, 2);
      return newScale;
    });
  };

  const handleZoomOut = (scale: number) => {
    setScale((prevScale) => {
      const newScale = Math.max(prevScale - scale, 1);
      return newScale;
    });
  };

  const handleWheel = (event: WheelEvent) => {
    if (event.deltaY < 0) {
      handleZoomIn(0.07);
    } else {
      handleZoomOut(0.07);
    }
  };

  const handlePrev = () => {
    galleryRef.current?.slideToIndex(galleryRef.current.getCurrentIndex() - 1);
  };

  const handleNext = () => {
    galleryRef.current?.slideToIndex(galleryRef.current.getCurrentIndex() + 1);
  };

  useEffect(() => {
    setCanZoomIn(scale < 2);
    setCanZoomOut(scale > 1);
  }, [scale]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('wheel', handleWheel);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('wheel', handleWheel);
    };
  }, [onClose]);

  const handleSlide = useCallback((index: number) => {
    setCurrentIndex(index);
  }, []);

  return (
    <Portal>
      <div className='image-viewer-wrapper' ref={imageViewerRef}>
        <div className='image-viewer-overlay' onClick={(e) => e.stopPropagation()}>
          <div className='image-viewer-content' ref={contentRef}>
            <div className='image-viewer-header'>
              {imageObjects && (
                <ViewerChild
                  key={imageObjects[currentIndex].id}
                  name={imageObjects[currentIndex].name}
                  jobType={imageObjects[currentIndex].jobType}
                  filename={imageObjects[currentIndex].fileName}
                  sentAt={imageObjects[currentIndex].sentAt}
                />
              )}
              <div className='image-viewer-header-icons'>
                <IconButton
                  icon='minus'
                  size={28}
                  onClick={() => handleZoomOut(0.3)}
                  color={canZoomOut ? 'white400' : 'white900'}
                  disabled={!canZoomOut}
                />
                <IconButton
                  icon='plus'
                  size={28}
                  onClick={() => handleZoomIn(0.3)}
                  color={canZoomIn ? 'white400' : 'white900'}
                  disabled={!canZoomIn}
                />
                <IconButton
                  icon='tray-download'
                  size={28}
                  onClick={handleDownload}
                  color='white400'
                />
                <IconButton
                  icon='close'
                  size={28}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose();
                  }}
                  color='white400'
                />
              </div>
            </div>
            <div className='image-viewer-container'>
              <div className='image-gallery-wrapper' style={{ transform: `scale(${scale})` }}>
                <ImageGallery
                  ref={galleryRef}
                  items={imageUrls.map((image) => ({ original: image }))}
                  showThumbnails={false}
                  autoPlay={false}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  startIndex={selectedIndex}
                  disableSwipe={true}
                  disableThumbnailScroll={true}
                  showNav={false}
                  slideDuration={0}
                  onSlide={handleSlide} // 슬라이드 변경 시 현재 인덱스를 업데이트
                />
              </div>
            </div>
            {imageUrls.length > 1 && (
              <>
                <IconButton
                  icon='arrow-left'
                  size={48}
                  color='black600'
                  className='-translate-y-1/2 absolute top-1/2 left-[40px] h-68 w-68 flex-center transform rounded-full bg-white50 bg-opacity-80'
                  onClick={handlePrev}
                />
                <IconButton
                  icon='arrow-right'
                  size={48}
                  color='black600'
                  className='-translate-y-1/2 absolute top-1/2 right-[40px] h-68 w-68 flex-center transform rounded-full bg-white50 bg-opacity-80'
                  onClick={handleNext}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Portal>
  );
}
