import SearchInput from 'afterdoc-design-system/components/Atoms/Input/SearchInput';
import { type KeyboardEvent, useEffect, useState } from 'react';

interface VideoContentSearchInputProps {
  searchKeyword: string;
  setSearchKeyword: React.Dispatch<React.SetStateAction<string>>;
}

export default function VideoContentSearchInput({
  searchKeyword,
  setSearchKeyword,
}: VideoContentSearchInputProps) {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleDelete = () => {
    setInputValue('');
    setSearchKeyword('');
  };

  const handleKeyDown = async (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      setSearchKeyword(inputValue);
    }
  };

  useEffect(() => {
    setInputValue(searchKeyword);
  }, [searchKeyword]);

  return (
    <>
      <SearchInput
        placeholder='영상의 검색키워드나 메인타이틀로 검색해 주세요.'
        className='px-60 py-20'
        value={inputValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onDelete={handleDelete}
      />
    </>
  );
}
