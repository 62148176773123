import * as React from "react";
import type { SVGProps } from "react";
const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#999"
      d="M16 17v2H2v-2s0-4 7-4 7 4 7 4m-3.5-9.5a3.5 3.5 0 1 0-7 0 3.5 3.5 0 0 0 7 0m3.44 5.5A5.32 5.32 0 0 1 18 17v2h4v-2s0-3.63-6.06-4M15 4a3.4 3.4 0 0 0-1.93.59 5 5 0 0 1 0 5.82A3.4 3.4 0 0 0 15 11a3.5 3.5 0 0 0 0-7"
    />
  </svg>
);
export default SvgUsers;
