import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { memo } from 'react';
import { Z_INDEXS } from 'web/templates/CustomerChat/constants/layout';

type ManualToggleButtonProps = {
  isManualOpen: boolean;
  onClick: () => void;
};

export const ManualToggleButton = memo(({ isManualOpen, onClick }: ManualToggleButtonProps) => {
  return (
    <div
      className='absolute top-[50%] left-[-17px] h-53 w-32 flex-center flex-col-center translate-y-1/2 transform cursor-pointer rounded-r10 border border-white600 bg-white50'
      onClick={onClick}
      // 왼쪽 채팅리스트 스크롤 때문에 추가
      style={{ zIndex: Z_INDEXS.ManualToogleButton }}
    >
      <Icon name={isManualOpen ? 'chevron-double-left' : 'chevron-double-right'} size={20} />
      <div className='text-center text-Body10 text-black500'>{isManualOpen ? '닫기' : '열기'}</div>
    </div>
  );
});

ManualToggleButton.displayName = 'ManualToggleButton';
