import { SHARED_UTILS } from '@shared-utils/utils';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiManualsElFoldersParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import {
  checkedLayersState,
  checkedOrphanSubLayersState,
  checkedSubLayersState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/states/selected-layers';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import { useSingleAlgorithmInfo } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-single-algorithm-info';

const textClassName = 'select-none text-Caption9 text-black800';

const getManualFolders = async (params: ApiManualsElFoldersParams) => {
  const response = await apiClient.v3.apiManualsElFolders(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function TreatmentTagSetting() {
  const isFirstRender = useRef(true);
  const tagContainerRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const { setValue, watch } = useFormContext<CounselAutomationAPIFormValues>();

  const { hospitalID } = useSelectedHospitalInfo();

  const algorithmMode = useAtomValue(algorithmModeState);
  const [checkedLayers, setCheckedLayers] = useAtom(checkedLayersState);
  const [checkedSubLayers, setCheckedSubLayers] = useAtom(checkedSubLayersState);
  const [checkedOrphanSubLayers, setCheckedOrphanSubLayers] = useAtom(checkedOrphanSubLayersState);
  const [tagMaxLengths, setTagMaxLengths] = useState<Record<string, number>>({});

  const { handleWithEditWarning } = useSingleAlgorithmInfo();

  const {
    data: { foldersHasTags, unassignedTags },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiManualsElFolders, { hospitalID }] as const,
    queryFn: ({ queryKey }) => getManualFolders(queryKey[1]),
    refetchOnMount: 'always',
  });

  const targetTreatmentTags = watch('targetTreatmentTags');

  const layers = useMemo(
    () =>
      foldersHasTags
        ?.filter((folder) => folder.treatmentTags?.length)
        ?.map((folder) => ({
          id: folder._id,
          title: folder.name,
          items: folder.treatmentTags?.map((tag) => ({
            id: tag._id,
            text: tag.categoryName,
            color: tag.color,
          })),
        })),
    [foldersHasTags],
  );

  const orphanSubLayers = useMemo(
    () =>
      unassignedTags?.map((tag) => ({
        id: tag._id,
        text: tag.categoryName,
        color: tag.color,
      })),
    [unassignedTags],
  );

  const handleLayerChange = (layerId: string) => {
    const updateLayers = () => {
      setCheckedLayers((prev) => {
        const isChecked = !prev[layerId]?.allChecked || false;
        const updated = {
          ...prev,
          [layerId]: {
            allChecked: isChecked,
            someChecked: false,
          },
        };

        const layer = layers?.find((layer) => layer.id === layerId);
        const newCheckedSubLayers = { ...checkedSubLayers };

        if (layer?.items) {
          for (const item of layer.items) {
            newCheckedSubLayers[item.id] = isChecked;
          }
          setCheckedSubLayers(newCheckedSubLayers);
        }

        const newTargetTreatmentTags = Object.keys({
          ...newCheckedSubLayers,
          ...checkedOrphanSubLayers,
        }).filter((key) => newCheckedSubLayers[key] || checkedOrphanSubLayers[key]);
        handleUpdateTargetTreatmentTags(newTargetTreatmentTags);

        return updated;
      });
    };

    handleWithEditWarning(updateLayers);
  };

  const handleSubLayerChange = (layerId: string, subLayerId: string) => {
    const updateSubLayers = () => {
      setCheckedSubLayers((prev) => {
        const isChecked = !prev[subLayerId];
        const updated = { ...prev, [subLayerId]: isChecked };

        const layer = layers?.find((layer) => layer.id === layerId);
        if (layer?.items) {
          const allChecked = layer.items.every((item) => updated[item.id]);
          const someChecked = layer.items.some((item) => updated[item.id]);

          setCheckedLayers((prevLayers) => ({
            ...prevLayers,
            [layerId]: { allChecked, someChecked },
          }));
        }

        const newTargetTreatmentTags = Object.keys({
          ...updated,
          ...checkedOrphanSubLayers,
        }).filter((key) => updated[key] || checkedOrphanSubLayers[key]);
        handleUpdateTargetTreatmentTags(newTargetTreatmentTags);

        return updated;
      });
    };

    handleWithEditWarning(updateSubLayers);
  };

  const handleOrphanSubLayerChange = (orphanSubLayerId: string) => {
    const updateOrphanSubLayers = () => {
      setCheckedOrphanSubLayers((prev) => {
        const updated = { ...prev, [orphanSubLayerId]: !prev[orphanSubLayerId] };

        const newTargetTreatmentTags = Object.keys({ ...checkedSubLayers, ...updated }).filter(
          (key) => checkedSubLayers[key] || updated[key],
        );
        handleUpdateTargetTreatmentTags(newTargetTreatmentTags);

        return updated;
      });
    };

    handleWithEditWarning(updateOrphanSubLayers);
  };

  const handleUpdateTargetTreatmentTags = (newTargetTreatmentTags: string[]) => {
    setValue('targetTreatmentTags', newTargetTreatmentTags, {
      shouldDirty: true,
      shouldTouch: true,
    });
    queryClient.refetchQueries({
      queryKey: [
        QUERY_KEY.targetableNationalitiesHandler,
        {
          hospitalID,
          treatmentTagId: newTargetTreatmentTags.join(','),
        },
      ],
    });
  };

  useEffect(() => {
    if (algorithmMode.mode === 'CREATE' || !targetTreatmentTags?.length) return;

    if (isFirstRender.current) {
      isFirstRender.current = false;
      setCheckedLayers((prev) => {
        return (layers ?? []).reduce(
          (acc, layer) => {
            const allChecked =
              layer.items?.every((item) => targetTreatmentTags.includes(item.id)) ?? false;
            const someChecked =
              layer.items?.some((item) => targetTreatmentTags.includes(item.id)) ?? false;
            acc[layer.id] = { allChecked, someChecked };
            return acc;
          },
          { ...prev }, // 기존 상태 유지
        );
      });

      setCheckedSubLayers((prev) => {
        const updated = { ...prev };
        for (const layer of layers ?? []) {
          for (const item of layer.items ?? []) {
            updated[item.id] = targetTreatmentTags.includes(item.id);
          }
        }
        return updated;
      });

      setCheckedOrphanSubLayers((prev) => {
        const updated = { ...prev };
        for (const item of orphanSubLayers ?? []) {
          updated[item.id] = targetTreatmentTags.includes(item.id);
        }
        return updated;
      });
    }
  }, [algorithmMode.mode, targetTreatmentTags]);

  useEffect(() => {
    if (!tagContainerRef.current) return;

    const measureDiv = document.createElement('div');
    measureDiv.style.position = 'absolute';
    measureDiv.style.visibility = 'hidden';
    measureDiv.style.whiteSpace = 'nowrap';
    measureDiv.style.fontSize = '12px';
    measureDiv.style.fontFamily = 'inherit';
    measureDiv.style.padding = '2px 8px';
    measureDiv.style.boxSizing = 'border-box';
    document.body.appendChild(measureDiv);

    const containerWidth = tagContainerRef.current.clientWidth;
    const maxTagWidth = containerWidth - 120;

    const calculateMaxLengths: Record<string, number> = {};

    const processTag = (tag: { id: string; text: string }) => {
      measureDiv.innerText = tag.text;
      let maxTextLength = tag.text.length;
      let tagWidth = measureDiv.getBoundingClientRect().width;

      const adjustedMaxWidth = maxTagWidth * 1.5;

      if (tagWidth > adjustedMaxWidth) {
        while (tagWidth > adjustedMaxWidth && maxTextLength > 3) {
          maxTextLength--;
          measureDiv.innerText = `${tag.text.slice(0, maxTextLength)}...`;
          tagWidth = measureDiv.getBoundingClientRect().width;
        }
      }
      calculateMaxLengths[tag.id] = maxTextLength;
    };

    for (const layer of layers ?? []) {
      for (const item of layer.items ?? []) {
        processTag(item);
      }
    }

    for (const orphanSubLayer of orphanSubLayers ?? []) {
      processTag(orphanSubLayer);
    }

    document.body.removeChild(measureDiv);
    setTagMaxLengths(calculateMaxLengths);
  }, [layers, orphanSubLayers]);

  return (
    <div className='mt-10 grid w-full'>
      <div className='flex w-full flex-col gap-4'>
        <LabelText isRequired textClassName='text-Body10Bold'>
          치료태그
        </LabelText>
        <div className='h-[250px] rounded-r10 border border-black200 bg-white50 py-4 pr-4 pl-16'>
          <Scrollbar disabledX>
            <div ref={tagContainerRef} className='flex flex-col gap-10 py-12'>
              {layers?.map((layer) => {
                if (!layer || !layer.items) return null;

                return (
                  <div key={layer.id} className='flex flex-col gap-10'>
                    <div className='flex items-center gap-7'>
                      <Checkbox
                        size={20}
                        checked={checkedLayers[layer.id]?.allChecked || false}
                        someChecked={checkedLayers[layer.id]?.someChecked || false}
                        onChange={() => {
                          handleLayerChange(layer.id);
                        }}
                        label={
                          <div className={textClassName}>
                            {layer.title.length > 0 ? layer.title : '이름없음'}
                          </div>
                        }
                      />
                    </div>
                    {layer.items.length > 0 && (
                      <div className='flex flex-col gap-10'>
                        {layer.items.map((subLayer) => (
                          <div className='ml-28 flex items-center gap-10' key={subLayer.id}>
                            <Checkbox
                              size={20}
                              checked={checkedSubLayers[subLayer.id] || false}
                              onChange={() => {
                                handleSubLayerChange(layer.id, subLayer.id);
                              }}
                              label={
                                <FilledTag
                                  maxTextLength={tagMaxLengths[subLayer.id]}
                                  className='cursor-pointer'
                                  tagSize='small'
                                  bgColor={subLayer.color}>
                                  {subLayer.text.length > 0 ? subLayer.text : '이름없음'}
                                </FilledTag>
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
              {orphanSubLayers?.map((orphanSubLayer) => (
                <div className='flex items-center gap-10' key={orphanSubLayer.id}>
                  <Checkbox
                    size={20}
                    checked={checkedOrphanSubLayers[orphanSubLayer.id] || false}
                    onChange={() => {
                      handleOrphanSubLayerChange(orphanSubLayer.id);
                    }}
                    label={
                      <FilledTag
                        maxTextLength={tagMaxLengths[orphanSubLayer.id]}
                        tagSize='small'
                        className='cursor-pointer'
                        bgColor={orphanSubLayer.color}>
                        {orphanSubLayer.text.length > 0 ? orphanSubLayer.text : '이름없음'}
                      </FilledTag>
                    }
                  />
                </div>
              ))}
            </div>
          </Scrollbar>
        </div>
      </div>
    </div>
  );
}
