import AdminChatMenu from '@shared/components/NavigationBar/components/AdminChatMenu';
import CustomerSupportMenu from '@shared/components/NavigationBar/components/CustomerSupportMenu';
import HospitalProfileMenu from '@shared/components/NavigationBar/components/HospitalProfileMenu';
import ServiceNoticeMenu from '@shared/components/NavigationBar/components/ServiceNoticeMenu';
import { useDeprecatedRoutingHandle } from '@shared/components/NavigationBar/hooks/use-deprecated-routing-handle';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useAtomValue } from 'jotai';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWindowBadgeCount } from 'web/hooks/use-window-badge-count';
import {
  MENU_ITEMS,
  type MenuItemType,
  ROUTES_PATH,
} from 'web/shared/components/NavigationBar/constants/menu-items';
import { isServiceManagerNavigatorVisibleState } from 'web/shared/states/is-service-manager-navigator-visible';
import { isUnBlockedState } from 'web/shared/states/is-there-some-thing-to-save';
import { useCustomerChatUnreadCount } from 'web/templates/CustomerChat/components/ChattingList/hooks/use-customer-chat-unread-count';
import MenuItem from './components/MenuItem';

const mode = import.meta.env.MODE;
const useTestPage = import.meta.env.VITE_ENABLE_TEST_PAGE_ONLY_DEV === 'true';
const getVersion = () => {
  if (mode === 'dev') {
    return `${import.meta.env.VITE_DEV_APP_VERSION || ''}.${import.meta.env.MODE}`;
  }

  if (mode === 'demo') {
    return `${import.meta.env.VITE_DEMO_APP_VERSION || ''}.${import.meta.env.MODE}`;
  }

  if (mode === 'prod') {
    return `${import.meta.env.VITE_APP_VERSION || ''}`;
  }
};

export default function NavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { usingSaaSReservation } = useSelectedHospitalInfo();
  const { deprecatedRoutingHandle, openElectronPage } = useDeprecatedRoutingHandle();

  useWindowBadgeCount();
  // 고객상담 뱃지 관리를 위한 커스텀훅 네비게이션바에서도 사용함에 따라 더 상위 계층인 App.container 에서 호출하여 여러곳에서 호출됨을 방지
  useCustomerChatUnreadCount();

  const prevLocation = useRef(location.pathname);

  const isUnBlocked = useAtomValue(isUnBlockedState);
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const [activatedKey, setActivatedKey] = useState<(typeof MENU_ITEMS)[number]['key']>(
    ROUTES_PATH.CUSTOMER_CHAT,
  );
  const [menuItems, setMenuItems] = useState(MENU_ITEMS);

  const memorizedMenuItems = useMemo(() => {
    if (usingSaaSReservation) return menuItems;
    return menuItems.reduce((acc, item) => {
      if (item.key === ROUTES_PATH.RESERVATION_MANAGEMENT) {
        return acc;
      }
      acc.push(item);
      return acc;
    }, [] as MenuItemType[]);
  }, [menuItems, usingSaaSReservation]);

  const hospitalSettingMenuItem = menuItems.find(
    (item) => item.key === ROUTES_PATH.HOSPITAL_SETTING,
  );

  const handleClick = (key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH]) => {
    const currentPath = location.pathname;
    const nextPath = key;

    if (
      currentPath === ROUTES_PATH.HOSPITAL_SETTING ||
      currentPath === ROUTES_PATH.CUSTOMER_CHAT ||
      currentPath === ROUTES_PATH.CONTENT ||
      currentPath === ROUTES_PATH.ADMIN_CHAT ||
      currentPath === ROUTES_PATH.RESERVATION_MANAGEMENT
    ) {
      // TODO: 추후 삭제
      deprecatedRoutingHandle(key, currentPath, nextPath);
    } else {
      const isElectronPage = openElectronPage(nextPath);
      if (!isElectronPage) {
        navigate(key);
      }
    }
  };

  useLayoutEffect(() => {
    if (mode !== 'dev') {
      // dev 모드가 아닐 때는 isLocal이 false인 항목만 표시
      setMenuItems(MENU_ITEMS.filter((item) => !item.isLocal));
    }
    // dev 모드이면서 VITE_ENABLE_TEST_PAGE_ONLY_DEV가 true가 아닐 때도 isLocal이 false인 항목만 표시
    else if (!useTestPage) {
      setMenuItems(MENU_ITEMS.filter((item) => !item.isLocal));
    }
    // dev 모드이면서 VITE_ENABLE_TEST_PAGE_ONLY_DEV가 true일 때는 모든 메뉴 표시 (필터링 없음)
    else {
      setMenuItems(MENU_ITEMS);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      setActivatedKey(ROUTES_PATH.CUSTOMER_CHAT);
    } else {
      setActivatedKey(location.pathname as (typeof MENU_ITEMS)[number]['key']);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      isUnBlocked &&
      prevLocation.current !== ROUTES_PATH.HOSPITAL_SETTING &&
      prevLocation.current !== ROUTES_PATH.CUSTOMER_CHAT &&
      prevLocation.current !== ROUTES_PATH.CONTENT
    ) {
      overlayPageService.popAll();
      modalService.popAll();
      dialogService.popAll();
    }

    prevLocation.current = location.pathname;
  }, [activatedKey]);

  return (
    <div
      className={`fixed top-0 left-0 z-50 flex h-full w-70 flex-col border-r border-r-white400 bg-white50 ${isServiceManagerNavigatorVisible ? 'ml-70' : 'ml-0'}`}>
      {/* 상단 메뉴 */}
      <div className='flex flex-col'>
        <ServiceNoticeMenu />
      </div>
      {/* 중간 메뉴 (스크롤 가능) */}
      <div
        className={customTwMerge(
          'scrollbar-hide flex flex-grow select-none flex-col justify-between overflow-y-auto',
        )}>
        <div className='flex flex-col'>
          <CustomerSupportMenu
            item={
              memorizedMenuItems.find((item) => item.key === ROUTES_PATH.CUSTOMER_CHAT) ??
              memorizedMenuItems[0]
            }
            activatedKey={activatedKey}
            onHandleClick={handleClick}
          />
          {memorizedMenuItems
            .filter(
              (item) =>
                item.key !== ROUTES_PATH.ADMIN_CHAT &&
                item.key !== ROUTES_PATH.CUSTOMER_CHAT &&
                item.key !== ROUTES_PATH.HOSPITAL_SETTING,
            )
            .map((item) => (
              <MenuItem
                key={item.key}
                item={item}
                isActivated={activatedKey === item.key}
                onHandleClick={handleClick}
              />
            ))}
          <AdminChatMenu
            item={
              memorizedMenuItems.find((item) => item.key === ROUTES_PATH.ADMIN_CHAT) ??
              memorizedMenuItems[0]
            }
            activatedKey={activatedKey}
            onHandleClick={handleClick}
          />
        </div>
      </div>
      {/* 하단 메뉴 */}
      <div className='flex flex-col'>
        <div className='my-10 text-center text-Body12 text-black100'>{getVersion()}</div>
        {hospitalSettingMenuItem && (
          <MenuItem
            item={hospitalSettingMenuItem}
            isActivated={activatedKey === hospitalSettingMenuItem.key}
            onHandleClick={handleClick}
          />
        )}
        <HospitalProfileMenu />
      </div>
    </div>
  );
}
