import { selectedContentEditPriceErrorAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useSetAtom } from 'jotai/index';
import { type ChangeEvent, useEffect, useState } from 'react';

interface SalePriceInputProps {
  price: number;
  salePrice: number;
  saleRate: number;
  saleType: string;
  onHandleSalePriceValue: (value: number) => void;
  onHandleSaleRateValue: (value: number) => void;
  onHandleSaleTypeValue: (value: string) => void;
}

export default function SalePriceInput({
  price,
  salePrice,
  saleRate,
  saleType,
  onHandleSalePriceValue,
  onHandleSaleRateValue,
  onHandleSaleTypeValue,
}: SalePriceInputProps) {
  const setPriceInputError = useSetAtom(selectedContentEditPriceErrorAtom);

  const [inputValue, setInputValue] = useState('');

  const handleSaleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, '');
    const parsedValue = Number.parseInt(inputValue, 10);

    if (!price || Number.isNaN(parsedValue)) {
      setInputValue('');
      if (saleType === 'rate') {
        onHandleSaleRateValue(0);
      } else {
        onHandleSalePriceValue(0);
      }
      return;
    }

    const value =
      saleType === 'rate'
        ? Math.min(parsedValue, 100)
        : Math.min(parsedValue, 1_000_000_000, price);

    setInputValue(saleType === 'rate' ? value.toString() : value.toLocaleString());
    saleType === 'rate' ? onHandleSaleRateValue(value) : onHandleSalePriceValue(value);
  };

  useEffect(() => {
    setPriceInputError(undefined);
    const initialValue = saleType === 'rate' ? saleRate : salePrice;
    setInputValue(initialValue === 0 ? '' : initialValue.toLocaleString());
  }, [salePrice, saleRate, saleType]);

  return (
    <div className='flex items-center gap-10 px-10'>
      <div className='flex flex-col'>
        <TextInput
          className='h-full'
          placeholder='0'
          inputClassName='w-[120px]'
          value={inputValue}
          onChange={handleSaleValueChange}
          label={{
            children: saleType === 'rate' ? '할인율' : '할인금액',
            position: 'horizontal',
            width: 45,
            textClassName: 'text-Body12 text-black200 font-regular',
          }}
        />
      </div>
      <TextFieldSelectBoxDropdown
        placeholder='선택'
        options={['원', '%']}
        onSelect={(index) => onHandleSaleTypeValue(index === 0 ? 'price' : 'rate')}
        selectedIndex={saleType === 'price' ? 0 : 1}
        wrapperClassName='w-[100px]'
      />
    </div>
  );
}
