import { useQuery } from '@tanstack/react-query';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useAtom } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { SingleAlgorithmHandlerParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { algorithmModeState } from '../containers/CounselMessageSetting/states/algorithm-mode';

const fetchSingleAlgorithmInfo = async (params: SingleAlgorithmHandlerParams) => {
  const response = await apiClient.v3.singleAlgorithmHandler(params);
  return response.data.data;
};

export function useSingleAlgorithmInfo() {
  const [algorithmMode, setAlgorithmMode] = useAtom(algorithmModeState);
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: singleAlgorithmInfo } = useQuery({
    queryKey: [
      QUERY_KEY.singleAlgorithmHandler,
      {
        algorithmId: algorithmMode.mode !== 'CREATE' ? algorithmMode.algorithmId : undefined,
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => {
      const algorithmId = queryKey[1].algorithmId;
      if (!algorithmId) return null;

      return fetchSingleAlgorithmInfo({
        algorithmId,
        hospitalID,
      });
    },
    enabled: algorithmMode.mode !== 'CREATE' && !!algorithmMode.algorithmId,
  });

  const handleWithEditWarning = (updateFn: () => void) => {
    if (algorithmMode.mode === 'EDIT') {
      modalService.defaultWarning({
        id: 'target-edit-warning',
        title: '대상자를 수정할까요?',
        contents: (
          <span className='text-Body12 text-black500'>
            <span className='text-red500'>
              {singleAlgorithmInfo?.targetCount?.toLocaleString() ?? 0}
            </span>
            명의 고객에게 메시지 발송을 중단하고 현재까지 기록된 통계가 사라집니다. 이후 치료태그를
            새로 입력한 고객들에게 적용됩니다.
          </span>
        ),
        onConfirm: () => {
          modalService.popById('target-edit-warning');
          setAlgorithmMode({ mode: 'TARGET_EDITED', algorithmId: algorithmMode.algorithmId });
          updateFn();
        },
      });
      return;
    }

    updateFn();
  };

  return {
    singleAlgorithmInfo,
    handleWithEditWarning,
  };
}
