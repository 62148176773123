import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useChatRoomInfoOne } from '../components/ChattingRoom/hooks/use-chatroom-info-one';
import { channelClosedStateSwitch } from '../functions/channel-closed-state-switch';
import { useChatTopPatient } from '../hooks/use-chat-top-patient';
import { chatRoomIDSelector, roomTypeSelector } from '../states/selected-chat-room';

export const useIsDisabledChat = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const roomType = useAtomValue(roomTypeSelector);

  const { ikakaoInfo } = useChatRoomInfoOne({ chatRoomID: chatRoomID ?? '' });
  const { iconSet } = useChatTopPatient();

  const [isDisabledSendingBottom, setIsDisabledSendingBottom] = useState(false);

  const isClosedChannelChat = channelClosedStateSwitch({ roomType, ikakaoInfo });

  useEffect(() => {
    const isDisabledSendingBottom =
      isClosedChannelChat || (iconSet?.isGrayAfterDocIcon && !iconSet?.isKakaoIcon);

    setIsDisabledSendingBottom(isDisabledSendingBottom);
  }, [isClosedChannelChat, iconSet?.isGrayAfterDocIcon, iconSet?.isKakaoIcon]);

  return isDisabledSendingBottom;
};
