import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { Suspense } from 'react';
import AccumulatedConsultationAutomationTargetsCardItem from 'web/templates/Automation/containers/Main/DashBoardContent/components/DashBoardCardList/components/AccumulatedConsultationAutomationTargetsCardItem';
import AutomatedConsultationCountCardItem from 'web/templates/Automation/containers/Main/DashBoardContent/components/DashBoardCardList/components/AutomatedConsultationCountCardItem';
import UnAnsweredAndResponseTimeCardItem from 'web/templates/Automation/containers/Main/DashBoardContent/components/DashBoardCardList/components/UnAnsweredAndResponseTimeCardItem';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';

export default function DashBoardCardList() {
  return (
    <div className='flex flex-col gap-10'>
      <div className='flex flex-col gap-10 rounded-r16 bg-white400 p-10'>
        <div className='flex flex-col'>
          <Suspense
            fallback={<CardItemLayout className='min-h-[192px] animate-pulse bg-white500' />}>
            <AccumulatedConsultationAutomationTargetsCardItem />
          </Suspense>
          <Divider type={'line'} className='my-10' />
          <Suspense
            fallback={<CardItemLayout className='min-h-[192px] animate-pulse bg-white500' />}>
            <AutomatedConsultationCountCardItem />
          </Suspense>
        </div>
      </div>
      <div className='rounded-r16 bg-[#E5EEF8] p-10'>
        <Suspense fallback={<CardItemLayout className='min-h-[192px] animate-pulse bg-white500' />}>
          <UnAnsweredAndResponseTimeCardItem />
        </Suspense>
      </div>
    </div>
  );
}
