import type { AuthorizationType } from '@apis/swaggers/swagger-docs';
import FullLoading from '@shared/components/FullLoading/FullLoading';
import { useUserInfo } from '@shared/hooks/use-user-info';
import HospitalSettingSaveFooter from '@templates/HospitalSetting/components/HospitalSettingSaveFooter';
import { HOSPITAL_SETTING_PAGE } from '@templates/HospitalSetting/constants/page';
import AccessDenied from '@templates/HospitalSetting/containers/AccessDenied/AccessDenied';
import AllAccountSettingContainer from '@templates/HospitalSetting/containers/AllAccountSetting/AllAccountSetting.container';
import AuthorizationContainer from '@templates/HospitalSetting/containers/AuthorizationSetting/Authorization.container';
import ChannelIntegrationContainer from '@templates/HospitalSetting/containers/ChannelIntegration/ChannelIntegration.container';
import CustomerMessageSendContainer from '@templates/HospitalSetting/containers/CustomerMessageSend/CustomerMessageSend.container';
import HospitalProfileContainer from '@templates/HospitalSetting/containers/HospitalProfile/HospitalProfile.container';
import ServiceSettingContainer from '@templates/HospitalSetting/containers/ServiceSetting/ServiceSetting.container';
import { HospitalInfoInputErrorMessagesState } from '@templates/HospitalSetting/states/hospital-info-input-error-messages-state';
import { hospitalSettingPageState } from '@templates/HospitalSetting/states/hospital-setting-page-state';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import type React from 'react';
import { Suspense, useEffect } from 'react';

export type PageState = (typeof HOSPITAL_SETTING_PAGE)[keyof typeof HOSPITAL_SETTING_PAGE];

interface PageConfig {
  component: React.ComponentType;
  canAccess: (auth: AuthorizationType | undefined) => boolean | undefined;
  showFooter: boolean;
}

const pageConfigMap: Record<PageState, PageConfig> = {
  [HOSPITAL_SETTING_PAGE.HOSPITAL_PROFILE]: {
    component: HospitalProfileContainer,
    canAccess: (auth) => auth?.canAccessHospitalInformation,
    showFooter: true,
  },
  [HOSPITAL_SETTING_PAGE.CUSTOMER_MESSAGE_SEND]: {
    component: CustomerMessageSendContainer,
    canAccess: (auth) => auth?.canAccessHospitalInformation,
    showFooter: true,
  },
  [HOSPITAL_SETTING_PAGE.SERVICE_SETTING]: {
    component: ServiceSettingContainer,
    canAccess: (auth) => auth?.canAccessHospitalService,
    showFooter: true,
  },
  [HOSPITAL_SETTING_PAGE.CHANNEL_INTEGRATION]: {
    component: ChannelIntegrationContainer,
    canAccess: (auth) => auth?.canAccessHospitalService,
    showFooter: false,
  },
  [HOSPITAL_SETTING_PAGE.ACCOUNT_SETTING]: {
    component: AllAccountSettingContainer,
    canAccess: (auth) => auth?.canAccessHospitalAccount,
    showFooter: false,
  },
  [HOSPITAL_SETTING_PAGE.PERMISSION_SETTING]: {
    component: AuthorizationContainer,
    canAccess: (auth) => auth?.canAccessHospitalAccount,
    showFooter: false,
  },
};

const RenderPageComponent = (currentPage: PageState): React.ComponentType => {
  return pageConfigMap[currentPage].component;
};

const checkAccessPermission = (
  authorizationTypeID: AuthorizationType | undefined,
  currentPage: PageState,
): boolean => {
  return pageConfigMap[currentPage].canAccess(authorizationTypeID) ?? false;
};

const visibleFooter = (currentPage: PageState) => {
  return pageConfigMap[currentPage].showFooter;
};

export default function WrapperPageContainer() {
  const { authorizationTypeID } = useUserInfo();

  const resetHospitalSettingPage = useResetAtom(hospitalSettingPageState);
  const resetHospitalInfoInputErrorMessages = useResetAtom(HospitalInfoInputErrorMessagesState);

  const currentPage = useAtomValue(hospitalSettingPageState);

  const ComponentToRender = RenderPageComponent(currentPage);

  const isAccessGranted = checkAccessPermission(authorizationTypeID, currentPage);
  const isFooterVisible = visibleFooter(currentPage);

  useEffect(() => {
    resetHospitalInfoInputErrorMessages();
  }, [currentPage]);

  useEffect(() => {
    return () => resetHospitalSettingPage();
  }, []);

  return (
    <div className={`${isFooterVisible && isAccessGranted ? 'mb-70' : ''} bg-blueLight`}>
      <Suspense fallback={<FullLoading />}>
        <Scrollbar disabledX={true}>
          <div className='mx-auto h-full w-[1000px] bg-white50'>
            {isAccessGranted ? <ComponentToRender /> : <AccessDenied />}
          </div>
        </Scrollbar>
      </Suspense>
      {isFooterVisible && isAccessGranted && <HospitalSettingSaveFooter />}
    </div>
  );
}
