import { navigationBarUnreadCountState } from '@jotai/navigation-bar-unread-count-state';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

export const useWindowBadgeCount = () => {
  const navigationBarUnreadCount = useAtomValue(navigationBarUnreadCountState);

  const [totalCount, setTotalCount] = useState<number | null>(null);

  useEffect(() => {
    const { serviceNotice, adminChat, customerChat } = navigationBarUnreadCount;

    const totalCount = serviceNotice + adminChat + customerChat;
    setTotalCount(totalCount);
  }, [navigationBarUnreadCount]);

  useEffect(() => {
    if (totalCount !== null) {
      window.electron?.ipcRenderer.send('Application.Set.badgeCount', totalCount);
    }
  }, [totalCount]);
};
