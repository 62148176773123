import { useClickOutside } from '@shared-hooks/use-click-outside';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, memo, useRef, useState } from 'react';
import {
  UNTAGGED_TABLE_HEADERS,
  UNTAGGED_TABLE_HEADERS_WITH_FILTER,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/components/UnTaggedCustomerManagementEditableTableHeader/constants/table';
import {
  type UnTaggedHeaderFilterState,
  unTaggedHeaderFilterState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';
import GenderFilter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/GenderFilter';
import IsFirstVisitFilter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/IsFirstVisitFilter';
import NationalityFilter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/NationalityFilter';
import TreatmentTagFilter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/TreatmentTagFilter';

const convertTableHeaderKRToEN = (
  header: (typeof UNTAGGED_TABLE_HEADERS_WITH_FILTER)[number],
): keyof UnTaggedHeaderFilterState => {
  switch (header) {
    case '초/재진':
      return 'isFirstVisit';
    case '성별':
      return 'gender';
    case '치료태그':
      return 'treatmentCategories';
    case '국적':
      return 'nationality';
  }
};

function UnTaggedCustomerManagementEditableTableHeader() {
  const unTaggedHeaderFilter = useAtomValue(unTaggedHeaderFilterState);

  const [selectedFilter, setSelectedFilter] = useState<
    (typeof UNTAGGED_TABLE_HEADERS_WITH_FILTER)[number] | null
  >(null);

  return (
    <>
      <colgroup>
        <col className='w-[320px]' />
        <col className='w-[140px]' />
        <col className='w-[120px]' />
        <col className='w-[130px]' />
        <col className='w-[130px]' />
        <col className='w-[90px]' />
        <col className='min-w-[178px]' />
        <col className='w-[160px]' />
      </colgroup>
      <thead className='sticky top-0 left-0 z-[1]'>
        <tr>
          {UNTAGGED_TABLE_HEADERS.map((header) => {
            const hasFilter = UNTAGGED_TABLE_HEADERS_WITH_FILTER.some((filterHeader) =>
              header.includes(filterHeader),
            );
            const filterHasValue =
              unTaggedHeaderFilter[
                convertTableHeaderKRToEN(
                  UNTAGGED_TABLE_HEADERS_WITH_FILTER.find((filterHeader) =>
                    header.includes(filterHeader),
                  ) as (typeof UNTAGGED_TABLE_HEADERS_WITH_FILTER)[number],
                )
              ] !== undefined;

            return (
              <th
                key={header}
                className='whitespace-nowrap border-line-table border-r bg-table px-12 py-10 text-left text-Header14 text-black700'>
                <div
                  className={customTwMerge(
                    hasFilter ? 'flex w-full items-center justify-between' : '',
                  )}>
                  {header}
                  {hasFilter && (
                    <div className='relative'>
                      <IconButton
                        icon='filter-outline'
                        color={filterHasValue ? 'blue500' : 'black200'}
                        size={20}
                        onClick={() =>
                          setSelectedFilter(
                            header as (typeof UNTAGGED_TABLE_HEADERS_WITH_FILTER)[number],
                          )
                        }
                      />
                      {selectedFilter === header && (
                        <Suspense>
                          <Filter
                            selectedFilter={selectedFilter}
                            onChangeSelectedFilter={setSelectedFilter}
                          />
                        </Suspense>
                      )}
                    </div>
                  )}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    </>
  );
}

const Filter = ({
  selectedFilter,
  onChangeSelectedFilter,
}: {
  selectedFilter: (typeof UNTAGGED_TABLE_HEADERS_WITH_FILTER)[number];
  onChangeSelectedFilter?: (
    filter: (typeof UNTAGGED_TABLE_HEADERS_WITH_FILTER)[number] | null,
  ) => void;
}) => {
  const [unTaggedHeaderFilter, setUnTaggedHeaderFilter] = useAtom(unTaggedHeaderFilterState);
  const filterRef = useRef<HTMLDivElement>(null);

  const handleIsFirstVisitFilterChange = (value: boolean | 'all' | undefined) => {
    setUnTaggedHeaderFilter((prev) => ({
      ...prev,
      isFirstVisit: value,
    }));
  };

  const handleGenderFilterChange = (value: 'MALE' | 'FEMALE' | 'all' | undefined) => {
    setUnTaggedHeaderFilter((prev) => ({
      ...prev,
      gender: value,
    }));
  };

  const handleTreatmentTagFilterChange = (value: string | undefined) => {
    setUnTaggedHeaderFilter((prev) => ({
      ...prev,
      treatmentCategories: value,
    }));
  };

  const handleNationalityFilterChange = (value: string | undefined) => {
    setUnTaggedHeaderFilter((prev) => ({
      ...prev,
      nationality: value,
    }));
  };

  useClickOutside({
    id: `${selectedFilter}-filter`,
    ref: filterRef,
    contentRef: filterRef,
    onClose: () => {
      onChangeSelectedFilter?.(null);
    },
  });

  const renderFilter = () => {
    if (selectedFilter === '초/재진') {
      return (
        <IsFirstVisitFilter
          value={unTaggedHeaderFilter.isFirstVisit}
          ref={filterRef}
          onValueChange={handleIsFirstVisitFilterChange}
        />
      );
    }

    if (selectedFilter === '성별') {
      return (
        <GenderFilter
          value={unTaggedHeaderFilter.gender}
          ref={filterRef}
          onValueChange={handleGenderFilterChange}
        />
      );
    }

    if (selectedFilter === '치료태그') {
      return (
        <TreatmentTagFilter
          value={unTaggedHeaderFilter.treatmentCategories}
          ref={filterRef}
          onValueChange={handleTreatmentTagFilterChange}
        />
      );
    }

    if (selectedFilter === '국적') {
      return (
        <NationalityFilter
          value={unTaggedHeaderFilter.nationality}
          ref={filterRef}
          onValueChange={handleNationalityFilterChange}
        />
      );
    }
    return null;
  };

  return <>{renderFilter()}</>;
};

export default memo(UnTaggedCustomerManagementEditableTableHeader);
