import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { useIsDisabledChat } from 'web/templates/CustomerChat/hooks/use-is-disabled-chat';
import {
  type ChatRoomID,
  chatRoomIDSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import type { ChattingResponse, TextChattingResponse } from 'web/templates/CustomerChat/types';
import { isActivatingChatTranslateState } from '../../../../../states/is-activating-chat-translate';
import { REQUEST_ROOM_TYPE_MAP } from '../../../../ChattingSending/constants/types-codes';
import { chattingRequestState } from '../../../states/chatting-request';
import { isSendingMessagesState } from '../../../states/isSendingMessages';
import type {
  MessageType,
  PayloadTypeByMessageType,
} from '../components/ChattingMessage/types/message';
import { createSocketConnection } from '../functions/createSocketConnection';
import { withLogging } from '../functions/withLogging';
import { isSendingTranslateState } from '../states/is-sending-translate';

type WsParams = {
  enableListening?: boolean;
};

export const useHandleChatSocket = ({ enableListening = true }: WsParams = {}) => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const req = useAtomValue(chattingRequestState);
  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);
  const setIsSendingTranslate = useSetAtom(isSendingTranslateState);
  const setIsSendingMessages = useSetAtom(isSendingMessagesState);
  const resetReq = useResetAtom(chattingRequestState);

  const { userId: userID } = useUserInfo();
  const isDisabledSendingBottom = useIsDisabledChat();

  const [res, setRes] = useState<ChattingResponse | TextChattingResponse | null>(null);
  const [resQueue, setResQueue] = useState<Array<ChattingResponse | TextChattingResponse>>([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const socketRef = useRef<Socket | null>(null);
  //최초 null에서 chatRoomID로 변경되는 시점에 socket의 연결이 finished되기 전에 한번더 cleanup 하는 케이스 방어
  const previousChatRoomID = useRef<ChatRoomID | null>(null);

  const isDisabled = userID === '' || chatRoomID === null || isDisabledSendingBottom;

  //이 부분은 비동기로 res가 한번 데이터가 바뀌기만 하면 되는 부분
  useEffect(() => {
    if (res) {
      setRes(null);
      resetReq(); //이걸 넣으면 로딩이 안사라짐.
    }
  }, [res]);

  // 큐를 useEffect로 반복문을 인위적으로 만들어 처리
  useEffect(() => {
    if (resQueue.length === 0 || isProcessing) return;

    const processNextMessage = async () => {
      setIsProcessing(true);
      const nextRes = resQueue[0]; // 큐의 첫 번째 메시지를 가져옴
      setRes(nextRes); // 현재 처리 중인 메시지를 상태로 저장
      setResQueue((prevQueue) => prevQueue.slice(1)); // 첫 번째 메시지를 큐에서 제거

      // 처리 로직
      const isIsTranslatedKey = nextRes.isTranslated ?? false;
      const isSent = nextRes.isSent ?? false;
      // const isLoginUser = nextRes.userID === userID;

      if (isIsTranslatedKey) {
        setIsSendingTranslate(false);
      }

      if (isSent) {
        setIsSendingMessages(false);
      }

      // 완료 후 처리 상태 초기화
      setIsProcessing(false);
    };

    processNextMessage();
  }, [resQueue, isProcessing]);

  useEffect(() => {
    if (isDisabled) return;

    if (socketRef.current === null) {
      socketRef.current = createSocketConnection({ userID, chatRoomID });
    }

    if (!enableListening) return;

    const handleReceivedMessage = async (event: ChattingResponse) => {
      // const isSent = event.isSent ?? false;
      if (event.chatRoomID !== chatRoomID) return; // 현재 채팅방과 다른 채팅방에서 온 메시지는 무시

      //서버에서 res로 보낼 목적이 분명한 res를 받았을 때만 처리
      // if (isSent) {
      setResQueue((prev) => [...prev, event]); // 큐에 메시지를 추가
      return;
      // }
    };

    if (socketRef.current) {
      // 특정 이벤트(msg_saas)에 대한 핸들러 // 백엔드에서 이전 버전과의 공존을 위해 msg, msg_saas 둘다 보내고 있어 불가피 하게 클라이언트에서 분기 처리해 활용
      // socket.on('msg_saas', handleReceivedMessage);

      // 모든 이벤트를 가로채는 핸들러 추가
      socketRef.current.onAny((eventName, ...args) => {
        // 필요에 따라 특정 이벤트 이름별 추가 로직 작성 가능
        if (eventName === 'msg_saas') {
          handleReceivedMessage(args[0] as ChattingResponse);
        }
      });
    }
  }, [chatRoomID, userID, enableListening, isDisabled]);

  useEffect(() => {
    return () => {
      if (previousChatRoomID.current && enableListening && socketRef.current) {
        // console.info('Socket cleanup start');
        socketRef.current.offAny();
        socketRef.current.disconnect();
        socketRef.current = null;
        // console.info('Socket cleanup complete:', socketRef.current === null); // null 확인
      }

      previousChatRoomID.current = chatRoomID;
    };
  }, [chatRoomID]);

  const sendMessage = useCallback(
    <T extends MessageType>(typeAndMessage: PayloadTypeByMessageType<T>) => {
      if (isDisabled) return;

      if (socketRef.current === null || socketRef.current.connected === false) {
        socketRef.current = createSocketConnection({ userID, chatRoomID });
      }

      setIsSendingMessages(true);

      const { payload } = typeAndMessage;

      switch (typeAndMessage.type) {
        case REQUEST_ROOM_TYPE_MAP.AFTERDOC.TEXT_TYPE: {
          // setReq(typeAndMessage);
          isActivatingChatTranslate && setIsSendingTranslate(true);
          break;
        }
      }

      if (socketRef.current) {
        socketRef.current.emit('msg_saas', payload);
        console.info('send msg', payload);
      } else {
        withLogging({
          msg: 'WebSocket 연결이 되어있지 않습니다.',
          type: 'error',
        });
      }
    },
    [
      chatRoomID,
      userID,
      isDisabled,
      isActivatingChatTranslate,
      setIsSendingMessages,
      setIsSendingTranslate,
    ],
  );

  return { sendMessage, req, res };
};
