import { useUserInfo } from '@shared/hooks/use-user-info';
import AccountListTableRow from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountListTable/components/AccountListTableRow';
import {
  type UpdateHospitalAccountParams,
  useHospitalAccount,
} from '@templates/HospitalSetting/containers/AllAccountSetting/hooks/use-hospital-account';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from 'utils/utils';

export default function AccountListTableBody() {
  const { userId } = useUserInfo();
  const { fetchAccountList, updateAccount, removeAccount } = useHospitalAccount();

  const { data: accounts } = fetchAccountList();

  const handleError = (error: Error, errorMessage: string) => {
    if (SHARED_UTILS.api.isApiErrorWithCode(error, 3020)) {
      toastService.errorMsg({ text: error.message });
    } else {
      toastService.errorMsg({ text: errorMessage });
    }
  };

  const handleChangeAccount = (params: UpdateHospitalAccountParams) => {
    updateAccount(params, {
      onSuccess: () => toastService.successMsg({ text: '수정되었습니다.' }),
      onError: (error) => handleError(error, '수정을 실패했습니다. 다시 시도해 주세요.'),
    });
  };

  const handleRemoveAccount = (params: UpdateHospitalAccountParams) => {
    removeAccount(params, {
      onSuccess: () => {
        toastService.successMsg({ text: '삭제되었습니다.' });
        if (params._id === userId) {
          window.electron?.ipcRenderer.send('Application.logout');
        }
      },
      onError: (error) => handleError(error, '삭제를 실패했습니다.'),
    });
  };

  return (
    <>
      <tbody>
        {accounts?.map((account) => (
          <AccountListTableRow
            account={account}
            key={account.id}
            handleChangeAccount={handleChangeAccount}
            handleRemoveAccount={handleRemoveAccount}
          />
        ))}
      </tbody>
    </>
  );
}
