import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import {
  CategoryScale,
  type ChartData,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import type { ApiStatsAlgorithmsConsultationMonthlyElFindData } from 'web/apis/swaggers/swagger-docs';
import { LINE_CHART_OPTIONS } from 'web/templates/Automation/containers/Main/DashBoardContent/constants/chart-options';
import { useGetAlgorithmsConsultationMonthly } from 'web/templates/Automation/containers/Main/DashBoardContent/hooks/use-get-algorithms-consultation-monthly';
import { selectedDateState } from 'web/templates/Automation/containers/Main/DashBoardContent/states/selected-date';
import { getMonthRange } from 'web/templates/Automation/containers/Main/DashBoardContent/utils/get-month-range';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export default function AccumulatedConsultationAutomationTargetsCardItem() {
  const { data } = useGetAlgorithmsConsultationMonthly();
  const selectedDate = useAtomValue(selectedDateState);

  const { startDate, endDate } = getMonthRange(selectedDate);

  const monthLabels = useMemo(() => {
    const months: { label: string; yearMonth: string }[] = [];
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    let current = start;
    while (current.isBefore(end) || current.isSame(end, 'month')) {
      months.push({
        label: current.format('M'),
        yearMonth: current.format('YYYY-MM'),
      });
      current = current.add(1, 'month');
    }
    return months;
  }, [startDate, endDate]);

  const dataSet: number[] = useMemo(() => {
    return monthLabels.map(({ yearMonth }) => {
      const matchingData: ApiStatsAlgorithmsConsultationMonthlyElFindData | undefined = data?.find(
        (item) => dayjs(item.registerDate).format('YYYY-MM') === yearMonth,
      );
      return matchingData?.totalUserCount || 0;
    });
  }, [data, monthLabels]);

  const chartData: ChartData<'line', number[], string> = {
    labels: monthLabels.map(({ label }) => `${label}월`),
    datasets: [
      {
        label: '고객 수',
        data: dataSet,
        borderColor: '#64B5F6',
        backgroundColor: 'rgba(100, 181, 246, 0.1)',
        fill: true,
        tension: 0,
        pointRadius: 0,
        borderWidth: 2,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  const thisMonthData =
    data?.find(
      (item) =>
        dayjs(item.registerDate).format('YYYY-MM') === dayjs(selectedDate).format('YYYY-MM'),
    )?.totalUserCount ?? 0;

  return (
    <CardItemLayout className='flex flex-col gap-24'>
      <TopInfo number={thisMonthData} />
      <div>
        <Line data={chartData} options={LINE_CHART_OPTIONS} height={90} />
      </div>
    </CardItemLayout>
  );
}

interface TopInfoProps {
  number?: number;
}

function TopInfo({ number }: TopInfoProps) {
  return (
    <div className='flex items-start justify-between'>
      <div>
        <div className='text-Header12 text-black500'>상담자동화 누적 대상자 수</div>
        <div className='mt-8 flex items-center gap-10'>
          <div className='text-Header16 text-black700'>{number}명</div>
        </div>
      </div>
      <HoverTooltip text={'현재까지 상담자동화가 적용된\n모든 고객 수를 의미합니다.'}>
        <div className='p-6'>
          <Icon name='info-circle-outline' color='black200' size={20} />
        </div>
      </HoverTooltip>
    </div>
  );
}
