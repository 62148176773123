import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoColorSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#2360FF"
      d="M13.577.665C8.422-1.31 2.64 1.265.665 6.421a10 10 0 0 0 2.422 10.802c-.42.952-1.163 1.857-1.59 2.297a.227.227 0 0 0 .159.384c1.829.03 3.487-.171 4.96-.502 5.106 1.84 10.767-.734 12.719-5.824 1.976-5.156-.6-10.936-5.756-12.913z"
    />
    <path
      fill="#fff"
      d="M15.223 6.115 11.025 3.69a1.69 1.69 0 0 0-1.686 0L5.14 6.115a1.69 1.69 0 0 0-.843 1.46v4.846c0 .602.322 1.158.843 1.461l4.198 2.424a1.69 1.69 0 0 0 1.686 0l4.198-2.424c.521-.3.843-.857.843-1.46V7.575c0-.602-.322-1.159-.843-1.461m.514 5.034c0 .075-.04.144-.106.183l-1.462.85v1.59c0 .075-.04.143-.106.182l-1.572.914a.077.077 0 0 1-.116-.067l.018-1.86-1.6.865c-.051.03-.134-.024-.134-.084V11.91c0-.076.04-.145.106-.183l1.61-.882v-1.69c0-.076.04-.144.106-.183l1.572-.913a.077.077 0 0 1 .116.066v1.915l1.452-.788a.077.077 0 0 1 .116.067z"
    />
  </svg>
);
export default SvgLogoColorSymbol;
