import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { useQueryClient } from '@tanstack/react-query';
import { postChatroomCounselorsAdd } from '@templates/CustomerChat/components/ChattingList/api/add-counselor';
import {
  type HandleSelectedChatRoomParmas,
  postChatroomUpdateRead,
} from '@templates/CustomerChat/components/ChattingList/components/ChattingListBody/ChattingListBody';
import {
  type ChatRoomID,
  selectedChatRoomState,
} from '@templates/CustomerChat/states/selected-chat-room';
import { useAtomValue, useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect, useState } from 'react';
import type { ChattingListTabValues } from 'web/templates/CustomerChat/states/selected-chatting-list-tab';
import { selectedMessageIDState } from '../../SupportBot/states/selected-message-id';

const refetchQueryKeys = [
  QUERY_KEY.apiChatroomElUnreadcount,
  QUERY_KEY.apiChatroomElCount,
  QUERY_KEY.apiChatroomElFind,
];

export const useSelectChatRoom = ({ selectedTab }: { selectedTab: ChattingListTabValues }) => {
  const queryClient = useQueryClient();

  const setSelectedChatRoom = useSetAtom(selectedChatRoomState);
  const selectedMessageID = useAtomValue(selectedMessageIDState);
  const resetSelectedMessageID = useResetAtom(selectedMessageIDState);

  const { userId } = useUserInfo();

  const [selectedChatRoomIDForUsability, setSelectedChatRoomIDForUsability] =
    useState<ChatRoomID>(null);

  const handleSelectChatRoom = useCallback(
    async ({
      chatRoomID,
      chatRoomParentHServiceID,
      patientIDInChattingListUserID,
      chatRoomHserviceID,
    }: HandleSelectedChatRoomParmas) => {
      // 이미 선택된 채팅방을 다시 선택할 경우, 특히, 북마크 상태일 경우 초기화할 때 활용
      if (selectedChatRoomIDForUsability === chatRoomID) {
        selectedMessageID && resetSelectedMessageID();
        Promise.all(
          refetchQueryKeys.map((key) => queryClient.invalidateQueries({ queryKey: [key] })),
        );
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatroomElFindOne, { chatRoomID }],
        });
        return;
      }

      setSelectedChatRoomIDForUsability(chatRoomID);

      try {
        // 1. 먼저 채팅방 정보를 가져옴
        const { roomType, ikakaoInfo, counselors } = await postChatroomUpdateRead(chatRoomID);

        // 2. 채팅방에 담당자가 없을 경우 본인을 담당자로 추가
        if (counselors?.length === 0) {
          await postChatroomCounselorsAdd({
            chatRoomID,
            onlyAddIDs: [userId],
          });
        }

        // 3. 모든 데이터가 준비된 후에 상태 업데이트
        setSelectedChatRoom({
          chatRoomID,
          chatRoomHserviceID,
          patientIDInChattingListUserID,
          chatRoomParentHServiceID,
          roomType,
          roomInfo: ikakaoInfo,
        });

        // 3-1. 채팅방 선택 시, ipcRenderer를 통해 main process로 chatRoomID를 전달, null로 초기화할 경우는 ChattingRoom 컴포넌트에서 초기화의 개념으로 reset과 함께 사용
        window.electron?.ipcRenderer.send('Application.Set.selectedChatRoomID', {
          value: chatRoomID,
        });

        // 4. 채팅방이 변함에 따라 연관된 api들의 쿼리 무효화를 Promise.all로 처리
        Promise.all(
          refetchQueryKeys.map((key) => queryClient.invalidateQueries({ queryKey: [key] })),
        );
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatroomElFindOne, { chatRoomID }],
        });
      } catch (error) {
        console.error('채팅방 선택 중 오류 발생:', error);
      }
    },
    [
      userId,
      selectedChatRoomIDForUsability,
      selectedMessageID,
      queryClient.invalidateQueries,
      resetSelectedMessageID,
      setSelectedChatRoom,
    ],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setSelectedChatRoomIDForUsability(null);
  }, [selectedTab]);

  return {
    handleSelectChatRoom,
    selectedChatRoomID: selectedChatRoomIDForUsability,
  };
};
