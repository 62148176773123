import * as React from "react";
import type { SVGProps } from "react";
const SvgDivider = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M7.5 10.834H4.167V9.167H7.5zM11.667 10.834H8.333V9.167h3.333zM15.833 10.834H12.5V9.167h3.333z"
    />
  </svg>
);
export default SvgDivider;
