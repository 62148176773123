import type { AwsObject } from '../../../../../../../../../types';
import { useChatMessageContext } from '../ChatMessageContext';
import ImageList from './components/ImageList/ImageList';

export default function ImageMessage() {
  const { fileIDs } = useChatMessageContext();
  if (!fileIDs || fileIDs.length === 0) return null;
  const { aws, expiredAt } = fileIDs[0];
  const isExpired = expiredAt ? new Date(expiredAt) < new Date() : false;
  //swagger에서 정상적으로 주지 않는 타입
  return <ImageList aws={aws as AwsObject[]} isDisabledViewer={false} isExpired={isExpired} />;
}
