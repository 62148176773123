import TextButton from 'afterdoc-design-system/components/Atoms/Button/TextButton';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { debounce } from 'lodash-es';
// import DownloadManualPDFButton from 'web/templates/HospitalManual/components/HospitalManualPanel/components/AddFolderAndDownloadManualBox/components/DownloadManualPDFButton/DownloadManualPDFButton';
import {
  isAddingNewFolderState,
  newFolderNameState,
} from 'web/templates/HospitalManual/components/HospitalManualPanel/states/is-adding-new-folder';

export default function AddFolderAndDownloadManualBox() {
  const [isAddingNewFolder, setIsAddingNewFolder] = useAtom(isAddingNewFolderState);
  const resetNewFolderName = useResetAtom(newFolderNameState);

  const handleAddFolder = debounce(() => {
    if (isAddingNewFolder) return;

    resetNewFolderName();
    setIsAddingNewFolder(true);
  }, 100);

  return (
    <div className='flex h-[40px] w-full items-center justify-between bg-white100 px-20'>
      <TextButton
        iconProps={{
          name: 'add-circle',
          color: 'blue500',
          size: 16,
        }}
        textColor='blue500'
        onClick={handleAddFolder}>
        폴더 추가
      </TextButton>
      {/* <DownloadManualPDFButton /> */}
    </div>
  );
}
