interface StatisticItemProps {
  label: string;
  value: number;
  unit: string;
}

export default function StatisticItem({ label, value, unit }: StatisticItemProps) {
  return (
    <div className='flex flex-1 flex-col items-start'>
      <div className='text-Body11 text-black200'>{label}</div>
      <div className='text-Header14 text-black700'>
        {value?.toLocaleString() || '-'}
        {unit}
      </div>
    </div>
  );
}
