import styles from './addBool.module.scss';

export default function EditorViewer({ htmlContent }: { htmlContent: string }) {
  return (
    <div className='editor-viewer'>
      <div
        className={`${styles.addBool} content break-words py-10 text-black700`}
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </div>
  );
}
