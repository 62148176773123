import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';
import { safeStringify } from 'web/shared/utils/safe-stringify';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import {
  selectedContentTabItemState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/origin-message';
import { selectedMarketingAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';
import AutomationDaySelector from 'web/templates/Automation/containers/shared/components/AutomationDaySelector';
import NextButton from 'web/templates/Automation/containers/shared/components/Buttons/NextButton';
import PrevButton from 'web/templates/Automation/containers/shared/components/Buttons/PrevButton';

const isValidDate = (year: number, month: number, day: number) => {
  if (year < 1900 || year > 9999) return false;
  if (month < 1 || month > 12) return false;
  const daysInMonth = new Date(year, month, 0).getDate();
  if (day < 1 || day > daysInMonth) return false;
  return true;
};

interface MarketingEventStartDateSettingContainerProps {
  hasVisitedMessageTab: boolean;
}
export default function MarketingEventStartDateSettingContainer({
  hasVisitedMessageTab,
}: MarketingEventStartDateSettingContainerProps) {
  const { setValue, watch, getValues } = useFormContext<MarketingAutomationAPIFormValues>();

  const dropdownOptions = useRef(Array.from({ length: 50 }, (_, i) => `${i + 1}`));
  const setSelectedMessage = useSetAtom(selectedMessageState);

  const messageLists = watch('messages');
  const startDay = watch('sendingSchedule.startDay');
  const sendingCount = watch('sendingSchedule.sendingCount');

  const algorithmMode = useAtomValue(algorithmModeState);
  const originMessages = useAtomValue(originMessagesState);
  const setSelectedContentTabItem = useSetAtom(selectedContentTabItemState);
  const setSelectedAutomationTab = useSetAtom(selectedMarketingAutomationTabState);

  const shouldShowWarning = useMemo(() => {
    const isCreateMode = algorithmMode.mode === 'CREATE';
    return !isCreateMode || (isCreateMode && hasVisitedMessageTab);
  }, [algorithmMode.mode, hasVisitedMessageTab]);

  const disableBeforeDate = useMemo(() => {
    const now = dayjs();
    const today8PM = now.hour(20).minute(0).second(0);

    // 현재 시각이 오후 8시를 넘었다면 내일부터, 아니면 오늘부터
    return now.isAfter(today8PM)
      ? now.add(1, 'day').hour(8).minute(0).second(0).toDate()
      : now.hour(8).minute(0).second(0).toDate();
  }, []);

  const onChangeStartDay = (date: Date) => {
    const newStartDay = dayjs(date).hour(8).minute(0).second(0).toISOString();

    setValue('sendingSchedule.startDay', newStartDay);

    let messageLists: (MessageInput & { id: string })[];

    if (algorithmMode.mode === 'CREATE') {
      messageLists = Array.from({
        length: sendingCount ?? 1,
      }).map((_, index) => {
        return {
          id: uuidv4(),
          name: getValues(`messages.${index}.name`),
          sendingType: getValues(`messages.${index}.sendingType`) ?? 'BASIC',
          content: {
            text: getValues(`messages.${index}.content.text`),
            image: getValues(`messages.${index}.content.image`),
          },
          sendingDateTime: dayjs(newStartDay)
            .add(index, 'day')
            .hour(8)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toISOString(),
        };
      });
    } else {
      messageLists = Array.from({
        length: sendingCount ?? 1,
      }).map((_, index) => {
        const message = originMessages.find((message) => message.id === originMessages[index].id);

        return {
          id: message?.id ?? uuidv4(),
          name: message?.name ?? '',
          sendingDateTime: dayjs(newStartDay)
            .add(index, 'day')
            .hour(8)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toISOString(),
          sendingType: message?.sendingType ?? 'BASIC',
          content: {
            text: message?.content?.text,
            image: message?.content?.images
              ? safeStringify(
                  message.content.images.map((image) => ({
                    id: message.content?.imageFileID,
                    url: image,
                  })),
                )
              : undefined,
          },
        };
      });
    }

    setSelectedContentTabItem(
      messageLists.reduce((acc, message) => Object.assign(acc, { [message.id]: '텍스트' }), {}),
    );
    setSelectedMessage({
      index: 0,
      ...messageLists[0],
    });

    setValue('messages', messageLists);
  };

  const onChangeSendingCount = (index: number) => {
    const count = Number(dropdownOptions.current[index]);
    setValue('sendingSchedule.sendingCount', count);

    const messageLists: (MessageInput & { id: string })[] = Array.from(
      {
        length: count,
      },
      (_, i) => {
        return {
          id: uuidv4(),
          content: {
            text: undefined,
          },
          name: `메시지 ${i + 1}`,
          sendingDateTime: dayjs(startDay)
            .add(i, 'day')
            .hour(8)
            .minute(0)
            .second(0)
            .millisecond(0)
            .toISOString(),
          sendingType: 'BASIC',
        };
      },
    );

    setSelectedMessage({
      index: 0,
      ...messageLists[0],
    });
    setValue('messages', messageLists);
    setSelectedContentTabItem(
      messageLists.reduce((acc, message) => Object.assign(acc, { [message.id]: '텍스트' }), {}),
    );
  };

  useEffect(() => {
    if (!messageLists.length) {
      const defaultMessageLists = Array.from({ length: sendingCount ?? 1 }).map(() => ({
        id: uuidv4(),
        name: '메시지 1',
        sendingDateTime: dayjs(startDay).hour(8).minute(0).second(0).millisecond(0).toISOString(),
        sendingType: 'BASIC' as const,
        content: {
          text: undefined,
        },
      }));

      setValue('messages', defaultMessageLists);
      setSelectedMessage({
        index: 0,
        ...defaultMessageLists[0],
      });
      setSelectedContentTabItem(
        defaultMessageLists.reduce(
          (acc, message) => Object.assign(acc, { [message.id]: '텍스트' }),
          {},
        ),
      );
    }
  }, [
    messageLists,
    startDay,
    sendingCount,
    setValue,
    setSelectedMessage,
    setSelectedContentTabItem,
  ]);

  return (
    <div className='mt-40 w-[700px] rounded-r16 bg-white50 px-40 py-20 shadow-modal'>
      <LabelText isRequired className='mb-10'>
        시작일/회차
      </LabelText>
      {shouldShowWarning && (
        <div className='text-Body10 text-red500'>
          회차를 수정할 경우, 등록된 메시지가 모두 초기화됩니다. 그대로 사용을 원하시는 경우&nbsp;
          <span className='font-bold'>시작일만 변경</span>해 주세요.
        </div>
      )}
      <div className='mt-8 flex items-center gap-8'>
        <AutomationDaySelector
          selectedDate={dayjs(startDay).hour(0).minute(0).second(0).toDate()}
          onSelect={onChangeStartDay}
          hasDayChangeButton={false}
          width={124}
          yearRange={[dayjs().year(), 2500]}
          disableBeforeDate={disableBeforeDate}
        />
        <span className='text-Body13 text-black500'>부터</span>
        <TextFieldSelectBoxDropdown
          width={80}
          options={dropdownOptions.current}
          onSelect={onChangeSendingCount}
          selectedIndex={dropdownOptions.current.findIndex(
            (option) => option === `${sendingCount}`,
          )}
        />
        <span className='text-Body13 text-black500'>회 메시지를 발송합니다.</span>
      </div>

      <div className='mx-auto mt-20 flex w-[200px] gap-8'>
        <PrevButton
          onClick={() => {
            setSelectedAutomationTab(0);
          }}
        />
        <NextButton
          onClick={() => {
            const year = dayjs(startDay).year();
            const month = dayjs(startDay).month() + 1;
            const day = dayjs(startDay).date();

            if (!isValidDate(year, month, day)) {
              return toastService.errorMsg({
                text: '유효한 날짜를 선택해 주세요.',
              });
            }

            if (dayjs(startDay).isBefore(dayjs(), 'day')) {
              return toastService.errorMsg({
                text: `발송 시작일은 ${
                  dayjs().isAfter(dayjs().hour(20).minute(0).second(0))
                    ? dayjs().add(1, 'day').format('YYYY-MM-DD')
                    : dayjs().format('YYYY-MM-DD')
                }부터 설정 가능합니다.`,
              });
            }

            return setSelectedAutomationTab(2);
          }}
        />
      </div>
    </div>
  );
}
