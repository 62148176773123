import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import Logo from 'afterdoc-design-system/components/Foundations/Logo/Logo';

interface ErrorPageProps {
  errorInfo?: string | null;
  errorType?: 'NetworkError' | 'TypeError' | 'UnknownError' | null;
}

export default function ErrorPage({ errorInfo, errorType }: ErrorPageProps) {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Scrollbar isAlwaysVisible>
      <div className='flex-full-center bg-blueLight text-center'>
        <div className='flex flex-col items-center justify-center'>
          <Logo name='LogoColor' size={220} />
          <div className='mt-16 text-Header16 text-blue500'>
            서비스 이용에 불편을 드려 죄송합니다.
          </div>
          <div className='mt-20 text-Body13 text-black500'>
            현재 서비스에 일시적인 오류가 발생하여, 요청하신 작업을 진행할 수 없습니다.
            <br />
            잠시후 아래 [새로고침] 버튼을 눌러 다시 진행해 주세요.
          </div>
          <ContainedButton className='mt-18' onClick={handleRefresh}>
            새로고침
          </ContainedButton>
          <Divider type='line' className='my-18' />
          <div className='text-Body11 text-black500'>
            지속적인 오류가 발생하는 경우 아래 카카오톡 채널로 문의해주세요.
          </div>
          <div className='mt-8 flex items-center gap-4'>
            <Icon name='logo-kakaotalk' size={24} className='rounded-r6' />
            <div>
              <span className='font-bold text-Body14 text-black200'>카카오톡 문의: </span>
              <a
                href='http://pf.kakao.com/_kHcjn/chat'
                className='font-bold text-Body14 text-blue500 underline'>
                @애프터닥헬프데스크
              </a>
            </div>
          </div>
          {/* Dev 환경에서만 상세한 에러 메시지 노출 */}
          {import.meta.env.MODE === 'dev' && errorInfo && errorType && (
            <ErrorInDevelopment errorInfo={errorInfo} errorType={errorType} />
          )}
        </div>
      </div>
    </Scrollbar>
  );
}

const ErrorInDevelopment = ({ errorInfo, errorType }: ErrorPageProps) => {
  const handleCopy = () => {
    if (errorInfo) {
      const textToCopy = `Error Type: ${errorType}\n\nError Details:\n${errorInfo}`;

      if (navigator.clipboard?.writeText) {
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => alert('복사 완료!'))
          .catch(() => alert('복사 실패'));
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = textToCopy;
        textArea.style.position = 'fixed';
        textArea.style.opacity = '0';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          document.execCommand('copy');
          alert('복사 완료!');
        } catch {
          alert('복사 실패');
        }

        document.body.removeChild(textArea);
      }
    }
  };

  return (
    <>
      <div className='h-[500px] w-[500px] text-red500'>
        <Scrollbar>
          <div className='p-30'>
            <h2 className='text-Header16'>Error Type: {errorType}</h2>
            <h2 className='mt-30 text-Header16'>Error Details:</h2>
            <div className='break-all text-Body14'>{errorInfo}</div>
          </div>
        </Scrollbar>
      </div>
      <ContainedButton onClick={handleCopy}>에러 정보 복사</ContainedButton>
    </>
  );
};
