import { atomWithReset } from 'jotai/utils';

export const checkedLayersState = atomWithReset<{
  [key: string]: {
    allChecked: boolean;
    someChecked: boolean;
  };
}>({});
export const checkedSubLayersState = atomWithReset<{ [key: string]: boolean }>({});
export const checkedOrphanSubLayersState = atomWithReset<{ [key: string]: boolean }>({});
