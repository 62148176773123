import { atom } from 'jotai';

export const navigationBarUnreadCountState = atom<{
  serviceNotice: number;
  adminChat: number;
  customerChat: number;
}>({
  serviceNotice: 0,
  adminChat: 0,
  customerChat: 0,
});
