import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

export const ChattingListNoData = ({ keyword }: { keyword: string }) => {
  return (
    <>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className='flex h-full w-full flex-center flex-col'
        title={keyword ? '검색 결과가 없습니다.' : '상담목록이 없습니다.'}
        subTitle={
          keyword
            ? '단어의 철자가 정확한지 확인해 주세요.'
            : '고객의 문의가 들어올 경우 여기에 표시됩니다.'
        }
      />
    </>
  );
};
