import * as React from "react";
import type { SVGProps } from "react";
const SvgSuperscript = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M15.5 7h-1.639a4.01 4.01 0 0 0 1.914-2.975 1.8 1.8 0 0 0-1.6-1.751A1.92 1.92 0 0 0 12.021 3.7a.5.5 0 1 0 .957.291.917.917 0 0 1 1.053-.725.81.81 0 0 1 .744.762c0 1.077-1.164 1.925-1.934 2.486A1.42 1.42 0 0 0 12 7.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1M9.651 5.241a1 1 0 0 0-1.41.108L6 7.964 3.759 5.349a1 1 0 1 0-1.519 1.3L4.683 9.5l-2.442 2.85a1 1 0 1 0 1.519 1.3L6 11.036l2.241 2.614a1 1 0 0 0 1.519-1.3L7.317 9.5l2.442-2.849a1 1 0 0 0-.108-1.41"
      className="superscript_svg__ql-fill"
    />
  </svg>
);
export default SvgSuperscript;
