import * as React from "react";
import type { SVGProps } from "react";
const SvgColor = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M3 15h12"
      className="color_svg__ql-color-label color_svg__ql-stroke color_svg__ql-transparent"
    />
    <path d="M5.5 11 9 3l3.5 8M11.63 9H6.38" className="color_svg__ql-stroke" />
  </svg>
);
export default SvgColor;
