import type { TagProperty } from 'afterdoc-design-system/components/Molecules/Dropdown/TagDropdown/types/tag-dropdown';
import { type RefObject, useCallback } from 'react';
import type { FixedSizeList } from 'react-window';

interface UseVirtualizedKeyboardNavigationProps {
  listRef: RefObject<FixedSizeList>;
  tags: TagProperty[];
  isComposing?: boolean;
  focusedIndex?: number;
  setFocusedIndex: (index?: number) => void;
  onSelect?: (index: number) => void;
  handleToggle: (isToggle?: boolean) => void;
}

export function useVirtualizedKeyboardNavigation({
  listRef,
  tags,
  isComposing,
  focusedIndex,
  setFocusedIndex,
  onSelect,
  handleToggle,
}: UseVirtualizedKeyboardNavigationProps) {
  return useCallback(
    (event: KeyboardEvent) => {
      if (isComposing || !listRef.current) return;

      let newFocusIndex = focusedIndex;

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          event.stopPropagation();
          newFocusIndex =
            focusedIndex === undefined ? 0 : Math.min(focusedIndex + 1, tags.length - 1);
          break;

        case 'ArrowUp':
          event.preventDefault();
          event.stopPropagation();
          newFocusIndex =
            focusedIndex === undefined ? tags.length - 1 : Math.max(focusedIndex - 1, 0);
          break;

        case 'Enter':
          event.preventDefault();
          event.stopPropagation();
          if (focusedIndex !== undefined) {
            onSelect?.(focusedIndex);
            if (tags.length > 1) {
              if (focusedIndex === 0) {
                setFocusedIndex(0);
              } else {
                setFocusedIndex(Math.max(0, focusedIndex - 1));
              }
            } else {
              setFocusedIndex(undefined);
            }
          }
          return;

        case 'Escape':
          handleToggle(false);
          break;

        default:
          return;
      }

      if (newFocusIndex !== undefined && newFocusIndex !== focusedIndex) {
        setFocusedIndex(newFocusIndex);
        listRef.current.scrollToItem(newFocusIndex, 'smart');
      }
    },
    [focusedIndex, isComposing, tags.length, onSelect, handleToggle, listRef, setFocusedIndex],
  );
}
