import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import SelectedCheckboxInImg from 'web/templates/CustomerChat/components/Manual/components/components/SelectedCheckboxInImg';
import { handleVideoDialogOpen } from '../functions/handle-video-dialog-open';
import type { VideoData } from '../types';

type HomeCareVideoItemProps = {
  homecareVideoData: VideoData;
  toggleSelectItem: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
  isSelected: boolean;
  isAbleSelect: boolean;
};

export function HomeCareVideoItem({
  homecareVideoData,
  toggleSelectItem,
  isSelected,
  isAbleSelect,
}: HomeCareVideoItemProps) {
  const { id, title, subtitle, url } = homecareVideoData;
  return (
    <div key={id} className='flex w-[170px] flex-col'>
      <div
        key={url}
        onClick={(e) => isAbleSelect && toggleSelectItem(e, id)}
        className={`relative inline-block h-[96px] rounded-r10 ${isAbleSelect && 'cursor-pointer'} overflow-hidden border ${
          isSelected ? 'border-blue500' : 'border-white600'
        } ${!isAbleSelect && 'opacity-50'}`}>
        {isAbleSelect && <SelectedCheckboxInImg isSelected={isSelected} />}
        <img src={url ?? ''} alt='콘텐츠 동영상' className='h-[96px] w-[170px] rounded-r10' />
        <div
          className='absolute right-2 bottom-2 flex size-32 flex-center cursor-pointer bg-black200'
          onClick={(e) => handleVideoDialogOpen(id, title, e)}
          style={{ borderRadius: '6px' }}>
          <Icon name='magnify-scan' size={20} color='white50' />
        </div>
      </div>
      <div className='my-10'>
        <span className='line-clamp-1 text-Body11 text-black200'>{subtitle}</span>
        <span className='line-clamp-2 h-36 text-Body13 text-black700'>{title}</span>
      </div>
    </div>
  );
}
