import { Color } from '@tailwind-base/styles/color';
import { useQuery } from '@tanstack/react-query';
import DashedTag from 'afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import GradientTag from 'afterdoc-design-system/components/Atoms/Tag/GradientTag/GradientTag';
import HoverComponent from 'afterdoc-design-system/components/Atoms/Tooltip/HoverComponent';
import { useAtomValue } from 'jotai';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { TreatmentTag } from 'web/apis/swaggers/swagger-docs';
import TagList from 'web/templates/CustomerChat/components/ChattingRoom/components/CustomerInfoTop/components/TreatmentTags/components/TagList';
import { chattingWidthAtom } from 'web/templates/CustomerChat/states/chattingWidth';
import {
  chatRoomIDSelector,
  chatRoomParentHServiceIDSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import { getPatient } from '../../../../../../hooks/use-patient';
import './TreatmentTags.scss';

export default function TreatmentTags() {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const chatRoomParentHServiceID = useAtomValue(chatRoomParentHServiceIDSelector);
  const chattingWidth = useAtomValue(chattingWidthAtom);

  const query = useMemo(
    () => ({
      patientId: chatRoomParentHServiceID,
      chatRoomID,
    }),
    [chatRoomParentHServiceID, chatRoomID],
  );

  const { data } = useQuery({
    queryKey: [QUERY_KEY.chattingTopPatientDetailInfo, query] as const,
    queryFn: ({ queryKey }) => getPatient(queryKey[1]),
    enabled: !!chatRoomParentHServiceID && !!chatRoomID,
    refetchOnMount: 'always',
  });

  const treatmentTags = data?.treatmentTags;
  const automationAppliedTreatmentTag = data?.automationAppliedTreatmentTag;

  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState<{ tag: TreatmentTag; maxTextLength: number }[]>(
    [],
  );
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);

  useLayoutEffect(() => {
    if (!containerRef.current || !treatmentTags) return;

    const calculateVisibleTags = () => {
      const container = containerRef.current as HTMLDivElement;
      const containerWidth = container.offsetWidth;

      const STABILITY_MARGIN = 8;

      let reservedWidth = 100;
      if (automationAppliedTreatmentTag) {
        const measureDiv = document.createElement('div');
        measureDiv.style.position = 'absolute';
        measureDiv.style.visibility = 'hidden';
        measureDiv.className = automationAppliedTreatmentTag.automationApplied
          ? 'gradient-tag-class'
          : 'filled-tag-class';
        measureDiv.innerText = automationAppliedTreatmentTag.name;
        document.body.appendChild(measureDiv);

        reservedWidth = measureDiv.offsetWidth + 6;
        document.body.removeChild(measureDiv);
      }

      const availableWidth = containerWidth - reservedWidth - STABILITY_MARGIN;

      let usedWidth = 0;
      const tagsToShow: { tag: TreatmentTag; maxTextLength: number }[] = [];
      let remainingCount = 0;

      const measureDiv = document.createElement('div');
      measureDiv.style.position = 'absolute';
      measureDiv.style.visibility = 'hidden';
      measureDiv.className = 'filled-tag-class';
      document.body.appendChild(measureDiv);

      const previousVisibleCount = visibleTags.length;
      const hysteresisThreshold = 8;

      // 더보기 버튼 최소화
      const remainingTagsCount = treatmentTags.length;
      const plusButtonText = `${remainingTagsCount}+`;
      measureDiv.innerText = plusButtonText;
      const minPlusButtonWidth = measureDiv.offsetWidth + 2;

      for (let i = 0; i < treatmentTags.length; i++) {
        const tag = treatmentTags[i];
        let maxTextLength = tag.name.length;
        measureDiv.innerText = tag.name;
        let tagWidth = measureDiv.offsetWidth;

        const needsSpaceForPlus = i < treatmentTags.length - 1;
        const availableWidthForTag = needsSpaceForPlus
          ? availableWidth - minPlusButtonWidth - (usedWidth > 0 ? 2 : 0)
          : availableWidth - (usedWidth > 0 ? 2 : 0);

        // 텍스트가 너무 길 경우에만 자르기
        if (tagWidth > availableWidthForTag) {
          // 더 점진적인 감소를 위해 0.99 사용
          maxTextLength = Math.ceil(maxTextLength * (availableWidthForTag / tagWidth) * 0.95);
          maxTextLength = Math.max(4, maxTextLength); // 최소 4자

          // 미세 조정: 남은 공간 최대한 활용
          let found = false;
          while (!found && maxTextLength <= tag.name.length) {
            measureDiv.innerText = tag.name.slice(0, maxTextLength);
            if (measureDiv.offsetWidth > availableWidthForTag) {
              maxTextLength--;
              found = true;
            } else if (maxTextLength === tag.name.length) {
              found = true;
            } else {
              maxTextLength++;
            }
          }
        }

        // 최종 태그 너비 계산 부분 수정
        measureDiv.innerText = tag.name.slice(0, maxTextLength);
        tagWidth = measureDiv.offsetWidth;

        // 태그 너비가 사용 가능한 공간보다 큰 경우, 태그를 자르기
        if (tagWidth > availableWidth - usedWidth) {
          // 남은 공간에 맞게 텍스트 길이 조정
          const remainingWidth = availableWidth - usedWidth;
          const ratio = remainingWidth / tagWidth;
          maxTextLength = Math.max(4, Math.floor(maxTextLength * ratio));

          // 자른 텍스트로 다시 너비 계산
          measureDiv.innerText = tag.name.slice(0, maxTextLength);
          tagWidth = measureDiv.offsetWidth;
        }

        // 히스테리시스를 적용한 태그 추가 결정
        const wouldExceedWidth = usedWidth + tagWidth > availableWidthForTag;
        const isNearPreviousCount = i === previousVisibleCount - 1;

        if (
          !wouldExceedWidth ||
          (isNearPreviousCount &&
            usedWidth + tagWidth <= availableWidthForTag + hysteresisThreshold)
        ) {
          tagsToShow.push({ tag, maxTextLength });
          usedWidth += tagWidth + (i < treatmentTags.length - 1 ? 2 : 0);
        } else {
          remainingCount = treatmentTags.length - i;
          break;
        }
      }

      document.body.removeChild(measureDiv);

      const hasChanged =
        tagsToShow.length !== visibleTags.length ||
        tagsToShow.some(
          (newTag, index) =>
            newTag.tag.tagId !== visibleTags[index]?.tag.tagId ||
            newTag.maxTextLength !== visibleTags[index]?.maxTextLength,
        );

      if (hasChanged) {
        setVisibleTags(tagsToShow);
        setHiddenTagsCount(remainingCount);
      }
    };

    calculateVisibleTags();
  }, [automationAppliedTreatmentTag, treatmentTags, chattingWidth]);

  return (
    <div className='treatment-tags flex w-full flex-row-center items-center gap-3'>
      <div className='w-37 whitespace-nowrap text-Body10Bold text-black500'>치료태그</div>
      {!automationAppliedTreatmentTag && treatmentTags?.length === 0 && (
        <DashedTag tagSize='big'>{'없음'}</DashedTag>
      )}
      <div ref={containerRef} className='flex w-full gap-6'>
        {automationAppliedTreatmentTag &&
          (automationAppliedTreatmentTag.automationApplied ? (
            <GradientTag
              key={automationAppliedTreatmentTag.tagId}
              bgColor={automationAppliedTreatmentTag.color}
              tagSize='big'
              className='whitespace-nowrap'>
              {automationAppliedTreatmentTag.name}
            </GradientTag>
          ) : (
            <FilledTag
              key={automationAppliedTreatmentTag.tagId}
              bgColor={automationAppliedTreatmentTag.color}
              tagSize='big'
              className='whitespace-nowrap'>
              {automationAppliedTreatmentTag.name}
            </FilledTag>
          ))}

        {visibleTags.length > 0 && !!treatmentTags?.length && (
          <div className='flex items-center gap-4'>
            {visibleTags.map(({ tag, maxTextLength }) => (
              <FilledTag
                tagSize='big'
                key={tag.tagId}
                bgColor={tag.color}
                className='whitespace-nowrap'
                maxTextLength={maxTextLength}>
                {tag.name}
              </FilledTag>
            ))}
            {hiddenTagsCount > 0 && (
              <HoverComponent
                wrapperProps={{
                  className: 'flex',
                }}
                content={
                  <TagList
                    treatmentTags={treatmentTags.slice(
                      treatmentTags.length - hiddenTagsCount,
                      treatmentTags.length - hiddenTagsCount + hiddenTagsCount,
                    )}
                  />
                }
                position='bottomRight'>
                <DashedTag bgColor={Color.transparent} tagSize='big'>
                  {`${hiddenTagsCount}+`}
                </DashedTag>
              </HoverComponent>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
