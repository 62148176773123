import * as React from "react";
import type { SVGProps } from "react";
const SvgOutdent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M3 14h12M3 4h12M9 9h6M5 7v4L3 9z"
      className="outdent_svg__ql-stroke"
    />
  </svg>
);
export default SvgOutdent;
