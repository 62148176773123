import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useSaveUser } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/save/use-save-user';
import { useSaveUserTemporary } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/save/use-save-user-temporary';
import { useTemporaryPatientsLock } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { useTemporaryPatientsModifier } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-modifier';
import { modifierInfoState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/modifier-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';

export default function FinalSaveButton() {
  const { watch } = useFormContext<{ rows: CustomerManagementTemporaryAPIFormValues }>();

  const { hospitalID } = useSelectedHospitalInfo();
  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();
  const { patchTemporaryPatientsLastModifierMutation } = useTemporaryPatientsModifier();
  const { handleTemporarySave } = useSaveUserTemporary();
  const { handleSaveUserInfoFinally } = useSaveUser();

  const rows = watch('rows');

  const setModifierInfo = useSetAtom(modifierInfoState);
  const [isLoading, setIsLoading] = useState(false);

  const handleSaveUser = async () => {
    try {
      setIsLoading(true);
      const latestLockData = await getPatientsIsLocked({ hospitalID });

      if (!latestLockData) return;

      // 1. 먼저 lock 해제
      await patchRequestLockMutation.mutateAsync({
        hospitalID,
        isLock: false,
        version: latestLockData.version,
      });

      // 2. 임시 저장 실행
      const temporarySaveResult = await handleTemporarySave(rows);

      // 3. 최종 저장 실행
      if (temporarySaveResult) {
        await handleSaveUserInfoFinally();
      }

      // 4. 마지막 수정자 정보 업데이트
      const result = await patchTemporaryPatientsLastModifierMutation.mutateAsync({
        hospitalID,
      });

      // 5. 수정자 정보 상태 업데이트
      setModifierInfo({
        createdAt: result.createdAt,
        userID: result.userID,
      });
    } catch (error) {
      console.error('Error during save process:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ContainedButton btnColor='blue' onClick={handleSaveUser} disabled={isLoading}>
      등록
    </ContainedButton>
  );
}
