import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiServiceSettingsElFindOneParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import AccountUpgradePrompt from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AccountUpgradePrompt/AccountUpgradePrompt';
import AccountAuthorization from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountInputArea/components/AccountAuthorization';
import AccountId from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountInputArea/components/AccountId';
import AccountJob from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountInputArea/components/AccountJob';
import AccountName from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountInputArea/components/AccountName';
import AccountPassword from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountInputArea/components/AccountPassword';
import AddAccountButton from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AddAccount/components/AddAccountInputArea/components/AddAccountButton';
import { useHospitalAccount } from '@templates/HospitalSetting/containers/AllAccountSetting/hooks/use-hospital-account';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { SHARED_UTILS } from 'utils/utils';

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const { data } = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function AddAccountInputArea() {
  const { hospitalID } = useSelectedHospitalInfo();
  const { fetchAccountList } = useHospitalAccount();

  const methods = useForm();

  const [isMaximumAccount, setIsMaximumAccount] = useState(false);

  const { data: serviceSettings } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElFindOne, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  const { data: accounts } = fetchAccountList();

  useEffect(() => {
    if (serviceSettings?.maximumCreatableAccountCount && accounts) {
      const isMaximum = accounts.length >= serviceSettings.maximumCreatableAccountCount;

      setIsMaximumAccount(isMaximum);
    }
  }, [serviceSettings, accounts]);

  const { accountCountTuningRequestedAt, finalAccountCountTunedAt } = serviceSettings;

  return (
    <>
      <FormProvider {...methods}>
        <form autoComplete='off'>
          <div className='relative mx-20 mt-20 rounded-r16 px-24 py-20 shadow-modal'>
            {isMaximumAccount && (
              <AccountUpgradePrompt
                accountCountTuningRequestedAt={accountCountTuningRequestedAt}
                finalAccountCountTunedAt={finalAccountCountTunedAt}
              />
            )}
            <div className='flex flex-wrap gap-10 py-10'>
              <AccountName />
              <AccountId />
              <AccountPassword />
              <AccountJob />
              <AccountAuthorization />
            </div>
            <AddAccountButton />
          </div>
        </form>
      </FormProvider>
    </>
  );
}
