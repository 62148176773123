import { AUTHORIZATION_ITEMS } from '@templates/HospitalSetting/containers/AllAccountSetting/constants/authorization-items';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function EditAccountAuthorization() {
  const [authorizationIndex, setAuthorizationIndex] = useState<number | undefined>(undefined);

  const {
    setValue,
    getValues,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const authorizationType = getValues('authorizationType');

  const isDisabled = useMemo(() => {
    return authorizationType === '마스터';
  }, [authorizationType]);

  const handleSelect = (index: number) => {
    setAuthorizationIndex(index);
    clearErrors('authorizationType');
    setValue('authorizationType', AUTHORIZATION_ITEMS[index]);
  };

  useEffect(() => {
    register('authorizationType');
  }, [register]);

  useEffect(() => {
    const initialJobIndex = AUTHORIZATION_ITEMS.findIndex((job) => job === authorizationType);
    if (initialJobIndex >= 0) {
      handleSelect(initialJobIndex);
    }
  }, []);

  return (
    <div className='flex flex-col gap-4'>
      <TextFieldSelectBoxDropdown
        label={{
          isRequired: true,
          children: '권한',
          width: 60,
          position: 'horizontal',
        }}
        options={AUTHORIZATION_ITEMS}
        width={272}
        onSelect={handleSelect}
        dropdownWrapperClassName={'ml-70'}
        disabled={isDisabled}
        defaultValue={isDisabled ? '마스터' : '선택'}
        selectedIndex={authorizationIndex}
      />
      {!!errors.authorizationType && (
        <span className='ml-70 text-Body10 text-red500'>권한을 선택해 주세요.</span>
      )}
    </div>
  );
}
