type ComponentMapType = {
  topPinned: JSX.Element;
  activation: JSX.Element;
  image: JSX.Element;
  eventTitle: JSX.Element;
  sellingPrice: JSX.Element;
  displayPeriod: JSX.Element;
  viewCount: JSX.Element;
  inquiryCount: JSX.Element;
};

export type EventHomeCareColumnType = {
  width: number;
  title: string;
  key: keyof ComponentMapType;
  className?: string;
};

const COMMON_COLUMNS: Omit<EventHomeCareColumnType, 'title'>[] = [
  { width: 60, key: 'topPinned', className: 'flex-center' },
  { width: 60, key: 'activation', className: 'flex-center' },
  { width: 60, key: 'image', className: 'flex-center' },
  { width: 242, key: 'eventTitle', className: 'px-10' },
  { width: 210, key: 'sellingPrice', className: 'px-10' },
  { width: 105, key: 'displayPeriod', className: 'px-10' },
  { width: 60, key: 'viewCount', className: 'flex-center' },
  { width: 60, key: 'inquiryCount', className: 'flex-center' },
];

const createColumnsWithDynamicTitle = (): EventHomeCareColumnType[] => {
  const titles = ['고정', '활성화', '이미지', '제목', '판매가격', '노출기간', '조회수', '상담수'];

  return COMMON_COLUMNS.map((column, index) => ({
    ...column,
    title: titles[index],
  }));
};

export const EVENT_LIST_COLUMNS: EventHomeCareColumnType[] = createColumnsWithDynamicTitle();

export const HOME_CARE_LIST_COLUMNS: EventHomeCareColumnType[] = createColumnsWithDynamicTitle();
