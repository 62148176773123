import { EmbedBlot, Scope } from 'parchment';

const ATTRIBUTES = ['alt', 'height', 'width'];

export default class ImageBlot extends EmbedBlot {
  static blotName = 'image';
  static tagName = 'IMG';
  static scope = Scope.INLINE;

  static create(value: string) {
    const node = super.create() as HTMLImageElement;
    if (typeof value === 'string') {
      node.setAttribute('src', value);
    }
    node.setAttribute('style', 'display: inline-block; max-width: 100%; vertical-align: bottom;');
    return node;
  }

  static formats(domNode: HTMLImageElement) {
    return ATTRIBUTES.reduce((formats: Record<string, string>, attribute) => {
      const value = domNode.getAttribute(attribute);
      if (value) formats[attribute] = value;
      return formats;
    }, {});
  }

  static match(url: string) {
    return /\.(jpe?g|gif|png|webp)$/.test(url) || /^data:image\/.+;base64/.test(url);
  }

  static async compressImage(base64: string): Promise<string> {
    try {
      // base64를 Blob으로 변환
      const fetchResponse = await fetch(base64);
      const blob = await fetchResponse.blob();

      // Blob URL 생성
      const blobUrl = URL.createObjectURL(blob);

      // Canvas 생성
      const img = document.createElement('img');
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 이미지 로드 대기
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = blobUrl;
      });

      // Canvas 크기 설정
      canvas.width = img.width;
      canvas.height = img.height;

      // 이미지 그리기
      ctx?.drawImage(img, 0, 0);

      // Blob URL 해제
      URL.revokeObjectURL(blobUrl);

      // Canvas를 Blob으로 변환
      const compressedBlob = await new Promise<Blob>((resolve) => {
        canvas.toBlob((blob) => resolve(blob as Blob), 'image/webp', 0.8);
      });

      // Blob을 base64로 변환
      const reader = new FileReader();
      const compressedBase64 = await new Promise<string>((resolve) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(compressedBlob);
      });

      return compressedBase64;
    } catch (error) {
      console.error('이미지 압축 실패:', error);
      return base64; // 실패 시 원본 반환
    }
  }

  static sanitize(url: string) {
    if (!url) return '';

    // data URL 처리
    if (url.startsWith('data:image/')) return url;

    // http/https URL 허용
    if (url.startsWith('http://') || url.startsWith('https://')) {
      try {
        const parsed = new URL(url);
        return parsed.toString();
      } catch {
        return '';
      }
    }

    return '';
  }

  static value(domNode: HTMLImageElement) {
    return domNode.getAttribute('src') || '';
  }

  format(name: string, value: string) {
    if (ATTRIBUTES.includes(name)) {
      if (value) {
        (this.domNode as HTMLImageElement).setAttribute(name, value);
      } else {
        (this.domNode as HTMLImageElement).removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
