import type { MessageType } from '@templates/CustomerChat/components/SupportBot/components/AlgorithmGuide/components/AutomationMessageCard';
import type { ResConsultationGuide } from '@templates/CustomerChat/components/SupportBot/components/AlgorithmGuide/hooks/use-get-algorithm-guide';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import dayjs from 'dayjs';

interface AutomationMessagePreviewDialogProps {
  type: MessageType;
  message:
    | ResConsultationGuide['lastConsultationAlgorithmMessage']
    | ResConsultationGuide['lastMarketingAlgorithmMessage'];
}

const formatDate = (dateString?: string, format = 'YYYY-MM-DD'): string => {
  const date = dayjs(dateString);
  return date.isValid() ? date.format(format) : '-';
};

const getFormattedDay = (
  type: MessageType,
  message?: AutomationMessagePreviewDialogProps['message'],
): string => {
  if (!message) return '';
  const { sendingDateTime, daysAfter } = message;

  if (type === 'MARKETING') {
    return formatDate(sendingDateTime, 'YYYY-MM-DD') || '';
  }
  return daysAfter ? `D+${daysAfter}` : '';
};

const getContentTypeText = (message?: AutomationMessagePreviewDialogProps['message']) => {
  switch (message?.contentType) {
    case 'IMAGE':
      return '이미지 포함';
    case 'VIDEO':
      return '동영상 포함';
    case 'WORKOUT_VIDEO':
      return '홈케어콘텐츠 포함';
    case 'HOME_CARE_PRODUCT':
      return '홈케어상품 포함';
    case 'EVENT':
      return '이벤트 포함';
    default:
      return '';
  }
};

export const AUTOMATION_MESSAGE_PREVIEW_DIALOG_ID = 'automation-message-preview-dialog';

export default function AutomationMessagePreviewDialog({
  type,
  message,
}: AutomationMessagePreviewDialogProps) {
  const day = getFormattedDay(type, message);

  const contentDescription = getContentTypeText(message);

  return (
    <div className='h-full px-5 py-20'>
      <Scrollbar disabledX>
        <div className='flex h-full flex-col gap-20 px-15'>
          <div className='flex items-center justify-between gap-10 bg-white900 px-16 py-8'>
            <span className='flex-shrink-0 text-Header14 text-white50'>{day}</span>
            <span className='truncate break-all text-Body13 text-white50'>
              {message?.name || '-'}
            </span>
          </div>

          <span className='whitespace-break-spaces break-all text-Body14 text-black'>
            {message?.contentText || '-'}
          </span>

          {message?.contentType && message.contentType !== 'TEXT' && (
            <div className='flex w-full flex-col gap-20'>
              <Divider type={'line'} />
              {message.contentType !== 'SURVEY' && (
                <span className='text-Body14 text-black'>{contentDescription}</span>
              )}
              {message.contentType === 'SURVEY' && message.content.survey && (
                <SurveyAnswers survey={message.content.survey} />
              )}
            </div>
          )}
        </div>
      </Scrollbar>
    </div>
  );
}

const SurveyAnswers = ({
  survey,
}: {
  survey: {
    question?: string;
    answers?: string[];
    surveyAnswerIndex?: number;
    surveyAnswerValue?: string;
  };
}) => {
  if (!survey?.answers) return null;

  return (
    <div className='flex flex-col gap-12'>
      {survey.answers.map((answer, index) => (
        <div key={`${answer}_${index}`} className='flex items-start gap-8 text-Body14 text-black'>
          <span className='flex-shrink-0'>{index + 1}.</span>
          <span className='flex-grow break-all'>{answer}</span>
        </div>
      ))}
    </div>
  );
};
