import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import BookmarkToggle from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/components/BookmarkToggle/BookmarkToggle';
import { isActivatingChatTranslateState } from 'web/templates/CustomerChat/components/ChattingRoom/states/is-activating-chat-translate';
import { isSendingMessagesState } from '../../../../../../states/isSendingMessages';
import { BASED_CHAT_QUERY } from '../../../../hooks/use-infinite-chat-data';
import { useChatMessageContext } from '../../ChatMessageContext';
import Message, { TEXT_TYPE_GROUP } from '../../Message/Message';
import { useLottieAnimation } from '../../Message/hooks/use-lottie-animation';
import MessageTimestamp from '../../MessageTimestamp';
import { MESSAGE_TYPE_CODE } from '../../constants/message';
import { makeChattingInfoStringDisplay } from '../../functions/makeChattingInfoStringDisplay';
import BottomError from './components/BottomError/BottomError';
import { useHandleErrorInMssage } from './hooks/use-handle-error-in-mssage';
import { useRightNormalChatTimeout } from './hooks/use-right-normal-chat-timeout';

export default function RightMessage() {
  const queryClient = useQueryClient();

  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);
  const resetIsSendingMessages = useResetAtom(isSendingMessagesState);

  const [isTimeError, setIsTimeError] = useState(false);

  const {
    userName,
    sentAt,
    type,
    userRealName,
    isUnread,
    status,
    isTranslated,
    _id,
    setResMessages,
    error,
    isLeft,
    isInResMessages,
  } = useChatMessageContext();

  const isMockingMessageInitial =
    (!isLeft && !isActivatingChatTranslate && type === MESSAGE_TYPE_CODE.TEXT_MOCKING) ||
    type === MESSAGE_TYPE_CODE.IMAGE_MOCKING ||
    type === MESSAGE_TYPE_CODE.FILE_MOCKING;
  const [isMockingMessage, setIsMockingMessage] = useState(isMockingMessageInitial);
  useEffect(() => {
    setIsMockingMessage(isMockingMessageInitial);
  }, [isMockingMessageInitial]);

  const isFailed = status === 'failed';
  const isSystem = type === MESSAGE_TYPE_CODE.SYSTEM;
  const displayRightUserName = makeChattingInfoStringDisplay({
    type: 'rightUserName',
    payload: { userName, userRealName },
  });

  //send한 메시지를 빠르게 보여줬을 경우(res 받기 전) 후의 res로 인해 timestamp, 읽지 않음 깜빡임을 방지하기 위한 로직
  //큰 이슈는 아닌 것 같아서 기록을 남겨놓고 추후 voc가 만약 나오면 개선.
  // const preventBlinkOnSend = !isSendingTranslate || !isLastMessage;
  const preventBlinkOnSend = true;
  const lottieRef = useLottieAnimation({ LottieName: 'messageLoading' });

  const handleRemoveMySelf = async () => {
    const { data } = await apiClient.v3.apiChatElRemove({
      _id,
      isRemoved: true,
    });
    //objectID가 아닌 uuid로 만든 id는 여기서 막혀서 res가 undefined로 나옴.
    const res = data.data;

    if (isInResMessages) {
      setResMessages((prev) => {
        if (prev) {
          const myIndex = prev.findIndex((message) => message._id === _id);
          const newResMessages = [...prev];
          newResMessages.splice(myIndex, 1);
          return newResMessages;
        }
        return prev;
      });
    } else {
      if (res.chatRoomID) {
        const query = {
          chatRoomID: res.chatRoomID,
          ...BASED_CHAT_QUERY,
        };

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiChatElFind, query],
        });
      }
    }

    resetIsSendingMessages();
  };
  //번역이 아니고&&오른쪽채팅 일 경우에 적용되는 timeout 로직
  useRightNormalChatTimeout({ isMockingMessage, setIsMockingMessage, setIsTimeError });
  const { isNull } = useHandleErrorInMssage({ error, handleRemoveMySelf });

  //error 가 있을 경우는 null로 결과를 잠깐이라도 안보여주는 동시에 useEffect를 통해 DB에서도 명시적으로 제거
  if (isNull) return null;

  //텍스트 타입이 아닌 경우에는 재전송, 삭제 기능 없음
  const isTextType = type
    ? type === TEXT_TYPE_GROUP[0] || type === MESSAGE_TYPE_CODE.TEXT_MOCKING
    : false;
  const isError = isTextType && (isFailed || isTimeError || isTranslated === false);

  return (
    <div className='mr-20 flex justify-end'>
      <div className='group flex flex-col items-end gap-8'>
        <div className='text-right text-Header12 text-black200'>
          {isSystem ? '시스템 안내' : displayRightUserName}
        </div>
        <div className='flex flex-row items-end justify-end gap-8'>
          {isMockingMessage && !isError && <div ref={lottieRef} className='h-30 w-30 pt-10' />}

          {!isMockingMessage && isUnread && preventBlinkOnSend && (
            <span className='text-Body10Bold text-black500'>읽지 않음</span>
          )}

          {!isMockingMessage && !isError && (
            <div className='flex flex-col items-end justify-end'>
              <BookmarkToggle />
              {preventBlinkOnSend && <MessageTimestamp sentAt={sentAt} />}
            </div>
          )}

          <Message />
        </div>
        {isError && <BottomError isError={isError} handleRemove={handleRemoveMySelf} />}
      </div>
    </div>
  );
}
