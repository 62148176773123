import type { Layer } from 'afterdoc-design-system/components/Atoms/PanelList/PanelList';
import PanelList from 'afterdoc-design-system/components/Atoms/PanelList/PanelList';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useSetAtom } from 'jotai';
import { clickedPanelIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/components/EventOrHomecareContentSelectDialog/components/EventOrHomecareContentPanel/states/clicked-panel-index';

const EVENT_PANEL_ITEMS: Layer[] = [
  {
    title: '이벤트',
    state: 'focus',
    items: [],
  },
  {
    title: '홈케어상품',
    state: 'default',
    items: [],
  },
];

export default function EventContentPanel() {
  const setClickedPanelIndex = useSetAtom(clickedPanelIndexState);

  return (
    <div className='h-[calc(100vh-220px)] w-full max-w-[180px] truncate border-r border-r-white400 bg-white50'>
      <Scrollbar>
        <PanelList
          layers={EVENT_PANEL_ITEMS}
          onLayerClick={(index) => setClickedPanelIndex(index)}
          subLayerClassName='px-0 pl-32 [&>#sub-layer-text]:truncate'
        />
      </Scrollbar>
    </div>
  );
}
