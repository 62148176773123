import dayjs from 'dayjs';

// Define the type for payload items
type Payload = { [key: string]: string | null | number | boolean | undefined };

const isString = (value: unknown): value is string => {
  return typeof value === 'string';
};
// Define the display functions
const displayIsFirstVisit = (payload: Payload) => {
  const { isFirstVisit } = payload;
  return isFirstVisit ? '초' : '재';
};

const displayName = (payload: Payload) => {
  const { name } = payload;
  if (typeof name !== 'string') return '';

  return name.length > 25 ? `${name.slice(0, 25)}...` : name;
};

const displayChartNumber = (payload: Payload) => {
  const { chartNumber } = payload;
  if (chartNumber) {
    const chartNumberString = chartNumber.toString();
    const chartNumberStringLimit =
      chartNumberString.length > 8 ? `${chartNumberString.slice(0, 5)}...` : chartNumberString;
    return `(${chartNumberStringLimit})`;
  }

  return '(-)';
};

const displayGenderAndBirthdate = (payload: Payload) => {
  const { gender, birthdate } = payload;

  const genderText = gender ? (gender === 'M' ? '남' : '여') : '-';

  const birthdateText =
    isString(birthdate) && dayjs(birthdate, 'YYYYMMDD', true).isValid()
      ? dayjs(birthdate, 'YYYYMMDD').format('YYYY-MM-DD')
      : '-';

  return `${genderText}/${birthdateText}`;
};
const displayCountry = (payload: Payload) => {
  const { koreanCountryName, internationalDialingCodes } = payload;
  return `${koreanCountryName}(${internationalDialingCodes})`;
};

const displayNationalityKoreanCountryName = (payload: Payload) => {
  const { nationalityKoreanCountryName } = payload;
  if (!nationalityKoreanCountryName) return '-';

  const name = nationalityKoreanCountryName.toString();
  return name.length > 5 ? `${name.slice(0, 5)}...` : name;
};

const displayPhoneNumber = (payload: Payload) => {
  const { phoneNumber } = payload;
  if (phoneNumber) {
    if (typeof phoneNumber !== 'string') return '없음';

    return `${phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')}`;
  }
  //국제 번호 스펙 아웃으로 인한 주석처리
  // if (phoneNumber && internationalDialingCodes) {
  //   if (typeof phoneNumber !== 'string' || typeof internationalDialingCodes !== 'string')
  //     return '없음';
  //   if (phoneNumber.startsWith(internationalDialingCodes)) {
  //     const localNumber = phoneNumber.slice(internationalDialingCodes.length);
  //     const formattedNumber = `0${localNumber.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')}`;
  //     return formattedNumber;
  //   }
  //   return phoneNumber;
  // }
  return '-';
};

// Initialize the map with display functions
const infoDisplayMap = new Map<string, (payload: Payload) => string>([
  ['isFirstVisit', displayIsFirstVisit],
  ['chartNumber', displayChartNumber],
  ['name', displayName],
  ['genderAndBirthdate', displayGenderAndBirthdate],
  ['country', displayCountry],
  ['phoneNumber', displayPhoneNumber],
  ['nationalityKoreanCountryName', displayNationalityKoreanCountryName],
]);

// The makeInfoDisplay function
export const makePatientInfoStringDisplay = ({
  type,
  payload,
}: { type: string; payload: Payload }) => {
  const displayFunction = infoDisplayMap.get(type);

  if (displayFunction) {
    return displayFunction(payload);
  }
};
