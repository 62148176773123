import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import TextButton from 'afterdoc-design-system/components/Atoms/Button/TextButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiPatientsElDetailData } from 'web/apis/swaggers/swagger-docs';
import ALIntegrationEdit from 'web/shared/components/ALIntegrationEdit/ALIntegrationEdit';
import { useHandleCustomerInfo } from 'web/shared/hooks/overlayPage/use-handle-customer-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { useSyncChattingListAndInfoTop } from 'web/templates/CustomerChat/components/ChattingRoom/components/CustomerInfoTop/hooks/use-sync-chatting-list-and-info-top';
import ReInvitationButton from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/ReInvitationButton';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';

const postChatRoomElCounselor = async (
  params: Parameters<typeof apiClient.v3.apiChatroomElCounselorAdd>[0],
) => {
  const response = await apiClient.v3.apiChatroomElCounselorAdd(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const postApprovalChatroomCreate = async (
  params: Parameters<typeof apiClient.v3.apiApprovalChatroomElCreate>[0],
) => {
  const response = await apiClient.v3.apiApprovalChatroomElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const postChatroomUserAdd = async (
  params: Parameters<typeof apiClient.v3.apiChatroomElUserAdd>[0],
) => {
  const response = await apiClient.v3.apiChatroomElUserAdd(params);
  return response;
};

const postCustomerDelete = async (
  params: Parameters<typeof apiClient.v3.apiHservicesElSuspend>[0],
) => {
  const response = await apiClient.v3.apiHservicesElSuspend(params);
  return response;
};

export default function CustomerInfoTitleContainer() {
  const queryClient = useQueryClient();
  const location = useLocation();

  const { updateChattingListAndInfoTop } = useSyncChattingListAndInfoTop();
  const { hospitalID, integrationSetting } = useSelectedHospitalInfo();
  const { userId, authorizationTypeID } = useUserInfo();

  const patientId = useAtomValue(patientIdState);
  const patientInfo = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  // 전능연동된 병원
  const isSmartDoctorLinkedHospital =
    integrationSetting &&
    integrationSetting.isRemoved === false &&
    !!integrationSetting.clientToken &&
    integrationSetting.clientToken.length > 0;
  // 고객 이용중단 권한이 있음
  const canBanPatientToService = !!authorizationTypeID?.canBanPatientToService;

  const {
    // 고객 id
    patientId: patientIdFromQuery,
    // 고객명
    name,
    // 애프터닥 가입 여부
    isJoinedUserToAfterDoc,
    // 카카오톡 연동 여부
    hasKakaoLinkage,
    // 스마트닥터 연동 여부
    hasSmartDoctorLinkage,
    // 이용제외 여부
    isClosed,
    // 채팅방 정보
    chatRoom: { users: chatRoomUsers, counselors: chatRoomCounselors, lastUserChat },
    recentChatRoom: { id: chatRoomID },
    recentChatRoomType,
  } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const isMoveChatButtonDisabled = useMemo(() => {
    // 애프터닥 가입자는 항상 채팅 가능
    if (isJoinedUserToAfterDoc) {
      return false;
    }

    // 이용중단 상태일 때는 채팅 내역 유무로 판단
    if (isClosed) {
      return !lastUserChat?.length;
    }

    // 미가입자는 채팅방 타입으로 판단
    if (!recentChatRoomType) {
      return true;
    }

    // afterdoc이 아닌 채팅방 타입은 허용
    return recentChatRoomType === 'afterdoc';
  }, [isClosed, lastUserChat, isJoinedUserToAfterDoc, recentChatRoomType]);

  const navigate = useNavigate();

  const { closeCustomerInfo } = useHandleCustomerInfo(patientIdFromQuery);

  const postChatRoomElCounselorMutation = useMutation({
    mutationFn: postChatRoomElCounselor,
  });

  const deleteCustomerMutation = useMutation({
    mutationFn: (params: { hserviceID: string; closeReason: string }) => postCustomerDelete(params),
    onSuccess: ({ data: { code } }) => {
      if (code === 0) {
        toastService.successMsg({
          text: '이용중단이 완료되었습니다.',
        });
        closeCustomerInfo();
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElListOrSearch] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElCount, { hospitalID }] });
        modalService.popById('interrupt-customer');
      } else {
        toastService.errorMsg({
          text: '처리를 실패했습니다.',
        });
      }
    },
    onError: (error) => {
      console.warn('error', error);
      toastService.errorMsg({
        text: '처리를 실패했습니다.',
      });
    },
  });

  const postApprovalChatroomCreateMutation = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiApprovalChatroomElCreate>[0]) =>
      postApprovalChatroomCreate(params),
    onSuccess: () => {
      modalService.defaultSuccess({
        id: 'chat-join-request-success',
        title: '채팅 참여',
        contents: `${chatRoomCounselors.map((user) => user.realName).join(', ')}님에게 참여 신청을 전송하였습니다.\n수락 시 채팅 리스트에서 확인할 수 있습니다.`,
        buttonText: '확인',
        onConfirm: () => {
          modalService.popById('chat-join-request-success');
        },
      });
    },
    onError: (error) => {
      console.warn('error', error);
    },
  });

  const mutationPostChatroomUserAdd = useMutation({
    mutationFn: (params: { chatRoomID: string; userIDs: string[] }) => postChatroomUserAdd(params),
    onError: (error) => {
      console.warn('error', error);
    },
    onSuccess: ({ data: { code, message, data } }) => {
      if (code === 0) {
        modalService.defaultSuccess({
          id: 'chat-join-request-success',
          title: '채팅 참여',
          contents: `${data.users?.map((user) => user.realName).join(', ')}님에게 참여 신청을 전송하였습니다.\n수락 시 채팅 리스트에서 확인할 수 있습니다.`,
          buttonText: '확인',
          onConfirm: () => {
            modalService.popById('chat-join-request-success');
          },
        });
      } else {
        toastService.errorMsg({
          text: message,
        });
      }
    },
  });

  const handleBackClick = () => {
    closeCustomerInfo();
  };

  const onMoveChatClick = () => {
    // Case 1. 상담 최고 권한 있음
    if (authorizationTypeID?.canControlPatientChatting) {
      if (location.pathname === '/customer-chat' && chatRoomID) {
        closeCustomerInfo();
        return;
      }

      // 고객 상담 - 해당 환자의 채팅방 화면으로 점프
      return navigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
    }

    // Case 2. 상담 최고 권한 없음, 해당 환자의 채팅방에 참여중인 경우
    if (!authorizationTypeID?.canControlPatientChatting) {
      if (chatRoomUsers.findIndex((user) => user._id === userId) !== -1) {
        if (location.pathname === '/customer-chat' && patientIdFromQuery && chatRoomID) {
          closeCustomerInfo();
          return;
        }

        // chatRoom.users 의 _id 목록에 로그인한 사용자의 userID 가 있는지 대조.
        //  해당 환자의 채팅방 화면으로 점프
        return navigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
      }

      // Case 2-1. 상담 최고 권한 없음, 해당 환자의 채팅방에 참여하고 있지 않은 경우
      // chatRoom.counselors 배열 값이 비어있지 않은지 체크
      if (chatRoomCounselors.length > 0) {
        // 담당자가 존재하는 경우
        modalService.defaultSuccess({
          id: 'chat-join',
          title: '채팅 참여',
          buttonType: 'CUSTOM',
          bottomButtons: (
            <>
              <OutlinedButton
                className='w-full'
                btnColor='blue'
                onClick={() => {
                  modalService.popById('chat-join');
                }}>
                취소
              </OutlinedButton>
              <ContainedButton
                className='w-full'
                btnColor='blue'
                onClick={() => {
                  modalService.popById('chat-join');
                  postApprovalChatroomCreateMutation.mutate({
                    chatRoomID,
                    hospitalID,
                    type: 'join',
                  });
                }}>
                확인
              </ContainedButton>
            </>
          ),
          contents: '참여하지 않은 채팅입니다. 채팅에 참여하시겠습니까?',
        });
      } else {
        // Case 2-2. 상담 최고 권한 없음, 해당 환자의 채팅방에 참여하고 있지않으나, 담당자가 비어있는 경우.
        // 담당자가 존재하지 않는 경우
        modalService.defaultSuccess({
          id: 'chat-join',
          title: '채팅 참여',
          buttonType: 'CUSTOM',
          bottomButtons: (
            <>
              <OutlinedButton
                className='w-full'
                btnColor='blue'
                onClick={() => {
                  modalService.popById('chat-join');
                }}>
                취소
              </OutlinedButton>
              <ContainedButton
                className='w-full'
                btnColor='blue'
                onClick={() => {
                  if (patientInfo && !patientInfo.chatRoom.counselors.length) {
                    postChatRoomElCounselorMutation.mutate(
                      {
                        chatRoomID: patientInfo.chatRoom.id,
                        counselors: [userId],
                      },
                      {
                        onSuccess: () => {
                          navigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
                        },
                      },
                    );
                  } else {
                    mutationPostChatroomUserAdd.mutate(
                      {
                        chatRoomID,
                        userIDs: [userId],
                      },
                      {
                        onSuccess: () => {
                          navigate(`/customer-chat?chatRoomId=${chatRoomID}&reload=${Date.now()}`);
                        },
                      },
                    );
                  }
                  modalService.popById('chat-join');
                }}>
                확인
              </ContainedButton>
            </>
          ),
          contents: '참여하지 않은 채팅입니다. 채팅에 참여하시겠습니까?',
        });
      }
    }
  };

  const onDeleteCustomer = () => {
    deleteCustomerMutation.mutate({
      hserviceID: patientIdFromQuery,
      closeReason: '이용중단',
    });
  };

  const onOpenALIntegrationEdit = () => {
    dialogService.push(
      <ALIntegrationEdit patientId={patientIdFromQuery} dialogId={'ALIntegrationEdit'} />,
      {
        width: 'auto',
        id: 'ALIntegrationEdit',
      },
    );
    updateChattingListAndInfoTop();
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        modalService.popById('leave-without-save');
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className='flex items-center gap-6'>
      <IconButton icon='chevron-left' size={24} onClick={handleBackClick} />
      <div className='w-full'>
        <Title
          wrapperClassName='px-0 pb-0'
          title={`${name}님의 상세정보`}
          adjacentChildren={{
            children: (
              <div className='flex items-center gap-8'>
                {isClosed ? (
                  <div className='flex items-center gap-4 rounded-r10 bg-disabled py-[3.5px] pr-10 pl-6'>
                    <Icon name='warning' color='black500' size={16} />
                    <span className='text-Body12 text-black500'>이용중단</span>
                  </div>
                ) : (
                  <>
                    <HoverTooltip
                      portalZindex={9999}
                      text={`애프터닥 ${isJoinedUserToAfterDoc ? '가입' : '미가입'} 고객입니다.`}
                      position='bottomRight'
                      wrapperProps={{
                        className: 'cursor-default',
                      }}>
                      {isJoinedUserToAfterDoc ? (
                        <Icon name='afterdoc' />
                      ) : (
                        <Icon name='afterdoc-non' />
                      )}
                    </HoverTooltip>
                    {hasKakaoLinkage && (
                      <HoverTooltip
                        text='카카오톡으로 유입된 고객입니다.'
                        portalZindex={9999}
                        position='bottomRight'
                        wrapperProps={{
                          className: 'cursor-default',
                        }}>
                        <Icon name='kakaotalk' />
                      </HoverTooltip>
                    )}
                  </>
                )}
                {isSmartDoctorLinkedHospital && (
                  <HoverTooltip
                    text={`스마트닥터에 계정 ${hasSmartDoctorLinkage ? '연동된' : '미연동된'} 고객입니다.`}
                    offsetY={-4}
                    position='bottomRight'>
                    <button
                      type='button'
                      className='flex items-center gap-4 rounded-r6 border border-white600 bg-white50 px-5 py-3'
                      onClick={(e) => {
                        e.stopPropagation();
                        onOpenALIntegrationEdit();
                      }}>
                      <Icon
                        name={hasSmartDoctorLinkage ? 'smart-doctor' : 'smart-doctor-non'}
                        size={17}
                      />
                      <Icon name='chevron-right' size={16} color='black200' />
                    </button>
                  </HoverTooltip>
                )}
              </div>
            ),
            position: 'right',
          }}
          trailingChildren={
            <div className='flex gap-40'>
              <Suspense>
                <ReInvitationButton />
              </Suspense>
              {!isClosed && canBanPatientToService && (
                <TextButton
                  onClick={() => {
                    modalService.defaultWarning({
                      id: 'interrupt-customer',
                      title: `${name}님을 이용중단할까요?`,
                      contents: (
                        <div className='whitespace-pre-wrap'>
                          {
                            "이용중단 시 고객이 사용중인 앱 서비스의 이용이 제한됩니다. 이용중단 하시려면 '네'를 선택해 주세요."
                          }
                        </div>
                      ),
                      onConfirm: onDeleteCustomer,
                    });
                  }}>
                  이용중단
                </TextButton>
              )}
            </div>
          }
        />
        <button
          className='mt-2 flex items-center gap-4 py-2'
          disabled={isMoveChatButtonDisabled}
          type='button'
          onClick={onMoveChatClick}>
          <Icon name='chat' color={isMoveChatButtonDisabled ? 'disabled' : 'blue500'} size={20} />
          <span
            className={customTwMerge(
              'text-Body13',
              isMoveChatButtonDisabled ? 'text-disabled' : 'text-blue500',
            )}>
            채팅으로 이동
          </span>
        </button>
      </div>
    </div>
  );
}
