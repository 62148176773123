import { useEffect, useRef } from 'react';

interface UseHandleTextAreaEventsProps {
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  onSend: () => void;
}

export const useHandleTextAreaEvents = ({ textAreaRef, onSend }: UseHandleTextAreaEventsProps) => {
  const isComposingRef = useRef(false);

  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd = () => {
    isComposingRef.current = false;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();

    if (
      event.key === 'Enter' &&
      textAreaRef.current &&
      document.activeElement === textAreaRef.current &&
      !isComposingRef.current
    ) {
      if (event.shiftKey) return; // Shift + Enter인 경우 줄바꿈을 허용

      event.preventDefault();
      onSend();
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const textarea = textAreaRef.current;

    if (textarea) {
      textarea.addEventListener('compositionstart', handleCompositionStart);
      textarea.addEventListener('compositionend', handleCompositionEnd);
      textarea.addEventListener('keydown', handleKeyDown);

      return () => {
        textarea.removeEventListener('compositionstart', handleCompositionStart);
        textarea.removeEventListener('compositionend', handleCompositionEnd);
        textarea.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [textAreaRef, onSend]); //맨 처음 실행되면 enter가 미동작그래서 onClinckSend를 넣어줌
};
