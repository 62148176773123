import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { useAtomValue } from 'jotai';
import { Suspense, memo } from 'react';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import FinalSaveButton from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/components/EditorModeButtons/components/FinalSaveButton';
import TemporarySaveButton from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/components/EditorModeButtons/components/TemporarySaveButton';
import { useLockRefreshTimer } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/components/EditorModeButtons/hooks/use-lock-refresh-timer';
import { useOpenLeaveWithoutSavingModal } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-open-leave-without-saving-modal';
import { useResetTable } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-reset-table/use-reset-table';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';

function EditorModeButtons() {
  const { resetTable, revertTableData } = useResetTable();

  const { openLeaveWithoutSavingModal } = useOpenLeaveWithoutSavingModal();
  const { hospitalID } = useSelectedHospitalInfo();

  const customerTableDisplayMode = useAtomValue(customerTableDisplayModeState);
  const isEditorMode = customerTableDisplayMode === 'EDITOR';

  useLockRefreshTimer(hospitalID, isEditorMode);

  return (
    <div className='flex gap-10'>
      <OutlinedButton
        onClick={() => {
          openLeaveWithoutSavingModal({
            successCb: async () => {
              resetTable();
              await revertTableData();
            },
          });
        }}
        btnColor='red'>
        취소
      </OutlinedButton>
      <Suspense fallback={<OutlinedButton btnColor='blue'>임시 저장</OutlinedButton>}>
        <TemporarySaveButton />
      </Suspense>
      <Suspense fallback={<ContainedButton btnColor='blue'>저장</ContainedButton>}>
        <FinalSaveButton />
      </Suspense>
    </div>
  );
}

export default memo(EditorModeButtons);
