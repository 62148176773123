export const MESSAGE_TEXT_MAX_LENGTH = 500;
export const urlRegex = /(https?:\/\/[^\s]+|www\.[a-zA-Z0-9]+\.[^\s]+)/g;
export const MESSAGE_TYPE_CODE = {
  SYSTEM: 40000,
  TEXT_MOCKING: 50100,
  IMAGE_MOCKING: 50300,
  FILE_MOCKING: 50500,
};
export const USER_TYPE_CODE = {
  PATIENT: 1000,
};

export const MOCKING_NUMBERS = Object.entries(MESSAGE_TYPE_CODE)
  .filter(([key]) => key.includes('_MOCKING'))
  .map(([, value]) => value);

export const MOCKING_IMAGE_LOCATION = '/images/MockingImage.png';
export const ERROR_IMAGE_LOCATION = '/images/ErrorImage.png';
