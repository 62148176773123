import { JOB_ITEMS } from '@templates/HospitalSetting/containers/AllAccountSetting/constants/job-items';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import TextInput, {
  type TextInputLabelTextProps,
} from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

const baseLabelProps: TextInputLabelTextProps = {
  isRequired: true,
  children: '직책/직급',
  width: 60,
  position: 'horizontal',
};

export default function EditAccountJob() {
  const [jobIndex, setJobIndex] = useState<number | undefined>(undefined);
  const [jobTypeText, setJobTypeText] = useState('');
  const [isDirectlySelected, setIsDirectlySelected] = useState(false);

  const {
    setValue,
    getValues,
    register,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const handleChange = () => {
    setIsDirectlySelected(!isDirectlySelected);
  };

  useEffect(() => {
    register('jobType');
  }, [register, isDirectlySelected]);

  useEffect(() => {
    let value: string | undefined;
    if (isDirectlySelected) {
      value = jobTypeText;
    } else {
      value = jobIndex !== undefined ? JOB_ITEMS[jobIndex] : undefined;
    }
    clearErrors('jobType');
    setValue('jobType', value);
  }, [isDirectlySelected, jobTypeText, jobIndex]);

  useEffect(() => {
    const initialJobType = getValues('jobType');
    if (initialJobType) {
      if (JOB_ITEMS.includes(initialJobType)) {
        setJobIndex(JOB_ITEMS.indexOf(initialJobType));
      } else {
        setJobTypeText(initialJobType);
        setIsDirectlySelected(true);
      }
    }
  }, []);

  return (
    <div className='flex w-full flex-col gap-4'>
      <div className=' flex-center gap-10'>
        {isDirectlySelected ? (
          <TextInput
            id='jobType'
            placeholder={'직책/직급 입력'}
            maxLength={15}
            width={188}
            className='flex-grow'
            value={jobTypeText}
            onChange={(e) => setJobTypeText(e.target.value)}
            label={baseLabelProps}
          />
        ) : (
          <TextFieldSelectBoxDropdown
            label={baseLabelProps}
            options={JOB_ITEMS}
            onSelect={(index) => setJobIndex(index)}
            dropdownWrapperClassName='ml-[70px]'
            wrapperClassName='flex-grow'
            width={188}
            defaultValue='선택'
            selectedIndex={jobIndex}
          />
        )}
        <Checkbox
          label='직접입력'
          gapSize={5}
          checked={isDirectlySelected}
          onChange={handleChange}
        />
      </div>
      {!!errors.jobType && (
        <span className='ml-70 text-Body10 text-red500'>직책/직급을 입력해 주세요.</span>
      )}
    </div>
  );
}
