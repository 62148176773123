import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import HospitalSettingContainer from 'web/templates/HospitalSetting/HospitalSetting.container';

export default function HospitalSettingPage() {
  return (
    <Scrollbar isAlwaysVisible>
      <HospitalSettingContainer />
    </Scrollbar>
  );
}
