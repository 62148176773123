import SearchInput from 'afterdoc-design-system/components/Atoms/Input/SearchInput';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { useAtomValue } from 'jotai';
import { type MouseEvent, useState } from 'react';
import { useSearchInput } from 'web/templates/CustomerChat/components/ChattingList/hooks/use-search-input';
import { useChatRoomInfoOne } from 'web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import SendingTopLine from 'web/templates/CustomerChat/components/Manual/components/SendingTopLine/SendingTopLine';
import { ROOM_TYPE_MAP } from 'web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import { isDisabledChat } from 'web/templates/CustomerChat/functions/is-disabled-Chat';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import {
  chatRoomHserviceIDSelector,
  chatRoomIDSelector,
  roomTypeSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import { NoData } from '../../../NoData';
import { postSendEvent } from './apis/post-send-event';
import { postSendHomecareProduct } from './apis/post-send-homecare-product';
import { postSendNotice } from './apis/post-send-notice';
import { postSendVideos } from './apis/post-send-videos';
import HomeCareProductAndEventItem from './components/HomeCareProductAndEventItem';
import { HomeCareVideoItem } from './components/HomeCareVideoItem';
import NoticeItem from './components/NoticeItem';
import { makePlaceholder } from './functions/make-placeholder';
import { useContentsData } from './hooks/use-contents-data';
import type { EventData, NoticeData, VideoData } from './types';

const MAX_NUM = 3;
const NOTICE_MAX_NUM = 1;

export default function ContentsFilesSelect({
  categoryID,
  categoryTitle,
}: { categoryID: string; categoryTitle: string }) {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const chatRoomHserivceID = useAtomValue(chatRoomHserviceIDSelector);

  const { patientId } = usePatient();
  const { ikakaoInfo } = useChatRoomInfoOne({
    chatRoomID: chatRoomID ?? '',
  });
  const roomType = useAtomValue(roomTypeSelector);
  const hserviceID = roomType === ROOM_TYPE_MAP.kakao ? chatRoomHserivceID : (patientId ?? '');

  const { iconSet } = useChatTopPatient();

  const [isDisabledSendButton, setIsDisabledSendButton] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState<string[]>([]);

  const { inputValue, keyword, handleChange, handleKeyDown, handleDelete } = useSearchInput();

  const isNoticeOrEvent =
    categoryTitle === '공지사항' || categoryTitle === '이벤트' || categoryTitle === '홈케어 상품';

  const { data, isLoading } = useContentsData({ categoryTitle, categoryID, keyword });

  const toggleSelectItem = (e: MouseEvent<HTMLDivElement>, id: string) => {
    e.preventDefault();

    setSelectedIDs((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id],
    );
  };

  const sendContentsToChat = async () => {
    if (!chatRoomID) return;
    if (!patientId) return;
    setIsDisabledSendButton(true);
    switch (categoryTitle) {
      //데이터 푸쉬로만 등장
      case '홈케어 상품': {
        const res = await postSendHomecareProduct({
          chatRoomID,
          hserviceID,
          haftercareID: selectedIDs[0],
        });
        res && setSelectedIDs([]);

        break;
      }
      case '이벤트': {
        const res = await postSendEvent({
          chatRoomID,
          hserviceID: patientId,
          heventID: selectedIDs?.[0],
        });

        res && setSelectedIDs([]);

        break;
      }
      case '공지사항': {
        const res = await postSendNotice({
          chatRoomID,
          hserviceID: patientId,
          hnoticeID: selectedIDs[0],
        });

        res && setSelectedIDs([]);

        break;
      }

      //통증 및 재활 영상(홈케어 영상)은 하위 카테고리(categoryTitle)가 목, 어깨 이런거라 default로 퉁쳐서 처리.
      default: {
        const res = await postSendVideos({
          chatRoomID,
          hserviceID,
          vchIDs: selectedIDs,
        });

        res && setSelectedIDs([]);
      }
    }
    setIsDisabledSendButton(false);
  };

  const isData = data.length > 0;

  const isChatRoomNotNull = !(chatRoomID === null);

  //useIsDisabledChat를 사용하면 렌더링 queue가 꼬여서 isDisabledChat을 사용함.
  const isSendButtonDisabled = isDisabledChat({ roomType, ikakaoInfo, iconSet });

  return (
    <div className='flex flex-col'>
      <div className='p-10'>
        <SearchInput
          placeholder={makePlaceholder(categoryTitle)}
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onDelete={handleDelete}
        />
      </div>
      {isData && isChatRoomNotNull && !!selectedIDs.length && (
        <SendingTopLine
          selectedLength={selectedIDs.length}
          maxSelectNumber={isNoticeOrEvent ? NOTICE_MAX_NUM : MAX_NUM}
          sendFunction={sendContentsToChat}
          isDisabled={isDisabledSendButton || isSendButtonDisabled}
        />
      )}

      <div className='mx-auto grid grid-cols-2 justify-items-start gap-x-20 gap-y-10'>
        {isLoading && (
          <div className='col-span-2 h-[222px] w-full flex-center'>
            <BaseLoading />
          </div>
        )}
        {!isData && !isLoading && (
          <div className='col-span-2 h-[222px] w-full'>
            <NoData />
          </div>
        )}
        {isData &&
          data.map((item) => {
            const isSelected = selectedIDs.some((id) => id === item.id);
            const isSelectable =
              selectedIDs.length < (isNoticeOrEvent ? NOTICE_MAX_NUM : MAX_NUM) || isSelected;
            const isAbleSelect = isChatRoomNotNull && isSelectable;

            switch (categoryTitle) {
              case '홈케어 상품':
              case '이벤트': {
                return (
                  <HomeCareProductAndEventItem
                    key={item.id}
                    eventData={item as EventData}
                    toggleSelectItem={toggleSelectItem}
                    isSelected={isSelected}
                    isAbleSelect={isAbleSelect}
                    categoryTitle={categoryTitle}
                  />
                );
              }
              case '공지사항': {
                return (
                  <NoticeItem
                    key={item.id}
                    noticeData={item as NoticeData}
                    toggleSelectItem={toggleSelectItem}
                    isSelected={isSelected}
                    isSelectable={isSelectable}
                    isChatRoomNotNull={isChatRoomNotNull}
                  />
                );
              }
              //통증 및 재활 영상(홈케어 영상)은 하위 카테고리(categoryTitle)가 목, 어깨 이런거라 default로 퉁쳐서 처리.
              default: {
                return (
                  <HomeCareVideoItem
                    key={item.id}
                    homecareVideoData={item as VideoData}
                    toggleSelectItem={toggleSelectItem}
                    isSelected={isSelected}
                    isAbleSelect={isAbleSelect}
                  />
                );
              }
            }
          })}
      </div>
    </div>
  );
}
