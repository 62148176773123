import { useCheckIsOverflow } from '@shared-hooks/use-check-is-overflow';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import CursorTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/CursorTooltip';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useRef } from 'react';
import type { OngoingAutomation } from 'web/apis/swaggers/swagger-docs';
import { useOpenLeaveWithoutSavingModal } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-open-leave-without-saving-modal';
import { clickedPanelIndexState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import { selectedCardInfoState } from '../../../state/selected-card-info';

interface OnGoingConsultingAutomationCardProps extends OngoingAutomation {}

export default function OnGoingConsultingAutomationCard({
  algorithmId,
  algorithmName,
  appliedNationalityNames,
  appliedTagNames,
  tagsHaveFolder,
  tagsHaveNoFolder,
}: OnGoingConsultingAutomationCardProps) {
  const nameRef = useRef<HTMLDivElement>(null);
  const nationalitiesRef = useRef<HTMLDivElement>(null);

  const resetClickedPanelIndex = useResetAtom(clickedPanelIndexState);

  const [selectedCardInfo, setSelectedCardInfo] = useAtom(selectedCardInfoState);

  const { isOverflow } = useCheckIsOverflow(nationalitiesRef);
  const { openLeaveWithoutSavingModal } = useOpenLeaveWithoutSavingModal();

  const handleClick = () => {
    if (selectedCardInfo?.algorithmId === algorithmId) return;

    openLeaveWithoutSavingModal({
      successCb: () => {
        resetClickedPanelIndex();
        setSelectedCardInfo({
          algorithmId,
          algorithmName,
          appliedNationalityNames,
          appliedTagNames,
          tagsHaveFolder,
          tagsHaveNoFolder,
        });
      },
      settledCb: () => {
        resetClickedPanelIndex();
        setSelectedCardInfo({
          algorithmId,
          algorithmName,
          appliedNationalityNames,
          appliedTagNames,
          tagsHaveFolder,
          tagsHaveNoFolder,
        });
      },
    });
  };

  const nationalities =
    appliedNationalityNames.length > 0 ? appliedNationalityNames.join(', ') : '';

  return (
    <div
      className={customTwMerge(
        'flex cursor-pointer flex-col justify-center gap-4 rounded-r16 border border-white600 px-20 py-16',
        selectedCardInfo?.algorithmId === algorithmId ? 'bg-blue50' : 'bg-white50',
      )}
      onClick={handleClick}>
      <div className={customTwMerge('flex items-center justify-between')}>
        <span className='truncate text-Header14 text-black500' ref={nameRef}>
          {algorithmName}
        </span>
        <HoverTooltip
          show={!!appliedTagNames?.length}
          text={appliedTagNames?.join(', ')}
          position='bottomLeft'
          offset={16}
          style={{
            maxWidth: '280px',
          }}>
          <Icon name='info-circle-outline' color='black200' size={16} />
        </HoverTooltip>
      </div>
      <CursorTooltip text={nationalities} show={isOverflow}>
        <div className='truncate text-Body13 text-black300' ref={nationalitiesRef}>
          {nationalities}
        </div>
      </CursorTooltip>
    </div>
  );
}
