import * as React from "react";
import type { SVGProps } from "react";
const SvgAfterdoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <rect width={18} height={18} x={0.5} y={0.5} fill="#E6F2FF" rx={5.5} />
    <rect width={18} height={18} x={0.5} y={0.5} stroke="#fff" rx={5.5} />
    <path
      fill="#2360FF"
      d="M12.184 2.499a7.496 7.496 0 0 0-7.868 12.417C4 15.63 3.443 16.31 3.124 16.64a.17.17 0 0 0 .118.288 15.8 15.8 0 0 0 3.72-.377A7.496 7.496 0 0 0 12.186 2.5z"
    />
    <path
      fill="#fff"
      d="m13.418 6.586-3.149-1.818a1.27 1.27 0 0 0-1.264 0L5.856 6.586c-.39.225-.632.642-.632 1.095v3.634c0 .452.241.869.632 1.096l3.149 1.817c.39.226.874.226 1.264 0l3.149-1.817c.39-.226.632-.643.632-1.096V7.681c0-.451-.242-.868-.632-1.095m.385 3.775a.16.16 0 0 1-.079.137l-1.097.638v1.192a.16.16 0 0 1-.08.137l-1.178.685a.058.058 0 0 1-.087-.05l.013-1.396-1.2.65c-.038.022-.1-.019-.1-.063v-1.359a.16.16 0 0 1 .079-.137l1.208-.661V8.867a.16.16 0 0 1 .079-.138l1.179-.685a.058.058 0 0 1 .087.05V9.53l1.09-.59a.058.058 0 0 1 .086.05z"
    />
  </svg>
);
export default SvgAfterdoc;
