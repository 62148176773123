import {
  mdiAccountAlert,
  mdiAccountCheck,
  mdiAccountEye,
  mdiAccountEyeOutline,
  mdiAlert,
  mdiApplicationOutline,
  mdiArrowLeft,
  mdiArrowRight,
  mdiArrowRightBottom,
  mdiArrowUp,
  mdiAutoFix,
  mdiBadgeAccountHorizontalOutline,
  mdiBell,
  mdiBellOutline,
  mdiBookCog,
  mdiBookCogOutline,
  mdiCakeVariantOutline,
  mdiCalendarBlankOutline,
  mdiCalendarCheckOutline,
  mdiCalendarRemoveOutline,
  mdiCellphone,
  mdiCheck,
  mdiChevronDoubleLeft,
  mdiChevronDoubleRight,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiClose,
  mdiCog,
  mdiCogOutline,
  mdiEmoticon,
  mdiEmoticonOutline,
  mdiEyedropperVariant,
  mdiFileCheckOutline,
  mdiFileDownloadOutline,
  mdiFileMoveOutline,
  mdiFileRemoveOutline,
  mdiFilterOutline,
  mdiFolderOpenOutline,
  mdiFolderOutline,
  mdiFolderQuestionOutline,
  mdiFormatBold,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatItalic,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiFormatQuoteClose,
  mdiFormatStrikethrough,
  mdiFormatUnderline,
  mdiGroup,
  mdiHelp,
  mdiHumanGreetingProximity,
  mdiHumanMaleBoardPoll,
  mdiImageMultipleOutline,
  mdiImageOffOutline,
  mdiImageOutline,
  mdiImagePlus,
  mdiInformationOutline,
  mdiLinkVariant,
  mdiMagnify,
  mdiMagnifyScan,
  mdiMenu,
  mdiMinus,
  mdiOpenInNew,
  mdiPageFirst,
  mdiPageLast,
  mdiPaperclip,
  mdiPlay,
  mdiPlayCircleOutline,
  mdiPlus,
  mdiPlusCircle,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
  mdiRedo,
  mdiReplay,
  mdiReply,
  mdiSelectionDrag,
  mdiSend,
  mdiSortAscending,
  mdiSortDescending,
  mdiTag,
  mdiTrashCanOutline,
  mdiTrayArrowDown,
  mdiTrayArrowUp,
  mdiUndo,
  mdiUnfoldMoreHorizontal,
  mdiViewCarouselOutline,
  mdiViewDayOutline,
  mdiVolumeHigh,
  mdiWeightLifter,
} from '@mdi/js';

export const MDIconsPaths = {
  cog: mdiCog,
  'cog-outline': mdiCogOutline,
  bell: mdiBell,
  'bell-outline': mdiBellOutline,
  search: mdiMagnify,
  'arrow-left': mdiArrowLeft,
  'arrow-right': mdiArrowRight,
  'arrow-top': mdiArrowUp,
  'add-circle': mdiPlusCircle,
  send: mdiSend,
  warning: mdiAlert,
  close: mdiClose,
  'open-in-new': mdiOpenInNew,
  'birthday-cake-outline': mdiCakeVariantOutline,
  cellphone: mdiCellphone,
  'person-alert': mdiAccountAlert,
  'person-alert-blue': mdiAccountAlert,
  'chevron-up': mdiChevronUp,
  'chevron-down': mdiChevronDown,
  'chevron-left': mdiChevronLeft,
  'chevron-right': mdiChevronRight,
  'left-last-page': mdiPageFirst,
  'right-last-page': mdiPageLast,
  'filter-outline': mdiFilterOutline,
  'radio-button-checked': mdiRadioboxMarked,
  'radio-button-unchecked': mdiRadioboxBlank,
  'calendar-blank-outline': mdiCalendarBlankOutline,
  'calendar-check-outline': mdiCalendarCheckOutline,
  'calendar-remove-outline': mdiCalendarRemoveOutline,
  emoji: mdiEmoticon,
  'emoji-outline': mdiEmoticonOutline,
  'hamburger-menu': mdiMenu,
  'un-fold-more-horizontal': mdiUnfoldMoreHorizontal,
  'volume-high': mdiVolumeHigh,
  done: mdiCheck,
  'attach-file': mdiPaperclip,
  replay: mdiReplay,
  'file-download-outline': mdiFileDownloadOutline,
  'file-remove-outline': mdiFileRemoveOutline,
  'file-check-outline': mdiFileCheckOutline,
  'file-move-outline': mdiFileMoveOutline,
  'sort-ascending': mdiSortAscending,
  'sort-descending': mdiSortDescending,
  'human-male-board-poll': mdiHumanMaleBoardPoll,
  'human-greeting-proximity': mdiHumanGreetingProximity,
  'account-view-outline': mdiAccountEyeOutline,
  'account-view': mdiAccountEye,
  'book-cog-outline': mdiBookCogOutline,
  'book-cog': mdiBookCog,
  group: mdiGroup,
  'account-badge-horizontal-outline': mdiBadgeAccountHorizontalOutline,
  'fitness-center': mdiWeightLifter,
  'folder-outline': mdiFolderOutline,
  'folder-open-outline': mdiFolderOpenOutline,
  'tray-upload': mdiTrayArrowUp,
  'tray-download': mdiTrayArrowDown,
  tag: mdiTag,
  'play-circle-outline': mdiPlayCircleOutline,
  'iframe-outline': mdiApplicationOutline,
  'magnify-scan': mdiMagnifyScan,
  'person-tick': mdiAccountCheck,
  'trash-can-outline': mdiTrashCanOutline,
  'link-variant': mdiLinkVariant,
  'question-mark': mdiHelp,
  'folder-question-outline': mdiFolderQuestionOutline,
  colorize: mdiEyedropperVariant,
  plus: mdiPlus,
  minus: mdiMinus,
  'auto-fix-high': mdiAutoFix,
  undo: mdiUndo,
  redo: mdiRedo,
  'format-list-numbers': mdiFormatListNumbered,
  'format-list-bulleted': mdiFormatListBulleted,
  'format-bold': mdiFormatBold,
  'format-strikethrough': mdiFormatStrikethrough,
  'format-underline': mdiFormatUnderline,
  'format-italic': mdiFormatItalic,
  'image-plus': mdiImagePlus,
  'format-header-1': mdiFormatHeader1,
  'format-header-2': mdiFormatHeader2,
  'format-header-3': mdiFormatHeader3,
  'format-quote-close': mdiFormatQuoteClose,
  play: mdiPlay,
  'image-filter': mdiImageMultipleOutline,
  reply: mdiReply,
  'selection-drag': mdiSelectionDrag,
  'view-carousel-outline': mdiViewCarouselOutline,
  'view-day-outline': mdiViewDayOutline,
  'arrow-right-bottom': mdiArrowRightBottom,
  'image-off-outline': mdiImageOffOutline,
  'image-outline': mdiImageOutline,
  'chevron-double-left': mdiChevronDoubleLeft,
  'chevron-double-right': mdiChevronDoubleRight,
  'info-circle-outline': mdiInformationOutline,
} as const;
