import EmojiPicker from 'emoji-picker-react';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef } from 'react';
import { isEmptyWithTrim } from 'web/templates/CustomerChat/functions/isEmptyWithTrim';
import { useIsDisabledChat } from 'web/templates/CustomerChat/hooks/use-is-disabled-chat';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { useChatRoomInfoOne } from '../../hooks/use-chatroom-info-one';
import { isSettingBottomTranslateLanguageState } from '../../states/is-setting-sending-translate-language';
import ChatTextArea from './components/ChatTextArea/ChatTextArea';
import ChattingSendingBottom from './components/ChattingSendingBottom/ChattingSendingBottom';
import TranslateLanguageSettingBlock from './components/TranslateLanguageSettingBlock/TranslateLanguageSettingBlock';
import { useChatInput } from './hooks/use-chat-input';
import { useEmojiPicker } from './hooks/use-emoji-picker';
import { useReservationListener } from './hooks/use-reservation-listener';

export default function ChattingSending() {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  if (!chatRoomID) return null;

  const isSettingBottomTranslateLanguage = useAtomValue(isSettingBottomTranslateLanguageState);
  const resetIsSettingBottomTranslateLanguage = useResetAtom(isSettingBottomTranslateLanguageState);

  const { isCounselInProgress, languageID } = useChatRoomInfoOne({
    chatRoomID,
  });

  const isDisabledSendingBottom = useIsDisabledChat();

  const placeholder = () => {
    if (isSettingBottomTranslateLanguage) return '';

    if (isDisabledSendingBottom) return '고객 정보를 알 수 없어 메시지를 보낼 수 없습니다.';

    if (isCounselInProgress === undefined || isCounselInProgress) {
      return '메시지를 입력해주세요.';
    }
    return '상담 종료한 채팅방입니다.\n상담을 진행하시려면 메시지를 입력해주세요.';
  };

  const { text, setText, handleChange, handleSend } = useChatInput();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useReservationListener(setText, isDisabledSendingBottom);

  const { showEmojiPicker, emojiPickerRef, toggleEmojiPicker, onEmojiClick } = useEmojiPicker(
    setText,
    textAreaRef,
  );

  //chatRoomID가 바뀔때 한번 렌더링이 발생하는 곳이면서 useChatInput를 선언하는 곳이기 때문에 여기서 초기화를 해준다.
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    resetIsSettingBottomTranslateLanguage();
    setText('');
  }, [chatRoomID]);

  return (
    <div
      className={`relative flex h-auto w-full flex-col ${isDisabledSendingBottom ? 'bg-white200' : 'bg-white50'}`}>
      {isSettingBottomTranslateLanguage && <TranslateLanguageSettingBlock />}
      <div className={`relative ${isSettingBottomTranslateLanguage ? 'pointer-events-none' : ''}`}>
        <ChatTextArea
          textAreaRef={textAreaRef}
          placeholder={placeholder()}
          onChange={handleChange}
          isShowBottom={false}
          errorText='에러 메시지'
          value={text}
          noBorder={true}
          maxLength={1000}
          maxHeight={185}
          className={`overflow-hidden ${isDisabledSendingBottom ? 'text-black200' : 'text-black700'}`}
          disabled={isDisabledSendingBottom}
        />
        {showEmojiPicker && (
          <div
            ref={emojiPickerRef}
            style={{
              position: 'absolute',
              top: '-375px',
              left: 0,
              zIndex: 100,
            }}>
            <EmojiPicker onEmojiClick={onEmojiClick} width={340} height={370} />
          </div>
        )}
      </div>
      <ChattingSendingBottom
        disabled={isEmptyWithTrim(text) || isSettingBottomTranslateLanguage}
        toggleEmojiPicker={toggleEmojiPicker}
        onClickSend={handleSend}
        textAreaRef={textAreaRef}
        languageID={languageID}
        chatRoomID={chatRoomID}
        isDisabledSendingBottom={isDisabledSendingBottom}
      />
    </div>
  );
}
