import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useSaveUserTemporary } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/hooks/save/use-save-user-temporary';
import { useTemporaryPatientsLock } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { useTemporaryPatientsModifier } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-modifier';
import { modifierInfoState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/modifier-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';

export default function TemporarySaveButton() {
  const { watch } = useFormContext<{ rows: CustomerManagementTemporaryAPIFormValues }>();

  const { hospitalID } = useSelectedHospitalInfo();
  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();
  const { patchTemporaryPatientsLastModifierMutation } = useTemporaryPatientsModifier();
  const { handleTemporarySave } = useSaveUserTemporary();

  const rows = watch('rows');
  const setModifierInfo = useSetAtom(modifierInfoState);

  const [isLoading, setIsLoading] = useState(false);
  const handleTemporarySaveClick = async () => {
    try {
      setIsLoading(true);
      const latestLockData = await getPatientsIsLocked({ hospitalID });

      if (!latestLockData) return;

      // 1. 먼저 lock 해제
      await patchRequestLockMutation.mutateAsync({
        hospitalID,
        isLock: false,
        version: latestLockData.version,
      });

      // 2. 임시 저장 실행
      await handleTemporarySave(rows, () => {
        toastService.successMsg({
          text: '임시 고객정보 저장이 완료되었습니다.',
        });
      });

      // 3. 마지막 수정자 정보 업데이트
      const result = await patchTemporaryPatientsLastModifierMutation.mutateAsync({
        hospitalID,
      });

      // 4. 수정자 정보 상태 업데이트
      setModifierInfo({
        createdAt: result.createdAt,
        userID: result.userID,
      });
    } catch (error) {
      console.error('Error during temporary save process:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <OutlinedButton btnColor='blue' disabled={isLoading} onClick={handleTemporarySaveClick}>
      임시 저장
    </OutlinedButton>
  );
}
