import type { CreateUpdatePopupParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/create-update-popup-param-type';
import type { PopupEditError } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/popup-edit-error-type';
import type { PopupTargetType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/popup-target-type';
import { hasIncompleteDates } from '@templates/Content/containers/ContentManager/functions/has-incomplete-dates';
import { isInvalidDateRange } from '@templates/Content/containers/ContentManager/functions/is-invalid-date-range';
import type { UploadImageFile } from '@templates/Content/containers/ContentManager/types/upload-image-file';

export const validatePopupParams = (
  params: CreateUpdatePopupParamType,
  popupTargetType: PopupTargetType,
  popupContentTempFile: UploadImageFile | null,
) => {
  const errors: PopupEditError = {
    name: null,
    date: null,
    target: null,
    noticeConnect: null,
    title: null,
    contentText: null,
    contentImage: null,
  };

  const {
    _id: id,
    name,
    startDate,
    endDate,
    filter,
    hospitalNoticeID,
    popupType,
    title,
    content,
  } = params;
  if (!name) {
    errors.name = '팝업 이름을 입력해 주세요.';
  }

  if (hasIncompleteDates(startDate, endDate)) {
    errors.date = '날짜를 다시 확인해 주세요.';
  } else if (isInvalidDateRange(startDate, endDate)) {
    errors.date = '종료일이 시작일보다 빠릅니다.';
  }

  if (popupTargetType === 'specific') {
    if (filter.treatmentCategoryID_in.length === 0 && filter.isFirstVisit_in.length === 0) {
      errors.target = '상세조건을 선택해 주세요.';
    }
  }

  if (!hospitalNoticeID) {
    errors.noticeConnect = '팝업 클릭 시 연결될 공지사항을 선택해 주세요.';
  }

  if (popupType === 'text') {
    if (!title) {
      errors.title = '팝업 제목을 입력해 주세요.';
    }

    if (!content) {
      errors.contentText = '팝업 내용을 입력해 주세요.';
    }
  }

  if (popupType === 'image' && !id && !popupContentTempFile?.file) {
    errors.contentImage = '이미지를 등록해 주세요.';
  }

  return Object.keys(errors).reduce((acc, key) => {
    const typedKey = key as keyof PopupEditError;
    if (errors[typedKey] !== null) {
      acc[typedKey] = errors[typedKey];
    }
    return acc;
  }, {} as PopupEditError);
};
