import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CommonAlgorithmInput } from 'web/apis/swaggers/swagger-docs';
import { useUserInfo } from 'web/shared/hooks/use-user-info';

// 시간과 분 옵션 생성
const hourOptions = Array.from({ length: 13 }, (_, i) => (i + 8).toString().padStart(2, '0'));
const minuteOptions = Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, '0'));

export default function MessageSendTime() {
  const { setValue, getValues } = useFormContext<CommonAlgorithmInput>();

  const { authorizationTypeID } = useUserInfo();

  const canUpsertAutomation = !!authorizationTypeID?.canUpsertAutomation;

  const [selectedHourIndex, setSelectedHourIndex] = useState<number>(0);
  const [selectedMinuteIndex, setSelectedMinuteIndex] = useState<number>(0);

  const sendTime = getValues('sendTime');

  useEffect(() => {
    if (sendTime) {
      const [hours, minutes] = sendTime.split(':');

      const hourIndex = hourOptions.findIndex((hour) => hour === hours);
      const minuteIndex = minuteOptions.findIndex((minute) => minute === minutes);

      if (hourIndex !== -1) setSelectedHourIndex(hourIndex);
      if (minuteIndex !== -1) setSelectedMinuteIndex(minuteIndex);
    }
  }, [sendTime]);

  const handleSelect = (index: number, type: 'hour' | 'minute') => {
    const updatedHour = type === 'hour' ? hourOptions[index] : hourOptions[selectedHourIndex];
    const updatedMinute =
      type === 'minute' ? minuteOptions[index] : minuteOptions[selectedMinuteIndex];

    if (type === 'hour') {
      setSelectedHourIndex(index);
    } else {
      setSelectedMinuteIndex(index);
    }

    // HH:mm 형식으로 저장
    const formattedTime = `${updatedHour}:${updatedMinute}`;
    setValue('sendTime', formattedTime);
  };

  const customScrollHandler = useCallback((index: number) => index - 2, []);

  return (
    <div>
      <LabelText isRequired={true}>발송시각</LabelText>
      <div className='mt-10 flex-center gap-4'>
        <TextFieldSelectBoxDropdown
          width={62}
          disabled={!canUpsertAutomation}
          options={hourOptions}
          manualInput={false}
          onSelect={(index) => handleSelect(index, 'hour')}
          selectedIndex={selectedHourIndex}
          focusedIndex={selectedHourIndex}
          customFocusScrollHandler={customScrollHandler}
          customSelectedScrollHandler={customScrollHandler}
        />

        <span className='text-Body12 text-black500'>:</span>

        <TextFieldSelectBoxDropdown
          width={62}
          disabled={!canUpsertAutomation}
          options={minuteOptions}
          manualInput={false}
          onSelect={(index) => handleSelect(index, 'minute')}
          selectedIndex={selectedMinuteIndex}
          focusedIndex={selectedMinuteIndex}
          customFocusScrollHandler={customScrollHandler}
          customSelectedScrollHandler={customScrollHandler}
        />
      </div>
    </div>
  );
}
