import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useMemo } from 'react';
import type { VideoContent } from 'web/shared/hooks/files/video/use-process-video-for-upload';

interface DisplayVideoProps {
  messageVideo?: string;
}

export default function DisplayVideo({ messageVideo }: DisplayVideoProps) {
  const parsedVideo: VideoContent = useMemo(() => {
    if (!messageVideo) return undefined;
    try {
      return JSON.parse(messageVideo);
    } catch {
      return undefined;
    }
  }, [messageVideo]);

  if (!parsedVideo) {
    return (
      <div className='flex flex-col gap-10 rounded-[12px] border-2 border-white50 bg-white100 px-8 py-22'>
        <NoData
          title='동영상을 등록해 주세요'
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          className='[&>#title]:text-black200'
        />
      </div>
    );
  }

  const fileNameParts = parsedVideo.fileName?.split('.');
  const fileBaseName = fileNameParts?.slice(0, -1).join('.');
  const fileExtension = fileNameParts?.[fileNameParts.length - 1];

  return (
    <div className='w-[234px] rounded-r10 bg-white50 px-16 py-10 shadow-modal'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-10'>
          <div className='rounded-r10 border border-white400 bg-white100 p-6'>
            <Icon name='tray-upload' size={24} color='black100' />
          </div>
          <div className='flex text-Body13 text-black200'>
            <span className='w-[60px] truncate'>{fileBaseName}</span>
            <span>.{fileExtension}</span>
          </div>
        </div>
        <div className='whitespace-nowrap text-Body12 text-black200'>
          {parsedVideo.fileSize &&
            Number.parseFloat((parsedVideo.fileSize / (1024 * 1024)).toFixed(2)).toString()}{' '}
          MB
        </div>
      </div>
    </div>
  );
}
