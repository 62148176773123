import * as Sentry from '@sentry/react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { sentryConfig } from 'web/config/sentry';
import AppRoutes from 'web/shared/routes/Routes';

Sentry.init(sentryConfig);

const rootElement = document.getElementById('root') as HTMLElement & {
  __reactRoot?: ReturnType<typeof ReactDOM.createRoot>;
};

async function enableMocking() {
  const isDevelopment = import.meta.env.MODE === 'dev';
  const isUsingMSW = import.meta.env.VITE_IS_USING_MSW_ONLY_DEV === 'true';

  if (!isDevelopment || !isUsingMSW) {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

if (rootElement) {
  if (!rootElement.__reactRoot) {
    rootElement.__reactRoot = ReactDOM.createRoot(rootElement);
  }
  enableMocking().then(() => {
    rootElement.__reactRoot?.render(
      <StrictMode>
        <RouterProvider router={AppRoutes} />
      </StrictMode>,
    );
  });
}
