import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import {
  type ChangeEvent,
  type FormEvent,
  type KeyboardEvent,
  type ReactNode,
  type RefObject,
  type TextareaHTMLAttributes,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from 'react';
import './ResizableTextArea.scss';

const RESIZABLE_TEXTAREA_STYLE = {
  baseStyle: 'outline-none bg-white50',
  disabledStyle:
    'disabled:bg-white200 disabled:cursor-not-allowed disabled:placeholder:text-black200 disabled:placeholder:text-Body12',
  focusStyle: 'focus:border-blue500',
};

export interface TextAreaProps
  extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'className' | 'width'> {
  hasError?: boolean;
  width?: number | string;
  height?: number | string;
  maxHeight?: number;
  errorText?: ReactNode;
  inputWrapperClassName?: string;
  inputClassName?: string;
  wrapperRef?: RefObject<HTMLDivElement>;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  maxLength?: number;
  onKeyDown?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  handleInput?: (event: FormEvent<HTMLTextAreaElement>) => void;
  className?: string;
  noBorder?: boolean;
}

const ResizableTextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      hasError = false,
      width,
      height,
      maxHeight = 150,
      disabled = false,
      placeholder,
      errorText,
      inputWrapperClassName,
      inputClassName,
      wrapperRef,
      value,
      onChange,
      maxLength = 40000,
      onKeyDown,
      handleInput,
      className,
      noBorder = false,
      ...props
    },
    ref,
  ) => {
    const { baseStyle, focusStyle, disabledStyle } = RESIZABLE_TEXTAREA_STYLE;
    const [textLength, setTextLength] = useState<number>(value?.length ?? 0);
    const [textareaElement, setTextareaElement] = useState<HTMLTextAreaElement | null>(null);

    const textareaRef = useCallback(
      (node: HTMLTextAreaElement | null) => {
        setTextareaElement(node);
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      },
      [ref],
    );

    useEffect(() => {
      const adjustHeight = () => {
        if (textareaElement) {
          textareaElement.style.height = '32px';
          const scrollHeight = textareaElement.scrollHeight;

          if (scrollHeight > maxHeight) {
            textareaElement.style.height = `${maxHeight}px`;
          } else {
            textareaElement.style.height = `${scrollHeight}px`;
          }
        }
      };

      adjustHeight();
      window.addEventListener('resize', adjustHeight);
      return () => window.removeEventListener('resize', adjustHeight);
    }, [value, maxHeight, textareaElement]);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newText = event.target.value;
      if (newText.length <= maxLength) {
        setTextLength(newText.length);
        onChange?.(event);
      }
      if (newText.length > maxLength) {
        setTextLength(maxLength);
        onChange?.({
          ...event,
          target: { ...event.target, value: newText.slice(0, maxLength) },
        });
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.metaKey || event.ctrlKey) {
        const allowedKeys = ['a', 'c', 'v', 'x', 'z'];
        if (allowedKeys.includes(event.key.toLowerCase())) {
          return;
        }
      }

      if (textLength >= maxLength && event.key !== 'Backspace' && event.key !== 'Delete') {
        event.preventDefault();
      }

      onKeyDown?.(event);
    };

    return (
      <textarea
        ref={textareaRef}
        placeholder={placeholder}
        disabled={disabled}
        className={customTwMerge(
          'resizable-text-area',
          'min-h-[34px] w-full resize-none rounded-r10 border border-white600 px-16 py-7 text-Body12 text-black700 leading-none placeholder-black200 outline-none placeholder:text-Body12 placeholder:text-black200',
          baseStyle,
          focusStyle,
          disabledStyle,
          inputClassName,
        )}
        onChange={handleChange}
        value={value}
        onKeyDown={handleKeyDown}
        onInput={handleInput}
        style={{ maxHeight: `${maxHeight}px` }}
        {...props}
      />
    );
  },
);

ResizableTextArea.displayName = 'ResizableTextArea';

export default ResizableTextArea;
