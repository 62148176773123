import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import {
  type ChangeEvent,
  type InputHTMLAttributes,
  type LabelHTMLAttributes,
  type ReactNode,
  forwardRef,
  useEffect,
  useId,
  useState,
} from 'react';
import Icon, { type IconType } from '../../Foundations/Icon/Icon';

type BaseAttributes = InputHTMLAttributes<HTMLInputElement>;

export interface CheckboxProps extends BaseAttributes {
  label?: string | ReactNode;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  defaultChecked?: boolean;
  someChecked?: boolean; // 자동화 등록/수정 시 dept 가 있는 체크박스에서 자식 중 하나롣 체크가 된 경우 아이콘 분기처리를 위한 flag
  size?: 20 | 24;
  gapSize?: 5 | 10;
}

interface CheckboxLabelProps {
  label: string | ReactNode;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxLabel = ({
  label,
  labelProps,
  id,
  checked,
  disabled,
  onChange,
}: CheckboxLabelProps) => {
  const uid = useId();

  return (
    <label
      {...labelProps}
      htmlFor={id || uid}
      className={customTwMerge(
        'text-Body12',
        checked ? 'text-black500' : disabled ? 'text-disabled' : 'text-black200',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        labelProps?.className,
      )}
      aria-disabled={disabled}
      onClick={() => {
        if (!disabled && onChange) {
          onChange({
            target: { checked: true },
          } as ChangeEvent<HTMLInputElement>);
        }
      }}>
      {label}
    </label>
  );
};

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      labelProps,
      id,
      className,
      checked,
      someChecked,
      onChange,
      defaultChecked = false,
      disabled,
      size = 24,
      gapSize = 10,
      ...checkboxProps
    },
    ref,
  ) => {
    const uid = useId();
    const [isChecked, setIsChecked] = useState(defaultChecked);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
      if (checked === undefined) {
        setIsChecked(e.target.checked);
      }
    };

    const renderIcon = (): IconType => {
      if (isChecked) {
        if (disabled) {
          return 'checkbox-marked-disabled';
        }
        return 'checkbox-marked';
      }
      if (someChecked) {
        return 'checkbox-part-marked';
      }
      return 'checkbox-blank-outline';
    };

    useEffect(() => {
      if (checked !== undefined) {
        setIsChecked(checked);
      }
    }, [checked]);

    const checkboxElement = (
      <div
        className={customTwMerge('relative inline-block shrink-0 text-center', className)}
        style={{ width: size, height: size }}>
        <input
          {...checkboxProps}
          ref={ref}
          id={id || uid}
          className='absolute left-0 h-0 w-0 opacity-0'
          type='checkbox'
          checked={checked !== undefined ? checked : isChecked}
          disabled={disabled}
          onChange={handleChange}
        />
        <label
          className={customTwMerge(
            'flex-full-center',
            disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          )}
          htmlFor={id || uid}>
          <Icon
            name={renderIcon()}
            color={isChecked || someChecked ? 'blue500' : disabled ? 'disabled' : 'black200'}
            size={size}
          />
        </label>
      </div>
    );

    return label ? (
      <div className={`flex select-none items-center ${gapSize === 10 ? 'gap-10' : 'gap-5'}`}>
        {checkboxElement}
        <CheckboxLabel
          label={label}
          labelProps={labelProps}
          id={id || uid}
          checked={isChecked}
          disabled={disabled}
        />
      </div>
    ) : (
      checkboxElement
    );
  },
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
