import { apiClient } from '@apis/instances/api-client';
import { useDataEventBusHandler } from '@apis/push/data-push/hooks/use-data-event-bus-handler';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiChatroomElUnreadcountParams } from '@apis/swaggers/swagger-docs';
import { navigationBarUnreadCountState } from '@jotai/navigation-bar-unread-count-state';
import type {
  MENU_ITEMS,
  ROUTES_PATH,
} from '@shared/components/NavigationBar/constants/menu-items';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import type { DataNotificationPayload } from '@shared/utils/event-bus';
import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

interface AdminChatMenuProps {
  item: (typeof MENU_ITEMS)[number];
  activatedKey: (typeof MENU_ITEMS)[number]['key'];
  onHandleClick: (key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH]) => void;
}

const fetchUnreadCount = async (param: ApiChatroomElUnreadcountParams) => {
  const { data } = await apiClient.v2.apiChatroomElUnreadcount(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function AdminChatMenu({ item, activatedKey, onHandleClick }: AdminChatMenuProps) {
  const { userId: userID } = useUserInfo();
  const { hospitalID } = useSelectedHospitalInfo();
  const [isBadge, setIsBadge] = useState(false);

  const setNavigationBarUnreadCount = useSetAtom(navigationBarUnreadCountState);

  const { data, refetch } = useQuery({
    queryKey: [
      QUERY_KEY.v2apiChatroomElUnreadcount,
      { hospitalID, userID, isDoctor: true },
    ] as const,
    queryFn: ({ queryKey }) => fetchUnreadCount(queryKey[1]),
  });

  const handleNotificationRef = useRef<((payload: DataNotificationPayload) => void) | null>(null);

  const stableRefetch = useCallback(() => refetch(), [refetch]);

  const updateWindowBadgeCount = (count: number) => {
    setNavigationBarUnreadCount((prev) => ({ ...prev, adminChat: count }));
  };

  useEffect(() => {
    const ipcRenderer = window.electron?.ipcRenderer;
    if (!ipcRenderer) return;

    const debouncedRefetch = debounce(() => {
      stableRefetch();
    }, 1000);

    const eventHandler = () => {
      debouncedRefetch();
    };

    ipcRenderer.on('Application.adminUnreadRefresh', eventHandler);

    return () => {
      ipcRenderer.removeListener('Application.adminUnreadRefresh', eventHandler);
      debouncedRefetch.cancel();
    };
  }, [stableRefetch]);

  useEffect(() => {
    if (!data?.length) {
      setIsBadge(false);
      updateWindowBadgeCount(0);
      return;
    }

    const userBadge = data.find((item) => item._id === userID);
    if (userBadge) {
      const badgeCount = userBadge.total || 0;
      setIsBadge(badgeCount > 0);
      updateWindowBadgeCount(badgeCount);
    } else {
      setIsBadge(false);
      updateWindowBadgeCount(0);
    }
  }, [data, userID]);

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_ChatroomUpdateDoctor_el'],
        handler: (payload: DataNotificationPayload) => handleNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleNotificationRef.current = (payload: DataNotificationPayload) => {
      const senderID = payload.data?.senderID;
      if (senderID !== userID) {
        refetch();
      }
    };
  }, [userID]);

  return (
    <>
      <div
        onClick={() => onHandleClick(item.key)}
        className='relative flex-center cursor-pointer flex-col gap-2 bg-white50 pt-10 pb-18'>
        {activatedKey === item.key ? item.activeIcon : item.nonActiveIcon}
        <div className={activatedKey === item.key ? 'text-Body10Bold' : 'text-Body10'}>
          {item.title}
        </div>
        {isBadge && (
          <span className='absolute top-8 right-18 h-8 w-8 rounded-full border border-white50 bg-red500' />
        )}
      </div>
    </>
  );
}
