import * as React from "react";
import type { SVGProps } from "react";
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path d="m7 7 4 4" className="link_svg__ql-stroke" />
    <path
      d="M8.9 4.577a3.476 3.476 0 0 1 .36 4.679A3.476 3.476 0 0 1 4.577 8.9c-1.392-1.4-2.542-2.5-.36-4.683S7.5 3.185 8.9 4.577"
      className="link_svg__ql-even link_svg__ql-stroke"
    />
    <path
      d="M13.423 9.1a3.476 3.476 0 0 0-4.679-.36 3.476 3.476 0 0 0 .36 4.679c1.392 1.392 2.5 2.542 4.679.36s1.032-3.279-.36-4.679"
      className="link_svg__ql-even link_svg__ql-stroke"
    />
  </svg>
);
export default SvgLink;
