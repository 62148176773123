import { useQueryClient } from '@tanstack/react-query';
import TextButton from 'afterdoc-design-system/components/Atoms/Button/TextButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import TextArea from 'afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiPatientsElDetailData } from 'web/apis/swaggers/swagger-docs';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';
import ServiceManagerMemoDialogContainer from './ServiceManagerMemoDialog.container';

export default function ServiceManagerMemoContainer() {
  const queryClient = useQueryClient();

  const patientId = useAtomValue(patientIdState);
  const { managerNote } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];
  const { userType } = useUserInfo();
  const isServiceManagerAccount = userType >= 3000;

  const bottomText =
    managerNote && dayjs(managerNote.finalEditedAt).isValid()
      ? `${dayjs(managerNote.finalEditedAt).format('YYYY-MM-DD HH:mm')}/${managerNote.finalEditorName}`
      : undefined;

  if (!isServiceManagerAccount) {
    return null;
  }

  return (
    <div className='w-full'>
      <Divider direction='horizontal' type='line' className='my-24' />
      <div className='mb-14 flex w-full flex-row items-center justify-between'>
        <div className='text-Header14 text-black700'>서비스매니저 메모</div>
        <TextButton
          onClick={() => {
            dialogService.push(
              <Suspense>
                <ServiceManagerMemoDialogContainer />
              </Suspense>,
              {
                wrapperClassName: 'h-[800px] mt-0 mb-0',
                width: '1000px',
                id: 'service-manager-memo-dialog',
                titleProps: {
                  title: '서비스매니저 메모',
                },
                hasCloseButton: true,
                onClose: () => {
                  dialogService.popById('service-manager-memo-dialog');
                },
              },
            );
          }}
          disabled={!managerNote}
          iconProps={{
            name: 'arrow-right',
            size: 16,
            color: 'black200',
            className: !managerNote ? 'opacity-30' : undefined,
          }}
          className='whitespace-nowrap disabled:text-black200 disabled:text-opacity-30'>
          전체보기
        </TextButton>
      </div>
      <TextArea
        placeholder='고객 상담 시, 참고 사항을 기록해둘 수 있습니다.'
        height={140}
        disabled={true}
        showRightBottomButton={false}
        value={managerNote?.content}
        leftBottomText={bottomText}
        additionalRightBottomButton={
          managerNote?.images && managerNote.images.length > 0 ? (
            <>
              <div className='flex flex-row items-center gap-6'>
                <Icon name='image-filter' size={16} color='black200' />
                <div className='text-Body10Bold text-black200'>이미지 첨부됨</div>
              </div>
            </>
          ) : undefined
        }
      />
    </div>
  );
}
