import { eventPushRequestCreateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import { useRequestPush } from '@templates/Content/containers/ContentManager/hooks/use-request-push';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useAtomValue } from 'jotai';

interface EventPushButtonGroupProps {
  hospitalEventID: string;
}

export default function EventPushButtonGroup({ hospitalEventID }: EventPushButtonGroupProps) {
  const eventPushRequestParam = useAtomValue(eventPushRequestCreateParamState);

  const { requestPushAction } = useRequestPush();

  const handleCancelButtonClick = () => {
    dialogService.popById('event-push-alarm-dialog');
  };

  const handleSuccess = () => {
    toastService.successMsg({ text: '알림을 발송했습니다.' });
  };

  const handleFailure = () => {
    toastService.errorMsg({ text: '알림 발송을 실패했습니다.' });
  };

  const handleSendButtonClick = () => {
    requestPushAction({
      shouldValidate: true,
      eventPushRequestParam,
      hospitalEventID,
      onSuccessCallback: handleSuccess,
      onFailureCallback: handleFailure,
    });
  };

  return (
    <div className='w-full flex-center gap-10'>
      <ContainedButton btnColor='secondary' onClick={handleCancelButtonClick}>
        취소
      </ContainedButton>
      <ContainedButton onClick={handleSendButtonClick}>보내기</ContainedButton>
    </div>
  );
}
