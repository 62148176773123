import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiManualsElSingleParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

const getSingleManualContent = async (params: ApiManualsElSingleParams) => {
  const { data } = await apiClient.v3.apiManualsElSingle(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useManualSingleData = ({ treatmentTagId }: { treatmentTagId: string }) => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data, error, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiManualsElSingle, { hospitalID, treatmentTagId }] as const,
    queryFn: ({ queryKey }) => getSingleManualContent(queryKey[1]),
  });

  const manualContent = data?.manualContent ?? '';
  const treatmentCautionImages = data?.cautionImages ?? [];

  return { data: { manualContent, treatmentCautionImages }, error, isLoading };
};
