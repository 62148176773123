import { type QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  AlgorithmWithMessagesInput,
  MessageInput,
  UpdateAlgorithmMainDataHandlerBodyRequest,
} from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useProcessImagesForUpload } from 'web/shared/hooks/files/images/use-process-images-for-upload';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import { messageWarningStatesState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-warning-states';
import { useHandleMarketingAutomationMessages } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/hooks/use-handle-marketing-automation-messages';
import { useMarketingAutomationResetAll } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/hooks/use-marketing-automation-reset-all';

const registerMarketingAutomationMessages = async (params: AlgorithmWithMessagesInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsSingle(params);
  return response.data;
};

const closeMarketingAutomationDialog = () => {
  modalService.popAll();
  overlayPageService.popById(OVERLAY_PAGE_ID['register-marketing-automation-dialog']);
};

export const useSubmitRegisterMarketingAutomation = () => {
  const queryClient = useQueryClient();
  const { getValues, reset } = useFormContext<MarketingAutomationAPIFormValues>();
  const { handleAutomationMessages, handleUpdateAlgorithmMainData } =
    useHandleMarketingAutomationMessages();

  const [disabled, setDisabled] = useState(true);
  const messageWarningStates = useAtomValue(messageWarningStatesState);
  const algorithmMode = useAtomValue(algorithmModeState);

  const { hospitalID } = useSelectedHospitalInfo();
  // 기본 데이터
  const name = getValues('name');
  const startDay = getValues('sendingSchedule.startDay');
  const sendingCount = getValues('sendingSchedule.sendingCount');

  // 메시지 데이터
  const messageLists = getValues('messages');

  const { processImagesForUpload } = useProcessImagesForUpload();

  const { resetAll } = useMarketingAutomationResetAll({
    reset,
  });

  const registerText = useMemo(() => {
    if (algorithmMode.mode === 'CREATE') {
      return '마케팅자동화가 등록되었습니다.';
    }

    if (algorithmMode.mode === 'COPY') {
      return '마케팅자동화가 복사되었습니다.';
    }
  }, [algorithmMode.mode]);

  const invalidateMarketingAutomationQueries = async (params: {
    hospitalID: string;
    algorithmId?: string;
  }) => {
    const { hospitalID, algorithmId } = params;

    const queries: QueryKey[] = [[QUERY_KEY.apiAutomationsElMainList, { hospitalID }]];

    if (algorithmId) {
      queries.push(
        [QUERY_KEY.apiAutomationsElAlgorithmsMessages, { algorithmId, hospitalID }],
        [QUERY_KEY.singleAlgorithmHandler, { algorithmId, hospitalID }],
      );
    }

    await Promise.all(queries.map((queryKey) => queryClient.invalidateQueries({ queryKey })));
  };

  const handleOperationSuccess = (message: string, shouldReset = true) => {
    if (algorithmMode.mode === 'CREATE') return;

    toastService.successMsg({ text: message });

    if (shouldReset) {
      resetAll();
    }

    invalidateMarketingAutomationQueries({
      hospitalID,
      algorithmId: algorithmMode.algorithmId,
    });

    closeMarketingAutomationDialog();
  };

  const processMessageImages = async (messages: MessageInput[]) => {
    const processedMessages = [...messages];

    for (const [index, message] of processedMessages.entries()) {
      if (message.content.image) {
        processedMessages[index].content.image = await processImagesForUpload({
          newImages: JSON.parse(message.content.image),
        });
      }
    }

    return processedMessages;
  };

  const registerMessagesMutation = useMutation({
    mutationFn: registerMarketingAutomationMessages,
    onSuccess: (data) => {
      if (data.code === 0) {
        toastService.successMsg({
          text: registerText,
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
        });

        resetAll();
        modalService.popAll();
        overlayPageService.popById(OVERLAY_PAGE_ID['register-marketing-automation-dialog']);
      } else {
        toastService.errorMsg({
          text: '마케팅자동화 등록을 실패했습니다. 다시 시도해 주세요.',
        });
      }
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({
        text: '마케팅자동화 등록을 실패했습니다. 다시 시도해 주세요.',
      });
    },
    onSettled: () => {
      setDisabled(false);
    },
  });

  const handleMarketingAutomationSubmit = async () => {
    if (disabled) return;

    const formValues = getValues();

    // 첫 번째 메시지의 발송 시점 검증
    const firstMessage = formValues.messages[0];
    const now = dayjs();

    if (dayjs.utc(firstMessage.sendingDateTime).isBefore(now)) {
      toastService.errorMsg({
        text: '첫 메시지의 발송 시간은 현재 시간 이후로 설정해주세요.',
      });
      return;
    }

    setDisabled(true);

    try {
      const processedMessages = await processMessageImages(formValues.messages);
      const updatedMessagesValues = { ...formValues, messages: processedMessages };

      if (algorithmMode.mode === 'EDIT') {
        const updatedAlgorithmValues: UpdateAlgorithmMainDataHandlerBodyRequest = {
          algorithmId: algorithmMode.algorithmId,
          hospitalID,
          name,
          sendingSchedule: {
            startDay,
            sendingCount: sendingCount ?? 1,
          },
        };

        await handleAutomationMessages({
          onSuccess: () => {
            setDisabled(false);
          },
          newMessagesValues: processedMessages as (MessageInput & { id: string })[],
          setDisabled,
        });

        await handleUpdateAlgorithmMainData({
          updatedAlgorithmValues,
          onSuccess: () => {
            handleOperationSuccess('마케팅자동화가 수정되었습니다.');
          },
        });
      } else {
        registerMessagesMutation.mutate(updatedMessagesValues);
      }
    } catch (error) {
      console.error('파일 업로드 실패:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (!messageLists.length) return;

    const isDisabled = Object.values(messageWarningStates).some((value) => value);

    setDisabled(isDisabled);
  }, [messageLists, messageWarningStates]);

  return {
    disabled: disabled || registerMessagesMutation.isPending,
    handleRegister: handleMarketingAutomationSubmit,
  };
};
