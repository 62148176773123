import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { forwardRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiTreatmentTagsElParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { filterWrapperClassName } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/class';

const fetchTreatmentTagsList = async (params: ApiTreatmentTagsElParams) => {
  const response = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const TreatmentTagFilter = forwardRef<
  HTMLDivElement,
  { value: string | undefined; onValueChange?: (value: string | undefined) => void }
>((props, ref) => {
  const { hospitalID } = useSelectedHospitalInfo();

  const {
    data: { treatmentTags },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTagsList(queryKey[1]),
  });

  const [checkState, setCheckState] = useState<{ [tagId: string]: boolean } | undefined>(() => {
    if (!props.value) return undefined;
    const initialState: { [tagId: string]: boolean } = {};
    for (const cur of props.value.split(',')) {
      initialState[cur] = true;
    }
    return initialState;
  });

  const handleCheckboxChange = (tagId: string) => {
    const newState = { ...checkState, [tagId]: !checkState?.[tagId] };
    setCheckState(newState);

    const selectedTags = Object.keys(newState).filter((key) => newState[key]);

    if (selectedTags.length === 0 || selectedTags.length === treatmentTags?.length) {
      props.onValueChange?.(undefined);
    } else {
      props.onValueChange?.(selectedTags.join(','));
    }
  };

  return (
    <div className={`${filterWrapperClassName} h-[200px] w-[300px]`} ref={ref}>
      <Scrollbar disabledX>
        <div className='p-16'>
          <div className='mb-10 text-Caption9 text-black200'>
            *체크한 값에 해당하는 고객만 표시됩니다.
          </div>
          {treatmentTags?.map((tag) => (
            <Checkbox
              key={tag.tagId}
              label={
                <FilledTag
                  className='cursor-pointer'
                  tagSize='small'
                  bgColor={tag.color}
                  maxTextLength={25}>
                  {tag.name}
                </FilledTag>
              }
              checked={!!checkState?.[tag.tagId]}
              onChange={() => handleCheckboxChange(tag.tagId)}
            />
          ))}
        </div>
      </Scrollbar>
    </div>
  );
});

export default TreatmentTagFilter;
