import { useEffect, useRef } from 'react';

interface UseHoverWithTimerOptions {
  layers: { id: string }[];
  isDragging: boolean;
  isFolderOpenedList: { [key: string]: boolean };
  canDropToFolder: (index: number | null) => void;
}

export const useDraggableFolderIndex = ({
  layers,
  isDragging,
  isFolderOpenedList,
  canDropToFolder,
}: UseHoverWithTimerOptions) => {
  // unmounted 대응을 위한 최신값 useRef 로 관리
  const layersRef = useRef(layers);
  const isDraggingRef = useRef(isDragging);
  const folderOpenedRef = useRef(isFolderOpenedList);

  const isMouseDownRef = useRef(false);

  const droppableRefs = useRef<HTMLDivElement[]>([]);
  const hoverTimer = useRef<NodeJS.Timeout>(undefined);
  const hoveredId = useRef<string | null>(null);

  useEffect(() => {
    layersRef.current = layers;
  }, [layers]);

  useEffect(() => {
    isDraggingRef.current = isDragging;
  }, [isDragging]);

  useEffect(() => {
    folderOpenedRef.current = isFolderOpenedList;
  }, [isFolderOpenedList]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (isDraggingRef.current && isMouseDownRef.current && droppableRefs.current.length) {
        for (const ref of droppableRefs.current) {
          const index = droppableRefs.current.indexOf(ref);

          if (ref) {
            const boundingBox = ref.getBoundingClientRect();
            const isInside =
              event.clientX >= boundingBox.left &&
              event.clientX <= boundingBox.right &&
              event.clientY >= boundingBox.top &&
              event.clientY <= boundingBox.bottom;

            if (isInside) {
              const targetId = layersRef.current[index].id;
              const opened = folderOpenedRef.current[targetId];
              if (hoveredId.current !== targetId) {
                hoveredId.current = null;
                clearTimeout(hoverTimer.current);
              }
              if (!opened) {
                hoverTimer.current = setTimeout(() => {
                  if (hoveredId.current) {
                    canDropToFolder(index);
                  }
                }, 200);
                hoveredId.current = targetId;
                return;
              }
            }
          }
        }
        canDropToFolder(null);
        hoveredId.current = null;
        clearTimeout(hoverTimer.current);
      }
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    const handleMouseDown = () => {
      isMouseDownRef.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownRef.current = false;
    };

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return { droppableRefs };
};
