import * as React from "react";
import type { SVGProps } from "react";
const SvgListCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M9 4h6M3 4l1 1 2-2M9 14h6M3 14l1 1 2-2M9 9h6M3 9l1 1 2-2"
      className="list-check_svg__ql-stroke"
    />
  </svg>
);
export default SvgListCheck;
