import { type ReactNode, createContext, useContext } from 'react';
import type { ChattingMessageProps } from './types/message';

type ChatMessageContextType = {
  isMine: boolean;
  messageWidthStyle: Record<string, string>;
  contentParts: string[];
  isOverLength: boolean;
  isInResMessages: boolean;
} & ChattingMessageProps;

const ChatMessageContext = createContext<ChatMessageContextType | undefined>(undefined);

export const ChatMessageProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: ChatMessageContextType;
}) => {
  return <ChatMessageContext.Provider value={value}>{children}</ChatMessageContext.Provider>;
};

export const useChatMessageContext = () => {
  const context = useContext(ChatMessageContext);
  if (context === undefined) {
    throw new Error('useChatMessageContext must be used within a ChatProvider');
  }
  return context;
};
