import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import HospitalManualContainer from 'web/templates/HospitalManual/HospitalManual.container';

export default function HospitalManualPage() {
  return (
    <Scrollbar isAlwaysVisible>
      <HospitalManualContainer />
    </Scrollbar>
  );
}
