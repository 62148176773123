import { eventCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-create-or-update-param-state';
import { homeCareCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/states/homecare-create-or-update-param-state';
import { detailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/detail-image-temp-file-state';
import { thumbnailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/thumbnail-image-temp-file-state';
import { NavBar, Statusbar } from 'afterdoc-design-system/assets/images';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import dayjs from 'dayjs';
import DOMPurify from 'dompurify';
import { useAtomValue } from 'jotai/index';
import { useEffect, useMemo, useState } from 'react';
import EditorViewer from 'web/shared/components/EditorViewer/EditorViewer';

interface EventHomeCarePreviewProps {
  representImagePath?: string;
  detailInfoImagePath?: string;
  previewType: 'event' | 'homeCare';
}

interface PlaceHolderConfig {
  descriptionPlaceHolder: string;
}

const placeHolderConfig: Record<string, PlaceHolderConfig> = {
  event: {
    descriptionPlaceHolder: '설명을 입력해 주세요.',
  },
  homeCare: {
    descriptionPlaceHolder: '설명을 입력해 주세요.',
  },
};

const formatDate = (date: string | undefined) => {
  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) {
    return 'YYYY-MM-DD';
  }

  return `~${parsedDate.format('YYYY-MM-DD')}`;
};

export default function EventHomeCarePreview({
  representImagePath,
  detailInfoImagePath,
  previewType,
}: EventHomeCarePreviewProps) {
  const params =
    previewType === 'event' ? eventCreateOrUpdateParamState : homeCareCreateOrUpdateParamState;

  const {
    title,
    description,
    price = 0,
    salePrice,
    saleRate,
    saleType,
    isLimitedTime,
    endDate,
    type,
    content,
  } = useAtomValue(params);

  const thumbnailImageTempFile = useAtomValue(thumbnailImageTempFileState);
  const detailImageTempFile = useAtomValue(detailImageTempFileState);

  const [thumbnailSrc, setThumbnailSrc] = useState(representImagePath);
  const [detailInfoImageSrc, setDetailInfoImageSrc] = useState(detailInfoImagePath);

  const sanitizedContent = DOMPurify.sanitize(content ?? '');

  const formatPriceDisplay = useMemo(() => {
    const isPriceValid = saleType === 'price' && salePrice !== 0;
    const isRateValid = saleType === 'rate' && saleRate !== 0;

    return price && (isPriceValid || isRateValid) ? price.toLocaleString() : '';
  }, [saleType, salePrice, saleRate, price]);

  const calculatePrice = useMemo(() => {
    if (saleType === 'price') {
      const result = price - (salePrice ?? 0);
      return (result > 0 ? result : 0).toLocaleString();
    }

    if (saleType === 'rate') {
      const discount = (price * (saleRate ?? 0)) / 100;
      return (price - discount).toLocaleString();
    }

    return '0';
  }, [saleType, salePrice, saleRate, price]);

  const calculateRate = useMemo(() => {
    if (saleType === 'rate') return saleRate ? `${saleRate}%` : '';
    if (salePrice === 0) return '';

    const rate = Math.round(((salePrice ?? 0) / price) * 100);
    if (!Number.isFinite(rate) || rate < 0) return '';
    return `${rate}%`;
  }, [saleType, salePrice, saleRate, price]);

  useEffect(() => {
    const url = thumbnailImageTempFile?.url;
    if (url) {
      setThumbnailSrc(url);
    }
  }, [thumbnailImageTempFile?.url]);

  useEffect(() => {
    const url = detailImageTempFile?.url;
    if (url) {
      setDetailInfoImageSrc(url);
    }
  }, [detailImageTempFile?.url]);

  return (
    <div className='flex w-[400px] flex-col bg-blue50'>
      <Title
        title='미리보기'
        subTitle='미리보기는 참고용이며, 고객의 디바이스에 따라 다소 차이가 있을 수 있습니다.'
      />
      <div className='mt-40 max-h-[800px] flex-grow px-16'>
        <div className='h-full flex-col-center overflow-hidden rounded-[24px] border-[5px] border-blueLight bg-white50 shadow-modal'>
          <Statusbar className='w-full bg-white50' />
          <div className='mt-12 flex w-full items-center justify-between px-20'>
            <Icon name='chevron-left' size={40} color='black800' />
            <p className='font-regular text-[16px] text-black leading-[18px]'>공유</p>
          </div>
          <div className='mt-8 w-full flex-grow'>
            <Scrollbar disabledX={true}>
              <div className='flex flex-col'>
                <div className='mb-24 px-12'>
                  <p className='mb-[8px] break-words font-bold text-[21px] text-black800'>
                    {title || '제목을 입력해 주세요.'}
                  </p>
                  <p className='break-words text-[12px] text-black200'>
                    {description || '설명을 입력해 주세요.'}
                  </p>
                  <div className='mt-18 flex min-w-0 gap-4'>
                    <div className='flex min-w-0 flex-grow flex-col'>
                      <p className='overflow-hidden text-ellipsis font-regular text-[#CCCCCC] text-[14px] line-through'>
                        {formatPriceDisplay}
                      </p>
                      <div className='flex items-center gap-10'>
                        <p className='overflow-hidden text-ellipsis whitespace-nowrap text-Header16 text-black800'>
                          {`${calculatePrice}원`}
                        </p>
                        <p className='text-Header16 text-[#FF5C71]'>{calculateRate}</p>
                      </div>
                    </div>
                    <p className='mb-6 w-[76px] self-end whitespace-nowrap font-regular text-[#929292] text-[12px]'>
                      {isLimitedTime && formatDate(endDate)}
                    </p>
                  </div>
                </div>
              </div>
              <Divider type='line' height={10} />
              <ThumbnailImage src={thumbnailSrc} />
              <Divider type='line' height={1} />
              <div className='pt-10 pb-8'>
                <Divider type='line' height={1} />
                <DetailInfo
                  type={type}
                  sanitizedContent={sanitizedContent}
                  src={detailInfoImageSrc}
                />
              </div>
            </Scrollbar>
          </div>
          <NavBar className='w-full' />
        </div>
      </div>
    </div>
  );
}

const ThumbnailImage = ({ src }: { src?: string }) => {
  if (src) {
    return <img src={src} alt='thumbnailImage' className='w-full overflow-hidden object-cover' />;
  }
  return <ImageEmpty />;
};

const DetailInfo = ({
  type,
  sanitizedContent,
  src,
}: {
  type: string;
  sanitizedContent: string;
  src?: string;
}) => {
  if (type === 'text') {
    if (!sanitizedContent) {
      return <span className='mt-10 px-16 text-Body14 text-black800'>내용을 입력해 주세요.</span>;
    }
    return (
      <>
        <EditorViewer htmlContent={sanitizedContent} />
      </>
    );
  }
  if (type === 'image' && src) {
    return (
      <img
        src={src}
        alt='detailInfoImage'
        className='h-auto max-w-full overflow-hidden object-contain'
      />
    );
  }
  return <ImageEmpty />;
};

const ImageEmpty = () => {
  return (
    <div className='aspect-[1/1] w-full flex-center'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className='my-auto whitespace-pre-wrap'
        title='이미지를 등록해 주세요.'
      />
    </div>
  );
};
