import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useSetAtom } from 'jotai';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';
import NextButton from 'web/templates/Automation/containers/shared/components/Buttons/NextButton';
import PrevButton from 'web/templates/Automation/containers/shared/components/Buttons/PrevButton';
import { generateDaysArray } from 'web/templates/Automation/containers/shared/functions/generate-days-array';

export default function CounselCommonReVisitMessageSettingContainer() {
  const dropdownOptions = useRef<string[]>(
    generateDaysArray({
      endDay: 365,
    }),
  );

  const [isDropdownToggled, setIsDropdownToggled] = useState(false);

  const { setValue, watch, getValues } = useFormContext<CounselAutomationAPIFormValues>();

  const setSelectedAutomationTab = useSetAtom(selectedCounselAutomationTabState);

  const customFocusScrollHandler = (focusedIndex: number) => {
    return focusedIndex - 2;
  };

  const customSelectedScrollHandler = (selectedIndex: number) => {
    return selectedIndex - 2;
  };

  const sendingDaysAfterStartValue = watch('commonAlgorithm.sendingDaysAfterStart') ?? 60;

  return (
    <div className='mx-auto mt-40 w-[700px] rounded-r16 bg-white50 px-40 py-20 shadow-modal'>
      <LabelText isRequired>공통 재내원 안내</LabelText>
      <div className='mt-10 flex w-full items-center justify-between'>
        <div className='text-Body13 text-black500'>
          대상 고객에게 공통 재내원 안내를 발송합니다.
        </div>
        <Toggle
          checked={watch('commonAlgorithm.enabled') ?? false}
          onChange={(e) => {
            setValue('commonAlgorithm.enabled', e.target.checked);

            if (e.target.checked) {
              const existingValue = getValues('commonAlgorithm.sendingDaysAfterStart');
              if (existingValue === undefined) {
                setValue('commonAlgorithm.sendingDaysAfterStart', 60);
              }
            }
          }}
        />
      </div>

      {watch('commonAlgorithm.enabled') && (
        <>
          <Divider type='line' className='my-20' />
          <LabelText isRequired>공통 재내원 안내 발송 일자</LabelText>
          <div className='mt-10 flex items-center gap-8'>
            <span className='text-Body13 text-black500'>자동화 실행 후</span>
            <TextFieldSelectBoxDropdown
              options={dropdownOptions.current}
              onSelect={(index) => {
                setIsDropdownToggled(!isDropdownToggled);

                setValue('commonAlgorithm', {
                  enabled: true,
                  sendingDaysAfterStart: index + 1,
                });
              }}
              selectedIndex={sendingDaysAfterStartValue - 1}
              focusedIndex={sendingDaysAfterStartValue - 1}
              width={100}
              customFocusScrollHandler={customFocusScrollHandler}
              customSelectedScrollHandler={customSelectedScrollHandler}
            />
            <span className='text-Body13 text-black500'>
              이후에 공통 재내원 메시지를 발송합니다.
            </span>
          </div>
        </>
      )}

      <div className='mx-auto mt-20 flex w-[200px] gap-8'>
        <PrevButton
          onClick={() => {
            setSelectedAutomationTab(0);
          }}
        />
        <NextButton
          onClick={() => {
            setSelectedAutomationTab(2);
          }}
        />
      </div>
    </div>
  );
}
