import { useInfiniteQuery } from '@tanstack/react-query';
import LoadingSpinner from 'afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElTemporaryListOrSearchParams,
  ApiServiceSettingsElCountriesData,
  ApiServiceSettingsElToBeDisplayedPatientsFieldsData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { useNavigationBlocker } from 'web/shared/hooks/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { nullIfEmpty } from 'web/shared/utils/null-if-empty';
import CustomerManagementEditableTableFooter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableBody/components/CustomerManagementEditableTableFooter';
import { useTemporaryPatientsLock } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/utils/ use-temporary-patients-lock';
import { TOTAL_PATIENT_ROW_COUNT } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/constants/total-patient-row-count';
import {
  fetchPatientsTemporaryList,
  useResetTable,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-reset-table/use-reset-table';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { customerManagementScrollbarRefState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-scrollbar';
import { editableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { createInitialRows } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/utils/create-initial-rows';
import { CUSTOMER_SEARCH_FIELD_OPTIONS } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/constants/dropdown-items';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaIndexState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { useIntersectionObserver } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import { useVirtualScroll } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-virtual-scroll';
import CustomerManagementEditableTableBody from '../components/CustomerManagementEditableTableBody/CustomerManagementEditableTableBody';

type CustomerManagementEditableTableBodyContainerProps = {
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTags: ApiTreatmentTagsElData['data']['treatmentTags'];
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

export default function CustomerManagementEditableTableBodyContainer({
  countriesList,
  treatmentTags,
  ...usingColumns
}: CustomerManagementEditableTableBodyContainerProps) {
  const { control, setValue } = useFormContext<{
    rows: CustomerManagementTemporaryAPIFormValues;
  }>();

  const [searchParams] = useSearchParams();
  const { hospitalID } = useSelectedHospitalInfo();

  const isFirstRender = useRef(true);
  const rowHeight = useRef(40);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const editableHeaderFilter = useAtomValue(editableHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const selectedSearchCriteriaIndex = useAtomValue(selectedSearchCriteriaIndexState);
  const customerTableDisplayMode = useAtomValue(customerTableDisplayModeState);

  const params: ApiPatientsElTemporaryListOrSearchParams = useMemo(
    () => ({
      hospitalID,
      limit: TOTAL_PATIENT_ROW_COUNT,
    }),
    [hospitalID],
  );

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage, isError, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch, params],
      queryFn: ({ pageParam }: { pageParam?: string }) =>
        fetchPatientsTemporaryList({
          pageParam,
          ...params,
        }),
      getNextPageParam: (lastPage) => {
        if (!lastPage || !lastPage.nextCursor) {
          return undefined;
        }
        return lastPage.nextCursor;
      },
      initialPageParam: undefined,
      refetchOnMount: 'always',
    });

  const initialRows = useMemo(() => {
    if (isFetching) {
      return [];
    }

    if (!data?.pages.flatMap((page) => page.patients)) {
      return createInitialRows(hospitalID);
    }

    const allPatients = data.pages.flatMap((page) =>
      page.patients?.map((patient) => ({
        order: patient.order,
        hospitalID,
        patientId: patient.patientId,
        name: nullIfEmpty(patient.name),
        chartNumber: nullIfEmpty(patient.chartNumber),
        phoneNumber: nullIfEmpty(patient.phoneNumber),
        birthdate: nullIfEmpty(patient.birthdate),
        isFirstVisit: patient.isFirstVisit,
        gender: patient.gender,
        treatmentTagIds: patient.treatmentTags?.map((tag) => tag.tagId),
        nationalityId: patient.nationality?.id,
      })),
    ) as CustomerManagementTemporaryAPIFormValues;

    return createInitialRows(hospitalID, allPatients);
  }, [data, hospitalID, isFetching]);

  const rows = useWatch({
    name: 'rows',
    control,
  });

  const handleAddRows = (newRows: CustomerManagementTemporaryAPIFormValues) => {
    setValue('rows', [...rows, ...newRows]);
  };

  const addNew50Rows = () => {
    const new50Rows: CustomerManagementTemporaryAPIFormValues = Array.from(
      { length: TOTAL_PATIENT_ROW_COUNT },
      (_, index) => ({
        order: rows.length + index + 1,
        hospitalID,
        patientId: null,
        name: null,
        chartNumber: null,
        isFirstVisit: null,
        phoneNumber: null,
        birthdate: null,
        gender: null,
        treatmentTagIds: [],
        nationalityId: null,
      }),
    );
    handleAddRows(new50Rows);
  };

  const { resetTable, revertTableData } = useResetTable();

  const { getPatientsIsLocked, patchRequestLockMutation } = useTemporaryPatientsLock();

  const handleConfirm = async () => {
    const latestLockData = await getPatientsIsLocked({ hospitalID });
    if (!latestLockData) return;

    await patchRequestLockMutation.mutateAsync({
      hospitalID,
      isLock: false,
      version: latestLockData.version,
    });

    resetTable();
    await revertTableData();
  };

  // 각 필터 함수를 메모이제이션
  const filterByFirstVisit = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (editableHeaderFilter.isFirstVisit === undefined) {
        return rows;
      }

      if (editableHeaderFilter.isFirstVisit === 'all') {
        return rows.filter((row) => row.isFirstVisit === true || row.isFirstVisit === false);
      }

      return rows.filter((row) => row.isFirstVisit === editableHeaderFilter.isFirstVisit);
    },
    [editableHeaderFilter.isFirstVisit],
  );

  const filterByGender = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (editableHeaderFilter.gender === undefined) {
        return rows;
      }

      if (editableHeaderFilter.gender === 'all') {
        // gender가 'MALE' 또는 'FEMALE'인 경우만 포함
        return rows.filter((row) => row.gender === 'MALE' || row.gender === 'FEMALE');
      }

      // 특정 성별만 선택된 경우
      return rows.filter((row) => row.gender === editableHeaderFilter.gender);
    },
    [editableHeaderFilter.gender],
  );

  const filterByTreatmentCategories = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!editableHeaderFilter.treatmentCategories) return rows;

      const categoryIds = editableHeaderFilter.treatmentCategories.split(',');

      return rows.filter((row) =>
        // row 전체를 유지하면서, treatmentTags 중에 매칭되는 것이 있는지만 확인
        row.treatmentTagIds?.some((tagId) => categoryIds.includes(tagId)),
      );
    },
    [editableHeaderFilter.treatmentCategories],
  );

  const filterByNationality = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!editableHeaderFilter.nationality) return rows;

      const nationalityIds = editableHeaderFilter.nationality.split(',');
      return rows.filter((row) => row.nationalityId && nationalityIds.includes(row.nationalityId));
    },
    [editableHeaderFilter.nationality],
  );

  // 검색 필터 함수
  const filterBySearch = useCallback(
    (rows: CustomerManagementTemporaryAPIFormValues) => {
      if (!searchText) return rows;

      const searchColumn = CUSTOMER_SEARCH_FIELD_OPTIONS[selectedSearchCriteriaIndex].key;
      const searchRegex = new RegExp(`.*${searchText}.*`, 'i');

      return rows.filter((row) => {
        const value = row[searchColumn as keyof typeof row];
        return value && searchRegex.test(String(value));
      });
    },
    [searchText, selectedSearchCriteriaIndex],
  );

  const filterFunctions = useMemo(
    () => [
      filterByFirstVisit,
      filterByGender,
      filterByTreatmentCategories,
      filterByNationality,
      filterBySearch,
    ],
    [
      filterByFirstVisit,
      filterByGender,
      filterByTreatmentCategories,
      filterByNationality,
      filterBySearch,
    ],
  );

  // 필터링된 rows 계산
  const displayRows = useMemo(() => {
    const currentRows = rows?.length ? rows : initialRows;

    const hasActiveFilters =
      Object.values(editableHeaderFilter).some((v) => v !== undefined) || !!searchText?.length;

    if (!hasActiveFilters) {
      return currentRows;
    }

    return filterFunctions.reduce((rows, filterFn) => filterFn(rows), currentRows);
  }, [rows, initialRows, filterFunctions, editableHeaderFilter, searchText, searchParams]);

  const virtualScrollData = useVirtualScroll({
    rows: displayRows,
    rowHeight: rowHeight.current,
    offset: 196,
  });
  const setScrollbarRef = useSetAtom(customerManagementScrollbarRefState);
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  useNavigationBlocker({
    shouldBlock: customerTableDisplayMode === 'EDITOR',
    onConfirm: handleConfirm,
  });

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isError && !isLoading,
  });

  useEffect(() => {
    if (isFetching) return;

    if (isFirstRender.current) {
      setValue('rows', initialRows);
      isFirstRender.current = false;
    }
  }, [initialRows, setValue, isFetching]);

  useEffect(() => {
    if (scrollbarRef.current) {
      setScrollbarRef(scrollbarRef.current);
    }
  }, [setScrollbarRef, virtualScrollData.visibleRows]);

  useEffect(() => {
    return () => {
      isFirstRender.current = true;
    };
  }, [isFetching]);

  if (isLoading && !data) {
    return <FullLoading />;
  }

  return (
    <>
      <Scrollbar
        ref={scrollbarRef}
        id='customer-management-table-body'
        onScroll={(scrollTop) => virtualScrollData.handleScroll(scrollTop)}
        style={{ height: `${window.innerHeight - 196}px` }}>
        <div style={{ height: `${virtualScrollData.totalHeight}px`, position: 'relative' }}>
          <table
            className='w-full table-fixed'
            style={{
              transform: `translateY(${virtualScrollData.offsetY}px)`,
            }}>
            <CustomerManagementEditableTableBody
              countriesList={countriesList}
              treatmentTagsList={treatmentTags}
              visibleRows={virtualScrollData.visibleRows}
              {...usingColumns}
            />
            {virtualScrollData.visibleRows.length > 0 && (
              <CustomerManagementEditableTableFooter
                handleAddRow={addNew50Rows}
                {...usingColumns}
              />
            )}
          </table>
          <div ref={loadMoreRef} className='h-1' />
        </div>
      </Scrollbar>

      {isFetchingNextPage && (
        <div className='flex w-full justify-center'>
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}
