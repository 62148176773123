import FilePicker, { type FilePickerHandle } from '@shared/components/FilePicker/FilePicker';
import { FILE_UPLOAD_MAX_SIZE } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/constants/file-upload-max-size';
import EventImageCropDialog from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventImageCropDialog/EventImageCropDialog';
import { selectedContentImageErrorAtom } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/content-edit-errors-state';
import { detailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/detail-image-temp-file-state';
import BigImage from 'afterdoc-design-system/components/Atoms/BigImage/BigImage';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useSetAtom } from 'jotai';
import { useAtom } from 'jotai/index';
import { useRef } from 'react';

const isFileTooLarge = (targetFile: File) => {
  if (targetFile.size >= FILE_UPLOAD_MAX_SIZE) {
    toastService.errorMsg({
      text: '30MB 이하의 이미지를 등록할 수 있습니다.',
    });
    return true;
  }
  return false;
};

interface ContentImagePickerProps {
  imageUrl?: string;
  onHandleChange: (url: string) => void;
}

export default function ContentImagePicker({ imageUrl, onHandleChange }: ContentImagePickerProps) {
  const filePickerRef = useRef<FilePickerHandle>(null);

  const [detailImageError, setDetailImageError] = useAtom(selectedContentImageErrorAtom);

  const setDetailImageTempFile = useSetAtom(detailImageTempFileState);

  const handleClick = () => {
    filePickerRef.current?.clickSingle();
  };

  const handleCropComplete = (url: string, file: File) => {
    onHandleChange(url);
    setDetailImageTempFile({ file, url });
    setDetailImageError(undefined);
  };

  const handleFileChange = (files: File[]) => {
    if (files.length === 0) return;
    const targetFile = files[0];

    if (isFileTooLarge(targetFile)) return;

    dialogService.push(
      <EventImageCropDialog file={files[0]} onCropComplete={handleCropComplete} />,
      {
        id: 'image-crop-dialog',
        titleProps: {
          title: '사이즈 조정',
          subTitle: '사진을 드래그하여 위치를 변경하거나 마우스 휠로 확대/축소할 수 있습니다.',
        },
        hasCloseButton: false,
        wrapperClassName: 'h-[592px]',
        width: 500,
      },
    );
  };

  return (
    <div className='flex flex-col gap-4'>
      <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
      <BigImage
        text='이미지 변경'
        width={170}
        height={170}
        onClickImage={handleClick}
        onClickNoImage={handleClick}>
        {!!imageUrl && (
          <img
            alt='eventDetailImage'
            className='h-full w-full rounded-xl object-cover'
            src={imageUrl}
          />
        )}
      </BigImage>
      {!!detailImageError && <span className='text-Body10 text-red500'>{detailImageError}</span>}
    </div>
  );
}
