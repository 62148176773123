import ChatIsBookmarkListItem from '@templates/CustomerChat/components/SupportBot/components/ChatIsBookmarkListItem';
import NoSupportBotContents from '@templates/CustomerChat/components/SupportBot/components/NoSuportBotContents/NoSupportBotContents';
import { useInfiniteChatIsBookmarkData } from '@templates/CustomerChat/components/SupportBot/hooks/use-infinite-chat-is-bookmark-data';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useIntersectionObserver } from 'hooks/use-intersection-observer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useBookmarkPushHandler } from './hooks/use-bookmark-push-handler';

interface BookmarkListProps {
  onChangeLoadingState: (isVisible: boolean) => void;
}

export default function BookmarkList({ onChangeLoadingState }: BookmarkListProps) {
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const [targets, setTargets] = useState<(Element | null)[]>([]);

  const {
    data: chatRooms,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    hasNextPage,
  } = useInfiniteChatIsBookmarkData();

  useBookmarkPushHandler({ refetch });

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  useIntersectionObserver(
    handleIntersection,
    { root: null, rootMargin: '20px', threshold: 0.5 },
    targets,
  );

  useEffect(() => {
    onChangeLoadingState(isLoading || isFetchingNextPage);
  }, [isLoading, isFetchingNextPage, onChangeLoadingState]);

  useEffect(() => {
    if (loadMoreRef.current) {
      setTargets([loadMoreRef.current]);
    }
  }, [loadMoreRef.current]);

  if (!isLoading && chatRooms.length === 0) return <NoSupportBotContents />;

  return (
    <>
      <Scrollbar disabledX>
        <div className='mx-16 flex flex-col gap-10'>
          {chatRooms.map((item) => (
            <ChatIsBookmarkListItem key={item._id} data={item} />
          ))}
        </div>
        <div ref={loadMoreRef} style={{ height: 1 }} />
      </Scrollbar>
    </>
  );
}
