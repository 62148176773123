import type { ReactNode } from 'react';

interface AuthorizationListTitle {
  icon: ReactNode;
  name?: string;
}

export default function AuthorizationListTitle({ icon, name }: AuthorizationListTitle) {
  return (
    <div className='flex h-[30px] w-[140px] items-center'>
      <div className='flex items-center gap-8'>
        {icon}
        <span className='text-Header14 text-black500'>{name ?? ''}</span>
      </div>
    </div>
  );
}
