import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useRef } from 'react';
import type { MemoWithFileID } from 'web/apis/swaggers/swagger-docs';
import { useIntersectionObserver } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import ManagementDirectionMemoCard from './ManagementDirectionMemoCard';

interface ManagementDirectionListWithTitleProps {
  managementDirectionLists: MemoWithFileID[];
  selectedManagementDirectionId?: string;
  handleClickMemoCard: (id: string) => void;
  onClickAddManagementDirection: () => void;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
}

export default function ManagementDirectionListsWithTitle({
  managementDirectionLists,
  selectedManagementDirectionId,
  handleClickMemoCard,
  onClickAddManagementDirection,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: ManagementDirectionListWithTitleProps) {
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isFetchingNextPage,
  });

  if (!managementDirectionLists || !managementDirectionLists.length) {
    return (
      <div className='flex h-full flex-col gap-10 rounded-r16 bg-white200 py-12'>
        <TitleWithAddButton onClickAddManagementDirection={onClickAddManagementDirection} />
        <div className='flex-full-center'>
          <NoData
            iconProps={{
              name: 'warning',
              size: 48,
              color: 'white600',
            }}
            title='표시할 내용이 없습니다.'
          />
        </div>
      </div>
    );
  }

  return (
    <div className='w-[320px] rounded-r16 bg-white200 py-12'>
      <TitleWithAddButton onClickAddManagementDirection={onClickAddManagementDirection} />
      <div className='mt-10 h-[660px] w-full'>
        <Scrollbar disabledX>
          <div className='flex flex-col gap-10 px-16'>
            {managementDirectionLists.map((managementDirection) => (
              <ManagementDirectionMemoCard
                key={managementDirection._id}
                managementDirection={managementDirection}
                selectedManagementDirectionId={selectedManagementDirectionId}
                onClick={() => handleClickMemoCard(managementDirection._id)}
              />
            ))}
            <div ref={loadMoreRef} style={{ height: 1 }} />
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

interface TitleWithAddButtonProps {
  onClickAddManagementDirection: () => void;
}

const TitleWithAddButton = ({ onClickAddManagementDirection }: TitleWithAddButtonProps) => {
  return (
    <div className='flex w-full justify-between px-16'>
      <button
        className='flex items-center gap-2 rounded-r6 py-2 pr-4 pl-8'
        type='button'
        onClick={onClickAddManagementDirection}>
        <div className='text-Body11 text-blue500'>관리방향 추가</div>
        <Icon name='add-circle' size={16} color='blue500' />
      </button>
    </div>
  );
};
