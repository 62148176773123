import * as React from "react";
import type { SVGProps } from "react";
const SvgListBullet = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M5.834 4.167H17.5v1.666H5.834zm0 6.666V9.167H17.5v1.666zm-2.5-7.083a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5m0 5a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5m2.5 7.083v-1.666H17.5v1.666zm-2.5-2.083a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5"
    />
  </svg>
);
export default SvgListBullet;
