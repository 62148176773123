import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { LabelTextProps } from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import GradientTag from 'afterdoc-design-system/components/Atoms/Tag/GradientTag/GradientTag';
import VirtualizedTagDropdown from 'afterdoc-design-system/components/Molecules/Dropdown/VirtualizedTagDropdown/VirtualizedTagDropdown';
import {
  forwardRef,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import './MultipleTextFieldSelectBoxTagDropdown.scss';

export interface BaseMultipleTagsProperty {
  name: string;
  id: string;
  color?: string;
  isSpecial?: boolean;
}

export interface TextFieldSelectBoxTagDropdownProps<T extends BaseMultipleTagsProperty> {
  id?: string;
  width?: number | string;
  placeholder?: string;
  value: T[] | null;
  tagOptions: T[];
  onSelect?: (index: number) => void;
  onSelectedTagsChange?: (tags: T[]) => void;
  onRemoveTag?: (tagId: string) => void;
  disabled?: boolean;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
  handleClickOutside?: () => void;
  className?: string;
  fieldWrapperClassName?: string;
  label?: LabelTextProps;
}

const MultipleTextFieldSelectBoxTagDropdown = forwardRef<
  HTMLDivElement,
  TextFieldSelectBoxTagDropdownProps<BaseMultipleTagsProperty>
>(
  (
    {
      id,
      value,
      width,
      onSelect,
      tagOptions,
      onSelectedTagsChange,
      onRemoveTag,
      disabled,
      placeholder,
      className,
      fieldWrapperClassName,
      label,
    },
    ref,
  ) => {
    const uniqueId = useId();
    const inputRef = useRef<HTMLDivElement>(null);
    const ignoreRef = useRef(null);
    const prevInputValueRef = useRef('');

    const [isToggle, setIsToggle] = useState(false);
    const [selectedTags, setSelectedTags] = useState<BaseMultipleTagsProperty[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [immediateInputValue, setImmediateInputValue] = useState('');
    const [isComposing, setIsComposing] = useState(false);

    useImperativeHandle(ref, () => inputRef.current as HTMLDivElement);

    const filteredTags = useMemo(() => {
      if (!isToggle) return [];

      const searchValue = inputValue.toLowerCase();
      return tagOptions.filter(
        (tag) =>
          tag.name.toLowerCase().includes(searchValue) &&
          !selectedTags.some((selectedTag) => selectedTag.name === tag.name),
      );
    }, [isToggle, inputValue, tagOptions, selectedTags]);

    const handleSelect = useCallback(
      (index: number) => {
        if (disabled) return;

        const selectedTag = filteredTags[index];
        if (!selectedTags.some((tag) => tag.id === selectedTag.id)) {
          requestAnimationFrame(() => {
            if (inputRef.current) {
              inputRef.current.textContent = '';
              prevInputValueRef.current = '';
              setInputValue('');

              const range = document.createRange();
              const sel = window.getSelection();
              range.selectNodeContents(inputRef.current);
              range.collapse(false);
              sel?.removeAllRanges();
              sel?.addRange(range);
              inputRef.current.focus();
            }

            const newSelectedTag = {
              id: selectedTag.id,
              name: selectedTag.name,
              color: selectedTag.color,
              isSpecial: selectedTag.isSpecial,
            };
            const newSelectedTags = [...selectedTags, newSelectedTag];
            setSelectedTags(newSelectedTags);
            onSelectedTagsChange?.(newSelectedTags);
            onSelect?.(index);
          });
        }
      },
      [disabled, filteredTags, selectedTags, onSelectedTagsChange, onSelect],
    );

    const handleToggle = (isToggle?: boolean) => {
      if (isToggle !== undefined) {
        setIsToggle(isToggle);
      } else {
        setIsToggle((prev) => !prev);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (disabled || isComposing) return;

      if (e.key === 'Enter') {
        e.preventDefault();
        if (!isToggle || !filteredTags.length) {
          setIsToggle(true);
          inputRef.current?.classList.remove('hidden-caret');
          inputRef.current?.focus();
        }
      } else if (e.key === 'Tab') {
        e.preventDefault();
        const nextFocusable = inputRef.current?.nextElementSibling as HTMLElement;
        if (nextFocusable) {
          nextFocusable.focus();
        }
      } else if (e.key === 'Backspace') {
        if (window.getSelection()?.toString() || e.ctrlKey || e.metaKey) {
          e.preventDefault();
          setSelectedTags([]);
          onSelectedTagsChange?.([]);
          setInputValue('');
          setImmediateInputValue('');
          if (inputRef.current) {
            inputRef.current.textContent = '';
          }
        } else if (inputValue === '' && selectedTags.length > 0) {
          e.preventDefault();
          const newSelectedTags = selectedTags.slice(0, -1);
          setSelectedTags(newSelectedTags);
          onSelectedTagsChange?.(newSelectedTags);
          setIsToggle(true);
          inputRef.current?.focus();
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setIsToggle(false);
        setInputValue('');
        setImmediateInputValue('');
        if (inputRef.current) {
          inputRef.current.blur();
          inputRef.current.classList.add('hidden-caret');
          inputRef.current.textContent = '';
          if (!selectedTags.length) {
            inputRef.current.setAttribute('data-placeholder', placeholder ?? '');
          }
        }
      }
    };

    const handleRemoveTag = (tagId: string) => {
      if (disabled) return;

      const newSelectedTags = selectedTags.filter((tag) => tag.id !== tagId);
      setSelectedTags(newSelectedTags);
      onSelectedTagsChange?.(newSelectedTags);
      onRemoveTag?.(tagId);
      setIsToggle(true);
      inputRef.current?.focus();
    };

    const handleInput = useCallback(
      (e: React.FormEvent<HTMLDivElement>) => {
        if (disabled) return;

        const text = e.currentTarget?.textContent || '';
        if (text === prevInputValueRef.current) return;

        prevInputValueRef.current = text;
        setInputValue(text);
        setIsToggle(true);
      },
      [disabled],
    );

    const handleFieldClick = useCallback(() => {
      if (disabled) return;
      setIsToggle(true);
      inputRef.current?.focus();
    }, [disabled]);

    const handleCompositionStart = () => {
      setIsComposing(true);
    };

    const handleCompositionEnd = (e: React.CompositionEvent<HTMLDivElement>) => {
      setIsComposing(false);
      const text = e.currentTarget?.textContent || '';
      setInputValue(text);
      setImmediateInputValue(text);
    };

    useEffect(() => {
      const observer = new MutationObserver(() => {
        const text = inputRef.current?.textContent || '';
        setImmediateInputValue(text);
      });

      if (inputRef.current) {
        observer.observe(inputRef.current, {
          childList: true,
          subtree: true,
          characterData: true,
        });
      }

      return () => {
        observer.disconnect();
      };
    }, [inputRef]);

    useEffect(() => {
      if (inputRef.current) {
        if (!selectedTags.length) {
          inputRef.current.setAttribute('data-placeholder', placeholder ?? '');
        } else {
          inputRef.current.removeAttribute('data-placeholder');
        }
      }
    }, [selectedTags, placeholder]);

    useEffect(() => {
      if (!value?.length) {
        setSelectedTags([]);
        setInputValue('');
        setImmediateInputValue('');
        if (inputRef.current) {
          inputRef.current.textContent = '';
        }
      } else {
        const tagMap = new Map(tagOptions.map((tag) => [tag.id, tag]));
        const orderedTags = value.reduce<BaseMultipleTagsProperty[]>((acc, valueTag) => {
          const matchedTag = tagMap.get(valueTag.id);

          if (matchedTag) {
            const newTag = {
              id: matchedTag.id,
              name: matchedTag.name,
              color: matchedTag.color,
              isSpecial: valueTag.isSpecial ?? matchedTag.isSpecial,
            };
            acc.push(newTag);
          }
          return acc;
        }, []);

        setSelectedTags(orderedTags);
      }
    }, [value, tagOptions]);

    return (
      <div className={customTwMerge('flex flex-col', className)}>
        {label && (
          <label htmlFor={uniqueId} className='mb-10 flex items-center text-Header12 text-black500'>
            <LabelText {...label} />
          </label>
        )}
        <div>
          <div
            ref={ignoreRef}
            onClick={handleFieldClick}
            className={customTwMerge(
              'flex flex-wrap items-center gap-4 rounded-r10 border px-16',
              'outline-none focus-within:border-blue500',
              'overflow-y-auto',
              'text-field-select-box-tag-dropdown-scroll',
              selectedTags.length > 0 ? 'py-[4.5px]' : 'py-6',
              disabled
                ? 'h-32 cursor-not-allowed border-white600 bg-white200'
                : 'border-white600 bg-white50',
              fieldWrapperClassName,
            )}
            style={{
              width: SHARED_UTILS.css.getCssSizeValue(width),
            }}>
            {selectedTags.filter((tag) => tag.isSpecial).length > 0 && (
              <GradientTag
                tagSize='small'
                iconProps={{
                  name: 'close',
                  color: 'white50',
                  size: 12,
                }}
                onClick={(e) => {
                  e?.stopPropagation();
                  handleRemoveTag(selectedTags.filter((tag) => tag.isSpecial)[0].id);
                }}>
                {selectedTags.filter((tag) => tag.isSpecial)[0].name}
              </GradientTag>
            )}
            {selectedTags
              .filter((tag) => !tag.isSpecial)
              .map((tag) => (
                <FilledTag
                  key={uuidv4()}
                  tagSize='small'
                  className={customTwMerge(disabled ? 'cursor-not-allowed' : 'cursor-pointer')}
                  iconProps={{
                    name: 'close',
                    size: 12,
                  }}
                  bgColor={tag.color}
                  onClick={(e) => {
                    if (disabled) return;
                    e?.stopPropagation();
                    handleRemoveTag(tag.id);
                  }}>
                  {tag.name}
                </FilledTag>
              ))}
            <div
              id={uniqueId}
              ref={inputRef}
              className='flex-1 border-none bg-transparent text-Body12 text-black700 placeholder-black200 outline-none placeholder:text-Body12 placeholder:text-black200'
              contentEditable={!disabled}
              onInput={handleInput}
              onKeyDown={handleKeyDown}
              onCompositionStart={handleCompositionStart}
              onCompositionEnd={handleCompositionEnd}
              suppressContentEditableWarning={true}
              onClick={(e) => {
                e.preventDefault();
                if (disabled) return;
                setIsToggle(true);
                inputRef.current?.focus();
                inputRef.current?.classList.remove('hidden-caret');
              }}
            />
          </div>
          {!disabled && isToggle && (
            <VirtualizedTagDropdown
              id={id}
              width={width}
              isComposing={isComposing}
              tags={filteredTags}
              ignoreRefs={[ignoreRef]}
              isToggle={isToggle}
              searchText={immediateInputValue}
              handleToggle={handleToggle}
              isDefaultFocus={!!immediateInputValue}
              onSelect={handleSelect}
            />
          )}
        </div>
      </div>
    );
  },
);

MultipleTextFieldSelectBoxTagDropdown.displayName = 'MultipleTextFieldSelectBoxTagDropdown';

export default MultipleTextFieldSelectBoxTagDropdown;
