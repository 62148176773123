import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { CHATTING_LIST_TABS } from 'web/templates/CustomerChat/constants/tab';
import { selectedChattingListTabState } from 'web/templates/CustomerChat/states/selected-chatting-list-tab';
import { DEFAULT_PARAMS } from '../constants/chatting-list-default-params';

type GetChatRoomLengthParams = {
  hospitalID: string | undefined;
  participant: string;
  way: number;
  isActive: boolean;
  isDoctor: boolean;
  sortOption?: string;
  keyword?: string;
};

const getChatRoomLength = async (params: GetChatRoomLengthParams) => {
  // keyword를 제거한 params 복사
  const { keyword, ...filteredParams } = params;

  // API 호출에 사용할 개별 params 구성
  const ingParams = {
    ...filteredParams,
    isCounselInProgress: true,
  };

  const totalParams = {
    ...filteredParams,
    isCounselInProgress: undefined,
  };

  // 병렬 API 호출
  const [ingResponse, totalResponse] = await Promise.all([
    apiClient.v3.apiChatroomElCount(ingParams),
    apiClient.v3.apiChatroomElCount(totalParams),
  ]);

  return {
    ingLength: SHARED_UTILS.api.checkApiResponse(ingResponse.data),
    totalLength: SHARED_UTILS.api.checkApiResponse(totalResponse.data),
  };
};

export const useChatRoomLength = () => {
  const selectedChattingListTab = useAtomValue(selectedChattingListTabState);

  const { hospitalID } = useSelectedHospitalInfo();
  const { userId: userID } = useUserInfo();
  const isCounselInProgress =
    selectedChattingListTab === CHATTING_LIST_TABS.IN_PROGRESS ? true : undefined;

  const query = useMemo(
    () => ({
      ...DEFAULT_PARAMS,
      hospitalID,
      participant: userID,
      isCounselInProgress,
    }),
    [hospitalID, userID],
  );

  const {
    data,
    isLoading: lengthResultIsLoading,
    refetch: lengthResultRefetch,
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiChatroomElCount, query] as const,
    queryFn: ({ queryKey }) => getChatRoomLength(queryKey[1]),
  });

  return {
    ingLength: data.ingLength,
    allLength: data.totalLength,
    lengthResultIsLoading,
    lengthResultRefetch,
  };
};
