import * as React from "react";
import type { SVGProps } from "react";
const SvgAutomationActivated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#333"
      fillRule="evenodd"
      d="M1.51 9.56a.69.69 0 0 0-.495.528.7.7 0 0 0 .243.682l3.447 2.778a.69.69 0 0 0 .709.091l10.713-4.794-9.338 6.718a.69.69 0 0 0-.289.563v5.181a.7.7 0 0 0 .363.611.68.68 0 0 0 .707-.035l4.082-2.74 3.358 2.704a.685.685 0 0 0 1.065-.273l6.873-16.618a.697.697 0 0 0-.435-.926.7.7 0 0 0-.376-.007z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAutomationActivated;
