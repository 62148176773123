export const TEXT_TYPE = 'text';
export const KAKAO_TEXT_TYPE_FOR_API = 'TX';
export const KAKAO_FILES_TYPE_FOR_API = 'ikakao';
export const FILE_TYPE = 'files';
export const IMAGE_TYPE = 'image';

export const REQUEST_ROOM_TYPE_MAP = {
  AFTERDOC: {
    TEXT_TYPE: 'afterdoc_text',
    IMAGE_TYPE: 'image',
    IMAGE_FILE_TYPE: 'afterdoc_files',
  },
  KAKAO: {
    TEXT_TYPE: 'ikakao',
    IMAGE_TYPE: 'kakao_images',
    FILE_TYPE: 'kakaoFiles',
    IMAGES_FILES_TYPE: 'kakaoFiles',
  },
} as const;
