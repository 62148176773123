import * as React from "react";
import type { SVGProps } from "react";
const SvgCalendarActivated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#333"
      d="M18.13 13.108v5.513H12v-5.513zM16.87 1h2.463v2.215h1.204q1.031 0 1.747.644.716.645.716 1.572v15.354q0 .927-.716 1.571-.717.645-1.747.644H3.464q-1.032 0-1.748-.644T1 20.784V5.432q0-.927.716-1.572.716-.644 1.748-.644h1.203V1H7.13v2.215h9.74zm3.666 19.785V8.728H3.465v12.057z"
    />
  </svg>
);
export default SvgCalendarActivated;
