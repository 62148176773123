import {
  initialUseSaasReservationState,
  useSaasReservationState,
} from '@templates/HospitalSetting/containers/ServiceSetting/containers/UsingReservationSetting/states/hospital-use-saas-reservation-state';
import { useHospitalInfo } from '@templates/HospitalSetting/hooks/use-hospital-info';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtom, useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useEffect } from 'react';

export default function UsingReservationSettingContainer() {
  const { hospitalInfo } = useHospitalInfo();

  const resetUseSaasReservation = useResetAtom(useSaasReservationState);
  const resetInitialUseSaasReservation = useResetAtom(initialUseSaasReservationState);

  const [useSaasReservation, setUseSaasReservation] = useAtom(useSaasReservationState);
  const setInitialUseSaasReservation = useSetAtom(initialUseSaasReservationState);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseSaasReservation(e.target.checked);
  };

  useEffect(() => {
    const state = hospitalInfo.usingSaaSReservation ?? false;
    setUseSaasReservation(state);
    setInitialUseSaasReservation(state);
  }, [hospitalInfo.usingSaaSReservation]);

  useEffect(() => {
    return () => {
      resetUseSaasReservation();
      resetInitialUseSaasReservation();
    };
  }, []);

  return (
    <div className='flex flex-col gap-20 py-20'>
      <div className='flex items-center px-80'>
        <Title
          title='예약관리 사용'
          subTitle='예약관리의 사용여부를 설정할 수 있습니다. 예약관리를 비활성화 할 경우, 예약과 관련된 화면이 숨겨집니다.'
          wrapperClassName='flex-grow'
        />
        <Toggle toggleSize='big' checked={useSaasReservation} onChange={handleChange} />
      </div>
      <Divider type='line' height={10} direction='horizontal' />
    </div>
  );
}
