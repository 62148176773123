import type { ChartOptions } from 'chart.js';

export const LINE_CHART_OPTIONS: ChartOptions<'line'> = {
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      right: 15,
    },
  },
  clip: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        color: '#888888',
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
    },
  },
  hover: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      displayColors: false,
      titleMarginBottom: 0,
      callbacks: {
        title: () => '',
      },
    },
  },
};

export const BAR_CHART_OPTIONS: ChartOptions<'bar'> = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: '#888888',
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false, // Y축 그리드 라인 제거
      },
      ticks: {
        color: '#888888',
        stepSize: 1,
        callback: (value: string | number) => Math.floor(Number(value)),
        autoSkip: true,
        maxTicksLimit: 6,
      },
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      suggestedMax: (context: any) => {
        const max = Math.max(...context.chart.data.datasets[0].data);
        return Math.ceil(max * 1.1);
      },
    },
  },
  hover: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      displayColors: false,
      titleMarginBottom: 0,
      callbacks: {
        title: () => '',
      },
    },
  },
};
