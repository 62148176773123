import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useAtomValue } from 'jotai';
import ALIntegrationEdit from 'web/shared/components/ALIntegrationEdit/ALIntegrationEdit';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useServiceSettings } from 'web/shared/hooks/use-serivce-settings';
import EnrollDialog from 'web/templates/CustomerChat/components/ChattingRoom/components/CustomerInfoTop/components/PatientTitle/components/EnrollDialog/EnrollDialog';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import { chatRoomHserviceIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { MAX_LAYOUT_WIDTH, MIN_LAYOUT_WIDTH } from '../../../../constants/layout';
import PatientDetail from './components/PatientDetail/PatientDetail';
import PatientTitle from './components/PatientTitle/PatientTitle';
import TranslateToggle from './components/TranslateToggle/TranslateToggle';
import TreatmentTags from './components/TreatmentTags/TreatmentTags';
import { useLinkChannelPushHandler } from './hooks/use-link-channel-push-handler';
import { useUpdateSuspendSyncChatRoomInfoPushHandler } from './hooks/use-update-suspend-sync-chat-room-info-push-handler';

interface CustomerInfoTopProps {
  chatRoomID: string;
}

export default function CustomerInfoTop({ chatRoomID }: CustomerInfoTopProps) {
  const chatRoomHserivceID = useAtomValue(chatRoomHserviceIDSelector);

  const { integrationSetting } = useSelectedHospitalInfo();
  const { isKakaoIcon, isColorAfterDocIcon, isGrayAfterDocIcon, hasSmartDoctorLinkage } =
    useChatTopPatient();
  const adminSettingResponse = useServiceSettings();

  useLinkChannelPushHandler({ chatRoomID });
  useUpdateSuspendSyncChatRoomInfoPushHandler();

  const isUseTranslate = !!adminSettingResponse?.usingChatTranslate;

  //카톡 고객 등록 버튼과 전능 연동 버튼은 공존 할 수 없음
  const isShowRegistButton =
    isKakaoIcon === true && isGrayAfterDocIcon === false && isColorAfterDocIcon === false;

  const isShowALMightyLinkButton =
    !hasSmartDoctorLinkage &&
    integrationSetting &&
    !integrationSetting.isRemoved &&
    integrationSetting.integrationType === 'ALM' &&
    integrationSetting.clientToken;

  const isShowDetail = !(
    isKakaoIcon === true &&
    isGrayAfterDocIcon === false &&
    isColorAfterDocIcon === false
  );

  return (
    <div
      className='flex min-h-[104px] flex-row justify-between gap-20 border-white border-white400 bg-white50 px-20 py-10'
      style={{ minWidth: `${MIN_LAYOUT_WIDTH}`, maxWidth: `${MAX_LAYOUT_WIDTH}` }}>
      <div className={customTwMerge('flex w-full flex-col ', isShowDetail ? '' : 'gap-4')}>
        <PatientTitle />
        {isShowDetail ? (
          <>
            <PatientDetail />
            <TreatmentTags />
          </>
        ) : (
          <div className='flex flex-col-center items-start text-Body12'>
            <span>카카오톡으로 문의한 고객입니다.</span>
            <span>
              [고객등록] 버튼을 클릭하여 고객으로 등록하면, 예약 및 고객정보를 기록할 수 있습니다.
            </span>
          </div>
        )}
      </div>

      <div className='flex flex-col justify-between'>
        <div>
          {isShowRegistButton ? (
            <OutlinedButton
              className='whitespace-nowrap'
              onClick={() => {
                dialogService.push(<EnrollDialog />, {
                  titleProps: {
                    title: '고객등록',
                  },
                  wrapperClassName: 'h-[326px]',
                });
              }}>
              고객등록
            </OutlinedButton>
          ) : (
            isShowALMightyLinkButton && (
              <OutlinedButton
                className='whitespace-nowrap'
                onClick={() => {
                  dialogService.push(
                    <ALIntegrationEdit
                      patientId={chatRoomHserivceID}
                      dialogId={'ALIntegrationEdit'}
                    />,
                    {
                      width: 'auto',
                      id: 'ALIntegrationEdit',
                    },
                  );
                }}>
                계정연동
              </OutlinedButton>
            )
          )}
        </div>

        {isUseTranslate && <TranslateToggle chatRoomID={chatRoomID} />}
      </div>
    </div>
  );
}
