import { useNotificationSound } from '@hooks/use-notification-sound';
import { OVERLAY_PAGE_ID } from '@shared/constants/overlay-page-id';
import eventBus from '@shared/utils/event-bus';
import { useQueryClient } from '@tanstack/react-query';
import { alertService } from 'afterdoc-design-system/components/Molecules/Alert/Alert.service';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useStore } from 'jotai/index';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DataPushNotification,
  type DataPushNotificationDataMap,
} from 'web/apis/push/data-push/data-push-notification';
import type { DataPushNotificationQuery } from 'web/apis/push/data-push/data-push-type';
import {
  MessagePushNotification,
  type MessagePushNotificationDataMap,
} from 'web/apis/push/message-push/message-push-notification';
import type { QueryKeyParamsMap } from 'web/apis/swaggers/query-key';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';

export const usePushNotificationObserver = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const store = useStore();

  const { playAudio } = useNotificationSound();

  const handleInvalidateQueries = async <D extends keyof DataPushNotificationDataMap>(
    queries: DataPushNotificationQuery<keyof QueryKeyParamsMap, D>[],
    notificationData: DataPushNotificationDataMap[D],
  ) => {
    const firstQueries: DataPushNotificationQuery<keyof QueryKeyParamsMap, D>[] = [];
    const asyncQueries: DataPushNotificationQuery<keyof QueryKeyParamsMap, D>[] = [];

    for (const query of queries) {
      if (query.first) {
        firstQueries.push(query);
      } else {
        asyncQueries.push(query);
      }
    }

    const processQueryInvalidation = async (
      query: DataPushNotificationQuery<keyof QueryKeyParamsMap, D>,
    ) => {
      const specificQueryKey = query.getQueryKey
        ? query.getQueryKey(notificationData)
        : [query.queryKey];

      const hasSpecificQuery = queryClient.getQueryData(specificQueryKey) !== undefined;

      if (hasSpecificQuery) {
        await queryClient.invalidateQueries({ queryKey: specificQueryKey });
      } else {
        const broaderQueryKey = [query.queryKey];
        await queryClient.invalidateQueries({ queryKey: broaderQueryKey });
      }
    };

    for (const query of firstQueries) {
      await processQueryInvalidation(query);
    }

    await Promise.all(asyncQueries.map((query) => processQueryInvalidation(query)));
  };

  // 현재 URL의 chatRoomId를 확인하는 헬퍼 함수
  const shouldShowChatNotification = async (notificationChatRoomId: string) => {
    const id = OVERLAY_PAGE_ID['customer-detail-info'];
    const currentChatRoomId = store.get(chatRoomIDSelector);

    if (location.pathname.includes('/customer-chat')) {
      const isShowOverlayPage = await overlayPageService.isShowOverlayPage(id);
      if (isShowOverlayPage) return true;
      return currentChatRoomId !== notificationChatRoomId;
    }
    return true;
  };

  useEffect(() => {
    const handleNotification = async (
      _: unknown,
      payload: {
        data: {
          code: keyof (DataPushNotificationDataMap & MessagePushNotificationDataMap);
        } & Record<string, unknown>;
        notification: { title: string; body: string };
      },
    ) => {
      console.info(`payload : ${payload.data.code}`, payload);

      const notificationCode = payload.data.code;
      const notificationData = payload.data;

      const isDataPushNotification = notificationCode.startsWith('d_');
      const isMessagePushNotification = notificationCode.startsWith('m_');

      // 데이터 푸시 노티
      if (isDataPushNotification) {
        eventBus.emit('dataPushNotification', payload);

        const dataNotificationCode = notificationCode as keyof DataPushNotificationDataMap;
        const dataNotificationData =
          notificationData as DataPushNotificationDataMap[typeof dataNotificationCode] & {
            code: typeof dataNotificationCode;
          };

        // Narrow down `queriesToRefetch` by type inference
        const queriesToRefetch = DataPushNotification[
          dataNotificationCode
        ] as DataPushNotificationQuery<keyof QueryKeyParamsMap, typeof dataNotificationCode>[];

        if (queriesToRefetch) {
          return handleInvalidateQueries<typeof dataNotificationCode>(
            queriesToRefetch,
            dataNotificationData,
          );
        }
      }

      // 메시지 푸시
      if (isMessagePushNotification) {
        const messageNotificationCode = notificationCode as keyof MessagePushNotificationDataMap;
        const messageNotificationData =
          payload.data as unknown as MessagePushNotificationDataMap[typeof messageNotificationCode];

        // 채팅 메시지 알림인 경우 현재 채팅방 확인
        if (messageNotificationCode.startsWith('m_ChatReceive_')) {
          const messagePushChatRoomId = (messageNotificationData as { chatRoomID: string })
            .chatRoomID;
          if (!(await shouldShowChatNotification(messagePushChatRoomId))) {
            return;
          }
        }

        playAudio();

        const notificationTitle = payload.notification?.title ?? 'Notification';
        const notificationBody = payload.notification?.body ?? '';

        const handler = MessagePushNotification[messageNotificationCode];
        if (handler) {
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          const alertProps = handler(messageNotificationData as any, navigate, queryClient);

          alertService.push({
            titleProps: {
              title: notificationTitle,
              adjacentChildren: alertProps.titleProps?.adjacentChildren,
            },
            contents: <>{notificationBody}</>,
            onClickAlert: alertProps.onClickAlert,
            shouldCloseOnClick: true,
          });
        } else {
          alertService.push({
            titleProps: { title: notificationTitle },
            contents: <>{notificationBody}</>,
          });
        }
      }
    };

    const handleClickNotification = (
      _: unknown,
      payload: {
        data: {
          code: keyof (DataPushNotificationDataMap & MessagePushNotificationDataMap);
        } & Record<string, unknown>;
        notification: { title: string; body: string };
      },
    ) => {
      const notificationCode = payload.data.code;
      const messageNotificationCode = notificationCode as keyof MessagePushNotificationDataMap;
      const messageNotificationData =
        payload.data as unknown as MessagePushNotificationDataMap[typeof messageNotificationCode];
      const handler = MessagePushNotification[messageNotificationCode];
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const alertProps = handler(messageNotificationData as any, navigate, queryClient) as {
        onClickAlert: Function;
      };
      const clickHandler = alertProps.onClickAlert;
      clickHandler();
    };

    window.electron?.ipcRenderer.on('Application.receiveNotification', handleNotification);
    window.electron?.ipcRenderer.on('Application.clickNotification', handleClickNotification);

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.receiveNotification');
      window.electron?.ipcRenderer.removeAllListeners('Application.clickNotification');
    };
  }, []);
};
