import AlignCenter from '../assets/editors/AlignCenter';
import AlignJustify from '../assets/editors/AlignJustify';
import AlignLeft from '../assets/editors/AlignLeft';
import AlignRight from '../assets/editors/AlignRight';
import Background from '../assets/editors/Background';
import Blockquote from '../assets/editors/Blockquote';
import Bold from '../assets/editors/Bold';
import Clean from '../assets/editors/Clean';
import Code from '../assets/editors/Code';
import Color from '../assets/editors/Color';
import DirectionLtr from '../assets/editors/DirectionLtr';
import DirectionRtl from '../assets/editors/DirectionRtl';
import Divider from '../assets/editors/Divider';
import Formula from '../assets/editors/Formula';
import Header from '../assets/editors/Header';
import Header2 from '../assets/editors/Header2';
import Header3 from '../assets/editors/Header3';
import Image from '../assets/editors/Image';
import Indent from '../assets/editors/Indent';
import Italic from '../assets/editors/Italic';
import Link from '../assets/editors/Link';
import ListBullet from '../assets/editors/ListBullet';
import ListCheck from '../assets/editors/ListCheck';
import ListOrdered from '../assets/editors/ListOrdered';
import Outdent from '../assets/editors/Outdent';
import Redo from '../assets/editors/Redo';
import Strike from '../assets/editors/Strike';
import Subscript from '../assets/editors/Subscript';
import Superscript from '../assets/editors/Superscript';
import Table from '../assets/editors/Table';
import Underline from '../assets/editors/Underline';
import Undo from '../assets/editors/Undo';
import Video from '../assets/editors/Video';

export default {
  align: {
    '': <AlignLeft />,
    center: <AlignCenter />,
    right: <AlignRight />,
    justify: <AlignJustify />,
  },
  background: <Background />,
  blockquote: <Blockquote />,
  bold: <Bold />,
  clean: <Clean />,
  code: <Code />,
  'code-block': <Code />,
  color: <Color />,
  direction: {
    '': <DirectionLtr />,
    rtl: <DirectionRtl />,
  },
  formula: <Formula />,
  header: {
    '1': <Header />,
    '2': <Header2 />,
    '3': <Header3 />,
  },
  italic: <Italic />,
  image: <Image />,
  indent: {
    '+1': <Indent />,
    '-1': <Outdent />,
  },
  link: <Link />,
  list: {
    bullet: <ListBullet />,
    check: <ListCheck />,
    ordered: <ListOrdered />,
  },
  script: {
    sub: <Subscript />,
    super: <Superscript />,
  },
  strike: <Strike />,
  table: <Table />,
  underline: <Underline />,
  video: <Video />,
  divider: <Divider />,
  redo: <Redo />,
  undo: <Undo />,
};
