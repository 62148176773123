import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import type Excel from 'exceljs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { useAtomValue } from 'jotai';
import Papa from 'papaparse';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { useGetUsingColumns } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/hooks/apis/use-get-using-columns';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

const MAX_FILE_SIZE_MB = 30;

export const useUploadExcelCustomerManagement = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const usingColumns = useGetUsingColumns();

  const { getValues, setValue } = useFormContext<{
    rows: CustomerManagementTemporaryAPIFormValues;
  }>();

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const abortController = useRef<AbortController | null>(null);

  const resetState = () => {
    fullDimmedLoadingService.off();
    toastService.close('uploading');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const validateTemplate = (
    headerValues: { A1: string; A2: string; A3: string; B3: string; C3?: string },
    _fileType: 'xlsx' | 'csv',
  ) => {
    const isBasicTemplateValid =
      headerValues.A1 === '고객 등록 양식' &&
      headerValues.A2 === '*양식을 임의로 수정하면 업로드에 실패할 수 있습니다.' &&
      headerValues.A3 === '고객명*';

    if (!isBasicTemplateValid) return false;

    // 차트번호 사용 여부에 따른 검증
    if (usingColumns?.usingChartNumber) {
      return headerValues.B3 === '차트번호' && headerValues.C3 === '휴대폰번호*';
    }
    // 차트번호를 사용하지 않는 경우
    return headerValues.B3 === '휴대폰번호*';
  };

  const selectedCell = useAtomValue(selectedCellState);
  const focusedCell = useAtomValue(focusedCellState);

  const getStartingRowIndex = () => {
    // 선택된 셀이나 포커스된 셀의 행 인덱스를 반환
    if (selectedCell.row !== null) return selectedCell.row;
    if (focusedCell.row !== null) return focusedCell.row;
    return 0; // 둘 다 없으면 첫 번째 행부터 시작
  };

  const normalizePhoneNumber = (phoneNumber: string | null): string | null => {
    if (!phoneNumber) return null;

    // 모든 특수문자와 공백 제거
    const cleaned = phoneNumber.replace(/[^0-9]/g, '');

    // 숫자만 있는 경우 반환
    if (cleaned.length === 0) return null;

    return cleaned;
  };

  const readXlsxFile = async (file: File): Promise<void> => {
    let workbook: typeof ExcelJS.Workbook | null = null;
    try {
      workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(file);
    } catch (error) {
      console.error('엑셀 파일 로드 에러 발생 :', error);
      toastService.errorMsg({ text: '엑셀 파일 로드에 실패했습니다.' });
      resetState();
      return;
    }

    const worksheet = workbook.getWorksheet(1);

    const headerValues: { A1: string; A2: string; A3: string; B3: string; C3?: string } = {
      A1: worksheet.getCell('A1').value as string,
      A2: worksheet.getCell('A2').value as string,
      A3: worksheet.getCell('A3').value as string,
      B3: worksheet.getCell('B3').value as string,
      C3: worksheet.getCell('C3').value as string,
    };

    if (!validateTemplate(headerValues, 'xlsx')) {
      modalService.defaultWarning({
        title: '양식 오류',
        contents: '업로드한 파일의 양식이 올바르지 않습니다. 양식을 다시 확인해 주세요.',
        buttonType: 'CONFIRM',
        buttonText: '확인',
      });
      resetState();
      return;
    }

    const jsonData: Partial<CustomerManagementTemporaryAPIFormValues[number]>[] = [];

    worksheet.eachRow((row: Excel.Row) => {
      const rowData: Partial<CustomerManagementTemporaryAPIFormValues[number]> = {};

      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        // Rich Text 객체 처리를 위한 함수
        const getCellValue = (cell: Excel.Cell) => {
          const value = cell.value;

          // Rich Text 객체 처리
          if (value && typeof value === 'object' && 'richText' in value) {
            return (value.richText as { text: string }[]).map((part) => part.text).join('');
          }

          // 일반 텍스트 처리
          if (value && typeof value === 'object' && 'text' in value) {
            return value.text;
          }

          // 빈 셀이나 null 값 처리
          if (value === null || value === undefined) {
            return null;
          }

          return value.toString().trim();
        };

        // 실제 데이터 매핑
        if (colNumber === 1) {
          rowData.name = getCellValue(cell);
        } else if (usingColumns?.usingChartNumber && colNumber === 2) {
          rowData.chartNumber = getCellValue(cell);
        } else if (
          (!usingColumns?.usingChartNumber && colNumber === 2) ||
          (usingColumns?.usingChartNumber && colNumber === 3)
        ) {
          const cellValue = getCellValue(cell);
          rowData.phoneNumber = cellValue ? normalizePhoneNumber(cellValue.toString()) : null;
        }
      });

      // 빈 행 필터링
      const hasValidData = rowData.name || rowData.phoneNumber;
      if (hasValidData) {
        // 데이터 유효성 검사
        if (rowData.name === '[object Object]') {
          console.warn('Invalid name format detected:', rowData);
          return;
        }
        jsonData.push(rowData);
      }
    });

    // 데이터 필터링 로직 강화
    const dataWithoutEmpty = jsonData?.slice(3)?.filter((item) => {
      return (
        item !== undefined &&
        item !== null &&
        Object.values(item).some((value) => {
          // [object Object] 문자열 체크 추가
          if (typeof value === 'string' && value === '[object Object]') {
            return false;
          }
          return value !== undefined && value !== null && value !== '';
        })
      );
    });

    const startingRowIndex = getStartingRowIndex();
    const existingRows = getValues('rows') || [];

    const filteredExistingRows = existingRows.filter((row) => {
      const { hospitalID, order, ...otherFields } = row;

      return Object.values(otherFields).some(
        (v) => v !== null && v !== undefined && !(Array.isArray(v) && v.length === 0),
      );
    });

    const updatedData: CustomerManagementTemporaryAPIFormValues = dataWithoutEmpty?.map(
      ({ name, phoneNumber, birthdate, chartNumber, isFirstVisit, gender }, index) => ({
        // 기존 order 값을 유지하면서 새로운 데이터 추가
        order:
          filteredExistingRows[startingRowIndex + index]?.order || startingRowIndex + index + 1,
        hospitalID,
        patientId: null,
        name,
        phoneNumber,
        birthdate,
        chartNumber,
        gender: gender as 'MALE' | 'FEMALE',
        isFirstVisit,
        nationalityId: null,
        treatmentTagIds: [],
      }),
    );

    // 전체 데이터의 길이 계산 (기존 데이터 + 새로운 데이터)
    const totalLength = Math.max(
      startingRowIndex + updatedData.length,
      filteredExistingRows.length,
    );

    // 50의 배수로 필요한 전체 행 수 계산
    const requiredTotalRows = Math.ceil(totalLength / 50) * 50;

    // 새로운 데이터의 order 값 조정
    const adjustedUpdatedData = updatedData.map((item, index) => ({
      ...item,
      order: startingRowIndex + index + 1,
    }));

    // 기존 데이터를 order 기준으로 정렬하고 빈 공간 채우기
    const sortedExistingRows = [...filteredExistingRows].sort(
      (a, b) => (a.order || 0) - (b.order || 0),
    );

    // 최종 데이터 배열 생성
    const finalData: CustomerManagementTemporaryAPIFormValues = [];

    // 1부터 requiredTotalRows까지 순회하면서 데이터 채우기
    for (let i = 1; i <= requiredTotalRows; i++) {
      // 현재 순서에 해당하는 데이터 찾기
      if (i >= startingRowIndex + 1 && i < startingRowIndex + updatedData.length + 1) {
        // 새로운 데이터 영역
        finalData.push(adjustedUpdatedData[i - startingRowIndex - 1]);
      } else {
        // 기존 데이터나 빈 행
        const existingRow = sortedExistingRows.find((row) => row.order === i);
        if (existingRow) {
          finalData.push(existingRow);
        } else {
          finalData.push({
            order: i,
            hospitalID,
            patientId: null,
            name: null,
            chartNumber: null,
            isFirstVisit: null,
            phoneNumber: null,
            birthdate: null,
            gender: null,
            treatmentTagIds: [],
            nationalityId: null,
          });
        }
      }
    }

    if (finalData.length > 0) {
      setValue('rows', finalData as CustomerManagementTemporaryAPIFormValues);
      resetState();
      return;
    }
  };

  const readCsvFile = (file: File) => {
    Papa.parse(file, {
      header: false,
      complete: (results) => {
        const rows = results.data as string[][];

        const headerValues = {
          A1: rows[0]?.[0] || '',
          A2: rows[1]?.[0] || '',
          A3: rows[2]?.[0] || '',
          B3: rows[2]?.[1] || '',
          C3: rows[2]?.[2] || '',
        };

        if (!validateTemplate(headerValues, 'csv')) {
          modalService.defaultWarning({
            title: '양식 오류',
            contents: '업로드한 파일의 양식이 올바르지 않습니다. 양식을 다시 확인해 주세요.',
            buttonType: 'CONFIRM',
            buttonText: '확인',
          });
          resetState();
          return;
        }

        const jsonData: Partial<CustomerManagementTemporaryAPIFormValues[number]>[] = [];

        for (let i = 3; i < rows.length; i++) {
          const row = rows[i];
          if (!row || row.length === 0) continue;

          const rowData: Partial<CustomerManagementTemporaryAPIFormValues[number]> = {
            name: row[0]?.trim() || null,
          };

          if (usingColumns?.usingChartNumber) {
            rowData.chartNumber = row[1]?.trim() || null;
            rowData.phoneNumber = normalizePhoneNumber(row[2]?.trim() || null);
          } else {
            rowData.phoneNumber = normalizePhoneNumber(row[1]?.trim() || null);
          }

          const hasValidData = rowData.name || rowData.phoneNumber;
          if (hasValidData) {
            jsonData.push(rowData);
          }
        }

        const dataWithoutEmpty = jsonData.filter((item) => {
          return (
            item !== undefined &&
            item !== null &&
            Object.values(item).some((value) => {
              if (typeof value === 'string' && value === '[object Object]') {
                return false;
              }
              return value !== undefined && value !== null && value !== '';
            })
          );
        });

        const startingRowIndex = getStartingRowIndex();
        const existingRows = getValues('rows') || [];

        const filteredExistingRows = existingRows.filter((row) => {
          const { hospitalID, order, ...otherFields } = row;
          return Object.values(otherFields).some(
            (v) => v !== null && v !== undefined && !(Array.isArray(v) && v.length === 0),
          );
        });

        const updatedData: CustomerManagementTemporaryAPIFormValues = dataWithoutEmpty?.map(
          ({ name, phoneNumber, birthdate, chartNumber, isFirstVisit, gender }, index) => ({
            order:
              filteredExistingRows[startingRowIndex + index]?.order || startingRowIndex + index + 1,
            hospitalID,
            patientId: null,
            name,
            phoneNumber,
            birthdate,
            chartNumber,
            gender: gender as 'MALE' | 'FEMALE',
            isFirstVisit,
            nationalityId: null,
            treatmentTagIds: [],
          }),
        );

        const totalLength = Math.max(
          startingRowIndex + updatedData.length,
          filteredExistingRows.length,
        );

        const requiredTotalRows = Math.ceil(totalLength / 50) * 50;

        const adjustedUpdatedData = updatedData.map((item, index) => ({
          ...item,
          order: startingRowIndex + index + 1,
        }));

        const sortedExistingRows = [...filteredExistingRows].sort(
          (a, b) => (a.order || 0) - (b.order || 0),
        );

        const finalData: CustomerManagementTemporaryAPIFormValues = [];

        for (let i = 1; i <= requiredTotalRows; i++) {
          if (i >= startingRowIndex + 1 && i < startingRowIndex + updatedData.length + 1) {
            finalData.push(adjustedUpdatedData[i - startingRowIndex - 1]);
          } else {
            const existingRow = sortedExistingRows.find((row) => row.order === i);
            if (existingRow) {
              finalData.push(existingRow);
            } else {
              finalData.push({
                order: i,
                hospitalID,
                patientId: null,
                name: null,
                chartNumber: null,
                isFirstVisit: null,
                phoneNumber: null,
                birthdate: null,
                gender: null,
                treatmentTagIds: [],
                nationalityId: null,
              });
            }
          }
        }

        if (finalData.length > 0) {
          setValue('rows', finalData as CustomerManagementTemporaryAPIFormValues);
          resetState();
          return;
        }
      },
      error: () => {
        modalService.defaultWarning({
          title: '파일 읽기 실패',
          contents: 'CSV 파일을 읽는데 실패했습니다.',
          buttonType: 'CONFIRM',
          buttonText: '확인',
        });
        resetState();
      },
    });
  };

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (abortController.current) {
      abortController.current = null;
    }

    abortController.current = new AbortController();

    toastService.loadingMsg({
      id: 'uploading',
      text: '파일 업로드 중입니다. 잠시만 기다려주세요.',
      rightButton: {
        text: '취소',
        onClick: () => {
          if (abortController.current) {
            abortController.current.abort();
          }
          resetState();
        },
      },
    });
    fullDimmedLoadingService.on();

    const file = event.target.files?.[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);

      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        modalService.defaultWarning({
          title: '파일 용량 초과',
          contents: '30MB 이하의 파일만 등록할 수 있습니다.',
          buttonType: 'CONFIRM',
          buttonText: '확인',
        });
        resetState();
        return;
      }

      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension === 'xlsx') {
        readXlsxFile(file);
      } else if (fileExtension === 'csv') {
        readCsvFile(file);
      } else {
        toastService.errorMsg({ text: '지원하지 않는 파일 형식입니다.' });
        resetState();
      }
    }
  };

  return {
    onUpload,
    fileInputRef,
  };
};
