import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAnimationToggle } from 'afterdoc-design-system/hooks/use-animation-toggle';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { type CSSProperties, type PropsWithChildren, useCallback, useRef, useState } from 'react';
import Tooltip from './Tooltip';
import type { HoverTooltipProps, TooltipPosition } from './Tooltip.type';

const OFFSET = 5;

const calcTooltipPos = (
  target: HTMLElement,
  tooltip: HTMLElement,
  pos: TooltipPosition,
  offset: number,
  offsetX: number,
  offsetY: number,
) => {
  const { top, left, width, height } = target.getBoundingClientRect();
  const tooltipHeight = tooltip.clientHeight;
  const tooltipWidth = tooltip.clientWidth;

  switch (pos) {
    case 'top':
      return {
        top: top - tooltipHeight - offset + offsetY,
        left: left + width / 2 - tooltipWidth / 2 + offsetX,
      };
    case 'topRight':
      return { top: top - tooltipHeight - offset + offsetY, left: left };
    case 'right':
      return { top: top + height / 2 - tooltipHeight / 2 + offsetY, left: left + offset + offsetX };
    case 'bottomRight':
      return { top: top + height + offset + offsetY, left: left + offsetX };
    case 'bottom':
      return {
        top: top + height + offset + offsetY,
        left: left / 2 + tooltipWidth / 2 + width / 2 + offsetX,
      };
    case 'bottomLeft':
      return { top: top + height + offsetY, left: left - tooltipWidth + offset + offsetX };
    case 'left':
      return {
        top: top + height / 2 - tooltipHeight / 2,
        left: left - tooltipWidth - offset + offsetX,
      };
    case 'topLeft':
      return {
        top: top - tooltipHeight - offset + offsetY,
        left: left + width - tooltipWidth,
      };
    default:
      return { top: 0, left: 0 };
  }
};

export default function HoverTooltip({
  children,
  position = 'top',
  show = true,
  portalZindex,
  offset = OFFSET,
  offsetX = 0,
  offsetY = 0,
  wrapperProps = {},
  ...props
}: PropsWithChildren<HoverTooltipProps>) {
  const ref = useRef<HTMLElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const { className, style: wrapperStyle, ...restWrapperProps } = wrapperProps;
  const { style, ...restTooltipProps } = props;
  const defaultZIndex = style?.zIndex || 10;

  const tooltipPos = useRef<CSSProperties>({});

  const [zIndex, setZIndex] = useState<CSSProperties['zIndex']>(defaultZIndex);

  const setTooltipPosition = useCallback(() => {
    if (ref.current && tooltipRef.current) {
      const effectiveOffsetX = offsetX;
      const effectiveOffsetY = offsetY;
      const { top, left } = calcTooltipPos(
        ref.current,
        tooltipRef.current,
        position,
        offset,
        effectiveOffsetX,
        effectiveOffsetY,
      );

      tooltipPos.current = { top, left };
    }
  }, [position, offset, offsetX, offsetY]);

  const {
    mounted,
    trigger: opacity,
    onShow,
    onHide,
  } = useAnimationToggle({ transitionDuration: 150, onShow: setTooltipPosition, openDelay: 10 });

  const onPointerEnter = () => {
    onShow();
    setZIndex(defaultZIndex);
  };

  const onPointerLeave = () => onHide();

  return (
    <span
      {...restWrapperProps}
      ref={ref}
      style={wrapperStyle}
      onMouseEnter={onPointerEnter}
      onMouseLeave={onPointerLeave}
      className={customTwMerge('inline-flex', className)}>
      {children}
      {mounted && show && props.text && (
        <Portal
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: portalZindex ?? 10,
          }}>
          <Tooltip
            {...restTooltipProps}
            ref={tooltipRef}
            style={{
              ...style,
              ...tooltipPos.current,
              position: 'absolute',
              transition: 'opacity 150ms ease',
              verticalAlign: 'middle',
              height: 'fit-content',
              opacity,
              zIndex,
            }}
          />
        </Portal>
      )}
    </span>
  );
}
