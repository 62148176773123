import { neverCheck } from 'web/shared/utils/never-check';
import { ROOM_TYPE_MAP } from '../constants/ROOM_TYPE_MAP';
import type { RoomType } from '../states/selected-chat-room';

export type ChannelClosedStateSwitchParams = {
  roomType: RoomType | null;
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  ikakaoInfo: Record<string, any> | undefined;
};

export const channelClosedStateSwitch = ({
  roomType,
  ikakaoInfo,
}: ChannelClosedStateSwitchParams) => {
  switch (roomType) {
    case ROOM_TYPE_MAP.kakao: {
      return ikakaoInfo?.isClosed;
    }
    case ROOM_TYPE_MAP.manager:
    case ROOM_TYPE_MAP.afterdoc:
    case null: //해당 함수가 isDisabledChat에 사용되었고 CautionImagesSelect 컴포넌트에서 사용됨
      return false;
    default:
      roomType satisfies never;
      neverCheck(roomType);
  }
};
