import { useCheckIsOverflow } from '@shared-hooks/use-check-is-overflow';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useQuery } from '@tanstack/react-query';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import CursorTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/CursorTooltip';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useRef } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElDetailData,
  ApiPatientsElListOrSearchData,
  ApiPatientsElListOrSearchParams,
} from 'web/apis/swaggers/swagger-docs';
import ALIntegrationEdit from 'web/shared/components/ALIntegrationEdit/ALIntegrationEdit';
import { useHandleCustomerInfo } from 'web/shared/hooks/overlayPage/use-handle-customer-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { korFilterNameKeyWithEngValue } from 'web/templates/CustomerManagement/constants/convert';
import { READ_ONLY_TABLE_HEADERS } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/components/CustomerManagementReadOnlyTableHeader/constants/table';
import { readOnlyHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/states/read-only-header-filter';
import {
  searchTextState,
  selectedPatientOptionState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { selectedCardInfoState } from 'web/templates/CustomerManagement/containers/ControlPanel/containers/OngoingConsultingAutomation/state/selected-card-info';

const TABLE_STYLE = {
  borderStyle: 'border-line-table border-b border-r',
  paddingStyle: 'px-12 py-10',
};

const fetchPatientsListOrSearch = async (params: ApiPatientsElListOrSearchParams) => {
  const response = await apiClient.v3.apiPatientsElListOrSearch(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface CustomerManagementReadOnlyTableBodyProps {
  handleTotalCount: (count: number) => void;
  handleNoDataOrNoSearchResult: (isNoData: boolean) => void;
  currentPage: number;
}

export default function CustomerManagementReadOnlyTableBody({
  handleTotalCount,
  handleNoDataOrNoSearchResult,
  currentPage,
}: CustomerManagementReadOnlyTableBodyProps) {
  const { hospitalID, integrationSetting } = useSelectedHospitalInfo();

  const readOnlyHeaderFilter = useAtomValue(readOnlyHeaderFilterState);
  const selectedCardInfo = useAtomValue(selectedCardInfoState);
  const searchText = useAtomValue(searchTextState);
  const selectedPatientOption = useAtomValue(selectedPatientOptionState);
  const selectedSearchCriteria = useAtomValue(selectedSearchCriteriaState);

  const { gender, isFirstVisit, nationality, treatmentCategories } = readOnlyHeaderFilter;

  // 전능연동된 병원
  const isSmartDoctorLinkedHospital =
    integrationSetting &&
    integrationSetting.isRemoved === false &&
    !!integrationSetting.clientToken &&
    integrationSetting.clientToken.length > 0;

  const params: ApiPatientsElListOrSearchParams = useMemo(() => {
    return {
      hospitalID,
      page: currentPage,
      limit: 20,
      chartNumber: selectedSearchCriteria === 'chartNumber' ? searchText : undefined,
      patientName: selectedSearchCriteria === 'name' ? searchText : undefined,
      phone: selectedSearchCriteria === 'phoneNumber' ? searchText : undefined,
      birth: selectedSearchCriteria === 'birthdate' ? searchText : undefined,
      target: korFilterNameKeyWithEngValue[selectedPatientOption],
      'request-purpose': selectedCardInfo ? 'ONGOING-AUTOMATION' : 'COMMON',
      appliedConsultationAlgorithmId: selectedCardInfo?.algorithmId,

      // 필터 관련
      nationality,
      treatmentCategories,
      isFirstVisit: isFirstVisit === 'all' ? undefined : isFirstVisit,
      gender: gender === 'all' ? undefined : gender,
    };
  }, [
    hospitalID,
    currentPage,
    selectedSearchCriteria,
    searchText,
    selectedPatientOption,
    selectedCardInfo,
    nationality,
    treatmentCategories,
    isFirstVisit,
    gender,
  ]);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiPatientsElListOrSearch, params] as const,
    queryFn: ({ queryKey }) => fetchPatientsListOrSearch(queryKey[1]),
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (data) {
      handleTotalCount(data.totalCount);
    }
  }, [data, handleTotalCount]);

  useEffect(() => {
    if (!data || data.patients?.length === 0) {
      handleNoDataOrNoSearchResult(true);
    } else {
      handleNoDataOrNoSearchResult(false);
    }
  }, [data, handleNoDataOrNoSearchResult]);

  if (isLoading) {
    return (
      <tr>
        <td colSpan={READ_ONLY_TABLE_HEADERS.length}>
          <div className='flex-full-center py-70'>
            <BaseLoading />
          </div>
        </td>
      </tr>
    );
  }

  if (!data || data.patients?.length === 0) {
    if (searchText && searchText.length > 0) {
      return (
        <tr>
          <td colSpan={READ_ONLY_TABLE_HEADERS.length}>
            <div className='flex-full-center'>
              <NoData
                iconProps={{
                  name: 'warning',
                  size: 48,
                  color: 'white600',
                }}
                title='검색 결과가 없습니다.'
                subTitle='단어의 철자가 정확한지 확인해 주세요.'
              />
            </div>
          </td>
        </tr>
      );
    }
    return (
      <tr>
        <td colSpan={READ_ONLY_TABLE_HEADERS.length}>
          <div className='flex w-full items-center justify-center py-70'>
            <NoData
              iconProps={{
                name: 'warning',
                size: 48,
                color: 'white600',
              }}
              title='등록된 고객이 없습니다.'
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      {data.patients?.map((patientData) => (
        <PatientRow
          isSmartDoctorLinkedHospital={isSmartDoctorLinkedHospital}
          key={patientData.patientId}
          data={patientData}
        />
      ))}
    </>
  );
}

const PatientRow = ({
  data,
  isSmartDoctorLinkedHospital,
}: {
  data: Exclude<ApiPatientsElListOrSearchData['data']['patients'], undefined>[0];
  isSmartDoctorLinkedHospital?: boolean;
}) => {
  const { openCustomerInfo } = useHandleCustomerInfo(data.patientId);

  const {
    name,
    isJoinedUserToAfterDoc,
    hasKakaoLinkage,
    hasSmartDoctorLinkage,
    // countryCode,
    nationality,
    phoneNumber,
    birthdate,
    gender,
    treatmentTags,
    automationAppliedTreatmentTag,
    isFirstVisit,
    chartNumber,
    isClosed,
    patientId,
  } = data;

  const displayedTreatmentTags = automationAppliedTreatmentTag
    ? [automationAppliedTreatmentTag.name, ...(treatmentTags?.map((tag) => tag.name) || [])]
        .filter(Boolean)
        .join(', ')
    : treatmentTags?.map((tag) => tag.name).join(', ');

  const handleShowDetail = () => {
    openCustomerInfo();
  };

  const rowData = {
    name,
    chartNumber: chartNumber && chartNumber.length > 0 ? chartNumber : '-',
    visitType:
      isFirstVisit !== undefined && isFirstVisit !== null ? (isFirstVisit ? '초진' : '재진') : '-',
    phoneNumber: phoneNumber && phoneNumber.length > 0 ? phoneNumber : '-',
    birthdate: birthdate ?? '-',
    gender: gender !== undefined && gender !== null ? (gender === 'MALE' ? '남성' : '여성') : '-',
    treatmentTags:
      displayedTreatmentTags && displayedTreatmentTags.length > 0 ? displayedTreatmentTags : '-',
    nationality:
      nationality !== undefined && nationality !== null ? nationality.koreanCountryName : '-',
  };

  return (
    <tr
      key={patientId}
      className={customTwMerge('cursor-pointer', isClosed ? 'bg-disabled' : 'hover:bg-blue50')}
      onClick={handleShowDetail}>
      <PatientInfoCell
        patientId={patientId}
        name={rowData.name}
        isJoinedUserToAfterDoc={isJoinedUserToAfterDoc}
        hasKakaoLinkage={hasKakaoLinkage}
        isSmartDoctorLinkedHospital={isSmartDoctorLinkedHospital}
        hasSmartDoctorLinkage={hasSmartDoctorLinkage}
      />
      {Object.entries(rowData)
        .filter(([key]) => key !== 'name')
        .map(([key, content]) => (
          <RowData key={key} content={content} />
        ))}
    </tr>
  );
};

const PatientInfoCell = ({
  patientId,
  name,
  isJoinedUserToAfterDoc,
  hasKakaoLinkage,
  isSmartDoctorLinkedHospital,
  hasSmartDoctorLinkage,
}: Pick<
  ApiPatientsElDetailData['data'],
  'patientId' | 'name' | 'isJoinedUserToAfterDoc' | 'hasKakaoLinkage' | 'hasSmartDoctorLinkage'
> & {
  isSmartDoctorLinkedHospital?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const { isOverflow } = useCheckIsOverflow(ref);
  const onOpenALIntegrationEdit = () => {
    dialogService.push(<ALIntegrationEdit patientId={patientId} dialogId={'ALIntegrationEdit'} />, {
      width: 'auto',
      id: 'ALIntegrationEdit',
    });
  };

  return (
    <td className={customTwMerge(TABLE_STYLE.paddingStyle, TABLE_STYLE.borderStyle)}>
      <div className='flex items-center justify-between'>
        <CursorTooltip
          text={name}
          show={isOverflow}
          wrapperProps={{
            className: 'truncate',
          }}>
          <div ref={ref} className='truncate'>
            {name}
          </div>
        </CursorTooltip>
        <div className='flex flex-shrink items-center gap-8'>
          <HoverTooltip
            text={`애프터닥 ${isJoinedUserToAfterDoc ? '가입' : '미가입'} 고객입니다.`}
            position='bottomRight'
            wrapperProps={{
              className: 'cursor-default',
            }}>
            {isJoinedUserToAfterDoc ? <Icon name='afterdoc' /> : <Icon name='afterdoc-non' />}
          </HoverTooltip>
          {hasKakaoLinkage && (
            <HoverTooltip
              text='카카오톡으로 유입된 고객입니다.'
              position='bottomRight'
              wrapperProps={{
                className: 'cursor-default',
              }}>
              <Icon name='kakaotalk' />
            </HoverTooltip>
          )}
          {isSmartDoctorLinkedHospital && (
            <HoverTooltip
              text={`스마트닥터에 계정이 ${hasSmartDoctorLinkage ? '연동된' : '연동되지 않은'} 고객입니다.`}
              offsetY={-4}
              position='bottomRight'>
              <button
                type='button'
                className='flex items-center gap-4 rounded-r6 border border-white600 bg-white50 px-5 py-3'
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenALIntegrationEdit();
                }}>
                <Icon
                  name={hasSmartDoctorLinkage ? 'smart-doctor' : 'smart-doctor-non'}
                  size={17}
                />
                <Icon name='chevron-right' size={16} color='black200' />
              </button>
            </HoverTooltip>
          )}
        </div>
      </div>
    </td>
  );
};

const RowData = ({ content }: { content?: string | JSX.Element }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { isOverflow } = useCheckIsOverflow(ref);

  return (
    <td className={customTwMerge(TABLE_STYLE.paddingStyle, TABLE_STYLE.borderStyle)}>
      <div className='flex'>
        <CursorTooltip
          text={content}
          show={isOverflow}
          wrapperProps={{
            className: 'truncate',
          }}>
          <div className='truncate' ref={ref}>
            {content}
          </div>
        </CursorTooltip>
      </div>
    </td>
  );
};
