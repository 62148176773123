interface UseDropdownKeyboardEventProps {
  listEl: HTMLDivElement;
  keycode: string | null;
  isComposing?: boolean;
  onEnter: (selectedValue: string) => void;
  onEsc: () => void;
  focusedIndex?: number;
  setFocusedIndex: (index?: number) => void;
}

export const useDropdownKeyboardEvent = ({
  keycode,
  listEl,
  isComposing,
  onEnter,
  onEsc,
  focusedIndex,
  setFocusedIndex,
}: UseDropdownKeyboardEventProps) => {
  const liNodes = Array.from(
    listEl.querySelectorAll(`div[aria-labelledby="dropdown-option"]`),
  ) as HTMLElement[];

  if (isComposing || !listEl) return;

  let newFocusIndex = focusedIndex;

  switch (keycode) {
    case 'ArrowDown': {
      if (liNodes.length === 0) return;

      newFocusIndex =
        focusedIndex === undefined ? 0 : Math.min(focusedIndex + 1, liNodes.length - 1);

      if (newFocusIndex !== undefined && newFocusIndex !== focusedIndex) {
        setFocusedIndex(newFocusIndex);
        liNodes[newFocusIndex]?.focus();
      }
      break;
    }

    case 'ArrowUp': {
      if (liNodes.length === 0) return;

      newFocusIndex =
        focusedIndex === undefined ? liNodes.length - 1 : Math.max(focusedIndex - 1, 0);

      if (newFocusIndex !== undefined && newFocusIndex !== focusedIndex) {
        setFocusedIndex(newFocusIndex);
        liNodes[newFocusIndex]?.focus();
      }
      break;
    }

    case 'Enter': {
      const targetElement = focusedIndex === undefined ? liNodes[0] : liNodes[focusedIndex];

      if (targetElement?.id) {
        onEnter(targetElement.id);
      }
      break;
    }

    case 'Escape': {
      onEsc();
      break;
    }

    default:
      return;
  }
};
