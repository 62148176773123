import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

import type { ManualHandleClickParams } from '../../ManualList';
import TagDetailPanel from './components/TagDetailPanel/TagDetailPanel';

interface ContentPanelFolderItemProps {
  id: string;
  parentIndex: number;
  childIndex: number;
  childID: string;
  color: string;
  text: string;
  treatmentTagsIds: string[];
  focusedManualTagID: string | null;
  handleClick: ({ id, parentIndex, childIndex }: ManualHandleClickParams) => void;
  isItemOpen: boolean;
}

export default function ManualPanelFolderItem({
  id,
  parentIndex,
  childIndex,
  childID,
  color,
  text,
  treatmentTagsIds,
  focusedManualTagID,
  handleClick,
  isItemOpen,
}: ContentPanelFolderItemProps) {
  const isInTreatmentTagsIds = treatmentTagsIds.includes(childID);

  const isInFocusedTag = focusedManualTagID === childID;

  return (
    <>
      <li>
        <div
          onClick={() => {
            handleClick({ id: childID, parentIndex, childIndex });
          }}
          className={`flex w-full flex-shrink-0 cursor-pointer items-center justify-start gap-4 py-11 pr-20 pl-56 font-regular text-Body12 text-black500 ${
            isInTreatmentTagsIds ? 'bg-purple100' : isInFocusedTag ? 'bg-blue50' : 'bg-white50'
          }`}>
          <div className='flex-shrink-0'>
            <Icon name={isItemOpen ? 'chevron-down' : 'chevron-right'} size={16} color='black200' />
          </div>
          <div className='flex-shrink-0'>
            <Icon name='tag' size={20} customColor={color} />
          </div>

          <span className='truncate'>{text}</span>
        </div>
      </li>
      {isItemOpen && <TagDetailPanel treatmentTagId={childID} />}
    </>
  );
}
