import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { forwardRef, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import FilePicker, { type FilePickerHandle } from 'web/shared/components/FilePicker/FilePicker';
import ImageViewer, { type ImageObject } from 'web/shared/components/ImageViewer/ImageViewer';
import {
  type ImageType,
  isLocalImage,
} from 'web/shared/hooks/files/images/use-upload-multiple-images';
import { extractImageObjectFromS3Url } from 'web/shared/utils/fileUpload/functions/extract-file-name';

interface ManagementDirectionImageGalleryProps {
  disabled?: boolean;
  images: ImageType[];
  onImagesChange: (images: ImageType[]) => void;
  handleFileChange: (files: File[]) => void;
  handleRemoveImage: (index: number) => void;
  handleAttachMultipleImages: () => void;
}

const VISIBLE_COUNT = 10;

const getImageObject = (image: ImageType): ImageObject => {
  if (isLocalImage(image)) {
    return {
      url: image.blobUrl,
      fileName: image.name,
    };
  }
  return extractImageObjectFromS3Url(image.url);
};

const ManagementDirectionImageGallery = forwardRef<
  FilePickerHandle,
  ManagementDirectionImageGalleryProps
>(
  (
    {
      disabled,
      images,
      onImagesChange,
      handleFileChange,
      handleRemoveImage,
      handleAttachMultipleImages,
    },
    ref,
  ) => {
    const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
    const [startIndex, setStartIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState<number>();

    const handlePrev = () => {
      setStartIndex((prev) => Math.max(prev - VISIBLE_COUNT, 0));
    };

    const handleNext = () => {
      setStartIndex((prev) =>
        Math.min(
          prev + VISIBLE_COUNT,
          images.length - (images.length % VISIBLE_COUNT || VISIBLE_COUNT),
        ),
      );
    };

    const handleAddImage = () => {
      if (disabled) return;
      handleAttachMultipleImages();
    };

    const onRemoveImage = (index: number) => {
      handleRemoveImage(index);
      onImagesChange(images.filter((_, i) => i !== index));
    };

    const handleCapture = () => {
      window.electron?.ipcRenderer.send('Application.showCaptureWindow');
    };

    useEffect(() => {
      window.electron?.ipcRenderer.on('Application.captureWindowBounds', async (_, payload) => {
        if (payload.data) {
          const base64Data = payload.data.split(',')[1];
          const byteCharacters = atob(base64Data);
          const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'image/png' });

          const blobUrl = URL.createObjectURL(blob);

          const localImage: ImageType = {
            id: uuidv4(),
            blobUrl,
            name: `captured-image-${uuidv4()}.png`,
            size: blob.size,
            type: blob.type,
            lastModified: Date.now(),
          };

          onImagesChange([...images, localImage]);
        }
      });

      return () => {
        window.electron?.ipcRenderer.removeAllListeners('Application.captureWindowBounds');
      };
    }, [images, onImagesChange]);

    useEffect(() => {
      if (images.length % VISIBLE_COUNT === 0) {
        setStartIndex(Math.max(images.length - VISIBLE_COUNT, 0));
      }
    }, [images]);

    return (
      <>
        <div className='absolute right-0 bottom-[16px] left-0 flex flex-col gap-12 px-20'>
          <div className='flex w-full items-center justify-between gap-12'>
            {!disabled && images.length > 0 && (
              <IconButton
                icon='chevron-left'
                size={24}
                color={'black500'}
                className={customTwMerge(
                  'h-24 w-24 flex-center rounded-r6 border border-white400 bg-white50 ',
                  startIndex === 0 && 'cursor-not-allowed opacity-50',
                )}
                onClick={handlePrev}
                disabled={startIndex === 0 || images.length <= VISIBLE_COUNT}
              />
            )}
            <div className='flex w-full items-center justify-start gap-12'>
              {images.slice(startIndex, startIndex + VISIBLE_COUNT).map((image, index) => (
                <div
                  key={uuidv4()}
                  className='relative flex cursor-pointer flex-row items-center gap-6'
                  onClick={() => {
                    setSelectedIndex(index + startIndex);
                    setIsImageViewerOpen(true);
                  }}>
                  <img
                    src={isLocalImage(image) ? image.blobUrl : image.url}
                    alt=''
                    className='h-40 w-40 rounded-r10 border border-white600'
                  />
                  <IconButton
                    icon='close'
                    color='white50'
                    size={16}
                    className='-right-[6px] -top-[6px] absolute h-16 w-16 flex-center rounded-full bg-[rgba(34,34,34,0.80)]'
                    onClick={(e) => {
                      e.stopPropagation();
                      onRemoveImage(index + startIndex);
                    }}
                  />
                </div>
              ))}
            </div>
            {!disabled && images.length > 0 && (
              <IconButton
                icon='chevron-right'
                size={24}
                color='black500'
                className={customTwMerge(
                  'h-24 w-24 flex-center rounded-r6 border border-white400 bg-white50',
                  startIndex >= images.length - VISIBLE_COUNT && 'cursor-not-allowed opacity-50',
                )}
                onClick={handleNext}
                disabled={
                  startIndex >= images.length - VISIBLE_COUNT || images.length <= VISIBLE_COUNT
                }
              />
            )}
          </div>
          <div className='flex gap-10'>
            <FilePicker ref={ref} onFileChange={handleFileChange} />
            <IconButton
              icon='image-plus'
              disabled={disabled}
              onClick={handleAddImage}
              color='black400'
              size={20}
            />
            <IconButton
              disabled={disabled}
              onClick={handleCapture}
              icon='selection-drag'
              color='black400'
              size={20}
            />
          </div>
        </div>
        {isImageViewerOpen && (
          <ImageViewer
            selectedIndex={selectedIndex}
            imageObjects={images.map(getImageObject)}
            imageUrls={images.map((image) => (isLocalImage(image) ? image.blobUrl : image.url))}
            onClose={(e) => {
              setIsImageViewerOpen(false);
              e?.stopPropagation();
              e?.preventDefault();
            }}
          />
        )}
      </>
    );
  },
);

export default ManagementDirectionImageGallery;
