import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElFindOneParams,
  ServiceSetting,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

interface TempServiceSetting extends ServiceSetting {
  usingChatTranslate: boolean;
}

const fetchServiceSettings = async (params: ApiServiceSettingsElFindOneParams) => {
  const response = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useServiceSettings = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: serviceSettings } = useQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElFindOne,
      {
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchServiceSettings(queryKey[1]),
  });

  return serviceSettings as TempServiceSetting;
};
