import type Quill from '../../../lib/quill-registry';

export const handleNextOrderedList = (quill: Quill) => {
  const range = quill.getSelection();
  if (!range) return;

  const [currentLine] = quill.getLine(range.index);
  if (!currentLine) return;

  const currentText = currentLine.domNode?.innerText.trim() || '';

  //현 텍스트에서 숫자 + . 으로 끝나는 경우
  const match = currentText.match(/^(\d+)\.$/);
  if (match) {
    const number = Number.parseInt(match[1], 10);

    //quill에서 강제 변환을 시킴으로 한타임 기다림 필요
    setTimeout(() => {
      const selection = quill.getSelection();
      if (!selection) return null; // 커서가 없으면 null 반환

      const [line] = quill.getLine(selection.index); // 라인과 오프셋 가져오기
      if (!line) return null; // 라인이 없으면 null 반환
      //새로운 scope에서 현 라인을 최신화해서 가져옴
      const currentLine = line.domNode;
      if (currentLine) {
        const listParent = currentLine.parentElement;

        if (listParent?.tagName === 'OL') {
          const previousTag = listParent.previousElementSibling;
          const isPreviousLineOrderedListItem =
            previousTag?.tagName === 'OL' && previousTag.lastElementChild?.tagName === 'LI';
          const hasCounterReset = listParent.style.counterReset;

          //이전 라인이 순서가 있는 리스트 아이템이 아니고, counterReset이 없는 경우, counterReset의 시작 숫자를 설정
          if (!isPreviousLineOrderedListItem && !hasCounterReset) {
            listParent.style.counterReset = `list-0 ${number - 1} `;
            listParent.setAttribute('data-counter-title', String(number));
          }
        }
      }
    }, 0);
  }
};
