import { useDebouncedWindowSize } from '@shared-hooks/use-debounced-window-size';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import {
  type CSSProperties,
  type ElementType,
  type HTMLAttributes,
  type MouseEventHandler,
  type ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import './Scrollbar.scss';

export interface ScrollbarProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onScroll'> {
  children: ReactNode;
  disabledX?: boolean;
  disabledY?: boolean;
  style?: CSSProperties;
  offsetBottom?: number;
  as?: ElementType;
  isAlwaysVisible?: boolean;
  onScroll?: (scrollTop: number) => void;
  onScrollToEnd?: () => void;
}

const Scrollbar = forwardRef<
  {
    scrollTo: (index: number, behavior?: ScrollBehavior, height?: number) => void;
  },
  ScrollbarProps
>(
  (
    {
      children,
      className,
      disabledX,
      disabledY,
      offsetBottom = 0,
      as: Children = 'div',
      style,
      isAlwaysVisible = false,
      onScroll,
      onScrollToEnd,
      ...props
    },
    ref,
  ) => {
    const yScrollRef = useRef<HTMLDivElement>(null);
    const xScrollRef = useRef<HTMLDivElement>(null);
    const yScrollbarRef = useRef<HTMLDivElement>(null);
    const xScrollbarRef = useRef<HTMLDivElement>(null);
    const viewPortRef = useRef<HTMLDivElement>(null);

    const scrollMinSize = 30;

    const [overflowState, setOverflowState] = useState({ isOverflowY: false, isOverflowX: false });
    const [scrollPosition, setPosition] = useState<{
      yPosition: number;
      xPosition: number;
    }>({
      yPosition: 0,
      xPosition: 0,
    });

    const scrollSizeRef = useRef<{ ySize: number; xSize: number }>({
      ySize: 0,
      xSize: 0,
    });
    const [scrollSizeTrigger, setScrollSizeTrigger] = useState(0);

    const [isDragging, setIsDragging] = useState<boolean>(false);
    const yScrollHideTimeout = useRef<ReturnType<typeof setTimeout>>();
    const xScrollHideTimeout = useRef<ReturnType<typeof setTimeout>>();

    const { width, height } = useDebouncedWindowSize();

    const clearYScrollTimeOut = () => {
      if (yScrollHideTimeout.current) {
        clearTimeout(yScrollHideTimeout.current);
      }
    };

    const clearXScrollTimeOut = () => {
      if (xScrollHideTimeout.current) {
        clearTimeout(xScrollHideTimeout.current);
      }
    };

    const toggleScrollbar = {
      yVisible: () => {
        if (yScrollbarRef.current && !isAlwaysVisible) {
          yScrollbarRef.current.style.opacity = '1';
          clearYScrollTimeOut();

          yScrollHideTimeout.current = setTimeout(() => {
            toggleScrollbar.yHidden();
          }, 800);
        }
      },
      yHidden: () => {
        if (yScrollbarRef.current && !isDragging && !isAlwaysVisible) {
          yScrollbarRef.current.style.opacity = '0';
        }
      },
      xVisible: () => {
        if (xScrollbarRef.current && !isAlwaysVisible) {
          xScrollbarRef.current.style.opacity = '1';
          clearXScrollTimeOut();

          xScrollHideTimeout.current = setTimeout(() => {
            toggleScrollbar.xHidden();
          }, 800);
        }
      },
      xHidden: () => {
        if (xScrollbarRef.current && !isDragging && !isAlwaysVisible) {
          xScrollbarRef.current.style.opacity = '0';
        }
      },

      allVisible: () => {
        toggleScrollbar.xVisible();
        toggleScrollbar.yVisible();
      },
      allHide: () => {
        toggleScrollbar.yHidden();
        toggleScrollbar.xHidden();
      },
    };

    const calculateScrollHeight = () => {
      if (viewPortRef.current?.firstElementChild) {
        const scrollHeight = viewPortRef.current.scrollHeight;
        const clientHeight = viewPortRef.current.clientHeight;

        const ratio = clientHeight / scrollHeight;
        return Math.max(clientHeight * ratio, scrollMinSize);
      }
      return 0;
    };

    const calculateScrollWidth = () => {
      if (viewPortRef.current?.firstElementChild) {
        const computedStyle = getComputedStyle(viewPortRef.current.firstElementChild as Element);
        const paddingLeft = Number.parseInt(computedStyle.paddingLeft, 10);
        const paddingRight = Number.parseInt(computedStyle.paddingRight, 10);
        const totalPadding = paddingLeft + paddingRight;

        const clientWidth = viewPortRef.current.clientWidth - totalPadding;
        const scrollWidth = viewPortRef.current.scrollWidth - totalPadding;
        const scrollRatio = clientWidth / scrollWidth;
        const newScrollWidth = clientWidth * scrollRatio;
        return Math.max(newScrollWidth, scrollMinSize);
      }
      return 0;
    };

    const updateScrollSizes = () => {
      const newYSize = calculateScrollHeight();
      const newXSize = calculateScrollWidth();

      scrollSizeRef.current = { ySize: newYSize, xSize: newXSize };
      updateOverflowState();

      setScrollSizeTrigger((prev) => prev + 1);
    };

    useEffect(() => {
      if (viewPortRef.current) {
        const observer = new MutationObserver(updateScrollSizes);
        observer.observe(viewPortRef.current, {
          childList: true,
          subtree: true,
          characterData: true,
        });

        updateScrollSizes();

        return () => {
          observer.disconnect();
        };
      }
    }, []);

    useEffect(() => {
      const calculateYScrollPosition = () => {
        if (viewPortRef.current) {
          const scrollPercent = Math.round(
            (viewPortRef.current.scrollTop /
              (viewPortRef.current?.scrollHeight - viewPortRef.current?.clientHeight)) *
              100,
          );

          const currentScrollHeight =
            viewPortRef.current?.clientHeight - scrollSizeRef.current.ySize;
          const position = Math.round((currentScrollHeight * scrollPercent) / 100);
          return Math.max(0, position - offsetBottom);
        }
        return 0;
      };

      const calculateXScrollPosition = () => {
        if (viewPortRef.current) {
          const scrollPercent = Math.round(
            (viewPortRef.current.scrollLeft /
              (viewPortRef.current?.scrollWidth - viewPortRef.current?.clientWidth)) *
              100,
          );

          const currentScrollWidth = viewPortRef.current?.clientWidth - scrollSizeRef.current.xSize;
          return Math.round((currentScrollWidth * scrollPercent) / 100);
        }
        return 0;
      };

      const handleScroll = () => {
        if (!isDragging) {
          toggleScrollbar.yVisible();
          toggleScrollbar.xVisible();
        }
        updateOverflowState();

        if (viewPortRef.current) {
          const scrollTop = viewPortRef.current.scrollTop;
          const scrollHeight = viewPortRef.current.scrollHeight;
          const clientHeight = viewPortRef.current.clientHeight;

          setPosition({
            yPosition: calculateYScrollPosition(),
            xPosition: calculateXScrollPosition(),
          });
          onScroll?.(scrollTop);

          if (scrollTop + clientHeight >= scrollHeight) {
            onScrollToEnd?.();
          }
        }
      };

      const handleMouseEnter = () => {
        toggleScrollbar.allVisible();
      };

      const viewPort = viewPortRef.current;

      if (viewPort) {
        viewPort.addEventListener('scroll', handleScroll);
        viewPort.addEventListener('mouseenter', handleMouseEnter);
      }

      return () => {
        if (viewPort) {
          viewPort.removeEventListener('scroll', handleScroll);
          viewPort.removeEventListener('mouseenter', handleMouseEnter);
        }
      };
    }, [isDragging, scrollSizeTrigger]);

    useEffect(() => {
      const handleMouseMove = (e: MouseEvent) => {
        if (isDragging) {
          if (!viewPortRef.current) {
            return;
          }

          if (draggingRef.current?.type === 'y') {
            const initialY = draggingRef.current.initialY || 0;
            const deltaY = e.clientY - initialY;
            const scrollRatio =
              (viewPortRef.current.scrollHeight - viewPortRef.current.clientHeight) /
              (viewPortRef.current.clientHeight - scrollSizeRef.current.ySize);
            viewPortRef.current.scrollTop += deltaY * scrollRatio;
            draggingRef.current.initialY = e.clientY;
          } else if (draggingRef.current?.type === 'x') {
            const initialX = draggingRef.current.initialX || 0;
            const deltaX = e.clientX - initialX;
            const scrollRatio =
              (viewPortRef.current.scrollWidth - viewPortRef.current.clientWidth) /
              (viewPortRef.current.clientWidth - scrollSizeRef.current.xSize);
            viewPortRef.current.scrollLeft += deltaX * scrollRatio;
            draggingRef.current.initialX = e.clientX;
          }
        }
      };

      const handleMouseUp = () => {
        setIsDragging(false);
        draggingRef.current = null;
        toggleScrollbar.allHide();
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }, [isDragging]);

    useEffect(() => {
      const handleYMouseDown = (e: MouseEvent) => {
        setIsDragging(true);
        clearYScrollTimeOut();
        toggleScrollbar.yVisible();
        draggingRef.current = { type: 'y', initialY: e.clientY };
      };

      const handleXMouseDown = (e: MouseEvent) => {
        setIsDragging(true);
        clearXScrollTimeOut();
        toggleScrollbar.xVisible();
        draggingRef.current = { type: 'x', initialX: e.clientX };
      };

      const yScroll = yScrollRef.current;
      const xScroll = xScrollRef.current;

      if (yScroll) {
        yScroll.addEventListener('mousedown', handleYMouseDown);
      }

      if (xScroll) {
        xScroll.addEventListener('mousedown', handleXMouseDown);
      }

      return () => {
        if (yScroll) {
          yScroll.removeEventListener('mousedown', handleYMouseDown);
        }

        if (xScroll) {
          xScroll.removeEventListener('mousedown', handleXMouseDown);
        }
      };
    }, []);

    const draggingRef = useRef<{ type: 'y' | 'x'; initialX?: number; initialY?: number } | null>(
      null,
    );

    const scrollTo = (index: number, behavior: ScrollBehavior = 'smooth', height?: number) => {
      if (viewPortRef.current) {
        viewPortRef.current.scrollTo({
          top: index * (height ?? 32),
          behavior,
        });
      }
    };

    const scrollToHeight = (
      height: number,
      behavior: ScrollBehavior = 'smooth',
      offset?: number,
    ) => {
      if (viewPortRef.current) {
        viewPortRef.current.scrollTo({
          top: height + (offset ?? 0),
          behavior,
        });
      }
    };

    const updateOverflowState = () => {
      if (viewPortRef.current) {
        const isOverflowY = viewPortRef.current.scrollHeight > viewPortRef.current.clientHeight;
        const isOverflowX = viewPortRef.current.scrollWidth > viewPortRef.current.clientWidth;
        setOverflowState({ isOverflowY, isOverflowX });
      } else {
        setOverflowState({ isOverflowY: false, isOverflowX: false });
      }
    };

    const handleYMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      setIsDragging(true);
      clearYScrollTimeOut();
      toggleScrollbar.yVisible();
      draggingRef.current = { type: 'y', initialY: e.clientY };
    };

    const handleXMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      setIsDragging(true);
      clearXScrollTimeOut();
      toggleScrollbar.xVisible();
      draggingRef.current = { type: 'x', initialX: e.clientX };
    };

    const handleYClick: MouseEventHandler<HTMLDivElement> = (e) => {
      if (viewPortRef.current && yScrollbarRef.current) {
        const scrollbarRect = yScrollbarRef.current.getBoundingClientRect();
        const viewportHeight = viewPortRef.current.clientHeight;
        const clickPosition = e.clientY - scrollbarRect.top;
        const scrollRatio =
          (viewPortRef.current.scrollHeight - viewportHeight) /
          (scrollbarRect.height - scrollSizeRef.current.ySize);

        const scrollTop = clickPosition * scrollRatio - viewportHeight / 2; // 클릭한 지점에 스크롤 상단이 아닌 스크롤 (thumb) 기준 가운데에 위치하게 (중앙 정렬)

        const targetY = Math.max(
          0,
          Math.min(scrollTop, viewPortRef.current.scrollHeight - viewportHeight),
        );

        smoothScrollTo('y', targetY, 200);
      }
    };

    const handleXClick: MouseEventHandler<HTMLDivElement> = (e) => {
      if (viewPortRef.current && xScrollbarRef.current) {
        const scrollbarRect = xScrollbarRef.current.getBoundingClientRect();
        const viewportWidth = viewPortRef.current.clientWidth;
        const clickPosition = e.clientX - scrollbarRect.left;
        const scrollRatio =
          (viewPortRef.current.scrollWidth - viewportWidth) /
          (scrollbarRect.width - scrollSizeRef.current.xSize);

        const scrollLeft = clickPosition * scrollRatio - viewportWidth / 2;

        const targetX = Math.max(
          0,
          Math.min(scrollLeft, viewPortRef.current.scrollWidth - viewportWidth),
        );

        smoothScrollTo('x', targetX, 200);
      }
    };

    const smoothScrollTo = (axis: 'x' | 'y', target: number, duration: number) => {
      if (!viewPortRef.current) return;

      const start = axis === 'y' ? viewPortRef.current.scrollTop : viewPortRef.current.scrollLeft;
      const change = target - start;
      const startTime = performance.now();

      const animateScroll = (currentTime: number) => {
        if (!viewPortRef.current) return;
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easeInOutCubic =
          progress < 0.5 ? 4 * progress * progress * progress : 1 - (-2 * progress + 2) ** 3 / 2;

        if (axis === 'y') {
          viewPortRef.current.scrollTop = start + change * easeInOutCubic;
        } else {
          viewPortRef.current.scrollLeft = start + change * easeInOutCubic;
        }

        if (progress < 1) {
          requestAnimationFrame(animateScroll);
        }
      };

      requestAnimationFrame(animateScroll);
    };

    useImperativeHandle(ref, () => ({
      ...viewPortRef.current,
      scrollTo,
      scrollToHeight,
    }));

    useEffect(() => {
      if (width === 0) return;

      const rafId = requestAnimationFrame(() => {
        updateScrollSizes();
      });

      return () => cancelAnimationFrame(rafId);
    }, [width, height]);

    useEffect(() => {
      if (isAlwaysVisible) {
        if (yScrollbarRef.current) {
          yScrollbarRef.current.style.opacity = '1';
        }
        if (xScrollbarRef.current) {
          xScrollbarRef.current.style.opacity = '1';
        }
      }
    }, [isAlwaysVisible]);

    return (
      <div
        className={customTwMerge('ScrollbarWrap', className)}
        style={style}
        onMouseEnter={!isAlwaysVisible ? toggleScrollbar.allVisible : undefined}
        onMouseLeave={!isAlwaysVisible ? toggleScrollbar.allHide : undefined}
        {...props}>
        <Children
          className='viewPort'
          ref={viewPortRef}
          style={{
            overflowY: disabledY ? 'hidden' : 'auto',
            overflowX: disabledX ? 'hidden' : 'auto',
          }}>
          {children}
        </Children>
        {!disabledY && overflowState.isOverflowY && (
          <div
            className={customTwMerge('yScrollbar', isAlwaysVisible ? 'opacity-100' : 'opacity-0')}
            style={{
              zIndex: disabledY ? -2 : 1000,
            }}
            ref={yScrollbarRef}
            onClick={handleYClick}>
            <div
              className='yThumb'
              ref={yScrollRef}
              onMouseEnter={toggleScrollbar.yVisible}
              onMouseDown={handleYMouseDown}
              style={{
                transform: `translateY(${scrollPosition.yPosition}px)`,
                height: `${scrollSizeRef.current.ySize}px`,
              }}
            />
          </div>
        )}
        {!disabledX && overflowState.isOverflowX && (
          <div
            className={customTwMerge('xScrollbar', isAlwaysVisible ? 'opacity-100' : 'opacity-0')}
            style={{
              zIndex: disabledX ? -2 : 1000,
            }}
            ref={xScrollbarRef}
            onClick={handleXClick}>
            <div
              className='xThumb'
              ref={xScrollRef}
              onMouseEnter={toggleScrollbar.xVisible}
              onMouseDown={handleXMouseDown}
              style={{
                transform: `translateX(${scrollPosition.xPosition}px)`,
                width: `${scrollSizeRef.current.xSize}px`,
              }}
            />
          </div>
        )}
      </div>
    );
  },
);

Scrollbar.displayName = 'Scrollbar';

export default Scrollbar;
