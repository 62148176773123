import { motion } from 'framer-motion';

interface AnimatedListProps {
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
}

export default function AnimatedList({ isOpen, children, className = '' }: AnimatedListProps) {
  return (
    <motion.ul
      initial={{ height: 0, opacity: 0 }}
      animate={isOpen ? { height: 'auto', opacity: 1 } : {}}
      exit={{ height: 0, opacity: 0 }}
      transition={{ duration: 0.4 }}
      className={`overflow-hidden ${className}`}>
      {children}
    </motion.ul>
  );
}
