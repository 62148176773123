import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { ISO_DATE_TIME_TIME_FORMAT } from '@shared/constants/iso-date-time-format';
import { useNavigationBlocker } from '@shared/hooks/use-navigation-blocker';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useUserInfo } from '@shared/hooks/use-user-info';
import type { KakaoLatestLinkageHistoryResType } from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/IntegrationFormWrapper';
import ConsultTalkIntegrationWrapper from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoConsultTalkForm/components/ConsultTalkIntegrationWrapper/ConsultTalkIntegrationWrapper';
import KakaoConsultationRequestButton from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoConsultTalkForm/components/KakaoConsultationRequestButton';
import RequestInfoWrapper from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/RequestInfoWrapper';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import dayjs from 'dayjs';
import { useAtom } from 'jotai/index';
import { isEqual } from 'lodash-es';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

const SUB_TITLE = '상담톡 연동과 관련하여 연락 받으실 분의 정보를 입력해주세요.';

const setTime = (hour: number, minute = 0): string => {
  return dayjs()
    .set('hour', hour)
    .set('minute', minute)
    .set('second', 0)
    .set('millisecond', 0)
    .format(ISO_DATE_TIME_TIME_FORMAT);
};

const formatDate = (date: string | undefined, fallback: string): string =>
  date ? dayjs(date).format(ISO_DATE_TIME_TIME_FORMAT) : fallback;

const calculateFutureDate = (baseDate: string, daysToAdd: number): string => {
  return dayjs(baseDate).add(daysToAdd, 'day').format(ISO_DATE_TIME_TIME_FORMAT);
};

const defaultStartTime = setTime(9);
const defaultEndTime = setTime(20);
const defaultToBeStartedAt = calculateFutureDate(defaultStartTime, 3);

interface KakaoConsultTalkFormProps {
  data?: KakaoLatestLinkageHistoryResType | null;
}

export default function KakaoConsultTalkForm({ data }: KakaoConsultTalkFormProps) {
  const { hospitalID } = useSelectedHospitalInfo();
  const { realName } = useUserInfo();

  const [hospitalSettingsModified, setHospitalSettingsModified] = useAtom(
    hospitalSettingsModifiedState,
  );

  const defaultValues = {
    hospitalID,
    requesterName: data?.requesterName ?? realName,
    toBeContactedPhone: data?.requesterContact ?? '',
    toBeStartedAt: formatDate(data?.toBeStartedAt, defaultToBeStartedAt),
    searchableChannelId: data?.searchableChannelId ?? '',
    consultationInformation: {
      ...{
        consultationStartTime: formatDate(
          data?.consultationInformation?.consultationStartTime,
          defaultStartTime,
        ),
        consultationEndTime: formatDate(
          data?.consultationInformation?.consultationEndTime,
          defaultEndTime,
        ),
      },
      ...{
        mondayAvailable: data?.consultationInformation?.mondayAvailable ?? true,
        tuesdayAvailable: data?.consultationInformation?.tuesdayAvailable ?? true,
        wednesdayAvailable: data?.consultationInformation?.wednesdayAvailable ?? true,
        thursdayAvailable: data?.consultationInformation?.thursdayAvailable ?? true,
        fridayAvailable: data?.consultationInformation?.fridayAvailable ?? true,
        saturdayAvailable: data?.consultationInformation?.saturdayAvailable ?? true,
        sundayAvailable: data?.consultationInformation?.sundayAvailable ?? true,
      },
    },
  };

  const methods = useForm<KakaoConsultationTalkLinkageHandlerBodyRequest>({
    defaultValues: { ...defaultValues },
  });

  const currentValues = methods.watch();

  useEffect(() => {
    setHospitalSettingsModified(
      Object.keys(defaultValues).some((key) => {
        const typedKey = key as keyof typeof defaultValues;
        const currentValue = currentValues[typedKey];
        const defaultValue = defaultValues[typedKey];

        if (typeof currentValue === 'string' && typeof defaultValue === 'string') {
          return currentValue !== defaultValue;
        }

        if (typeof currentValue === 'object' && typeof defaultValue === 'object') {
          return !isEqual(currentValue, defaultValue);
        }
        return currentValue !== defaultValue;
      }),
    );
  }, [currentValues]);

  useNavigationBlocker({
    shouldBlock: hospitalSettingsModified,
  });

  return (
    <FormProvider {...methods}>
      <form autoComplete='off'>
        <div className='flex h-full flex-col'>
          <RequestInfoWrapper subTitle={SUB_TITLE} />
          <ConsultTalkIntegrationWrapper />
          <KakaoConsultationRequestButton />
        </div>
      </form>
    </FormProvider>
  );
}
