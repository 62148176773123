import { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import { useAtomValue } from 'jotai';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { getRandomColor } from 'web/shared/utils/get-random-color';
import { useChatRoomInfoOne } from 'web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { USER_TYPE_CODE } from '../ChattingContent/components/ChattingMessage/constants/message';
import { makingTooltipNames } from './functions/making-tooltip-names';

type ColorLiteral = (typeof Color)[keyof typeof Color];

interface TooltipContainerProps {
  text: string;
  children: React.ReactNode;
}

function TooltipContainer({ text = '', children }: TooltipContainerProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (wrapperRef.current) {
      const width = wrapperRef.current.clientWidth;
      setOffset(width);
    }
  }, [children]);

  return (
    <div className='absolute top-[-2px] right-21' ref={wrapperRef}>
      <HoverTooltip
        show={true}
        text={text}
        offset={offset}
        position='bottomLeft'
        wrapperProps={{
          className: 'cursor-default',
        }}>
        {children}
      </HoverTooltip>
    </div>
  );
}

const ChattingParticipant = memo(
  ({
    text,
    bgColor,
    isRemainedPatients = false,
  }: {
    text: string;
    bgColor?: ColorLiteral;
    isRemainedPatients?: boolean;
  }) => {
    return (
      <div
        className={customTwMerge(
          'h-24 w-24 flex-full-center rounded-r6 border border-white50 text-white50',
          isRemainedPatients ? 'text-Body10Bold' : 'text-Header14',
        )}
        style={{ backgroundColor: bgColor || getRandomColor() }}>
        {text}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.text === nextProps.text,
);

export default function ChattingParticipants() {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  if (!chatRoomID) return null;
  const { participants } = useChatRoomInfoOne({ chatRoomID });
  const participantsWithoutPatient = participants.filter(
    (participant) => Number(participant.type) !== USER_TYPE_CODE.PATIENT,
  );
  const participantsWithRealName = participantsWithoutPatient.map((participant) => ({
    ...participant,
    realName: participant?.realName || participant?.name,
  }));
  const paricipantsLength = participantsWithRealName.length;
  const tooltipNames = useMemo(() => {
    return makingTooltipNames(participantsWithRealName);
  }, [participants]);

  switch (paricipantsLength) {
    case 0:
      return null;
    case 1:
      return (
        <TooltipContainer text={tooltipNames}>
          <ChattingParticipant text={participantsWithRealName[0]?.realName[0]} />
        </TooltipContainer>
      );
    case 2:
      return (
        <TooltipContainer text={tooltipNames}>
          <div className='flex'>
            <div className='absolute right-20'>
              <ChattingParticipant text={participantsWithRealName[0]?.realName[0]} />
            </div>
            <div>
              <ChattingParticipant text={participantsWithRealName[1]?.realName[0]} />
            </div>
          </div>
        </TooltipContainer>
      );
    default: // 3명 이상
      return (
        <TooltipContainer text={tooltipNames}>
          <div className='flex'>
            <div className='absolute right-50'>
              <ChattingParticipant text={participantsWithRealName[0]?.realName[0]} />
            </div>
            <div className='absolute right-30'>
              <ChattingParticipant text={participantsWithRealName[1]?.realName[0]} />
            </div>
            <div>
              <ChattingParticipant
                text={`+${participantsWithRealName.length - 2}`}
                bgColor={Color.black700}
                isRemainedPatients={true}
              />
            </div>
          </div>
        </TooltipContainer>
      );
  }
}
