import {
  eventPushRequestCreateParamState,
  eventPushSendToHospitalAtom,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import {
  RadioGroup,
  type ValueType,
} from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

export default function EventTargetRadioGroup() {
  const resetEventPushRequestParam = useResetAtom(eventPushRequestCreateParamState);
  const [isSendToHospital, setIsSendToHospital] = useAtom(eventPushSendToHospitalAtom);

  const handleChange = (value: ValueType) => {
    if (value === 'all') {
      resetEventPushRequestParam();
    } else {
      setIsSendToHospital(false);
    }
  };

  return (
    <div className='mt-20 flex flex-col gap-10'>
      <RadioGroup
        name='pushRequestTarget'
        value={isSendToHospital ? 'all' : 'specific'}
        onChange={handleChange}>
        <Radio label='모든 고객에게 푸시알림 보내기' id='all' value='all' />
        <Radio label={<RadioLabel />} id='specific' value='specific' />
      </RadioGroup>
    </div>
  );
}

const RadioLabel = () => {
  return (
    <div className='flex-center gap-10'>
      <p>특정 고객에게 푸시알림 보내기</p>
      <div className='flex-center'>
        <HoverTooltip
          position='right'
          offset={24}
          text={'조건별 정보 수정은 [설정]에서 할 수 있습니다.'}
          portalZindex={100000}>
          <Icon name={'info-circle-outline'} color={'black200'} size={16} />
        </HoverTooltip>
      </div>
    </div>
  );
};
