import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtomValue, useSetAtom } from 'jotai';
import { intersection, xor } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { TargetableNationalitiesHandlerParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { isSubset } from 'web/shared/utils/is-subset';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import { useSingleAlgorithmInfo } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-single-algorithm-info';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';
import NextButton from 'web/templates/Automation/containers/shared/components/Buttons/NextButton';

const getTargetableNationalities = async (params: TargetableNationalitiesHandlerParams) => {
  const response = await apiClient.v3.targetableNationalitiesHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const isNationalitySubset = (
  toBeAppliedNationalityIds: string[],
  targetableNationalities: { _id: string }[],
) => {
  return isSubset(
    toBeAppliedNationalityIds,
    targetableNationalities.map((nationality) => nationality._id),
  );
};

const canProceedToNextTab = (
  mode: string,
  toBeAppliedNationalityIds: string[] | undefined,
  targetableNationalities: { _id: string }[],
  originalNationalityIds?: string[],
  targetTreatmentTags?: string[],
  originalTreatmentTagIds?: string[],
) => {
  switch (mode) {
    case 'CREATE':
      if (
        !toBeAppliedNationalityIds?.length &&
        targetableNationalities &&
        targetableNationalities[0]._id !== '-1'
      ) {
        return false;
      }

      return isNationalitySubset(toBeAppliedNationalityIds || [], targetableNationalities);

    case 'EDIT': {
      if (!originalNationalityIds || !targetTreatmentTags || !originalTreatmentTagIds) return false;

      // 수정사항이 있는지 확인
      const isTreatmentTagsChanged = !!xor(targetTreatmentTags, originalTreatmentTagIds).length;

      const isNationalityChanged = !!xor(toBeAppliedNationalityIds, originalNationalityIds).length;

      // 치료태그나 국적이 변경된 경우 TARGET_EDITED로 처리되어야 함
      if (isTreatmentTagsChanged || isNationalityChanged) return false;

      // 아무것도 변경되지 않은 경우만 EDIT으로 처리
      return true;
    }

    case 'TARGET_EDITED': {
      const isUnchanged =
        !xor(toBeAppliedNationalityIds, originalNationalityIds).length &&
        !xor(targetTreatmentTags, originalTreatmentTagIds).length;

      // 변경사항이 없는 경우
      if (isUnchanged) {
        // 다음으로 이동 불가
        return false;
      }

      // 기존에 선택됐던 국적이 없거나 / 기존에 선택됐던 치료태그가 없거나 / 대상 치료태그가 없거나
      if (!originalNationalityIds || !targetTreatmentTags || !originalTreatmentTagIds) {
        // 다음으로 이동 불가
        return false;
      }

      if (
        // 선택된 국적이 하나도 없으면서
        !toBeAppliedNationalityIds?.length &&
        // 선택 가능한 국적이 존재하면서
        targetableNationalities &&
        // 국적 미입력이 없는 경우 (=이미 국적 미입력으로 만들어진 자동화가 존재하는 경우)
        targetableNationalities[0]._id !== '-1'
      ) {
        // 다음으로 이동 불가
        return false;
      }

      // 치료태그 변경 여부 확인
      const isTreatmentTagsChanged = !!xor(targetTreatmentTags, originalTreatmentTagIds).length;

      // 치료태그가 변경된 경우
      if (isTreatmentTagsChanged) {
        // targetableNationalities가 존재하면 (해당 치료태그로 생성된 자동화가 없으면) 진행 가능
        return targetableNationalities.length > 0;
      }

      // 치료태그는 그대로이고 국적만 변경된 경우
      return isNationalitySubset(toBeAppliedNationalityIds || [], targetableNationalities);
    }

    case 'COPY': {
      const isUnchanged =
        !xor(toBeAppliedNationalityIds, originalNationalityIds).length &&
        !xor(targetTreatmentTags, originalTreatmentTagIds).length;

      // 변경사항이 없는 경우
      if (isUnchanged) {
        // 다음으로 이동 불가
        return false;
      }

      // 기존에 선택됐던 국적이 없거나 / 기존에 선택됐던 치료태그가 없거나 / 대상 치료태그가 없거나
      if (!originalNationalityIds || !originalTreatmentTagIds || !targetTreatmentTags) {
        // 다음으로 이동 불가
        return false;
      }

      if (
        // 선택된 국적이 하나도 없으면서
        !toBeAppliedNationalityIds?.length &&
        // 선택 가능한 국적이 존재하면서
        targetableNationalities &&
        // 국적 미입력이 없는 경우 (=이미 국적 미입력으로 만들어진 자동화가 존재하는 경우)
        targetableNationalities[0]._id !== '-1'
      ) {
        // 다음으로 이동 불가
        return false;
      }

      const isTreatmentTagsChanged = !!xor(targetTreatmentTags, originalTreatmentTagIds).length;
      if (isTreatmentTagsChanged) {
        // targetableNationalities가 존재하면 (해당 치료태그로 생성된 자동화가 없으면) 진행 가능
        return targetableNationalities.length > 0;
      }

      return intersection(toBeAppliedNationalityIds || [], originalNationalityIds).length === 0;
    }

    default:
      return false;
  }
};

export default function BaseSettingNextButton() {
  const { watch, getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const algorithmMode = useAtomValue(algorithmModeState);
  const name = watch('name');
  const targetTreatmentTags = watch('targetTreatmentTags');

  const { singleAlgorithmInfo } = useSingleAlgorithmInfo();

  const { data: targetableNationalities } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.targetableNationalitiesHandler,
      {
        hospitalID,
        treatmentTagId: targetTreatmentTags?.join(','),
        exceptionTargetAlgorithmId:
          algorithmMode.mode !== 'CREATE' ? algorithmMode.algorithmId : undefined,
      },
    ] as const,
    queryFn: ({ queryKey }) => getTargetableNationalities(queryKey[1]),
    staleTime: 0,
    gcTime: 0,
  });

  const setSelectedAutomationTab = useSetAtom(selectedCounselAutomationTabState);

  const onChangeTab = () => {
    // 1. 기본 유효성 검사
    if (!name?.length || !targetTreatmentTags?.length) {
      toastService.errorMsg({
        text: '현재 페이지의 필수정보를 모두 입력해 주세요.',
      });
      return;
    }

    // 3. 자동화 설정 가능 여부 체크
    const toBeAppliedNationalityIds = getValues('toBeAppliedNationalityIds');

    const canProceed = canProceedToNextTab(
      algorithmMode.mode,
      toBeAppliedNationalityIds,
      targetableNationalities,
      singleAlgorithmInfo?.appliedNationalityIds,
      targetTreatmentTags,
      singleAlgorithmInfo?.appliedTreatmentTagIds,
    );

    if (!canProceed) {
      toastService.errorMsg({
        text: '현재 설정된 치료태그로 설정된 자동화가 이미 존재합니다.',
      });
      return;
    }

    // 4. 모든 검증을 통과한 경우에만 다음 탭으로 이동
    setSelectedAutomationTab(1);
  };

  return <NextButton className='mx-auto mt-20 w-[200px]' onClick={onChangeTab} />;
}
