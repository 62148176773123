import { contentPanelLayerState } from '@templates/Content/containers/ContentPanel/states/content-panel-layer-state';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { AnimatePresence } from 'framer-motion';
import { useAtomValue } from 'jotai/index';
import AnimatedList from 'web/shared/components/AnimatedList/AnimatedList';
import type { ContentsHandleClickParams } from '../../../../ContentsList';
import ContentsFilesSelect from './components/ContentsFilesSelect/ContentsFilesSelect';
interface ContentPanelFolderItemProps {
  id: string;
  categoryTitle: string;
  folderIndex: number;
  isOpen: boolean;
  itemIndex: number;
  handleClick: ({ title, folderIndex, itemIndex }: ContentsHandleClickParams) => void;
}

export default function ChatContentPanelFolderItem({
  id,
  categoryTitle,
  folderIndex,
  isOpen,
  itemIndex,
  handleClick,
}: ContentPanelFolderItemProps) {
  const contentPanelLayer = useAtomValue(contentPanelLayerState);

  const folder = contentPanelLayer[folderIndex];
  const item = folder.items[itemIndex];
  if (item.text === '팝업') return null;

  return (
    <AnimatePresence initial={false}>
      {folder.isOpen && (
        <AnimatedList isOpen={folder.isOpen}>
          <li>
            <div
              onClick={() => handleClick({ title: id, folderIndex, itemIndex })}
              className={`line-clamp-1 flex w-full cursor-pointer items-center justify-start gap-4 py-11 pr-20 pl-56 text-Body12 ${
                isOpen
                  ? 'bg-blue50 font-bold text-black500'
                  : 'bg-white50 font-regular text-black500 hover:bg-blueLight'
              }`}>
              <Icon name={isOpen ? 'chevron-down' : 'chevron-right'} size={16} color='black200' />
              {item.text}
            </div>
          </li>
          {isOpen && <ContentsFilesSelect categoryID={id} categoryTitle={categoryTitle} />}
        </AnimatedList>
      )}
    </AnimatePresence>
  );
}
