import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAtomValue } from 'jotai';
import { Outlet } from 'react-router-dom';
import { isServiceManagerNavigatorVisibleState } from 'web/shared/states/is-service-manager-navigator-visible';

export default function PageLayout() {
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  return (
    <div
      className={customTwMerge(
        'flex h-full w-full',
        isServiceManagerNavigatorVisible ? 'pl-[140px]' : 'pl-[70px]',
      )}>
      <Outlet />
    </div>
  );
}
