import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, forwardRef, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import SendContentFormHeader from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/components/FormLayout/SendContentFormHeader';
import SendContentFormTab from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/components/FormLayout/SendContentFormTab';
import ImageContentForm from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/ImageContentForm';
import TextContentForm from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/TextContentForm';
import {
  MARKETING_REPLACEABLE_TEXTS,
  MAX_LENGTH,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import {
  selectedContentTabItemState,
  selectedMessageIdState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import type { ReplaceableText } from 'web/templates/Automation/containers/shared/functions/calculate-text';

export default function SendContentForm() {
  const textRef = useRef<HTMLTextAreaElement>(null);

  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const selectedContentTabItem = useAtomValue(selectedContentTabItemState);

  return (
    <>
      {/* 내용 헤더 */}
      <SendContentFormHeader />

      {/* 내용 탭 (텍스트, 이미지) */}
      <SendContentFormTab />

      {/* 전송내용 - 텍스트 */}
      {selectedContentTabItem?.[selectedMessageId] === '텍스트' && (
        <TextContentForm ref={textRef} />
      )}

      {/* 전송내용 - 이미지 */}
      <Suspense>
        {selectedContentTabItem?.[selectedMessageId] === '이미지' && (
          <ImageContentForm ref={textRef} />
        )}
      </Suspense>

      {/* 치환 문자 */}
      <SendContentFormReplaceableTextLists ref={textRef} />
    </>
  );
}

const SendContentFormReplaceableTextLists = forwardRef<HTMLTextAreaElement>((_, textRef) => {
  const { getValues, setValue } = useFormContext<MarketingAutomationAPIFormValues>();

  const [actualTextLength, setActualTextLength] = useAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const handleInsertReplaceText = (text: string, length: number) => {
    if (actualTextLength + length < MAX_LENGTH) {
      setValue(
        `messages.${selectedMessageIndex}.content.text`,
        (getValues(`messages.${selectedMessageIndex}.content.text`) ?? '') + text,
      );

      setActualTextLength((prev) => prev + length);

      if (textRef && 'current' in textRef) {
        textRef.current?.focus();
      }
    }
  };

  return (
    <MarketingReplaceableTextLists
      replaceableTexts={MARKETING_REPLACEABLE_TEXTS}
      handleInsertReplaceText={handleInsertReplaceText}
    />
  );
});

interface MarketingReplaceableTextListsProps {
  handleInsertReplaceText: (text: string, length: number) => void;
  replaceableTexts: ReplaceableText[];
  className?: string;
}

function MarketingReplaceableTextLists({
  handleInsertReplaceText,
  replaceableTexts,
  className,
}: MarketingReplaceableTextListsProps) {
  return (
    <div className={customTwMerge('mt-20 flex items-center gap-10', className)}>
      <div className='text-Header12 text-black500'>치환 문자</div>
      {replaceableTexts.map(({ text, description, length }) => (
        <HoverTooltip text={description} position='bottomRight' key={text} portalZindex={1000}>
          <button
            className='rounded-r6 bg-white200 px-6 py-3 font-regular text-Body12 text-black500'
            type='button'
            onClick={() => {
              handleInsertReplaceText(text, length);
            }}>
            {text}
          </button>
        </HoverTooltip>
      ))}
    </div>
  );
}
