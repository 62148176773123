import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiMemosElFindWithPinnedParams,
  ApiPatientsElDetailData,
  File,
  MemoWithFileID,
} from 'web/apis/swaggers/swagger-docs';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';
import ServiceManagerMemoContent from './components/ServiceManagerMemoContent';
import ServiceManagerMemoListWithTitle from './components/ServiceManagerMemoListWithTitle';

const fetchServiceManagerMemoWithPinned = async (params: ApiMemosElFindWithPinnedParams) => {
  const response = await apiClient.v3.apiMemosElFindWithPinned(params);

  return SHARED_UTILS.api.checkApiResponse(response.data) as MemoWithFileID[];
};

export default function ServiceManagerMemoDialogContainer() {
  const queryClient = useQueryClient();

  const patientId = useAtomValue(patientIdState);
  const [selectedMemoId, setSelectedMemoId] = useState<string>();
  const [images, setImages] = useState<string[]>();

  const { patientId: patientIdFromQuery } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [
      'serviceManagerMemo',
      {
        hserviceID: patientIdFromQuery,
        type: 3001,
      },
    ],
    queryFn: async ({ pageParam = 0 }) => {
      return fetchServiceManagerMemoWithPinned({
        hserviceID: patientIdFromQuery,
        type: 3001,
        skip: pageParam,
        limit: 5,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedMemos = allPages.flat().length;
      if (lastPage.length === 5) {
        return totalLoadedMemos;
      }
      return undefined;
    },
  });

  useEffect(() => {
    if (data && data.pages[0].length > 0) {
      setSelectedMemoId(data.pages[0][0]._id);
    }
  }, [data]);

  const selectedMemo = useMemo(
    () => data?.pages.flat().find((memo) => memo._id === selectedMemoId),
    [data, selectedMemoId],
  );

  const onCloseDialog = () => {
    dialogService.popById('service-manager-memo-dialog');
  };

  // const imageFileIds = selectedMemo?.fileIDs as File[];
  // const images = imageFileIds?.map(({ paths }) => {
  //   return paths[0] ?? '';
  // });

  useEffect(() => {
    if (!selectedMemo) return;

    const imageFileIds = selectedMemo.fileIDs as File[] | undefined;
    const viewingImages = imageFileIds?.map(({ paths }) => {
      return paths[0];
    });
    setImages(viewingImages);
  }, [selectedMemo]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onCloseDialog();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if (!data || data.pages.flat().length === 0) {
    return null;
  }

  return (
    <div className='mt-10 grid h-full grid-cols-[320px,1fr] gap-20 px-20 pb-20'>
      <ServiceManagerMemoListWithTitle
        managerNotes={data.pages.flat()}
        selectedMemoId={selectedMemoId}
        onSelectMemo={setSelectedMemoId}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
      {selectedMemo && (
        <div className='flex h-full w-full flex-col gap-10'>
          <ServiceManagerMemoContent content={selectedMemo.content ?? ''} images={images} />
          <div className='text-Body10 text-black200'>
            {selectedMemo?.updatedAt
              ? `${dayjs(selectedMemo.updatedAt).format('YYYY-MM-DD HH:mm')}/${selectedMemo.modifierName ?? selectedMemo.writerName}`
              : undefined}
          </div>
        </div>
      )}
    </div>
  );
}
