import { useAtomValue } from 'jotai';
import { debounce } from 'lodash-es';
import { type MutableRefObject, useEffect, useLayoutEffect, useState } from 'react';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import type { DataResponse } from '../../../hooks/use-conditional-chat-data';

// Define the props for useScrollObserver
type UseBottomScrollObserverProps = {
  fetchNextPage: () => Promise<void>;
  isLoading: boolean;
  chatContainerRef: MutableRefObject<HTMLDivElement | null>;
  loadNextMoreRef: MutableRefObject<HTMLDivElement | null>;
  setPreviousScrollHeight: React.Dispatch<React.SetStateAction<number>>;
  isFetchingNextPage: boolean | null;
  previousScrollHeight: number;
  messages: DataResponse[];
};

//아래의 부분은 .withDebounce를 사용하기 위한 실험적 Function prototype 확장
declare global {
  interface Function {
    withDebounce(options: { wait: number }): Function;
    withSetTimeout(delay: number): void;
  }
}

const extendFunctionPrototypeForDebounce = () => {
  if (!Function.prototype.withDebounce) {
    Function.prototype.withDebounce = function ({ wait = 1000 }) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return debounce((...args: any[]) => {
        return this.apply(this, args);
      }, wait);
    };
  }
  if (!Function.prototype.withSetTimeout) {
    Function.prototype.withSetTimeout = function (delay) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return setTimeout((...args: any[]) => {
        this.apply(this, args);
      }, delay);
    };
  }
};

extendFunctionPrototypeForDebounce();

export const useBottomScrollObserver = ({
  isFetchingNextPage,
  fetchNextPage,
  isLoading,
  chatContainerRef,
  loadNextMoreRef,
  setPreviousScrollHeight,
  previousScrollHeight,
  messages,
}: UseBottomScrollObserverProps) => {
  const selectedMessageID = useAtomValue(selectedMessageIDState);

  const [isBottomButtonVisible, setIsBottomButtonVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        for (const entry of entries) {
          const container = chatContainerRef.current;
          if (container) {
            if (entry.target === loadNextMoreRef.current) {
              if (entry.isIntersecting && !isLoading) {
                setIsBottomButtonVisible(false);

                if (selectedMessageID) {
                  setPreviousScrollHeight(container.scrollHeight);
                  await fetchNextPage.withDebounce({ wait: 100 })();
                }
              } else {
                setIsBottomButtonVisible(true);
              }
            }
          }
        }
      },
      {
        root: chatContainerRef.current,
        rootMargin: '100px 0px 0px 0px',
        threshold: 0.5,
      },
    );

    if (loadNextMoreRef.current) {
      observer.observe(loadNextMoreRef.current);
    }

    return () => {
      if (loadNextMoreRef.current) {
        observer.unobserve(loadNextMoreRef.current);
        observer.disconnect();
      }
    };
  }, [
    selectedMessageID,
    isLoading,
    chatContainerRef.current,
    loadNextMoreRef.current,
    // fetchNextPage,//수시로 바뀌는 함수이기 ㄸ문에 의존성을 넣지 않음
    setPreviousScrollHeight,
    fetchNextPage.withDebounce,
  ]);

  //use-forced-move-to-bottom.ts의 scrollToTop과 연관성이 있어서 함께 봐야함
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    const container = chatContainerRef.current;

    if (selectedMessageID && container && messages) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          container.scrollTop = previousScrollHeight - container.scrollHeight;
        }, 5);
      });
    }
  }, [isFetchingNextPage, chatContainerRef.current]);

  return { isBottomButtonVisible };
};
