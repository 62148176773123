import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import DashedTag from 'afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom, useAtomValue } from 'jotai';
import { type ChangeEvent, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FixedSizeList, type ListChildComponentProps } from 'react-window';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  TargetableNationalitiesHandlerData,
  TargetableNationalitiesHandlerParams,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { isNationalitySelectBoxOpenedState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/states/is-nationality-select-box-opened';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';

const getTargetableNationalities = async (params: TargetableNationalitiesHandlerParams) => {
  const response = await apiClient.v3.targetableNationalitiesHandler(params);

  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function NationalitySetting() {
  const { hospitalID } = useSelectedHospitalInfo();
  const tagContainerRef = useRef<HTMLDivElement>(null);

  const { setValue, watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const algorithmMode = useAtomValue(algorithmModeState);
  const [isOpened, setIsOpened] = useAtom(isNationalitySelectBoxOpenedState);
  const [tagMaxLengths, setTagMaxLengths] = useState<Record<string, number>>({});

  const targetTreatmentTags = watch('targetTreatmentTags');
  const isForNoTreatmentTags = watch('isForNoTreatmentTags');

  const { data } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.targetableNationalitiesHandler,
      {
        hospitalID,
        exceptionTargetAlgorithmId:
          algorithmMode.mode !== 'CREATE' ? algorithmMode.algorithmId : undefined,
      },
    ] as const,
    queryFn: ({ queryKey }) => getTargetableNationalities(queryKey[1]),

    select: (data) => {
      return data.filter(
        (nationalities) =>
          nationalities._id !== '-1' && nationalities.countryCodeISOAlpha3 !== 'KOR',
      );
    },
  });

  const listData: ListData = useMemo(() => {
    return {
      nationalities: [
        {
          _id: 'no-nationality',
          koreanCountryName: '국적 미입력',
          countryCodeISOAlpha2: '',
          countryCodeISOAlpha3: '',
          englishCountryName: '',
          internationalDialingCodes: '',
          isNoNationality: false,
        } as const,
        ...data,
      ],
      tagMaxLengths,
    };
  }, [data, tagMaxLengths]);

  const handleClick = () => {
    if (!targetTreatmentTags?.length && !isForNoTreatmentTags) {
      return;
    }

    setIsOpened((prev) => !prev);
  };

  useEffect(() => {
    if (targetTreatmentTags === undefined) return;

    if (
      // 치료태그가 선택되지 않았고,
      !targetTreatmentTags.length &&
      // 치료태그 미입력의 선택이 해제되었고,
      !isForNoTreatmentTags &&
      // 국적 선택 모달이 열려있다면,
      isOpened
    ) {
      // 국적 선택 모달을 닫고,
      setIsOpened(false);
      // 선택된 국적을 초기화하고,
      setValue('toBeAppliedNationalityIds', undefined);
      // 치료태그 미입력의 선택을 해제합니다.
      setValue('isForNoNationality', false);
    }
  }, [targetTreatmentTags, isForNoTreatmentTags, isOpened, setIsOpened, setValue]);

  useEffect(() => {
    if (!tagContainerRef.current) return;

    const measureDiv = document.createElement('div');
    measureDiv.style.position = 'absolute';
    measureDiv.style.visibility = 'hidden';
    measureDiv.style.whiteSpace = 'nowrap';
    measureDiv.style.fontSize = '12px';
    measureDiv.style.fontFamily = 'inherit';
    measureDiv.style.padding = '2px 8px';
    measureDiv.style.boxSizing = 'border-box';
    document.body.appendChild(measureDiv);

    const containerWidth = tagContainerRef.current.clientWidth;
    const maxTagWidth = containerWidth - 40;

    const calculateMaxLengths: Record<string, number> = {};

    const processTag = (tag: { id: string; text: string }) => {
      measureDiv.innerText = tag.text;
      let maxTextLength = tag.text.length;
      let tagWidth = measureDiv.getBoundingClientRect().width;

      const adjustedMaxWidth = maxTagWidth * 1.5;

      if (tagWidth > adjustedMaxWidth) {
        while (tagWidth > adjustedMaxWidth && maxTextLength > 3) {
          maxTextLength--;
          measureDiv.innerText = `${tag.text.slice(0, maxTextLength)}...`;
          tagWidth = measureDiv.getBoundingClientRect().width;
        }
      }
      calculateMaxLengths[tag.id] = maxTextLength;
    };

    for (const nationality of data ?? []) {
      processTag({ id: nationality._id, text: nationality.koreanCountryName });
    }

    document.body.removeChild(measureDiv);
    setTagMaxLengths(calculateMaxLengths);
  }, [data]);

  return (
    <div className='mt-16 rounded-r10 border border-white600 bg-white200 p-10'>
      <button
        type='button'
        className='flex w-full justify-between'
        onClick={handleClick}
        disabled={!targetTreatmentTags?.length && !isForNoTreatmentTags}>
        <div className='text-Header12 text-black500'>국적 (선택)</div>
        <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} color='black200' />
      </button>

      <AnimatePresence initial={false}>
        {isOpened && (
          <motion.div
            className='mt-10'
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.2 }}>
            <LabelText textClassName='text-Body10Bold'>국적</LabelText>
            <div className='mt-4 rounded-r10 border border-black200 bg-white50'>
              <div className='mr-4 py-4' ref={tagContainerRef}>
                <FixedSizeList
                  height={278}
                  width='100%'
                  itemCount={listData.nationalities.length}
                  itemSize={30}
                  itemData={listData}
                  className='virtualizedList pr-4'>
                  {NationalityItem}
                </FixedSizeList>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

type ListData = {
  nationalities: (TargetableNationalitiesHandlerData['data'][0] & { isNoNationality?: boolean })[];
  tagMaxLengths: Record<string, number>;
};

const NationalityItem = memo(({ index, style, data }: ListChildComponentProps<ListData>) => {
  const nationality = data.nationalities[index];

  const { watch, setValue } = useFormContext<MarketingAutomationAPIFormValues>();

  const algorithmMode = useAtomValue(algorithmModeState);
  const disabled = algorithmMode.mode === 'EDIT';

  const isForNoNationality = watch('isForNoNationality');
  const toBeAppliedNationalityIds = watch('toBeAppliedNationalityIds') ?? [];

  const handleIsForNoNationalityChange = (checked: boolean) => {
    setValue('isForNoNationality', checked);
    if (checked && !toBeAppliedNationalityIds?.length) {
      setValue('toBeAppliedNationalityIds', undefined);
    }
  };

  const handleSelectTagChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setValue(
      'toBeAppliedNationalityIds',
      isChecked
        ? [...toBeAppliedNationalityIds, value]
        : toBeAppliedNationalityIds.filter((id) => id !== value),
    );
  };

  if (nationality.isNoNationality) {
    return (
      <div style={style} className='flex items-center gap-10 pr-4 pl-16'>
        <Checkbox
          disabled={disabled}
          size={20}
          label={<DashedTag tagSize='small'>국적 미입력</DashedTag>}
          checked={isForNoNationality}
          onChange={(e) => {
            handleIsForNoNationalityChange(e.target.checked);
          }}
        />
      </div>
    );
  }

  return (
    <div style={style} className='flex items-center gap-10 pr-4 pl-16'>
      <Checkbox
        disabled={disabled}
        size={20}
        value={nationality._id}
        label={
          <FilledTag
            maxTextLength={data.tagMaxLengths[nationality._id]}
            className={disabled ? 'cursor-not-allowed hover:bg-white400' : 'cursor-pointer'}
            clickable={!disabled}
            tagSize='small'>
            {nationality.koreanCountryName}
          </FilledTag>
        }
        checked={toBeAppliedNationalityIds.includes(nationality._id)}
        onChange={handleSelectTagChange}
      />
    </div>
  );
});
