import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { nullIfEmpty } from 'web/shared/utils/null-if-empty';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { customerManagementScrollbarRefState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-scrollbar';
import { editableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { useGetUsingColumns } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/hooks/apis/use-get-using-columns';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

const postPatientsTemporaryInfo = async (
  params: Parameters<typeof apiClient.v3.apiPatientsElTemporary>[0],
) => {
  const response = await apiClient.v3.apiPatientsElTemporary(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

// 휴대폰 번호 정규화 함수 추가
const normalizePhoneNumber = (phoneNumber: string | null): string | null => {
  if (!phoneNumber) return null;

  // 모든 특수문자와 공백 제거
  const cleaned = phoneNumber.replace(/[^0-9]/g, '');

  // 숫자만 있는 경우 반환
  if (cleaned.length === 0) return null;

  return cleaned;
};

export const useSaveUserTemporary = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const usingColumns = useGetUsingColumns();

  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);
  const resetEditableHeaderFilter = useResetAtom(editableHeaderFilterState);
  const resetSearchText = useResetAtom(searchTextState);
  const resetSelectedSearchCriteria = useResetAtom(selectedSearchCriteriaState);

  const setCustomerTableDisplayMode = useSetAtom(customerTableDisplayModeState);

  const scrollbarRef = useAtomValue(customerManagementScrollbarRefState);

  const handleChangeDisplayMode = () => {
    setCustomerTableDisplayMode('VIEWER');
  };

  const processRowForAPI = useCallback(
    (row: CustomerManagementTemporaryAPIFormValues[number], index: number) => {
      const {
        order,
        patientId,
        birthdate,
        chartNumber,
        gender,
        isFirstVisit,
        name,
        phoneNumber,
        nationalityId,
        treatmentTagIds,
      } = row;

      return {
        order: order ?? index + 1,
        hospitalID,
        patientId,
        name,
        chartNumber,
        isFirstVisit,
        phoneNumber: normalizePhoneNumber(phoneNumber ?? null),
        birthdate,
        gender,
        treatmentTagIds: treatmentTagIds ?? [],
        nationalityId,
      };
    },
    [hospitalID],
  );

  const temporarySaveMutation = useMutation({
    mutationFn: (params: Parameters<typeof postPatientsTemporaryInfo>[0]) =>
      postPatientsTemporaryInfo(params),
  });

  const handleTemporarySave = async (
    currentRows: CustomerManagementTemporaryAPIFormValues,
    onSuccessCallback?: () => void,
  ) => {
    if (!currentRows.length) return;

    const usingChartNumber = usingColumns?.usingChartNumber;
    const usingBirthDay = usingColumns?.usingBirthDay;
    const usingGender = usingColumns?.usingGender;
    const usingTreatmentTag = usingColumns?.usingTreatmentTag;
    const usingFirstVisitStartDate = usingColumns?.usingFirstVisitStartDate;
    const usingNationality = usingColumns?.usingNationality;

    const rowsWithOrder = currentRows
      .map((row) => {
        const fieldUpdates = {
          name: nullIfEmpty(row.name),
          phoneNumber: nullIfEmpty(row.phoneNumber),
          chartNumber: !usingChartNumber ? null : row.chartNumber,
          isFirstVisit: !usingFirstVisitStartDate ? null : row.isFirstVisit,
          birthdate: !usingBirthDay ? null : row.birthdate,
          gender: !usingGender ? null : row.gender,
          treatmentTagIds: !usingTreatmentTag ? [] : row.treatmentTagIds,
          nationalityId: !usingNationality ? null : row.nationalityId,
        };

        return {
          ...row,
          ...fieldUpdates,
        };
      })
      .map((row, index) => processRowForAPI(row, index));

    const getLastModifiedRowIndex = (rows: typeof rowsWithOrder) => {
      let lastModifiedIndex = -1;

      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (!row) continue;

        const { hospitalID, order, ...otherFields } = row;

        // 데이터가 있는 row 확인
        const hasData = Object.values(otherFields).some(
          (v) => v !== null && v !== undefined && !(Array.isArray(v) && v.length === 0),
        );

        if (hasData) {
          lastModifiedIndex = i;
        }
      }

      return lastModifiedIndex;
    };

    const lastModifiedIndex = getLastModifiedRowIndex(rowsWithOrder);

    // 마지막 수정된 row까지만 포함
    const relevantRows =
      lastModifiedIndex >= 0 ? rowsWithOrder.slice(0, lastModifiedIndex + 1) : rowsWithOrder;

    const updatedValues =
      relevantRows.length > 0
        ? relevantRows
        : [
            {
              order: 1,
              hospitalID,
              patientId: null,
              name: null,
              chartNumber: null,
              isFirstVisit: null,
              phoneNumber: null,
              birthdate: null,
              gender: null,
              treatmentTagIds: [],
              nationalityId: null,
            },
          ];

    const filteredValues = updatedValues.filter((row) => {
      const { hospitalID, order, treatmentTagIds, ...rest } = row;

      // treatmentTagIds는 배열의 길이로 확인
      const isEmptyTreatmentTags = !treatmentTagIds?.length;

      // 나머지 필드들이 모두 null인지 확인
      const isAllFieldsNull = Object.values(rest).every(
        (value) => value === null || value === '' || value === undefined,
      );

      // hospitalID와 order는 제외하고, 다른 필드 중 하나라도 값이 있으면 true 반환
      return !isEmptyTreatmentTags || !isAllFieldsNull;
    });

    // 데이터가 있는 row들의 order 값들을 추출
    const ordersWithData = filteredValues.map((row) => row.order);
    const minOrder = Math.min(...ordersWithData);
    const maxOrder = Math.max(...ordersWithData);

    // 최소 order부터 최대 order까지의 연속된 배열 생성
    const orderedValues = Array.from({ length: maxOrder - minOrder + 1 }, (_, index) => {
      const currentOrder = minOrder + index;
      const existingRow = filteredValues.find((row) => row.order === currentOrder);

      if (existingRow) {
        // existingRow에서 patientId 제거
        const { patientId, ...rowWithoutPatientId } = existingRow;
        return rowWithoutPatientId;
      }

      // 해당 order에 데이터가 없으면 빈 row 생성
      return {
        order: currentOrder,
        hospitalID,
        name: null,
        chartNumber: null,
        isFirstVisit: null,
        phoneNumber: null,
        birthdate: null,
        gender: null,
        treatmentTagIds: [],
        nationalityId: null,
      };
    });

    const finalValues =
      orderedValues.length > 0
        ? orderedValues
        : [
            {
              order: 1,
              hospitalID,
              name: null,
              chartNumber: null,
              isFirstVisit: null,
              phoneNumber: null,
              birthdate: null,
              gender: null,
              treatmentTagIds: [],
              nationalityId: null,
            },
          ];

    const result = await temporarySaveMutation.mutateAsync(finalValues, {
      onSuccess: async () => {
        // 셀 선택 초기화
        resetSelectedCell();
        resetFocusedCell();

        // 필터 초기화
        resetEditableHeaderFilter();
        // 검색 초기화
        resetSearchText();
        // 검색 기준 초기화
        resetSelectedSearchCriteria();

        // 스크롤을 최상단으로 이동
        scrollbarRef?.scrollTo(0, 'auto');

        handleChangeDisplayMode();
        onSuccessCallback?.();
      },
      onError: (error) => {
        console.error('임시 고객정보 저장 에러 발생 :', error);
      },
    });
    return result;
  };

  return {
    handleTemporarySave,
  };
};
