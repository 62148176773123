import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';

export default function SendContentFormHeader() {
  return (
    <div className='mt-20 flex w-full items-center justify-between'>
      <LabelText width='fit-content' isRequired>
        내용
      </LabelText>
    </div>
  );
}
