import { useNavigationBlocker } from '@shared/hooks/use-navigation-blocker';
import { useHServiceMemo } from '@templates/CustomerChat/components/CustomerMemo/hooks/use-hservice-memo';
import TextArea from 'afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { format, parseISO } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';

export const CustomerMemo = () => {
  // console.count('CustomerMemo');
  const { queryResult, isUpdating, updateHServiceMemoAction } = useHServiceMemo();
  const { data, status, error, isLoading, refetch } = queryResult;

  const [content, setContent] = useState('');
  const [defaultContent, setDefaultContent] = useState('');

  const isSameTextAndDefaultText = useMemo(
    () => content === defaultContent,
    [content, defaultContent],
  );

  const bottomText = useMemo(() => {
    if (!data) return '-/-';

    const { createdAt, updatedAt, modifierID } = data;

    if (createdAt && updatedAt && createdAt === updatedAt) {
      return '-/-';
    }

    const formattedDate =
      updatedAt && !Number.isNaN(parseISO(updatedAt).getTime())
        ? format(parseISO(updatedAt), 'yyyy-MM-dd HH:mm')
        : '-';

    const formattedName = modifierID?.realName || '-';

    return `${formattedDate}/${formattedName}`;
  }, [data]);

  useNavigationBlocker({
    shouldBlock: !isSameTextAndDefaultText,
  });

  const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
  }, []);

  const handleSave = useCallback(() => {
    updateHServiceMemoAction(content, {
      onSuccessCallback: () => {
        toastService.successMsg({ text: '상담메모를 저장했습니다.' });
        refetch();
      },
      onFailureCallback: () => {
        console.error('Error saving memo:', error);
        toastService.errorMsg({ text: '상담메모 저장을 실패했습니다.' });
      },
    });
  }, [content, error, refetch, updateHServiceMemoAction]);

  useEffect(() => {
    const content = data?.content || '';
    setContent(content);
    setDefaultContent(content);
  }, [data]);

  return (
    <div className='flex h-200 flex-col gap-8 border-b border-b-white400 px-16 pt-20 pb-10'>
      <LabelText textClassName='text-Header16 text-black700 ml-4'>상담메모</LabelText>
      <div className='relative'>
        <TextArea
          placeholder='상담 시 참고해야 할 고객정보에 대해 입력해 주세요.'
          onSave={handleSave}
          onChange={handleChange}
          disabled={status === 'error'}
          hasError={status === 'error'}
          errorText={error ? error.message : ''}
          isShowBottom={true}
          leftBottomText={bottomText}
          value={content}
          isSaveButtonDisabled={isSameTextAndDefaultText}
          width={368}
          height={128}
        />
        {(isLoading || isUpdating) && (
          <div className='absolute inset-0 flex-center bg-white50 opacity-40'>
            <BaseLoading />
          </div>
        )}
      </div>
    </div>
  );
};
