import * as React from "react";
import type { SVGProps } from "react";
const SvgSubscript = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M15.5 15h-1.639a3.86 3.86 0 0 0 1.914-2.975 1.8 1.8 0 0 0-1.6-1.751 1.92 1.92 0 0 0-2.154 1.426.5.5 0 1 0 .957.291.914.914 0 0 1 1.053-.725.81.81 0 0 1 .744.762c0 1.076-1.17 1.87-1.94 2.43A1.46 1.46 0 0 0 12 15.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1M9.65 5.241a1 1 0 0 0-1.409.108L6 7.964 3.759 5.349a1 1 0 0 0-1.567 1.243q.023.03.049.057L4.684 9.5l-2.443 2.85a1 1 0 0 0 1.469 1.357q.026-.027.049-.057L6 11.036l2.241 2.614a1 1 0 1 0 1.567-1.243q-.023-.03-.049-.057L7.316 9.5l2.443-2.849a1 1 0 0 0-.109-1.41"
      className="subscript_svg__ql-fill"
    />
  </svg>
);
export default SvgSubscript;
