import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

type MultiImageCoverProps = {
  isExpired: boolean;
  isMocking: boolean;
  imagesLength: number;
  handleImageOpen: (index: number) => void;
};

export default function MultiImageCover({
  isExpired,
  isMocking,
  imagesLength,
  handleImageOpen,
}: MultiImageCoverProps) {
  return (
    <>
      {!isExpired && !isMocking && (
        <div className='absolute inset-0 rounded-r10 bg-black opacity-50 ' />
      )}
      <div
        className='absolute inset-0 flex items-center justify-center'
        onClick={() => {
          handleImageOpen(1);
        }}>
        <div
          className='flex h-28 w-81 items-center justify-center gap-6 rounded-r10 text-Body11 text-white50'
          style={{ backgroundColor: 'rgba(34, 34, 34, 0.5)' }}>
          <Icon name={isExpired ? 'image-off-outline' : 'image-filter'} color='white50' size={16} />
          총 {imagesLength}장
        </div>
      </div>
    </>
  );
}
