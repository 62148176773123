import type { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import type { AlgorithmMessage, AlgorithmWithMessagesInput } from 'web/apis/swaggers/swagger-docs';
import EventContent from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/EventContent/EventContent';
import HomecareContent from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/HomecareContent/HomecareContent';
import ImageContent from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/ImageContent/ImageContent';
import SurveyContent from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/SurveyContent/SurveyContent';
import VideoContent from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/VideoContent/VideoContent';
import WorkoutVideoContent from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/WorkoutVideoContent/WorkoutVideoContent';

const KAKAO_GUIDE_MESSAGE = `안녕하세요. 카카오톡 알림톡 검수 담당자입니다.

알림톡은 수신자의 액션을 기반한 정보성 메시지에 한하여 발송 가능합니다.
허나 해당 메시지는 수신자가 요청하지 않은 내용으로 광고성 및 공지성 메시지에 해당함에 따라 알림톡 발송 불가합니다.

더 자세한 알림톡 검수 가이드는 딜러사를 통해 확인 부탁드립니다.
감사합니다.
`;

interface MessageCardItemProps extends AlgorithmMessage {
  algorithmName: NonNullable<AlgorithmWithMessagesInput['name']>;
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
  isLast: boolean;
}

export function MessageCardItem({ algorithmName, type, isLast, ...item }: MessageCardItemProps) {
  const {
    content,
    daysAfter,
    name,
    sendingType,
    sendingDateTime,
    id: messageId,
    isCommonAlgorithmMessage,
    hasApprovedAlimtalkTemplate,
    rejectedReasonForAlimtalkTemplate,
    canBeSentToFriendTalk,
  } = item;

  const displayedDay = useMemo(() => {
    if (type === 'MARKETING') {
      return dayjs(sendingDateTime).format('YYYY-MM-DD');
    }

    if (type === 'CONSULTATION') {
      return `D+${daysAfter ?? 0}`;
    }
  }, [daysAfter, sendingDateTime, type]);

  const headerClassName: `bg-${keyof typeof Color}` = useMemo(() => {
    if (isCommonAlgorithmMessage) {
      return 'bg-purple500';
    }

    if (sendingType === 'BASIC') {
      return 'bg-white900';
    }

    if (sendingType === 'REVISIT_INDUCEMENT') {
      return 'bg-blue500';
    }

    if (sendingType === 'SURVEY') {
      return 'bg-red500';
    }

    return 'bg-white900';
  }, [sendingType, isCommonAlgorithmMessage]);

  const contentBorderClassName = useMemo(() => {
    if (isCommonAlgorithmMessage) {
      return 'border-purple500';
    }

    if (sendingType === 'BASIC') {
      return 'border-white400';
    }

    if (sendingType === 'REVISIT_INDUCEMENT') {
      return 'border-blue500';
    }

    if (sendingType === 'SURVEY') {
      return 'border-red500';
    }
  }, [sendingType, isCommonAlgorithmMessage]);

  return (
    <div
      className='relative z-[1000] mx-20 h-fit w-[300px] select-text'
      onMouseDown={(e) => {
        e.stopPropagation();
      }}>
      {hasApprovedAlimtalkTemplate ? (
        <Icon name='kakaotalk' size={17} className='-top-30 absolute left-5' />
      ) : rejectedReasonForAlimtalkTemplate?.length ? (
        <div className='-top-30 absolute left-5'>
          <HoverTooltip
            position='topRight'
            text={<div className='whitespace-pre-wrap'>{KAKAO_GUIDE_MESSAGE}</div>}>
            <Icon name='info-circle-outline' color='black200' size={16} />
          </HoverTooltip>
        </div>
      ) : null}
      {type === 'MARKETING' && canBeSentToFriendTalk && (
        <Icon name='kakaotalk' size={17} className='-top-30 absolute left-5' />
      )}
      <div
        className={customTwMerge(
          'flex w-full justify-between gap-10 truncate rounded-r16 px-16 py-8',
          headerClassName,
        )}>
        <div className='text-Header14 text-white50'>{displayedDay}</div>
        <div className='truncate text-Body13 text-white50'>{name}</div>
      </div>
      <div
        className={customTwMerge(
          'mt-10 w-full rounded-r16 border bg-white50 p-20',
          contentBorderClassName,
        )}>
        {content && (
          <>
            {!!content.text && (
              <pre className='whitespace-pre-wrap break-all font-sans text-Body14 text-black500'>
                {content.text}
              </pre>
            )}
            {content.images && content.images.length > 0 && (
              <>
                {!!content.text && <Divider type='line' className='my-16 bg-white400' />}
                <ImageContent images={content.images} />
              </>
            )}
            {content.video && (
              <>
                {!!content.text && <Divider type='line' className='my-16 bg-white400' />}
                <VideoContent video={content.video} />
              </>
            )}
            {content.workoutVideo && (
              <>
                {!!content.text && <Divider type='line' className='my-16 bg-white400' />}
                <WorkoutVideoContent workoutVideo={content.workoutVideo} />
              </>
            )}
            {content.event && (
              <>
                {!!content.text && <Divider type='line' className='my-16 bg-white400' />}
                <EventContent event={content.event} />
              </>
            )}
            {content.homecareContent && (
              <>
                {!!content.text && <Divider type='line' className='my-16 bg-white400' />}
                <HomecareContent homecareContent={content.homecareContent} />
              </>
            )}
            {content.survey?.answers && content.survey?.answers?.length > 0 && (
              <>
                {!!content.text && <Divider type='line' className='my-16 bg-white400' />}
                <SurveyContent
                  algorithmName={algorithmName}
                  messageId={messageId}
                  messageName={name}
                  survey={content.survey}
                />
              </>
            )}
          </>
        )}
      </div>
      {!isLast && (
        <div className='-right-[40px] absolute top-[55px]'>
          <Icon name='arrow-line' size={40} />
        </div>
      )}
    </div>
  );
}
