import { useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { useDataEventBusHandler } from 'web/apis/push/data-push/hooks/use-data-event-bus-handler';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';

export const useUpdateChatroomOnePushHandler = () => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  if (!chatRoomID) return;

  const queryClient = useQueryClient();

  const handleNotification = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiChatroomElFindOne, { chatRoomID }],
    });
  }, [chatRoomID, queryClient.invalidateQueries]);

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_ChatroomUpdate_el'],
        handler: handleNotification,
      },
    ],
    [handleNotification],
  );

  useDataEventBusHandler(notificationConfigs);
};
