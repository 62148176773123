import MonthPicker from 'afterdoc-design-system/components/Atoms/Calendar/MonthPicker';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { Suspense, useRef, useState } from 'react';
import DashBoardUpdateTime from 'web/templates/Automation/containers/Main/DashBoardContent/components/DashBoardHeader/components/DashBoardUpdateTime';
import { selectedDateState } from 'web/templates/Automation/containers/Main/DashBoardContent/states/selected-date';

export default function DashBoardHeader() {
  const weekPickerIgnoreRef = useRef<HTMLDivElement>(null);

  const [isMonthPickerOpened, setIsMonthPickerOpened] = useState(false);
  const [selectedDate, setSelectedDate] = useAtom(selectedDateState);

  return (
    <div className='flex items-center justify-between gap-20 pt-20 pb-8'>
      <div className='flex flex-col gap-4'>
        <div className='flex items-center text-Header16 text-black700'>상담자동화 대시보드</div>
        <Suspense fallback={<div className='text-Body11 text-black200'>업데이트: -</div>}>
          <DashBoardUpdateTime />
        </Suspense>
      </div>
      <div className='flex items-center gap-12'>
        <div className='relative h-auto'>
          <div
            ref={weekPickerIgnoreRef}
            onClick={() => setIsMonthPickerOpened(!isMonthPickerOpened)}
            className='flex cursor-pointer gap-19 rounded-r16 bg-white50 py-4 pr-6 pl-10'>
            <div className='text-Header16 text-black500'>{`${dayjs(selectedDate).format('YYYY년 MM월')}`}</div>
            <div className='rounded-r10 bg-white200 px-2 py-3'>
              <Icon name='chevron-down' size={16} color='white800' />
            </div>
          </div>

          {isMonthPickerOpened && (
            <MonthPicker
              selectedDate={selectedDate}
              onChangeDate={setSelectedDate}
              handleToggle={() => setIsMonthPickerOpened(!isMonthPickerOpened)}
              ignoreRefs={[weekPickerIgnoreRef]}
              wrapperClassName='absolute top-25 w-[400px] -left-[150px]'
            />
          )}
        </div>
      </div>
    </div>
  );
}
