import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { isServiceManagerNavigatorVisibleState } from 'web/shared/states/is-service-manager-navigator-visible';
import CustomerDetailInfoContainer from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/CustomerDetailInfo.container';

export const useHandleCustomerInfo = (patientId: string) => {
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const openCustomerInfo = useCallback(
    (customClasses?: string) => {
      const positionLeft = isServiceManagerNavigatorVisible ? 140 : 70;
      const widthClass = isServiceManagerNavigatorVisible
        ? 'w-[calc(100vw-140px)]'
        : 'w-[calc(100vw-70px)]';

      overlayPageService.push(<CustomerDetailInfoContainer patientId={patientId} />, {
        id: OVERLAY_PAGE_ID['customer-detail-info'],
        position: {
          left: positionLeft,
          top: 0,
        },
        className: customTwMerge(
          'h-screen flex-col items-center justify-center bg-white50',
          widthClass,
          customClasses || '',
        ),
      });
    },
    [isServiceManagerNavigatorVisible, patientId],
  );

  const closeCustomerInfo = useCallback(() => {
    overlayPageService.popById(OVERLAY_PAGE_ID['customer-detail-info']);
  }, []);

  return {
    openCustomerInfo,
    closeCustomerInfo,
  };
};
