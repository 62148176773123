import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import type { ReplaceableText } from 'web/templates/Automation/containers/shared/functions/calculate-text';

interface ReplaceableTextListsProps {
  handleInsertReplaceText: (text: string, length: number) => void;
  replaceableTexts: ReplaceableText[];
  className?: string;
  disabled?: boolean;
}

export default function ReplaceableTextLists({
  handleInsertReplaceText,
  replaceableTexts,
  className,
  disabled,
}: ReplaceableTextListsProps) {
  return (
    <div className={customTwMerge('mt-20 flex items-center gap-10', className)}>
      <div className='text-Header12 text-black500'>치환문자</div>
      {replaceableTexts.map(({ text, description, length }) => (
        <HoverTooltip
          show={!disabled}
          text={description}
          position='bottomRight'
          key={text}
          portalZindex={1000}>
          <button
            disabled={disabled}
            className='rounded-r6 bg-white200 px-6 py-3 font-regular text-Body12 text-black500 disabled:cursor-not-allowed'
            type='button'
            onClick={() => {
              handleInsertReplaceText(text, length);
            }}>
            {text}
          </button>
        </HoverTooltip>
      ))}
    </div>
  );
}
