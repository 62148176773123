import type { ReservationDetail } from '@apis/swaggers/swagger-docs';
import { useCustomerReservation } from '@templates/CustomerChat/components/CustomerReservation/hooks/use-customer-reservation';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import AlwaysVisibleScrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/AlwaysVisibleScrollbar';
import { useCallback, useEffect, useState } from 'react';
import { useChatTopPatient } from '../../hooks/use-chat-top-patient';
import { CustomerReservationCell } from './CustomerReservationCell';
import { CustomerReservationEmpty } from './CustomerReservationEmpty';
import { handleOpenElectronReservation } from './functions/handle-open-electron-reservation';

export const CustomerReservation = () => {
  // console.count('CustomerReservation');
  const { isGrayAfterDocIcon, isColorAfterDocIcon } = useChatTopPatient();

  const {
    isFetching,
    isFetchingError,
    isAllDataFetched,
    reservations,
    chatRoomParentHServiceID,
    handleScrollToEnd,
  } = useCustomerReservation();

  const [isScrollbarVisible, setIsScrollbarVisible] = useState(true);
  const [isBottom, setIsBottom] = useState(false);

  const handleOverflowStateChange = useCallback((isOverflowY: boolean, _: boolean) => {
    setIsScrollbarVisible(isOverflowY);
  }, []);

  const handleScrollEndPosition = useCallback(
    (isAtBottom: boolean) => {
      setIsBottom(isAtBottom);
      if (isAtBottom) {
        handleScrollToEnd();
      }
    },
    [handleScrollToEnd],
  );

  const handleClick = useCallback(() => {
    const isDisabled = !isColorAfterDocIcon && !isGrayAfterDocIcon;
    handleOpenElectronReservation({ isDisabled, chatRoomParentHServiceID });
  }, [isColorAfterDocIcon, isGrayAfterDocIcon, chatRoomParentHServiceID]);

  const renderReservations = reservations;

  const isShowBottomGradient =
    isScrollbarVisible && !isFetching && !isFetchingError && !(isBottom && isAllDataFetched);

  useEffect(() => {
    setIsBottom(false);
  }, [chatRoomParentHServiceID, setIsBottom]);

  return (
    <>
      <div className='p-20 pb-8'>
        <LabelText textClassName='text-Header16 text-black700 ml-4'>예약내역</LabelText>
      </div>
      <div className='mx-16 mb-16'>
        <OutlinedButton
          onClick={handleClick}
          className='mb-10 w-full justify-center'
          btnColor='blue'
          iconProps={{ name: 'add-circle', color: 'blue500', size: 20 }}>
          예약등록
        </OutlinedButton>
        <div className='relative flex h-[300px] flex-col'>
          {renderReservations.length !== 0 && (
            <>
              <AlwaysVisibleScrollbar
                className='justify-between'
                onOverflowStateChange={handleOverflowStateChange}
                onScrollToEnd={handleScrollEndPosition}>
                <div
                  className={`flex flex-grow flex-col gap-10 ${isScrollbarVisible ? 'mr-26' : ''}`}>
                  {renderReservations.map((reservation) => {
                    const key = (reservation as ReservationDetail)._id;
                    return (
                      <CustomerReservationCell
                        key={key}
                        reservation={reservation as ReservationDetail}
                      />
                    );
                  })}
                </div>
              </AlwaysVisibleScrollbar>
              {isShowBottomGradient && (
                <div
                  className='pointer-events-none absolute inset-x-0 bottom-0 mr-26 h-[48px]'
                  style={{
                    background:
                      'linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0))',
                  }}
                />
              )}
            </>
          )}
          {!isFetching && (isFetchingError || renderReservations.length === 0) && (
            <CustomerReservationEmpty isFetchingError={isFetchingError} />
          )}
          {isFetching && (
            <div className='absolute inset-10 flex-center bg-white50 opacity-40'>
              <BaseLoading />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
