import UserInfoSettingContainer from '@templates/UserInfoSetting/UserInfoSetting.container';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';

export default function UserInfoSettingPage() {
  return (
    <Scrollbar isAlwaysVisible>
      <UserInfoSettingContainer />
    </Scrollbar>
  );
}
