import NotificationSettingItem from '@templates/UserInfoSetting/containers/NotificationSetting/components/NotificationSettingItem';
import NotificationTimeRangeControl from '@templates/UserInfoSetting/containers/NotificationSetting/components/NotificationTimeRangeControl/NotificationTimeRangeControl';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';

export default function AdminChatNotification() {
  return (
    <div>
      <Title title={'직원채팅'} />
      <div className='mx-20 mt-10 flex flex-col gap-10'>
        <NotificationSettingItem
          fieldKey={'onManagerChatsAll'}
          label={'내가 참여한 직원채팅방의 신규 메시지 알림을 받습니다.'}>
          <NotificationTimeRangeControl
            fieldKey={'onManagerChatsDependsTime'}
            startTimeKey={'managerChatStartedAt'}
            endTimeKey={'managerChatEndedAt'}
          />
        </NotificationSettingItem>
      </div>
    </div>
  );
}
