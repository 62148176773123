import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect } from 'react';
import type { useVirtualScroll } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-virtual-scroll';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

export type TableCellElement = HTMLInputElement | HTMLDivElement;
export type TableCellRefs = Array<Array<TableCellElement | null>>;

type UseUnTaggedTablecellInteractionProps = {
  maxRows: number;
};

function useUnTaggedTablecellInteraction(
  { maxRows }: UseUnTaggedTablecellInteractionProps,
  _virtualScroll?: ReturnType<typeof useVirtualScroll>,
) {
  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  const [selectedCell, setSelectedCell] = useAtom(selectedCellState);
  const [focusedCell, setFocusedCell] = useAtom(focusedCellState);

  const handleChangeSelectedCell = useCallback(
    (rowIndex: number, colIndex: number) => {
      setSelectedCell({ row: rowIndex, col: colIndex });
    },
    [setSelectedCell],
  );

  const handleChangeFocusedCell = useCallback(
    (rowIndex: number | null, colIndex: number | null) => {
      setFocusedCell({ row: rowIndex, col: colIndex });
    },
    [setFocusedCell],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (
        focusedCell.row === null &&
        focusedCell.col === null &&
        selectedCell.row === null &&
        selectedCell.col === null
      ) {
        return;
      }

      // Focused 상태일 때
      if (focusedCell.row !== null && focusedCell.col !== null) {
        if (e.key === 'Enter') {
          setFocusedCell(selectedCell);
        } else if (e.key === 'Escape') {
          setFocusedCell({ row: null, col: null });
        } else if (e.key === 'Tab' || e.key === 'ArrowRight') {
          e.preventDefault();
          setSelectedCell((prev) => {
            let nextCol = prev.col + 1;
            let nextRow = prev.row;

            if (nextCol > 7) {
              nextCol = 6;
              nextRow += 1;
              if (nextRow >= maxRows) {
                nextRow = 0; // 마지막 row에서 다시 첫 번째 row로 이동
              }
            }

            setFocusedCell({ row: nextRow, col: nextCol });
            return { row: nextRow, col: nextCol };
          });
        } else if ((e.key === 'Tab' && e.shiftKey) || e.key === 'ArrowLeft') {
          e.preventDefault();
          setSelectedCell((prev) => {
            let nextCol = prev.col - 1;
            let nextRow = prev.row;

            if (nextCol < 6) {
              nextCol = 7;
              nextRow -= 1;
              if (nextRow < 0) {
                nextRow = maxRows - 1; // 첫 번째 row에서 마지막 row로 이동
              }
            }

            setFocusedCell({ row: nextRow, col: nextCol });
            return { row: nextRow, col: nextCol };
          });
        }
      }

      // Selected 상태일 때
      if (
        selectedCell.row !== null &&
        selectedCell.col !== null &&
        focusedCell.row === null &&
        focusedCell.col === null
      ) {
        if (e.key === 'Backspace') {
          e.preventDefault();
        } else if (e.key === 'ArrowUp') {
          setSelectedCell((prev) => {
            const newRow = Math.max(0, prev.row - 1);
            setFocusedCell({ row: null, col: null });
            return { row: newRow, col: prev.col };
          });
        } else if (e.key === 'ArrowDown') {
          setSelectedCell((prev) => {
            const newRow = Math.min(maxRows - 1, prev.row + 1);
            setFocusedCell({ row: null, col: null });
            return { row: newRow, col: prev.col };
          });
        } else if (e.key === 'ArrowLeft' || (e.key === 'Tab' && e.shiftKey)) {
          if (selectedCell.col === 6 || selectedCell.col === 7) {
            e.preventDefault();
            setSelectedCell((prev) => {
              let nextCol = prev.col - 1;
              let nextRow = prev.row;

              if (nextCol < 6) {
                nextCol = 7;
                nextRow -= 1;
                if (nextRow < 0) {
                  nextRow = maxRows - 1; // 첫 번째 row에서 마지막 row로 이동
                }
              }

              setFocusedCell({ row: null, col: null });
              return { row: nextRow, col: nextCol };
            });
          }
        } else if (e.key === 'ArrowRight' || e.key === 'Tab') {
          if (selectedCell.col === 6 || selectedCell.col === 7) {
            e.preventDefault();
            setSelectedCell((prev) => {
              let nextCol = prev.col + 1;
              let nextRow = prev.row;

              if (nextCol > 7) {
                nextCol = 6;
                nextRow += 1;
                if (nextRow >= maxRows) {
                  nextRow = 0; // 마지막 row에서 첫 번째 row로 이동
                }
              }

              setFocusedCell({ row: null, col: null });
              return { row: nextRow, col: nextCol };
            });
          }
        } else if (e.key === 'Enter') {
          setFocusedCell(selectedCell);
          e.preventDefault();
        }
      }
    },
    [focusedCell, selectedCell, setFocusedCell, setSelectedCell, maxRows],
  );

  const handleCellClick = useCallback(
    (rowIndex: number, colIndex: number) => {
      if (colIndex === 6 || colIndex === 7) {
        setSelectedCell({ row: rowIndex, col: colIndex });
        setFocusedCell({ row: rowIndex, col: colIndex });
      }
    },
    [setSelectedCell, setFocusedCell],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    return () => {
      resetSelectedCell();
      resetFocusedCell();
    };
  }, [resetSelectedCell, resetFocusedCell]);

  return {
    selectedCell,
    focusedCell,
    handleCellClick,
    handleChangeSelectedCell,
    handleChangeFocusedCell,
    resetSelectedCell,
    resetFocusedCell,
  };
}

export default useUnTaggedTablecellInteraction;
