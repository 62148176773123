import { Color } from '@tailwind-base/styles/color';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { useAtomValue } from 'jotai';
import { isActivatingChatTranslateState } from 'web/templates/CustomerChat/components/ChattingRoom/states/is-activating-chat-translate';
import { useChatMessageContext } from '../../../../ChatMessageContext';
import ContentText from '../../../components/ContentText';
import LeftChatReTranslateError from './components/LeftChatReTranslateError';

type TranslatedMessageBundleProps = {
  first: string[];
  second: string[];
  isTruncate?: boolean;
  restartTimer: () => void;
  isLeftError: boolean;
  errorMessage: string;
};

export default function TranslatedMessageBundle({
  first,
  second,
  isTruncate = true,
  restartTimer,
  isLeftError,
  errorMessage,
}: TranslatedMessageBundleProps) {
  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);
  const { isTranslated, status } = useChatMessageContext();

  const isBlockTranslateAboutSendingContentsInKakaoLink =
    isActivatingChatTranslate && isTranslated === true && status === 'noTranslation';
  return (
    <div className='flex flex-col justify-start gap-10'>
      <ContentText parts={first} isTruncate={isTruncate} />
      {isBlockTranslateAboutSendingContentsInKakaoLink ? null : (
        <>
          <Divider type='line' />{' '}
          <div className='flex flex-col gap-6'>
            <FilledTag
              className='rounded-r10 px-8 py-3'
              textColor={isLeftError ? Color.red500 : Color.blue500}
              bgColor={isLeftError ? Color.red50 : Color.blue50}>
              {isLeftError ? '번역 실패' : '번역'}
            </FilledTag>
            {isLeftError ? (
              <>
                <ContentText
                  textColor={Color.red500}
                  parts={[errorMessage as string]}
                  isTruncate={isTruncate}
                />
                <LeftChatReTranslateError restartTimer={restartTimer} />
              </>
            ) : (
              <ContentText textColor={Color.blue500} parts={second} isTruncate={isTruncate} />
            )}
          </div>
        </>
      )}
    </div>
  );
}
