import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { memo } from 'react';

export const CustomerReservationChatRoomNull = memo(() => {
  return (
    <>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className='flex h-full w-full flex-col items-center justify-center'
        title='고객을 선택해 주세요.'
        subTitle='고객의 정보에 대해 확인할 수 있습니다.'
      />
    </>
  );
});

CustomerReservationChatRoomNull.displayName = 'CustomerReservationChatRoomNull';
