import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import ChattingContainer from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/ChattingContainer';
import ChattingParticipants from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingParticipants/ChattingParticipants';
import ProgressContent from 'web/templates/CustomerChat/components/ChattingRoom/components/ProgressContent/ProgressContent';
import { MIN_LAYOUT_WIDTH } from 'web/templates/CustomerChat/constants/layout';
import { useResetCustomerChatAtomState } from '../../hooks/use-reset-customer-chat-atom-state';
import { chatRoomIDSelector } from '../../states/selected-chat-room';
import ChatRoomIDNull from './components/ChatRoomIDNull/ChatRoomIDNull';
import ChattingSending from './components/ChattingSending/ChattingSending';
import CustomerInfoTop from './components/CustomerInfoTop/CustomerInfoTop';
import ParticipantsRequest from './components/ParticipantsRequest/ParticipantsRequest';
import useChatRoomResizeObserver from './hooks/use-chat-room-resize-observer';

export default function ChattingRoom({ isManualOpen }: { isManualOpen: boolean }) {
  // console.count('ChattingRoom');
  const divRef = useRef<HTMLDivElement>(null);

  const chatRoomID = useAtomValue(chatRoomIDSelector);

  const [isLoading, setIsLoading] = useState(false);

  useChatRoomResizeObserver(divRef, isManualOpen);

  const { resetChattingProgressStateAll } = useResetCustomerChatAtomState();

  //고객입력에 갔다가 상담하기로 올 경우 최고 상단에서 해당 로직을 선언하기 때문에undefined를 한번 찍고 chatRoomID가 적용됨
  //null일 경우는 ChattingRoom 컴포넌트에서 초기화의 개념으로 reset과 함께 사용
  useEffect(() => {
    if (chatRoomID === null) {
      window.electron?.ipcRenderer.send('Application.Set.selectedChatRoomID', {
        value: undefined,
      });
    }
    return () => {
      resetChattingProgressStateAll();
    };
  }, [chatRoomID, resetChattingProgressStateAll]);

  if (chatRoomID === null) return <ChatRoomIDNull />;

  return (
    <div
      className='relative flex h-full w-full flex-col'
      ref={divRef}
      style={{ minWidth: `${MIN_LAYOUT_WIDTH}px` }}>
      <CustomerInfoTop chatRoomID={chatRoomID} />

      <div
        className='relative flex h-full w-full flex-col space-y-6 overflow-y-auto border-white400 border-y bg-blueLight'
        style={{
          minWidth: `${MIN_LAYOUT_WIDTH}px`,
        }}>
        <ParticipantsRequest />

        <ChattingContainer chatRoomID={chatRoomID} onLoading={setIsLoading} />
        <ChattingParticipants />

        <ProgressContent />
      </div>

      <ChattingSending />
      {isLoading && (
        <div className='absolute inset-0 flex-center bg-white50 opacity-40'>
          <BaseLoading />
        </div>
      )}
    </div>
  );
}
