import axios from 'axios';
import { KAKAO_INTEGRATION_API_URL } from 'web/config/url';

const kakaoIntegrationAxiosInstance = axios.create({
  timeout: 30000,
  baseURL: KAKAO_INTEGRATION_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

kakaoIntegrationAxiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');

    if (token) {
      config.headers.Authorization = JSON.parse(token);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { kakaoIntegrationAxiosInstance };
