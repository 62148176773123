import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useLottieAnimation } from '../hooks/use-lottie-animation';

type RenderingDownloadIconProps = {
  isComplete: boolean;
  percentage: number;
  isExpired: boolean;
};

export const RenderingDownloadIcon = ({
  isComplete,
  percentage,
  isExpired,
}: RenderingDownloadIconProps) => {
  if (isExpired) return <Icon name='image-off-outline' />;

  const lottieRef = useLottieAnimation({ LottieName: 'dotsSpinner' });
  if (isComplete) {
    return <Icon name='done' />;
  }
  if (percentage === 0) {
    return <Icon name='tray-download' />;
  }
  return <div ref={lottieRef} />;
};
