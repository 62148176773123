import * as React from "react";
import type { SVGProps } from "react";
const SvgManualActivated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#333"
      d="M4 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2z"
    />
    <path fill="#fff" d="M8 2h5v9l-2.5-1L8 11z" />
  </svg>
);
export default SvgManualActivated;
