import * as React from "react";
import type { SVGProps } from "react";
const SvgFormula = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M11.759 2.482a2.56 2.56 0 0 0-3.53.607A7.66 7.66 0 0 0 6.8 6.2c-.691 2.988-1.525 8.477-2.65 8.727a1.55 1.55 0 0 0-1.3-.933.92.92 0 0 0-.85 1.042S1.954 16 4.119 16s3.091-2.691 3.7-5.553c.177-.826.36-1.726.554-2.6L8.775 6.2c.381-1.421.807-2.521 1.306-2.676a1.01 1.01 0 0 0 1.02.56.966.966 0 0 0 .658-1.602"
      className="formula_svg__ql-fill"
    />
    <rect
      width={5}
      height={1.6}
      x={5.15}
      y={6.2}
      className="formula_svg__ql-fill"
      rx={0.8}
      ry={0.8}
    />
    <path
      d="M13.663 12.027a1.7 1.7 0 0 1 .266-.276q.193.069.456.138a2 2 0 0 0 .535.069 1.08 1.08 0 0 0 .767-.3 1.04 1.04 0 0 0 .314-.8.84.84 0 0 0-.238-.619.8.8 0 0 0-.594-.239 1.15 1.15 0 0 0-.781.3 4.6 4.6 0 0 0-.781 1q-.091.15-.218.346l-.246.38q-.1-.431-.212-.885c-.459-1.847-2.494-.984-2.941-.8-.482.2-.353.647-.094.529a.87.87 0 0 1 1.281.585c.217.751.377 1.436.527 2.038a6 6 0 0 1-.362.467 3 3 0 0 1-.264.271q-.221-.08-.471-.147a2 2 0 0 0-.522-.066 1.08 1.08 0 0 0-.768.3 1.06 1.06 0 0 0-.317.813.82.82 0 0 0 .832.852 1.13 1.13 0 0 0 .787-.3 5 5 0 0 0 .776-.993q.141-.219.215-.34.07-.116.223-.346a2.8 2.8 0 0 0 .918 1.726 2.58 2.58 0 0 0 2.376-.185c.317-.181.212-.565 0-.494a.81.81 0 0 1-.951-.051 5.16 5.16 0 0 1-.913-2.446q.224-.314.4-.527"
      className="formula_svg__ql-fill"
    />
  </svg>
);
export default SvgFormula;
