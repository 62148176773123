import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import CustomerChatContainer from 'web/templates/CustomerChat/CustomerChat.container';

export default function CustomerChatPage() {
  return (
    <Scrollbar isAlwaysVisible>
      <CustomerChatContainer />
    </Scrollbar>
  );
}
