import * as React from "react";
import type { SVGProps } from "react";
const SvgDirectionLtr = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="m3 11 2-2-2-2zM15 4h-4"
      className="direction-ltr_svg__ql-stroke direction-ltr_svg__ql-fill"
    />
    <path d="M11 3a3 3 0 0 0 0 6h1V3z" className="direction-ltr_svg__ql-fill" />
    <path
      d="M11 4h1v11h-1zM13 4h1v11h-1z"
      className="direction-ltr_svg__ql-fill"
    />
  </svg>
);
export default SvgDirectionLtr;
