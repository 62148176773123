import { OPTION_DETAIL, OPTION_RESERVATION_ENROLL } from '../constants/dropdown';

type FilterFunction<T = string> = (data: T[], condition: boolean) => T[];

export const onlyKakaoFilterOptions = (data: string[], isOnlyKakao: boolean): string[] => {
  return isOnlyKakao ? data.filter((item) => item !== OPTION_DETAIL) : data;
};
export const noUseReservationFilterOptions = (
  data: string[],
  isNoUseReservation: boolean,
): string[] => {
  return isNoUseReservation ? data.filter((item) => item !== OPTION_RESERVATION_ENROLL) : data;
};

export const applyFilters =
  <T>(filters: Array<[FilterFunction<T>, boolean]>) =>
  (data: T[]): T[] => {
    return filters.reduce((filteredData, [filterFn, condition]) => {
      return filterFn(filteredData, condition);
    }, data);
  };
