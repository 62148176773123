import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from 'afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useFormContext } from 'react-hook-form';
import type { CommonAlgorithmInput } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useResetAll } from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/hooks/use-reset-all';
import { useUnsavedChangesWarning } from 'web/templates/Automation/containers/shared/hooks/use-unsaved-changes-warning';

export default function EditCommonAutomationDialogHeader() {
  const {
    formState: { isDirty, defaultValues },
    getValues,
    reset,
    watch,
  } = useFormContext<CommonAlgorithmInput>();

  const { resetAll } = useResetAll({
    reset,
  });

  const handleConfirmLeave = () => {
    modalService.pop();
    overlayPageService.popById(OVERLAY_PAGE_ID['edit-common-algorithm-dialog']);
  };

  const handleCancelLeave = () => {
    modalService.popById('unsaved-changes-warning');
  };

  const { showWarning } = useUnsavedChangesWarning<CommonAlgorithmInput>({
    formMethods: {
      isDirty,
      defaultValues: defaultValues as CommonAlgorithmInput,
      getValues,
      watch,
    },
    onReset: resetAll,
    onConfirm: handleConfirmLeave,
    onCancel: handleCancelLeave,
  });

  return (
    <Title
      title='공통 재내원 안내'
      subTitle='상담자동화가 적용된 고객에게 발송되는 재내원 안내 메시지입니다. 자동화별 발송시점을 설정할 수 있습니다.'
      trailingChildren={
        <IconButton icon='close' size={24} color='black200' onClick={showWarning} />
      }
      wrapperClassName='px-60'
    />
  );
}
