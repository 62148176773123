import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

export default function SelectedCheckboxInImg({ isSelected }: { isSelected: boolean }) {
  return (
    <div className='absolute top-2 left-2 size-24'>
      {isSelected ? (
        <Icon name='checkbox-marked' size={24} color='blue500' />
      ) : (
        <Icon name='checkbox-blank-outline' size={24} color='white600' />
      )}
    </div>
  );
}
