import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { isActivatingChatTranslateState } from 'web/templates/CustomerChat/components/ChattingRoom/states/is-activating-chat-translate';
import { ROOM_TYPE_MAP } from 'web/templates/CustomerChat/constants/ROOM_TYPE_MAP';

export const useHandleErrorInMssage = ({
  error,
  handleRemoveMySelf,
}: { error: Record<string, unknown> | undefined; handleRemoveMySelf: () => void }) => {
  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);

  const isNull = !isActivatingChatTranslate && error;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (error) {
      if (isNull && error.type === ROOM_TYPE_MAP.kakao && error.code === -410) {
        handleRemoveMySelf();
      }
    }
  }, [error]);

  return { isNull };
};
