import { useCheckIsOverflow } from '@shared-hooks/use-check-is-overflow';
import ParentDropdown from '@templates/CustomerChat/components/ParentDropDown/ParentDropdown';
import { useDropdown } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown';
import CursorTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/CursorTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtomValue } from 'jotai';
import { useRef } from 'react';
import type { DropdownDataParams } from 'web/templates/CustomerChat/components/ParentDropDown/hooks/use-parent-dropdown-options';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import { chattingWidthAtom } from 'web/templates/CustomerChat/states/chattingWidth';
import { makePatientInfoStringDisplay } from '../../functions/makePatientInfoStringDisplay';

export default function PatientTitle() {
  const {
    isFirstVisit,
    name,
    chartNumber,
    isKakaoIcon,
    isColorAfterDocIcon,
    isGrayAfterDocIcon,
    iconSet,
  } = useChatTopPatient();

  const isFirstVisitDisplay = makePatientInfoStringDisplay({
    type: 'isFirstVisit',
    payload: {
      isFirstVisit,
    },
  });
  const chartNumberDisplay = makePatientInfoStringDisplay({
    type: 'chartNumber',
    payload: {
      chartNumber,
    },
  });

  const isNoVisitInfo = isFirstVisit !== undefined && isFirstVisit !== null;
  const isVisitStatus = isKakaoIcon && !isColorAfterDocIcon && !isGrayAfterDocIcon;

  return (
    <div className='mt-5 flex items-center justify-between'>
      <div className='flex items-center'>
        {!isVisitStatus && isNoVisitInfo ? (
          <div className='mr-10 h-20 w-28 rounded-[5px] bg-blueLight pt-1 text-center text-Header12 text-blue500'>
            {isFirstVisitDisplay}
          </div>
        ) : null}
        <div className='relative flex h-22 min-w-0 flex-center gap-4'>
          <NameTextWithCursor name={name} iconSet={iconSet} />
          <ChartNumberTextWithCursor
            chartNumber={chartNumberDisplay}
            isVisitStatus={isVisitStatus}
          />
        </div>
        <div className='ml-6 flex flex-center gap-6'>
          {isColorAfterDocIcon && <Icon name='afterdoc' />}
          {isGrayAfterDocIcon && <Icon name='afterdoc-non' />}
          {isKakaoIcon && <Icon name='kakaotalk' />}
        </div>
      </div>
    </div>
  );
}

interface NameTextWithCursorProps {
  name?: string;
  iconSet: DropdownDataParams;
}

function NameTextWithCursor({ name, iconSet }: NameTextWithCursorProps) {
  const { isDropdownOpen, handleDropdown } = useDropdown();

  const ref = useRef<HTMLDivElement>(null);
  const { isOverflow } = useCheckIsOverflow(ref);

  const chattingWidth = useAtomValue(chattingWidthAtom);

  return (
    <CursorTooltip
      text={name}
      show={isOverflow}
      wrapperProps={{
        className: 'truncate',
        style: {
          maxWidth: `${chattingWidth / 1.5}px`,
        },
      }}>
      <div
        ref={ref}
        className='cursor-pointer truncate text-Header16 text-black700'
        onClick={handleDropdown}>
        {name}
      </div>
      {isDropdownOpen && (
        <ParentDropdown
          dropdownDataParams={iconSet}
          handleToggle={handleDropdown}
          style={{
            position: 'fixed',
          }}
        />
      )}
    </CursorTooltip>
  );
}

interface ChartNumberTextWithCursorProps {
  chartNumber?: string;
  isVisitStatus: boolean;
}

function ChartNumberTextWithCursor({ chartNumber, isVisitStatus }: ChartNumberTextWithCursorProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { isOverflow } = useCheckIsOverflow(ref);

  const chattingWidth = useAtomValue(chattingWidthAtom);

  return (
    <CursorTooltip
      text={chartNumber}
      show={isOverflow}
      wrapperProps={{
        className: 'flex',
        style: {
          maxWidth: `${chattingWidth / 3}px`,
        },
      }}>
      {!isVisitStatus && (
        <div className='flex text-Body10 text-black700'>
          <div ref={ref} className='mx-[1px] truncate'>
            {chartNumber}
          </div>
        </div>
      )}
    </CursorTooltip>
  );
}
