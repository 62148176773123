import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';

interface NextButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export default function NextButton({ disabled, onClick, className }: NextButtonProps) {
  return (
    <div className={customTwMerge('w-full', className)}>
      <ContainedButton className='w-full' disabled={disabled} onClick={onClick}>
        다음
      </ContainedButton>
    </div>
  );
}
