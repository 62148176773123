import { useCheckIsOverflow } from '@shared-hooks/use-check-is-overflow';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import CursorTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/CursorTooltip';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect, useRef } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElDetailData,
  ApiV2ReservationElFindParams,
  ReservationDetail,
  UserNameAndType,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import ChangedReservationsList from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ChangedReservationsList/ChangedReservationsList';
import { RESERVATION_HISTORY_TABLE_HEADERS } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/constants/reservation-history-table-headers';
import { RESERVATION_HISTORY_TABLE_LIMIT } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/constants/reservation-history-table-limit';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';

const RESERVATION_HISTORY_TABLE_STYLE = {
  paddingStyle: 'px-12 py-10',
  borderStyle: 'border-line-table border-b',
};

const fetchReservationHistoryList = async (params: ApiV2ReservationElFindParams) => {
  const response = await apiClient.v2.apiV2ReservationElFind(params);
  return SHARED_UTILS.api.checkApiResponse<ReservationDetail[]>(response.data);
};

type ModifierIDType = {
  _id: string;
  name?: string;
  realName?: string;
  type?: number;
  jobType?: string;
};

interface ReservationHistoryTableBodyProps {
  reservationSelectedDate: {
    startedAt: Date;
    endedAt: Date;
  } | null;
  currentPage: number;
}

export default function ReservationHistoryTableBody({
  reservationSelectedDate,
  currentPage,
}: ReservationHistoryTableBodyProps) {
  const queryClient = useQueryClient();

  const skip = (currentPage - 1) * RESERVATION_HISTORY_TABLE_LIMIT;
  const { hospitalID } = useSelectedHospitalInfo();
  const patientId = useAtomValue(patientIdState);
  const { patientId: patientIdFromQuery } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const params = {
    hospitalID,
    hserviceID: patientIdFromQuery,
    limit: RESERVATION_HISTORY_TABLE_LIMIT,
    skip,
    key: 'startedAt',
    way: -1,
    startedAt: reservationSelectedDate?.startedAt,
    endedAt: reservationSelectedDate?.endedAt,
    isRemoved: false,
  };

  const { data: reservationList, refetch } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiV2ReservationElFind, params] as const,
    queryFn: ({ queryKey }) => fetchReservationHistoryList(queryKey[1]),
  });

  useEffect(() => {
    window.electron?.ipcRenderer.on('Application.doneUpdateReservation', (_, payload) => {
      if (payload) {
        refetch();

        queryClient.refetchQueries({
          queryKey: [
            QUERY_KEY.apiV2ReservationElCount,
            {
              hospitalID,
              hserviceID: patientIdFromQuery,
              startedAt: reservationSelectedDate?.startedAt,
              endedAt: reservationSelectedDate?.endedAt,
              isRemoved: false,
            },
          ],
        });
      }
    });

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.doneUpdateReservation');
    };
  }, []);

  const getWriterName = (modifierID?: ModifierIDType, writerID?: UserNameAndType) => {
    const getName = (id?: { type?: number | string; realName?: string }): string | undefined => {
      if (!id) return undefined;
      return id.type === 1000 || id.type === '1000' ? '고객' : id.realName;
    };

    return getName(modifierID) || getName(writerID) || '-';
  };

  if (!reservationList || reservationList.length === 0) {
    return (
      <tr>
        <td colSpan={RESERVATION_HISTORY_TABLE_HEADERS.length}>
          <div className='flex h-[230px] w-full items-center justify-center'>
            <NoData
              iconProps={{
                name: 'warning',
                size: 48,
                color: 'white400',
              }}
              title='표시할 내용이 없습니다.'
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      {reservationList.map(
        (
          data: {
            modifierID?: ModifierIDType;
          } & ReservationDetail,
        ) => {
          const {
            groupTitle,
            columnTitle,
            programTitle,
            note,
            writerID,
            modifierID,
            createdAt,
            startedAt,
            isCanceled,
            _id: reservationId,
          } = data;

          const ReservationHistoryRowData = {
            // 예약일시
            reservationDate: dayjs(startedAt).format('YYYY-MM-DD HH:mm') ?? '-',
            // 부서
            department: groupTitle ?? '-',
            // 담당자
            columnTitle: columnTitle ?? '-',
            // 프로그램
            programName: programTitle ?? '-',
            // 예약메모
            note: note ?? '-',
            // 최종수정자
            writerName: getWriterName(modifierID, writerID),
          };

          return (
            <tr key={reservationId} className={`${isCanceled ? 'text-red500' : ''}`}>
              {Object.entries(ReservationHistoryRowData).map(([key, value]) => (
                <ReservationHistoryTableBodyRow key={key} content={value} />
              ))}

              <td className='truncate border-line-table border-b px-12 py-10'>
                <ContainedButton
                  buttonSize='small'
                  btnColor='secondary'
                  type='button'
                  onClick={() => {
                    dialogService.push(
                      <Suspense>
                        <ChangedReservationsList reservationId={reservationId} />
                      </Suspense>,
                      {
                        titleProps: {
                          title: '변경 내역',
                        },
                        hasCloseButton: true,
                        wrapperClassName: 'h-[398px]',
                        width: 1000,
                      },
                    );
                  }}
                  disabled={!createdAt}>
                  보기
                </ContainedButton>
              </td>
            </tr>
          );
        },
      )}
    </>
  );
}

const ReservationHistoryTableBodyRow = ({ content }: { content?: string | JSX.Element }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { isOverflow } = useCheckIsOverflow(ref);

  return (
    <td
      className={customTwMerge(
        RESERVATION_HISTORY_TABLE_STYLE.paddingStyle,
        RESERVATION_HISTORY_TABLE_STYLE.borderStyle,
      )}>
      <div className='flex'>
        <CursorTooltip
          text={content}
          show={isOverflow}
          wrapperProps={{
            className: 'truncate',
            style: {
              cursor: 'default',
            },
          }}>
          <div className='truncate' ref={ref}>
            {content}
          </div>
        </CursorTooltip>
      </div>
    </td>
  );
};
