import Block from '../blots/block';

class DividerBlot extends Block {
  static blotName = 'divider';
  static tagName = 'HR';
  static className = 'ql-divider';

  static create() {
    return document.createElement('hr');
  }

  html() {
    return '<hr>';
  }
}

export default DividerBlot;
