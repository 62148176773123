import FullLoading from '@shared/components/FullLoading/FullLoading';
import NotificationSettingSaveFooter from '@templates/UserInfoSetting/components/NotificationSettingSaveFooter/NotificationSettingSaveFooter';
import AccountSettingContainer from '@templates/UserInfoSetting/containers/AccountSetting/AccountSetting.container';
import NotificationSettingContainer from '@templates/UserInfoSetting/containers/NotificationSetting/NotificationSetting.container';
import { userInfoPageState } from '@templates/UserInfoSetting/states/user-info-page-state';
import type { UserInfoPageType } from '@templates/UserInfoSetting/types/user-info-page-type';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtomValue } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import type React from 'react';
import { Suspense, useEffect } from 'react';

const RenderPageComponent = (currentPage: UserInfoPageType): React.ComponentType => {
  switch (currentPage) {
    case 'USER_INFO_SETTING': {
      return AccountSettingContainer;
    }
    case 'NOTIFICATION_SETTING': {
      return NotificationSettingContainer;
    }
  }
};

export default function UserInfoSettingsPageWrapper() {
  const resetUserInfoPage = useResetAtom(userInfoPageState);

  const currentPage = useAtomValue(userInfoPageState);

  const ComponentToRender = RenderPageComponent(currentPage);

  useEffect(() => {
    return () => resetUserInfoPage();
  }, []);

  return (
    <div className={`${currentPage === 'NOTIFICATION_SETTING' ? 'mb-70' : ''} bg-blueLight`}>
      <Suspense fallback={<FullLoading />}>
        <Scrollbar disabledX={true}>
          <div className='mx-auto h-full w-[1000px] bg-white50'>
            <ComponentToRender />
          </div>
        </Scrollbar>
      </Suspense>
      {currentPage === 'NOTIFICATION_SETTING' && <NotificationSettingSaveFooter />}
    </div>
  );
}
