import { S3Client } from '@aws-sdk/client-s3';

const AWS_FILE_BUCKET_NAME = import.meta.env.VITE_AWS_FILE_BUCKET_NAME as string;
const AWS_FILE_REGION = import.meta.env.VITE_AWS_FILE_REGION as string;
const AWS_FILE_ACCESS_KEY_ID = import.meta.env.VITE_AWS_FILE_ACCESS_KEY_ID as string;
const AWS_FILE_SECRET_ACCESS_KEY = import.meta.env.VITE_AWS_FILE_SECRET_ACCESS_KEY as string;

const s3 = new S3Client({
  region: AWS_FILE_REGION,
  credentials: {
    accessKeyId: AWS_FILE_ACCESS_KEY_ID,
    secretAccessKey: AWS_FILE_SECRET_ACCESS_KEY,
  },
});

export { AWS_FILE_BUCKET_NAME, AWS_FILE_REGION, s3 };
