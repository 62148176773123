import type {
  FilePaths,
  VideoContentExerciseSetFilePaths,
  VideoContentHospitalThumbnails,
} from '@apis/swaggers/swagger-docs';
import ExerciseSetInfo from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentDetail/components/ExerciseSetInfo';
import StimulateMusclesInfo from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentDetail/components/StimulateMusclesInfo';
import VideoContentDetailThumbnail from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentDetail/components/VideoContentDetailThumbnail';
import VideoContentDetailTitle from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentDetail/components/VideoContentDetailTitle';
import VideoContentSection from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentDetail/components/VideoContentSection';
import { CONTENT_SECTIONS } from '@templates/Content/containers/ContentManager/containers/VideoContent/componants/VideoContentDetail/constants/content-sections';
import { selectedVideoContentState } from '@templates/Content/containers/ContentManager/containers/VideoContent/states/selected-video-content-state';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';

const getChildrenContent = (
  content: VideoContentHospitalThumbnails,
  fileKey: keyof VideoContentHospitalThumbnails | undefined,
) => {
  if (!fileKey || !content[fileKey]) {
    return null;
  }

  switch (fileKey) {
    case 'exerciseSet':
      return (
        <ExerciseSetInfo exerciseSets={content[fileKey] as VideoContentExerciseSetFilePaths[]} />
      );
    case 'stimulateMusclesImageID':
      return <StimulateMusclesInfo filePaths={(content[fileKey] as FilePaths).paths ?? []} />;
    default:
      return null;
  }
};

export default function VideoContentDetailContainer() {
  const selectedVideoContent = useAtomValue(selectedVideoContentState);

  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior) => void;
  }>(null);

  const {
    _id: contentID = '',
    title = '',
    subtitle = '',
    thumbnailFileID,
  } = selectedVideoContent ?? {};

  const thumbnailURL = thumbnailFileID?.paths?.[0] ?? '';

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTo(0, 'auto');
    }
  }, [selectedVideoContent]);

  if (!selectedVideoContent._id) {
    return (
      <div className='min-h-screen w-[400px] flex-center border-white600 border-l bg-white50 '>
        <NoData
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          title='영상을 선택해 주세요.'
          subTitle='선택한 영상의 상세정보가 여기에 표시됩니다.'
        />
      </div>
    );
  }

  return (
    <div className='flex min-h-screen w-[400px] flex-col border-white600 border-l bg-white50 '>
      <Scrollbar disabledX={true} className='flex-grow' ref={scrollbarRef}>
        <div className='h-full flex-col-center p-40'>
          <VideoContentDetailThumbnail
            title={title}
            vchID={contentID}
            thumbnailURL={thumbnailURL}
          />
          <VideoContentDetailTitle title={title} subTitle={subtitle} />
          <div className='mt-30 flex w-full flex-col gap-10'>
            {CONTENT_SECTIONS.map((section) => {
              return (
                <VideoContentSection
                  key={`${section.title}_${section.key}`}
                  section={section}
                  content={selectedVideoContent}>
                  {getChildrenContent(selectedVideoContent, section.fileKey)}
                </VideoContentSection>
              );
            })}
          </div>
        </div>
      </Scrollbar>
    </div>
  );
}
