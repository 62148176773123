import { hospitalInfoState } from '@templates/HospitalSetting/containers/HospitalProfile/states/hospital-info-state';
import TextArea from 'afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useAtom, useSetAtom } from 'jotai/index';
import { selectAtom } from 'jotai/utils';

const hospitalIntroductionAtom = selectAtom(hospitalInfoState, (hospital) => hospital.introduction);

export default function HospitalIntroductionInput() {
  const [introduction] = useAtom(hospitalIntroductionAtom);
  const setHospitalInfo = useSetAtom(hospitalInfoState);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setHospitalInfo((prev) => ({
      ...prev,
      introduction: event.target.value,
    }));
  };

  return (
    <div className='mt-20 flex-1'>
      <LabelText>소개글</LabelText>
      <div className='mt-10'>
        <TextArea
          onSave={() => {}}
          placeholder='소개글을 입력해 주세요.(최대 500자)'
          height={86}
          width='100%'
          maxLength={500}
          onChange={handleInputChange}
          isShowBottom={false}
          value={introduction ?? ''}
        />
      </div>
    </div>
  );
}
