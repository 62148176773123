import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { NavBar, Statusbar } from 'afterdoc-design-system/assets/images';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { Suspense, useEffect } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiEventHospitalAftercareElFindOneParams } from 'web/apis/swaggers/swagger-docs';
import { formatDate } from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/shared/functions/format-date';
import { useGetPriceInfo } from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/shared/hooks/use-get-price-info';

const fetchHospitalHomeCareDetail = async (params: ApiEventHospitalAftercareElFindOneParams) => {
  const response = await apiClient.v3.apiEventHospitalAftercareElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface HomecareDetailContentDialogProps {
  homecareId: string;
}

export default function HomecareDetailContentDialog({
  homecareId,
}: HomecareDetailContentDialogProps) {
  return (
    <div className='h-full w-[400px] px-16 pt-16 pb-20'>
      <div className='h-full flex-col-center overflow-hidden rounded-[24px] border-[5px] border-blueLight'>
        <Statusbar className='w-full bg-white50' />
        <div className='mt-12 flex w-full items-center justify-between px-20'>
          <Icon name='chevron-left' size={40} color='black800' />
          <p className='font-regular text-[16px] text-black leading-[18px]'>공유</p>
        </div>
        <div className='mt-8 w-full flex-grow'>
          <Suspense>
            <HomecareDetailContent homecareId={homecareId} />
          </Suspense>
        </div>
        <NavBar className='w-full' />
      </div>
    </div>
  );
}

function HomecareDetailContent({ homecareId }: HomecareDetailContentDialogProps) {
  const {
    data: {
      title,
      description,
      representImage,
      content,
      type,
      saleType,
      fileIDs,
      isLimitedTime,
      endDate,
      price = 0,
      salePrice = 0,
      saleRate = 0,
    },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiEventHospitalAftercareElFindOne, { _id: homecareId }] as const,
    queryFn: ({ queryKey }) => fetchHospitalHomeCareDetail(queryKey[1]),
  });

  const { formattedPriceDisplay, calculatedPrice, calculatedRate, sanitizedContent } =
    useGetPriceInfo({
      content,
      price,
      salePrice,
      saleRate,
      saleType,
    });

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        dialogService.popById('homecare-detail-content-dialog');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Scrollbar disabledX={true}>
      <div className='flex flex-col'>
        <div className='mb-24 px-12'>
          <p className='mb-[8px] break-words font-bold text-[21px] text-black800'>{title}</p>
          <p className='break-words text-[12px] text-black200'>{description}</p>
          <div className='flex min-w-0 gap-4'>
            <div className='flex min-w-0 flex-grow flex-col'>
              <p className='overflow-hidden text-ellipsis font-regular text-[#CCCCCC] text-[14px] line-through'>
                {formattedPriceDisplay}
              </p>
              <div className='flex items-center gap-10'>
                <p className='overflow-hidden text-ellipsis whitespace-nowrap text-Header16 text-black800'>
                  {`${calculatedPrice}원`}
                </p>
                <p className='text-Header16 text-[#FF5C71]'>{calculatedRate}</p>
              </div>
            </div>
            <p className='mb-6 w-[76px] self-end whitespace-nowrap font-regular text-[#929292] text-[12px]'>
              {isLimitedTime && formatDate(endDate)}
            </p>
          </div>
        </div>
        <Divider type='line' height={10} className='bg-white200' />
        <img
          src={representImage?.paths?.[0]}
          alt='homecareThumbnail'
          className='w-full overflow-hidden object-cover'
        />
        <div className='editor-viewer pt-16 pb-8'>
          {type === 'text' && content && (
            <div
              className='content break-words px-16 py-10 text-black700'
              // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
              dangerouslySetInnerHTML={{ __html: sanitizedContent }}
            />
          )}
          {type === 'image' && (
            <img
              src={fileIDs?.[0]?.paths?.[0]}
              alt='homecareContentImage'
              className='h-auto max-w-full overflow-hidden object-contain'
            />
          )}
        </div>
      </div>
    </Scrollbar>
  );
}
