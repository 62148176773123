import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Suspense, useMemo } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiAutomationsElMainListParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import AutomationSearchFilter from 'web/templates/Automation/containers/Main/AutomationSearchFilter/AutomationSearchFilter';
import { automationSearchTextState } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/automation-search';
import { selectedAutomationSearchCriteriaState } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/selected-automation-search-criteria';
import CounselAutomationContent from 'web/templates/Automation/containers/Main/CounselAutomationContent/CounselAutomationContent';
import DashBoardContent from 'web/templates/Automation/containers/Main/DashBoardContent/DashBoardContent';
import MarketingAutomationContent from 'web/templates/Automation/containers/Main/MarketingAutomationContent/MarketingAutomationContent';
import './Automation.scss';

export default function AutomationContainer() {
  const { authorizationTypeID } = useUserInfo();
  const canReadAutomationDashboard = !!authorizationTypeID?.canReadAutomationDashboard;

  return (
    <div className='w-full'>
      {/* 검색 */}
      <AutomationSearchFilter />
      <div className='automation-container flex h-[calc(100vh-73px)] gap-x-50 overflow-auto bg-blueLight pb-80'>
        {/* 왼쪽 컨텐츠 (자동화 카드들) */}
        <div className='mx-auto flex gap-x-50 px-40 pt-40'>
          <Content />
          {/* 오른쪽 컨텐츠 (대시보드) */}
          {canReadAutomationDashboard && <DashBoardContent />}
        </div>
      </div>
    </div>
  );
}
const fetchAutomationsElMainList = async (params: ApiAutomationsElMainListParams) => {
  const response = await apiClient.v3.apiAutomationsElMainList(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const Content = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const automationSearchText = useAtomValue(automationSearchTextState);
  const selectedAutomationSearchCriteria = useAtomValue(selectedAutomationSearchCriteriaState);

  const params: ApiAutomationsElMainListParams = useMemo(() => {
    return {
      hospitalID,
      algorithmNameFilter:
        selectedAutomationSearchCriteria === 'algorithmName' ? automationSearchText : undefined,
      treatmentTagNameFilter:
        selectedAutomationSearchCriteria === 'treatmentTagName' ? automationSearchText : undefined,
      nationalityNameFilter:
        selectedAutomationSearchCriteria === 'nationalityName' ? automationSearchText : undefined,
    };
  }, [hospitalID, automationSearchText, selectedAutomationSearchCriteria]);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiAutomationsElMainList, { ...params }] as const,
    queryFn: ({ queryKey }) => fetchAutomationsElMainList(queryKey[1]),
  });

  return (
    <div className='w-[1024px]'>
      {/* 상담자동화 */}
      <Suspense>
        <CounselAutomationContent
          consultationAlgorithms={data.consultationAlgorithms}
          commonAlgorithm={data.commonAlgorithm}
        />
      </Suspense>
      {/* 마케팅자동화 */}
      <Suspense>
        <MarketingAutomationContent marketingAlgorithms={data.marketingAlgorithms} />
      </Suspense>
    </div>
  );
};
