import { Color } from '@tailwind-base/styles/color';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { useChatMessageContext } from '../../ChatMessageContext';
import { useLottieAnimation } from '../hooks/use-lottie-animation';

export default function IsTranslatingMessage() {
  const { isMine, messageWidthStyle } = useChatMessageContext();
  const lottieRef = useLottieAnimation({ LottieName: 'dotsSpinner' });

  const messageWidthStyleWithFitContent = {
    ...messageWidthStyle,
    width: 'fit-content',
  };

  return (
    <div
      className={`flex gap-8 rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
      style={messageWidthStyleWithFitContent}>
      <FilledTag className='rounded-r10 px-8 py-3' textColor={Color.blue500} bgColor={Color.blue50}>
        번역중
      </FilledTag>
      <div ref={lottieRef} className='h-20 w-20' />
    </div>
  );
}
