import { Suspense } from 'react';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import CustomerInfoCustomerMemo from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoInputBoard/components/CustomerInfoCustomerMemo';
import ManagementDirection from './ManagementDirection/ManagementDirection';
import ApplyingAutomation from './components/ApplyingAutomation';

export default function CustomerInfoInputBoardContainer() {
  return (
    <div className='grid h-full min-h-[300px] w-full grid-cols-3 items-center gap-20 rounded-r16 bg-white100 px-24 py-32'>
      {/* 적용 중인 상담자동화 */}
      <LayoutBox noStyle>
        <ApplyingAutomation />
      </LayoutBox>

      {/* 고객메모 */}
      <LayoutBox noStyle>
        <CustomerInfoCustomerMemo />
      </LayoutBox>

      {/* 관리방향 */}
      <Suspense>
        <ManagementDirection />
      </Suspense>
    </div>
  );
}
