import ChattingList from '@templates/CustomerChat/components/ChattingList/ChattingList';
import { useAtomValue } from 'jotai';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import ChattingRoom from './components/ChattingRoom/ChattingRoom';
import { CustomerMemo } from './components/CustomerMemo/CustomerMemo';
import { CustomerReservation } from './components/CustomerReservation/CustomerReservation';
import { CustomerReservationChatRoomNull } from './components/CustomerReservation/CustomerReservationChatRoomNull';
import Manual from './components/Manual/Manual';
import { ManualToggleButton } from './components/ManualToggleButton/ManualToggleButton';
import { SupportBot } from './components/SupportBot/SupportBot';
import useIsManualOpen from './hooks/use-is-manual-open';
import { chatRoomIDSelector } from './states/selected-chat-room';

export default function CustomerChatContainer() {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const { usingSaaSReservation } = useSelectedHospitalInfo();

  const { isManualOpen, toggleManual } = useIsManualOpen();

  const isUseReservation = !!usingSaaSReservation;

  return (
    <div className='h-full w-full flex-row-center'>
      <div className='h-full w-[400px] shrink-0'>
        <ChattingList />
      </div>
      <div
        className={`h-full shrink-0 overflow-hidden border-white400 border-l transition-[width] duration-200 ${isManualOpen ? 'w-[400px]' : 'w-0'}
          `}>
        <Manual />
      </div>
      <div className='relative h-full w-full flex-1 border-y border-y-white400 border-r border-r-white400 border-l-[4px] border-l-white400'>
        <ChattingRoom isManualOpen={isManualOpen} />
        <ManualToggleButton isManualOpen={isManualOpen} onClick={toggleManual} />
      </div>
      <div className='flex h-full w-[400px] shrink-0 flex-col'>
        {chatRoomID === null ? (
          <CustomerReservationChatRoomNull />
        ) : (
          <>
            <CustomerMemo />
            {isUseReservation && <CustomerReservation />}
            <SupportBot />
          </>
        )}
      </div>
    </div>
  );
}
