import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { Suspense } from 'react';
import OnGoingConsultingAutomationCardsList from './components/OnGoingConsultingAutomationCardsList/OnGoingConsultingAutomationCardsList';

export default function OngoingConsultingAutomationContainer() {
  return (
    <>
      <Title title='진행중인 상담자동화' />
      <OnGoingConsultingAutomationCardsListContainer />
    </>
  );
}

function OnGoingConsultingAutomationCardsListContainer() {
  return (
    <div className='h-[calc(100vh-222px)]'>
      <Suspense
        fallback={
          <div className='h-[calc(100vh-222px)] w-[360px] flex-center'>
            <BaseLoading />
          </div>
        }>
        <OnGoingConsultingAutomationCardsList />
      </Suspense>
    </div>
  );
}
