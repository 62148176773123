import { useQueryClient } from '@tanstack/react-query';
import SearchInput from 'afterdoc-design-system/components/Atoms/Input/SearchInput';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Radio from 'afterdoc-design-system/components/Atoms/Radio/Radio';
import { RadioGroup } from 'afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useEffect, useMemo, useState } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import ALIntegrationEditIntegrated from './ALIntegrationEditIntegrated';
import { ALIntegrationEditPatientInfo } from './ALIntegrationEditPatientInfo';
import { ALIntegrationEditRepresented } from './ALIntegrationEditRepresented';
import ALIntegrationEditSearchedCustomer from './ALIntegrationEditSearchedCustomer';
import {
  getCustomerUsingCustomerNumbers,
  getCustomerUsingName,
  getCustomerUsingPhone,
  getDuplicatedCustomerNumber,
  getPatientDetailInfo,
  updateHService,
} from './hooks/network';
import { isALIntegrationData } from './hooks/parser';
import { type ALIntegrationUserData, defaultArray, defaultData } from './hooks/type';

interface ALIntegrationEditProps {
  patientId: string;
  dialogId: string;
}

const EnabledButtonStyle = {
  background: '#007aff',
  color: '#ffffff',
};
const DisabledButtonStyle = {
  background: '#d9d9d9',
  color: '#ffffff',
};

export default function ALIntegrationEdit({ patientId, dialogId }: ALIntegrationEditProps) {
  const queryClient = useQueryClient();

  const [patientInfo, setPatientInfo] = useState<ALIntegrationUserData>();
  const [searchType, setSearchType] = useState<string | number | undefined>('name');
  const [keyword, setKeyword] = useState<string | undefined>();
  const [searchedUsers, setSearchedUsers] = useState<ALIntegrationUserData[]>();
  const [tempUsers, setTempUsers] = useState<ALIntegrationUserData[]>();
  const [hasSearch, setHasSearch] = useState(false);
  const [isShowEditRepresent, setIsShowEditRepresent] = useState(false);
  const [searchLoadingCount, setSearchLoadingCount] = useState(0);
  //TODO - usePatientDetailInfo의 chatRoom가 없다는 에러가 발생하는데 usePatientDetailInfo 안의 patientID 를 세팅하는 부분이 필요
  // const {
  //   chatRoom: { id: chatRoomID },
  // } = usePatientDetailInfo();

  // MAKR: - Computed
  const isDuplicated = useMemo(() => {
    return tempUsers?.find((u) => u.isDuplicated === true) !== undefined;
  }, [tempUsers]);
  const hasRepresentUser = useMemo(() => {
    return tempUsers?.find((u) => u.isRepresented === true) !== undefined;
  }, [tempUsers]);
  const canNext = useMemo(() => {
    return isShowEditRepresent ? hasRepresentUser : isDuplicated;
  }, [isDuplicated]);
  const buttonStyle = useMemo(() => {
    return isDuplicated ? { ...DisabledButtonStyle } : { ...EnabledButtonStyle };
  }, [canNext]);
  const isSearchLoading = useMemo(() => {
    return searchLoadingCount > 0;
  }, [searchLoadingCount]);

  // MARK: - Event Handlers
  const incrementLoadingCount = () => {
    setSearchLoadingCount((prev) => prev + 1);
  };
  const decrementLoadingCount = () => {
    setSearchLoadingCount((prev) => prev - 1);
  };

  const handleSearchEnter = async () => {
    setHasSearch(true);
    if (keyword === undefined || keyword === '') {
      setSearchedUsers(defaultArray);
      return;
    }

    incrementLoadingCount();
    if (searchType === 'name') await handleSearchUser(await getCustomerUsingName(keyword));
    else await handleSearchUser(await getCustomerUsingPhone(keyword));
    decrementLoadingCount();
  };
  const handleSearchUser = async (users: ALIntegrationUserData[]) => {
    const customerNumbers = patientInfo?.ALCustomerNumbers;
    for (const user of users) {
      if (!customerNumbers) continue;
      user.isSelected = customerNumbers.includes(user.ALCustomerNumber ?? '-');
      if (tempUsers?.find((u) => u.ALCustomerNumber === user.ALCustomerNumber))
        user.isSelected = true;
      user.isDuplicated = await checkDuplicated(user);
    }
    setSearchedUsers(users);
    showSearchResultToast(
      `${keyword}(와)과 일치하는 ${users.length}건의 정보가 조회되었습니다. 연동할 고객정보를 선택해 주세요.`,
    );
  };
  const handleNext = () => {
    if (isShowEditRepresent) {
      updateALCustomerNumbers();
    } else {
      if (tempUsers?.length === 0) {
        updateALCustomerNumbers();
        return;
      }
      if (!hasRepresentUser) updateRepresentedUser(0);
      setIsShowEditRepresent(true);
    }
  };
  const handleClose = () => {
    dialogService.popById(dialogId);
  };

  const showSearchResultToast = (message: string) => {
    toastService.errorMsg({ text: message });
  };

  const showSuccessAdded = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler],
      // queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler, { chatRoomID }],
    });

    toastService.successMsg({ text: '연동되었습니다.' });
  };

  const showSuccessRemoved = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler],
      // queryKey: [QUERY_KEY.chatRoomElFindServiceInformationHandler, { chatRoomID }],
    });

    toastService.successMsg({ text: '스마트닥터와 고객연동이 해제되었습니다.' });
  };

  // MARK: - Network
  const loadPatientDetail = () => {
    getPatientDetailInfo({ patientId }).then((data) => {
      if (isALIntegrationData(data)) setPatientInfo(data);
    });
  };

  const loadALCustomerNumberDetail = () => {
    const numbers = patientInfo?.ALCustomerNumbers;
    // const numbers = ['202401080006']; // for test
    if (numbers === undefined) return;
    incrementLoadingCount();
    getCustomerUsingCustomerNumbers(numbers).then(async (result) => {
      for (const newUser of result) {
        const oldUser = tempUsers?.find((u) => u.ALCustomerNumber === newUser.ALCustomerNumber);
        if (oldUser !== undefined) newUser.isSelected = true;
        newUser.isDuplicated = await checkDuplicated(newUser);
      }
      setTempUsers(result);
      decrementLoadingCount();
    });
  };

  const updateALCustomerNumbers = async () => {
    const representedUser = tempUsers?.find((u) => u.isRepresented === true);
    const numbers = tempUsers?.map((u) => u.ALCustomerNumber);
    await updateHService(patientId, representedUser?.ALCustomerNumber ?? null, numbers);
    if (numbers?.length === 0) showSuccessRemoved();
    else showSuccessAdded();
    await queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElDetail] });
    handleClose();
  };

  // MARK: - Update from child
  const updateSearchedUsers = async (index: number, user: ALIntegrationUserData) => {
    if (searchedUsers === undefined) return;
    const updatedArray = [...searchedUsers];
    updatedArray.splice(index, 1, user);
    setSearchedUsers(updatedArray);

    const newTempUsers = [...(tempUsers ?? defaultArray)];
    const old = newTempUsers.find((u) => u.ALCustomerNumber === user.ALCustomerNumber);
    if (old) {
      const i = newTempUsers.indexOf(old);
      newTempUsers.splice(i, 1);
    } else newTempUsers.push(user);
    setTempUsers(newTempUsers);
  };

  const updateTempUsers = async (index: number) => {
    const newArray = [...(tempUsers ?? defaultArray)];
    newArray.splice(index, 1);
    setTempUsers(newArray);

    if (searchedUsers === undefined) return;
    for (const [index, user] of searchedUsers.entries()) {
      const i = newArray.findIndex((u) => u.ALCustomerNumber === user.ALCustomerNumber);
      const newSearchedUsers = [...searchedUsers];
      newSearchedUsers[index].isSelected = i >= 0;
      setSearchedUsers(newSearchedUsers);
    }
  };

  const updateRepresentedUser = async (index: number) => {
    if (tempUsers === undefined) return;
    const newUsers = [...tempUsers];
    for (const user of newUsers) user.isRepresented = false;
    if (newUsers.length === 0) return;
    newUsers[index].isRepresented = true;
    setTempUsers(newUsers);
  };

  const checkDuplicated = async (user: ALIntegrationUserData) => {
    const number = user.ALCustomerNumber;
    if (number === undefined) return false;
    const result = await getDuplicatedCustomerNumber(number, patientId);
    return (result?.length ?? 0) !== 0;
  };

  // MARK: - Watch
  useEffect(loadPatientDetail, [patientId]);
  useEffect(loadALCustomerNumberDetail, [patientInfo]);

  return (
    <div className='flex h-full w-full flex-col'>
      <div className='p-20 pb-16 text-Header16'>고객정보 연동</div>
      <div className='flex flex-grow items-center gap-10 px-20'>
        {isShowEditRepresent === false && (
          <div className='flex h-full flex-col gap-10'>
            <ALIntegrationEditPatientInfo {...(patientInfo ?? defaultData)} />
            <div className='flex flex-grow gap-10 '>
              <div className='flex h-full w-full flex-col gap-10 rounded-r16 bg-white100 p-10'>
                <div className='pt-6 text-Header14'>연동할 고객 선택</div>
                <div className='flex gap-20'>
                  <RadioGroup
                    name='searchType'
                    value={searchType}
                    onChange={(value: string | number) => {
                      setSearchType(value);
                    }}>
                    <Radio label='이름으로 검색' value='name' checked={searchType === 'name'} />
                    <Radio
                      label='휴대폰번호로 검색'
                      value='phone'
                      checked={searchType === 'phone'}
                    />
                  </RadioGroup>
                </div>
                <div>
                  <SearchInput
                    id='alintegration-search-input'
                    placeholder={`${searchType === 'name' ? '이름으로' : '휴대폰번호로'} 검색`}
                    className=''
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSearchEnter();
                      }
                    }}
                  />
                </div>
                <div className='flex h-full flex-center flex-col'>
                  {isSearchLoading === true && (
                    <div className='flex h-full w-full items-center justify-center'>
                      <BaseLoading />
                    </div>
                  )}
                  {isSearchLoading === false && (
                    <ALIntegrationEditSearchedCustomer
                      value={searchedUsers ?? defaultArray}
                      hasSearch={hasSearch}
                      setter={updateSearchedUsers}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isShowEditRepresent === false && (
          <ALIntegrationEditIntegrated
            tempUsers={tempUsers ?? defaultArray}
            updateTempUsers={updateTempUsers}
          />
        )}
      </div>

      {isShowEditRepresent === true && (
        <ALIntegrationEditRepresented
          tempUsers={tempUsers ?? defaultArray}
          updateRepresentedUser={updateRepresentedUser}
        />
      )}
      <div className='flex flex-center gap-10 pt-10 pb-20'>
        <button
          type='button'
          className='rounded-r10 bg-white100 px-20 py-6 text-body13 text-white800'
          onClick={handleClose}>
          닫기
        </button>
        <button
          type='button'
          className='rounded-r10 px-20 py-6 text-body13'
          style={buttonStyle}
          onClick={handleNext}
          disabled={isDuplicated}>
          {isShowEditRepresent === true ? '연동' : '다음'}
        </button>
      </div>
    </div>
  );
}
