import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { useEffect, useState } from 'react';

export default function ErrorTest() {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldThrowError, setShouldThrowError] = useState(false);

  // 렌더링 중에 에러를 발생시키기 위한 효과
  useEffect(() => {
    if (shouldThrowError) {
      throw new Error('This is a test error');
    }
  }, [shouldThrowError]);

  // 네트워크 에러 시뮬레이션
  const simulateNetworkError = async () => {
    setIsLoading(true);
    try {
      await fetch('https://non-existing-url-123456.com');
    } catch (error) {
      // 에러를 state 변경을 통해 발생시킴
      setShouldThrowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // TypeError 시뮬레이션
  const simulateTypeError = () => {
    setShouldThrowError(true);
  };

  const throwError = () => {
    setShouldThrowError(true);
  };

  return (
    <div className='flex flex-col gap-4'>
      <ContainedButton onClick={simulateNetworkError} disabled={isLoading}>
        네트워크 에러 테스트
      </ContainedButton>

      <ContainedButton onClick={simulateTypeError}>TypeError 테스트</ContainedButton>

      <ContainedButton onClick={throwError}>Throw Error</ContainedButton>
    </div>
  );
}
