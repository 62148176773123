import AutomationMessagePreviewDialog, {
  AUTOMATION_MESSAGE_PREVIEW_DIALOG_ID,
} from '@templates/CustomerChat/components/SupportBot/components/AlgorithmGuide/components/dialog/AutomationMessagePreviewDialog';
import type { ResConsultationGuide } from '@templates/CustomerChat/components/SupportBot/components/AlgorithmGuide/hooks/use-get-algorithm-guide';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';

export type MessageType = 'MARKETING' | 'CONSULTATION';

interface AutomationMessageCardProps {
  type: MessageType;
  message?:
    | ResConsultationGuide['lastConsultationAlgorithmMessage']
    | ResConsultationGuide['lastMarketingAlgorithmMessage'];
}

const formatDate = (dateString?: string, format = 'YYYY-MM-DD'): string => {
  const date = dayjs(dateString);
  return date.isValid() ? date.format(format) : '-';
};

const getFormattedDescription = (
  type: MessageType,
  message?: AutomationMessageCardProps['message'],
): string => {
  if (!message) return '';
  const { sendingDateTime, name, daysAfter } = message;

  if (type === 'MARKETING') {
    const formattedDate = formatDate(sendingDateTime, 'YYYY-MM-DD');
    return `[${formattedDate}] ${name || ''}`;
  }

  const dPlus = daysAfter ? `[D+${daysAfter}]` : '';
  return `${dPlus} ${name || ''}`;
};

export default function AutomationMessageCard({ type, message }: AutomationMessageCardProps) {
  const handleClick = () => {
    dialogService.push(<AutomationMessagePreviewDialog type={type} message={message} />, {
      id: AUTOMATION_MESSAGE_PREVIEW_DIALOG_ID,
      titleProps: { title: '상세내용' },
      wrapperClassName: 'h-[500px] mt-0 mb-0',
      hasCloseButton: true,
      width: 400,
    });
  };

  const formattedDescription = useMemo(
    () => getFormattedDescription(type, message),
    [type, message],
  );

  const formattedDate = useMemo(
    () => formatDate(message?.messageSentAt, 'YYYY-MM-DD HH:mm'),
    [message?.messageSentAt],
  );

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        const { id } = (await dialogService.getLastDialog()) ?? {};
        if (id === AUTOMATION_MESSAGE_PREVIEW_DIALOG_ID) {
          dialogService.popById(AUTOMATION_MESSAGE_PREVIEW_DIALOG_ID);
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      {message && (
        <div
          className='flex cursor-pointer flex-col gap-8 rounded-r10 bg-white200 p-12'
          onClick={handleClick}>
          <div className='flex items-center gap-16'>
            <span className='flex-grow truncate break-all text-Header14 text-black700'>
              {message?.algorithmName || '-'}
            </span>
            <span
              className={`flex-center flex-shrink-0 rounded-r6 px-8 text-Body12 ${
                type === 'MARKETING' ? 'bg-purple100 text-purple500' : 'bg-blue50 text-blue500'
              }`}>
              {type === 'MARKETING' ? '마케팅' : '상담'}
            </span>
          </div>
          <div className='flex items-center gap-16'>
            <span className='flex-grow truncate break-all text-Body12 text-black200'>
              {formattedDescription}
            </span>
            <span className='flex-shrink-0 text-Caption9 text-black200'>{formattedDate}</span>
          </div>
        </div>
      )}
    </>
  );
}
