import { useSuspenseQuery } from '@tanstack/react-query';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtom } from 'jotai';
import { Suspense, useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiPatientsElDetailParams } from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';
import CustomerInfoInputFormContainer from './containers/CareAndCustomerInfoInputForm/CareAndCustomerInfoInputForm.container';
import CustomerInfoInputBoardContainer from './containers/CustomerInfoInputBoard/CustomerInfoInputBoard.container';
import CustomerInfoTitleContainer from './containers/CustomerInfoTitle/CustomerInfoTitle.container';
import ReservationManagementContainer from './containers/ReservationManagement/ReservationManagement.container';

interface CustomerDetailInfoContainerProps {
  patientId: string;
}

export const getPatientDetailInfo = async (params: ApiPatientsElDetailParams) => {
  const response = await apiClient.v3.apiPatientsElDetail(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function CustomerDetailInfoContainer({
  patientId,
}: CustomerDetailInfoContainerProps) {
  const [globalPatientId, setGlobalPatientId] = useAtom(patientIdState);

  useEffect(() => {
    if (patientId) {
      setGlobalPatientId(patientId);
    }

    return () => setGlobalPatientId(null);
  }, [patientId, setGlobalPatientId]);

  if (!globalPatientId) return null;

  return (
    <Suspense fallback={<FullLoading />}>
      <CustomerDetailInfoContent patientId={patientId} />
    </Suspense>
  );
}

interface CustomerDetailInfoContentProps {
  patientId: string;
}

function CustomerDetailInfoContent({ patientId }: CustomerDetailInfoContentProps) {
  useSuspenseQuery({
    queryKey: [QUERY_KEY.apiPatientsElDetail, { patientId }] as const,
    queryFn: ({ queryKey }) => getPatientDetailInfo(queryKey[1]),
    refetchOnMount: 'always',
  });

  return (
    <Scrollbar>
      <div className='w-full px-40'>
        <Suspense>
          <CustomerInfoTitleContainer />
        </Suspense>

        <div className='grid w-full grid-cols-[1fr,374px] gap-40'>
          {/* 좌측 고객정보 */}
          <LeftSideCustomerInfo />

          {/* 우측 고객정보 */}
          <RightSideCustomerInfo />
        </div>
      </div>
    </Scrollbar>
  );
}

function LeftSideCustomerInfo() {
  return (
    <div className='mt-18 grid grid-rows-[auto,auto] gap-34'>
      {/* 적용 중인 상담자동화, 고객메모, 관리방향 */}
      <LayoutBox noStyle>
        <CustomerInfoInputBoardContainer />
      </LayoutBox>

      {/* 예약관리 */}
      <Suspense>
        <ReservationManagementContainer />
      </Suspense>
    </div>
  );
}

function RightSideCustomerInfo() {
  return (
    <Suspense>
      <CustomerInfoInputFormContainer />
    </Suspense>
  );
}
