import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import type { CommonAlgorithm } from 'web/apis/swaggers/swagger-docs';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import ConsultationAlgorithmItem from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/components/EditCommonAutomationDialogContent/components/ConsultationAlgorithmItem';
import MessageContent from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/components/EditCommonAutomationDialogContent/components/MessageContent';
import MessageSendTime from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/components/EditCommonAutomationDialogContent/components/MessageSendTime';
import UsingCommonAlgorithmOrNotToggle from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/components/EditCommonAutomationDialogContent/components/UsingCommonAlgorithmOrNotToggle';
import { useSubmitEditCommonAutomation } from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/hooks/use-submit-edit-common-automation';

interface EditCommonAutomationDialogContentProps {
  consultationAlgorithms: CommonAlgorithm['consultationAlgorithms'];
}

export default function EditCommonAutomationDialogContent({
  consultationAlgorithms,
}: EditCommonAutomationDialogContentProps) {
  const { handleRegister, isPending } = useSubmitEditCommonAutomation();
  const { authorizationTypeID } = useUserInfo();
  const canUpsertAutomation = !!authorizationTypeID?.canUpsertAutomation;

  return (
    <div className='grid h-[calc(100vh-70px)] grid-cols-[600px,80px,1fr] pt-40 pr-60 pl-80'>
      <LayoutBox size={40} direction='vertical' width='100%'>
        <LayoutBox size={40} direction='horizontal'>
          <UsingCommonAlgorithmOrNotToggle />
          <MessageSendTime />
        </LayoutBox>
        <LayoutBox noStyle>
          <MessageContent />
          {canUpsertAutomation && (
            <ContainedButton onClick={handleRegister} disabled={isPending} className='ml-auto'>
              저장
            </ContainedButton>
          )}
        </LayoutBox>
      </LayoutBox>
      <Divider type='line' direction='vertical' className='mx-40' />
      <CounselAutomationLists consultationAlgorithms={consultationAlgorithms} />
    </div>
  );
}

interface CounselAutomationListsProps {
  consultationAlgorithms: CommonAlgorithm['consultationAlgorithms'];
}

const CounselAutomationLists = ({ consultationAlgorithms }: CounselAutomationListsProps) => {
  const { authorizationTypeID } = useUserInfo();
  const canUpsertAutomation = !!authorizationTypeID?.canUpsertAutomation;

  if (!consultationAlgorithms?.length) {
    return (
      <div className='flex flex-col'>
        <LabelText isRequired>상담자동화 목록</LabelText>
        <NoData
          className='flex h-full justify-center'
          title='표시할 내용이 없습니다.'
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          subTitle='등록된 자동화가 여기에 표시됩니다.'
        />
      </div>
    );
  }

  return (
    <div className='h-fit w-full'>
      <div className='pr-20'>
        <LabelText isRequired>상담자동화 목록</LabelText>
        <div className='my-10 flex w-full justify-between'>
          <FilledTag className='rounded-r6' tagSize='big'>
            발송일
          </FilledTag>
          <FilledTag className='rounded-r6' tagSize='big'>
            사용여부
          </FilledTag>
        </div>
      </div>
      <div className='h-[calc(100vh-270px)]'>
        <Scrollbar>
          <div className='flex h-full flex-col gap-10 pr-20'>
            {consultationAlgorithms.map((consultationAlgorithm) => {
              return (
                <ConsultationAlgorithmItem
                  key={consultationAlgorithm._id}
                  disabled={!canUpsertAutomation}
                  consultationAlgorithm={consultationAlgorithm}
                />
              );
            })}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};
