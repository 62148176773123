import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';

type SendingTopLineProps = {
  selectedLength: number;
  maxSelectNumber: number;
  sendFunction: () => void;
  isDisabled: boolean;
};

export default function SendingTopLine({
  selectedLength,
  maxSelectNumber,
  sendFunction,
  isDisabled,
}: SendingTopLineProps) {
  return (
    <div className='mb-10 flex h-40 flex-center flex-row justify-between bg-white100 px-20 py-9'>
      <span className='text-Body10 text-black500'>
        {`${selectedLength} 선택(최대 ${maxSelectNumber}개)`}
      </span>
      <ContainedButton onClick={sendFunction} buttonSize={'small'} disabled={isDisabled}>
        전송
      </ContainedButton>
    </div>
  );
}
