import { useClickOutside } from '@shared-hooks/use-click-outside';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, memo, useRef, useState } from 'react';
import {
  READ_ONLY_TABLE_HEADERS,
  READ_ONLY_TABLE_HEADERS_WITH_FILTER,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/components/CustomerManagementReadOnlyTableHeader/constants/table';
import {
  type ReadOnlyHeaderFilterState,
  readOnlyHeaderFilterState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/states/read-only-header-filter';
import GenderFilter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/GenderFilter';
import IsFirstVisitFilter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/IsFirstVisitFilter';
import NationalityFilter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/NationalityFilter';
import TreatmentTagFilter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/Filters/TreatmentTagFilter';

const convertTableHeaderKRToEN = (
  header: (typeof READ_ONLY_TABLE_HEADERS_WITH_FILTER)[number],
): keyof ReadOnlyHeaderFilterState => {
  switch (header) {
    case '초/재진':
      return 'isFirstVisit';
    case '성별':
      return 'gender';
    case '치료태그':
      return 'treatmentCategories';
    case '국적':
      return 'nationality';
  }
};

function CustomerManagementReadOnlyTableHeader() {
  const readOnlyHeaderFilter = useAtomValue(readOnlyHeaderFilterState);

  const [selectedFilter, setSelectedFilter] = useState<
    (typeof READ_ONLY_TABLE_HEADERS_WITH_FILTER)[number] | null
  >(null);

  return (
    <thead>
      <tr className='sticky top-0'>
        {READ_ONLY_TABLE_HEADERS.map((header) => {
          const hasFilter = READ_ONLY_TABLE_HEADERS_WITH_FILTER.some((filterHeader) =>
            header.includes(filterHeader),
          );
          const filterHasValue =
            readOnlyHeaderFilter[
              convertTableHeaderKRToEN(
                READ_ONLY_TABLE_HEADERS_WITH_FILTER.find((filterHeader) =>
                  header.includes(filterHeader),
                ) as (typeof READ_ONLY_TABLE_HEADERS_WITH_FILTER)[number],
              )
            ] !== undefined;

          return (
            <th
              key={header}
              className='whitespace-nowrap border-line-table border-y border-r bg-table px-12 py-10 text-left text-Header14 text-black700'>
              <div
                className={customTwMerge(
                  hasFilter ? 'flex w-full items-center justify-between' : '',
                )}>
                {header}
                {hasFilter && (
                  <div className='relative'>
                    <IconButton
                      icon='filter-outline'
                      color={filterHasValue ? 'blue500' : 'black200'}
                      size={20}
                      onClick={() =>
                        setSelectedFilter(
                          header as (typeof READ_ONLY_TABLE_HEADERS_WITH_FILTER)[number],
                        )
                      }
                    />
                    {selectedFilter === header && (
                      <Suspense>
                        <Filter
                          selectedFilter={selectedFilter}
                          onChangeSelectedFilter={setSelectedFilter}
                        />
                      </Suspense>
                    )}
                  </div>
                )}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

const Filter = ({
  selectedFilter,
  onChangeSelectedFilter,
}: {
  selectedFilter: (typeof READ_ONLY_TABLE_HEADERS_WITH_FILTER)[number];
  onChangeSelectedFilter?: (
    filter: (typeof READ_ONLY_TABLE_HEADERS_WITH_FILTER)[number] | null,
  ) => void;
}) => {
  const [readOnlyHeaderFilter, setReadOnlyHeaderFilter] = useAtom(readOnlyHeaderFilterState);
  const filterRef = useRef<HTMLDivElement>(null);

  useClickOutside({
    id: `${selectedFilter}-filter`,
    ref: filterRef,
    contentRef: filterRef,
    onClose: () => {
      onChangeSelectedFilter?.(null);
    },
  });

  const handleIsFirstVisitFilterChange = (value: boolean | 'all' | undefined) => {
    setReadOnlyHeaderFilter((prev) => ({
      ...prev,
      isFirstVisit: value,
    }));
  };

  const handleGenderFilterChange = (value: 'MALE' | 'FEMALE' | 'all' | undefined) => {
    setReadOnlyHeaderFilter((prev) => ({
      ...prev,
      gender: value,
    }));
  };

  const handleTreatmentTagFilterChange = (value: string | undefined) => {
    setReadOnlyHeaderFilter((prev) => ({
      ...prev,
      treatmentCategories: value,
    }));
  };

  const handleNationalityFilterChange = (value: string | undefined) => {
    setReadOnlyHeaderFilter((prev) => ({
      ...prev,
      nationality: value,
    }));
  };

  const renderFilter = () => {
    if (selectedFilter === '초/재진') {
      return (
        <IsFirstVisitFilter
          value={readOnlyHeaderFilter.isFirstVisit}
          ref={filterRef}
          onValueChange={handleIsFirstVisitFilterChange}
        />
      );
    }

    if (selectedFilter === '성별') {
      return (
        <GenderFilter
          value={readOnlyHeaderFilter.gender}
          ref={filterRef}
          onValueChange={handleGenderFilterChange}
        />
      );
    }

    if (selectedFilter === '치료태그') {
      return (
        <TreatmentTagFilter
          value={readOnlyHeaderFilter.treatmentCategories}
          ref={filterRef}
          onValueChange={handleTreatmentTagFilterChange}
        />
      );
    }

    if (selectedFilter === '국적') {
      return (
        <NationalityFilter
          value={readOnlyHeaderFilter.nationality}
          ref={filterRef}
          onValueChange={handleNationalityFilterChange}
        />
      );
    }
    return null;
  };

  return <>{renderFilter()}</>;
};

export default memo(CustomerManagementReadOnlyTableHeader);
