import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { ALIntegrationUserData } from './hooks/type';

interface ALIntegrationEditIntegrationItemProps {
  list: ALIntegrationUserData[];
  index: number;
  update: (index: number) => void;
}
export default function ALIntegrationEditIntegrationItem({
  list,
  index,
  update,
}: ALIntegrationEditIntegrationItemProps) {
  const item = list[index];
  const birth = item.birthdate?.substring(0, 6) ?? '-';

  const handleRemove = () => {
    update(index);
  };
  return (
    <div className='pb-10'>
      <div
        style={{ width: '312px' }}
        className='flex gap-10 overflow-hidden rounded-r10 border border-white600 bg-white50'>
        <div className='flex w-full flex-col gap-10 p-16' style={{ height: '86.46px' }}>
          <div className='flex gap-10'>
            {item.isRepresented && (
              <div className='flex flex-center flex-col bg-blueLight'>
                <span className='text-Header12 text-blue500'>대표</span>
              </div>
            )}
            <span className='text-Header14'>{item.name}</span>
            <span className='text-Body11'>{item.chartNumber}</span>
          </div>
          <div className='flex gap-10'>
            <span className='rounded-r6 bg-white100 px-8 text-Body12'>
              {item.gender}/{birth}
            </span>
            <span className='rounded-r6 bg-white100 px-8 text-Body12'>{item.phoneNumber}</span>
          </div>
        </div>
        <button
          type='button'
          className='flex flex-center bg-white700'
          style={{ width: '32px' }}
          onClick={handleRemove}>
          <Icon name='trash-can-outline' size={20} color='white50' />
        </button>
      </div>
      {item.isDuplicated && (
        <span className='mb-10 text-Body10 text-red500'>
          * 위 정보는 다른 고객과 연동되어 있어 사용할 수 없습니다.
        </span>
      )}
    </div>
  );
}
