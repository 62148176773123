import type { BrowserOptions, ErrorEvent } from '@sentry/react';
import * as Sentry from '@sentry/react';

// 최근 업데이트 : 2025-01-17
const TEST_HOSPITAL_IDS = [
  // 지현테스트
  '677f6733bc3b2a0b8bbdaca1',
  // 서비스매니저 추가된 지현테스트1
  '6784c0e01f49110bad0443cd',
  // 지현연동테스트
  '678761a23cc1d70b8ba85ea0',
];

interface SentryEnvironmentConfig {
  enabled: boolean;
  environment: string;
  tracePropagationTargets: (string | RegExp)[];
  tracesSampleRate: number;
  debug: boolean;
}

const getSentryEnvironmentConfig = (
  mode: string,
  hospitalID?: string | null,
): SentryEnvironmentConfig => {
  const isProduction = mode === 'prod';
  const isTestHospital = hospitalID && TEST_HOSPITAL_IDS.includes(hospitalID);

  // 프로덕션 테스트 환경인 경우 다른 샘플링 레이트 적용
  const getEnvironmentSpecificConfig = () => {
    if (isProduction && isTestHospital) {
      return {
        environment: 'prod-test',
        tracesSampleRate: 0.5,
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 0.1,
      };
    }

    return {
      environment: 'prod',
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 0.1,
    };
  };

  const getTracePropagationTargets = () => {
    if (isProduction) {
      return [
        'https://api.afterdoc.net',
        'https://frontend.afterdoc.net',
        'https://chat.afterdoc.net',
        'https://ikakao.afterdoc.net',
        'https://fileproxy.afterdoc.net',
      ];
    }
    return [];
  };

  return {
    enabled: isProduction,
    ...getEnvironmentSpecificConfig(),
    tracePropagationTargets: getTracePropagationTargets(),
    debug: !isProduction,
  };
};

export const sentryConfig: BrowserOptions = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: `web@${import.meta.env.VITE_APP_VERSION}`,
  integrations: [
    Sentry.browserTracingIntegration({
      traceFetch: true,
      traceXHR: true,
    }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
      maskAllInputs: true,
    }),
  ],
  beforeSend(event: ErrorEvent) {
    try {
      if (event.user) {
        event.user.ip_address = undefined;
      }

      const hospitalID =
        (event.extra?.hospitalID as string) ||
        (event.contexts?.hospital?.id as string) ||
        (localStorage.getItem('hospitalID') as string);

      const envConfig = getSentryEnvironmentConfig(import.meta.env.MODE, hospitalID);
      event.environment = envConfig.environment;

      return event;
    } catch (error) {
      event.environment = import.meta.env.MODE;
      return event;
    }
  },
  maxBreadcrumbs: 50,
  sendClientReports: true,
  normalizeDepth: 3,
  maxValueLength: 250,
  ...getSentryEnvironmentConfig(import.meta.env.MODE, localStorage.getItem('hospitalID')),
};

// hospitalID를 Sentry 컨텍스트에 추가하는 유틸리티 함수
export const setSentryHospitalContext = (hospitalID: string) => {
  Sentry.setContext('hospital', {
    id: hospitalID,
  });
};
