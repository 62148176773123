import * as React from "react";
import type { SVGProps } from "react";
const SvgIndent = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path d="M3 14h12M3 4h12M9 9h6" className="indent_svg__ql-stroke" />
    <path
      d="M3 7v4l2-2z"
      className="indent_svg__ql-fill indent_svg__ql-stroke"
    />
  </svg>
);
export default SvgIndent;
