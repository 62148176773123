import type {
  MENU_ITEMS,
  ROUTES_PATH,
} from '@shared/components/NavigationBar/constants/menu-items';
import { hasUnreadMessageBadgeState } from '@shared/states/has-unread-message-badge-state';
import { useAtomValue } from 'jotai/index';
import { memo } from 'react';

interface CustomerSupportMenuProps {
  item: (typeof MENU_ITEMS)[number];
  activatedKey: (typeof MENU_ITEMS)[number]['key'];
  onHandleClick: (key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH]) => void;
}

function CustomerSupportMenu({ item, activatedKey, onHandleClick }: CustomerSupportMenuProps) {
  const hasUnreadMessageBadge = useAtomValue(hasUnreadMessageBadgeState);

  return (
    <>
      <div
        onClick={() => onHandleClick(item.key)}
        className='relative flex-center cursor-pointer flex-col gap-2 bg-white50 pt-10 pb-18'>
        {activatedKey === item.key ? item.activeIcon : item.nonActiveIcon}
        <div className={activatedKey === item.key ? 'text-Body10Bold' : 'text-Body10'}>
          {item.title}
        </div>
        {hasUnreadMessageBadge && (
          <span className='absolute top-8 right-18 h-8 w-8 rounded-full border border-white50 bg-red500' />
        )}
      </div>
    </>
  );
}

export default memo(CustomerSupportMenu);
