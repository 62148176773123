import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import type { Hospital } from 'web/apis/swaggers/swagger-docs';
import { hospitalInfosAtom, selectedHospitalIDAtom } from 'web/jotai/token';
import { LOCAL_HOSPITAL_ID, LOCAL_INTEGRATION_SETTING } from '../constants/temp-id';

export const useSelectedHospitalInfo = () => {
  const hospitalInfos = useAtomValue(hospitalInfosAtom);
  const selectedHospitalIDFromElectron = useAtomValue(selectedHospitalIDAtom);

  const selectedHospitalInfoFromElectron: Hospital | undefined = useMemo(() => {
    return hospitalInfos?.find((hospital) => hospital?._id === selectedHospitalIDFromElectron);
  }, [hospitalInfos, selectedHospitalIDFromElectron]);

  if (selectedHospitalInfoFromElectron) {
    const integrationSettingFromElectron =
      selectedHospitalInfoFromElectron?.integrationSetting as unknown as
        | typeof LOCAL_INTEGRATION_SETTING
        | undefined;

    return {
      ...selectedHospitalInfoFromElectron,
      integrationSetting: integrationSettingFromElectron,
      hospitalID: selectedHospitalInfoFromElectron._id as string,
    };
  }

  return {
    ...hospitalInfos?.[0],
    hospitalID: LOCAL_HOSPITAL_ID,
    integrationSetting: LOCAL_INTEGRATION_SETTING,
    name: '테스트 병원',
  };
};
