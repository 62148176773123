import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { ALIntegrationEditRepresentedItem } from './ALIntegrationEditRepresentedItem';
import type { ALIntegrationUserData } from './hooks/type';

interface ALIntegrationEditRepresentedProps {
  tempUsers: ALIntegrationUserData[];
  updateRepresentedUser: (index: number) => void;
}

export const ALIntegrationEditRepresented = ({
  tempUsers,
  updateRepresentedUser,
}: ALIntegrationEditRepresentedProps) => {
  return (
    <div
      className='mx-20 flex h-full w-full flex-col rounded-r16 bg-white100 px-10 py-10'
      style={{ width: '300px' }}>
      <div className='pt-6 pb-10 text-Header14'>대표 계정 설정</div>
      <div className='flex flex-center flex-cols gap-10 rounded-r16 bg-white400 px-16 py-8'>
        <div className=''>
          <Icon name='person-tick' size={20} color='white700' />
        </div>
        <div className=''>
          <span className='text-Body12 text-black200'>
            연동할 정보 중, 대표 프로필로 설정할 계정을 선택해 주세요. 대표 프로필은 고객 정보를
            조회하거나, 예약을 등록할 때 사용됩니다.
          </span>
        </div>
      </div>
      {/* <div className='h-full'> */}
      <Scrollbar className=''>
        {tempUsers.map((user, index) => (
          <ALIntegrationEditRepresentedItem
            key={index}
            index={index}
            user={user}
            updateRepresentedUser={updateRepresentedUser}
          />
        ))}
      </Scrollbar>
      {/* </div> */}
    </div>
  );
};
