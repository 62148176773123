import { useEffect } from 'react';

export const useGlobalAnchorClick = () => {
  useEffect(() => {
    const handleAnchorClick = (event: MouseEvent) => {
      let target = event.target as HTMLElement;

      // 부모 요소를 따라가면서 a 태그를 찾음
      while (target && target.tagName !== 'A') {
        target = target.parentElement as HTMLElement;
      }

      if (target && target.tagName === 'A') {
        console.info('global-anchor-click-action');
        event.preventDefault();
        const href = (target as HTMLAnchorElement).href;
        window.electron?.ipcRenderer.send('Application.openInExternalBrowser', href);
      }
    };

    document.addEventListener('click', handleAnchorClick);

    return () => {
      document.removeEventListener('click', handleAnchorClick);
    };
  }, []);
};
