import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiStatsChatsMonthlyElFindHospitalParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { selectedDateState } from 'web/templates/Automation/containers/Main/DashBoardContent/states/selected-date';
import { getMonthRange } from 'web/templates/Automation/containers/Main/DashBoardContent/utils/get-month-range';

export interface ApiStatsChatsMonthlyElFindHospitalData {
  unansweredCount: number;
  respondedCount: number;
  totalResponseTime: number;
  responseTimePerCount: number;
  _id: string;
  hospitalID: string;
  registerDate: string;
  __v: number;
  createdAt: string;
  updatedAt: string;
}

type QueryKeyType = [
  typeof QUERY_KEY.apiStatsChatsMonthlyElFindHospital,
  ApiStatsChatsMonthlyElFindHospitalParams,
];

const getAutomatedConsultationCount = async (params: ApiStatsChatsMonthlyElFindHospitalParams) => {
  const response = await apiClient.v3.apiStatsChatsMonthlyElFindHospital(params);
  return SHARED_UTILS.api.checkApiResponse(
    response.data,
  ) as ApiStatsChatsMonthlyElFindHospitalData[];
};

export const useGetStatsChatsMonthly = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const selectedDate = useAtomValue(selectedDateState);
  const { startDate, endDate } = getMonthRange(selectedDate);

  const { data } = useSuspenseQuery<
    ApiStatsChatsMonthlyElFindHospitalData[],
    Error,
    ApiStatsChatsMonthlyElFindHospitalData[],
    QueryKeyType
  >({
    queryKey: [
      QUERY_KEY.apiStatsChatsMonthlyElFindHospital,
      { hospitalID, startDate, endDate },
    ] as const,
    queryFn: ({ queryKey }) => getAutomatedConsultationCount(queryKey[1]),
  });

  return { data };
};
