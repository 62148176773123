import * as React from "react";
import type { SVGProps } from "react";
const SvgHeader3 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#7F7F7F"
      d="M2.5 3.333h1.667v5H7.5v-5h1.667V15H7.5v-5H4.167v5H2.5zm10 0h3.333A1.666 1.666 0 0 1 17.5 5v8.333A1.666 1.666 0 0 1 15.833 15H12.5a1.666 1.666 0 0 1-1.667-1.667V12.5H12.5v.833h3.333V10H12.5V8.333h3.333V5H12.5v.833h-1.667V5A1.667 1.667 0 0 1 12.5 3.333"
    />
  </svg>
);
export default SvgHeader3;
