import { useMutation, useQueryClient } from '@tanstack/react-query';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useAtomValue } from 'jotai';
import { useRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiMemosElUpdateData,
  ApiPatientsElDetailData,
  MemoWithFileID,
} from 'web/apis/swaggers/swagger-docs';
import { useIntersectionObserver } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';
import ServiceManagerMemoCard from './ServiceManagerMemoCard';

interface ServiceManagerMemoListWithTitleProps {
  managerNotes: MemoWithFileID[];
  selectedMemoId?: string;
  onSelectMemo: (memoId?: string) => void;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
}

export default function ServiceManagerMemoListWithTitle({
  managerNotes,
  selectedMemoId,
  onSelectMemo,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
}: ServiceManagerMemoListWithTitleProps) {
  const queryClient = useQueryClient();

  const patientId = useAtomValue(patientIdState);
  const [pinnedMemoId, setPinnedMemoId] = useState<string | undefined>(
    managerNotes.find((note) => note.isPinned)?._id,
  );
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const { patientId: patientIdFromQuery } = queryClient.getQueryData([
    QUERY_KEY.apiPatientsElDetail,
    { patientId },
  ]) as ApiPatientsElDetailData['data'];

  const updatePinMutation = useMutation({
    mutationFn: async (params: Parameters<typeof apiClient.v3.apiMemosElUpdatePin>[0]) => {
      const response = await apiClient.v3.apiMemosElUpdatePin(params);
      return response.data;
    },
    onMutate: async (newPin) => {
      const previousPinned = pinnedMemoId;

      return { previousPinned, pinId: newPin._id };
    },
    onError: ({
      previousPinned,
    }: {
      previousPinned?: string;
    }) => {
      if (previousPinned !== undefined) {
        setPinnedMemoId(previousPinned);
      }
      toastService.errorMsg({ text: '상단 고정을 실패했습니다.' });
    },

    onSuccess: ({ data: newPin }: ApiMemosElUpdateData) => {
      queryClient.invalidateQueries({
        queryKey: [
          'serviceManagerMemo',
          {
            hserviceID: patientIdFromQuery,
            type: 3001,
          },
        ],
      });

      if (pinnedMemoId === newPin._id && !newPin.isPinned) {
        toastService.successMsg({ text: '상단 고정 해제했습니다.' });
      } else {
        toastService.successMsg({ text: '상단 고정했습니다.' });
      }

      setPinnedMemoId(newPin._id);
    },
  });

  const handleTogglePin = (memoInfo: MemoWithFileID) => {
    updatePinMutation.mutate({
      _id: memoInfo._id,
      isPinned: !memoInfo.isPinned,
    });
  };

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isFetchingNextPage,
  });

  if (!managerNotes.length) {
    return (
      <div className='flex-full-center'>
        <NoData
          title='등록한 서비스매니저 메모가 없습니다.'
          iconProps={{
            name: 'warning',
            color: 'white600',
            size: 48,
          }}
        />
      </div>
    );
  }

  return (
    <div className='w-[320px] rounded-r16 bg-white200 pt-12'>
      <div className='px-16 text-Header14 text-black500'>등록한 메모 리스트</div>
      <div className='mt-10 h-[660px] w-full'>
        <Scrollbar disabledX>
          <div className='flex flex-col gap-10 px-16'>
            {managerNotes.map((managerNote) => (
              <ServiceManagerMemoCard
                key={managerNote._id}
                managerNote={managerNote}
                onClick={() => onSelectMemo(managerNote._id)}
                selectedMemoId={selectedMemoId}
                onTogglePin={handleTogglePin}
              />
            ))}
            <div ref={loadMoreRef} style={{ height: 1 }} />
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}
