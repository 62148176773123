import { v4 as uuidv4 } from 'uuid';
import type { AlertProps } from './Alert';

export const alertService = {
  push: (props: AlertProps) => {
    const event = new CustomEvent<AlertProps>('pushAlert', {
      detail: {
        ...props,
        id: props.id || uuidv4(),
        createdAt: Date.now(),
      },
    });
    document.dispatchEvent(event);
  },

  pop: () => {
    document.dispatchEvent(new Event('popAlert'));
  },

  popById: (id: string) => {
    const event = new CustomEvent<string>('popAlertById', {
      detail: id,
    });
    document.dispatchEvent(event);
  },

  popAll: () => {
    document.dispatchEvent(new Event('popAllAlerts'));
  },
};
