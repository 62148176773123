import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useChatMessageContext } from '../ChatMessageContext';
import FileDownload from './FileDownloadContainer';

export default function FileMessage() {
  const { fileIDs, isMine, messageWidthStyle, createdAt } = useChatMessageContext();

  if (!fileIDs || fileIDs.length === 0)
    return (
      <div
        key={createdAt}
        className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}>
        <Icon name='file-remove-outline' color='black200' />
      </div>
    );

  return (
    <div className='flex flex-col gap-y-20'>
      {fileIDs?.map((fileID) => (
        <div
          key={fileID.createdAt}
          className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
          style={messageWidthStyle}>
          <FileDownload key={fileID.createdAt} id={fileID._id} fileID={fileID} />
        </div>
      ))}
    </div>
  );
}
