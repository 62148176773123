import type { PatientsField } from '@templates/HospitalSetting/types/PatientsField';
import { atomWithReset } from 'jotai/utils';

const initialPatientsField: PatientsField = {
  // 차트번호
  usingChartNumber: false,
  // 초/재진
  usingFirstVisitStartDate: false,
  // 생년월일
  usingBirthDay: false,
  // 성별
  usingGender: false,
  // 치료태그
  usingTreatmentTag: false,
  // 국적
  usingNationality: false,
};

export const displayedPatientsFieldsState = atomWithReset<PatientsField>(initialPatientsField);

export const initialDisplayedPatientsFieldsState =
  atomWithReset<PatientsField>(initialPatientsField);
