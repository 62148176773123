import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import MonthPicker from 'afterdoc-design-system/components/Atoms/Calendar/MonthPicker';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { reservationSelectedDateState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';

export default function MonthSelector() {
  const monthPickerIgnoreRef = useRef<HTMLDivElement>(null);

  const setReservationSelectedDate = useSetAtom(reservationSelectedDateState);
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    const startDate = dayjs(selectedDate).startOf('month').startOf('day');

    const endDate = dayjs(selectedDate).endOf('month').endOf('day');

    setReservationSelectedDate({
      startedAt: startDate.utc().toDate(),
      endedAt: endDate.utc().toDate(),
    });
  }, [selectedDate, setReservationSelectedDate]);

  const handleMonthChange = (monthChange: number) => {
    setSelectedDate(dayjs(selectedDate).add(monthChange, 'month').toDate());
  };

  return (
    <div className='flex items-center gap-10'>
      <IconButton
        icon='chevron-left'
        size={16}
        color='black500'
        className='h-24 w-24 rounded-r6 border border-white400 p-3'
        onClick={() => handleMonthChange(-1)}
      />
      <div className='relative'>
        <TextInput
          readOnly
          inputNotAllowed
          onClick={() => setIsMonthDropdownOpen((prev) => !prev)}
          value={dayjs(selectedDate).format('YYYY-MM')}
          contentEditable={false}
        />
        {isMonthDropdownOpen && (
          <MonthPicker
            selectedDate={selectedDate}
            onChangeDate={setSelectedDate}
            handleToggle={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)}
            ignoreRefs={[monthPickerIgnoreRef]}
            wrapperClassName='-left-0 absolute top-35 z-10 w-[400px]'
          />
        )}
      </div>
      <IconButton
        icon='chevron-right'
        size={16}
        color='black500'
        className='h-24 w-24 rounded-r6 border border-white400 p-3'
        onClick={() => handleMonthChange(1)}
      />
    </div>
  );
}
