import { noticeCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-create-or-update-param-state';
import { selectedNoticeEditContentErrorAtom } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-edit-error-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import AfterDocTextEditor from 'afterdoc-text-editor';
import { useAtom, useSetAtom } from 'jotai/index';
import { useRef } from 'react';

interface NoticeContentEditorProps {
  content: string;
  isDisabled: boolean;
}

export default function NoticeContentEditor({ content, isDisabled }: NoticeContentEditorProps) {
  const setNoticeCreateOrUpdateParams = useSetAtom(noticeCreateOrUpdateParamState);

  const [noticeContentError, setNoticeContentError] = useAtom(selectedNoticeEditContentErrorAtom);

  const isFirstEditorRender = useRef(true);

  const handleEditorInput = (content: string) => {
    if (!isFirstEditorRender.current) {
      setNoticeContentError(null);
      setNoticeCreateOrUpdateParams((prev) => ({ ...prev, content }));
      return;
    }
    isFirstEditorRender.current = false;
  };

  return (
    <div className='mt-10 flex items-start gap-24'>
      <LabelText isRequired={true} width={76} className='flex h-40 items-center whitespace-nowrap'>
        상세정보
      </LabelText>
      <div className='flex w-full flex-col gap-4'>
        <AfterDocTextEditor
          height={350}
          value={content}
          onChange={handleEditorInput}
          disabled={isDisabled}
        />
        {noticeContentError && (
          <span className='text-Body10 text-red500'>{noticeContentError}</span>
        )}
      </div>
    </div>
  );
}
