import * as React from "react";
import type { SVGProps } from "react";
const SvgTable = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path d="M3 3h12v12H3z" className="table_svg__ql-stroke" />
    <path d="M5 5h3v2H5zM9 5h4v2H9z" className="table_svg__ql-fill" />
    <g className="table_svg__ql-fill table_svg__ql-transparent">
      <path d="M5 8h3v2H5zM9 8h4v2H9zM5 11h3v2H5zM9 11h4v2H9z" />
    </g>
  </svg>
);
export default SvgTable;
