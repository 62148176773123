import * as React from "react";
import type { SVGProps } from "react";
const SvgVideo = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path d="M3 3h12v12H3z" className="video_svg__ql-stroke" />
    <path d="M5 3h1v12H5zM12 3h1v12h-1z" className="video_svg__ql-fill" />
    <path
      d="M5 8h8v2H5zM3 5h3v1H3zM3 7h3v1H3zM3 10h3v1H3zM3 12h3v1H3zM12 5h3v1h-3zM12 7h3v1h-3zM12 10h3v1h-3zM12 12h3v1h-3z"
      className="video_svg__ql-fill"
    />
  </svg>
);
export default SvgVideo;
