import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { MENU_ITEMS, ROUTES_PATH } from '../constants/menu-items';

interface MenuItemProps {
  item: (typeof MENU_ITEMS)[number];
  isActivated: boolean;
  onHandleClick: (key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH]) => void;
}

export default function MenuItem({ item, isActivated, onHandleClick }: MenuItemProps) {
  return (
    <>
      <div
        onClick={() => onHandleClick(item.key)}
        className='flex-center cursor-pointer flex-col gap-2 bg-white50 pt-10 pb-18'>
        {isActivated ? item.activeIcon : item.nonActiveIcon}
        <div className={customTwMerge(isActivated ? 'text-Body10Bold' : 'text-Body10')}>
          {item.title}
        </div>
      </div>
      {item.hasLine && <div className='mx-10 border-b-2 border-b-white400' />}
    </>
  );
}
