import FilePicker, { type FilePickerHandle } from '@shared/components/FilePicker/FilePicker';
import { FILE_UPLOAD_MAX_SIZE } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/constants/file-upload-max-size';
import EventImageCropDialog from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventImageCropDialog/EventImageCropDialog';
import { popupContentTempFileState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-content-temp-file-state';
import { selectedPopupEditContentImageErrorAtom } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-edit-error-state';
import BigImage from 'afterdoc-design-system/components/Atoms/BigImage/BigImage';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useAtom, useSetAtom } from 'jotai/index';
import { useEffect, useRef, useState } from 'react';

const isFileTooLarge = (targetFile: File) => {
  if (targetFile.size >= FILE_UPLOAD_MAX_SIZE) {
    toastService.errorMsg({
      text: '30MB 이하의 이미지를 등록할 수 있습니다.',
    });
    return true;
  }
  return false;
};

interface PopupContentImageProps {
  contentImage?: string;
  isDisabled: boolean;
}

export default function PopupContentImage({ contentImage, isDisabled }: PopupContentImageProps) {
  const filePickerRef = useRef<FilePickerHandle>(null);
  const [imageSrc, setImageSrc] = useState<string | undefined>();

  const [contentImageError, setContentImageError] = useAtom(selectedPopupEditContentImageErrorAtom);

  const setPopupContentTempFile = useSetAtom(popupContentTempFileState);

  const handleClick = () => {
    if (!isDisabled) {
      filePickerRef.current?.clickSingle();
    }
  };

  const handleCropComplete = (url: string, file: File) => {
    setImageSrc(url);
    setPopupContentTempFile({ file, url });
    setContentImageError(null);
  };

  const handleFileChange = (files: File[]) => {
    if (files.length === 0) return;
    const targetFile = files[0];

    if (isFileTooLarge(targetFile)) return;

    dialogService.push(
      <EventImageCropDialog
        file={files[0]}
        onCropComplete={handleCropComplete}
        stencilProps={{
          aspectRatio: 1 / 1.4,
          resizable: false,
          stencilSize: {
            height: 100,
          },
        }}
      />,
      {
        id: 'image-crop-dialog',
        titleProps: {
          title: '사이즈 조정',
          subTitle: '사진을 드래그하여 위치를 변경하거나 마우스 휠로 확대/축소할 수 있습니다.',
        },
        hasCloseButton: false,
        wrapperClassName: 'h-[592px]',
        width: 500,
      },
    );
  };

  useEffect(() => {
    if (contentImage) {
      setImageSrc(contentImage);
    }
  }, [contentImage]);

  return (
    <div className='flex min-h-40 items-start gap-24 py-4'>
      <LabelText isRequired width={76} className='flex h-32 items-center'>
        팝업 내용
      </LabelText>
      <div className='flex flex-col gap-4'>
        <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
        <ImagePreview isDisabled={isDisabled} imageSrc={imageSrc} handleClick={handleClick} />
        <span className='text-Body12 text-black200'>
          권장 이미지 사이즈 : 가로 620 * 세로 878 (A4 비율)
        </span>
        {contentImageError && <span className='text-Body10 text-red500'>{contentImageError}</span>}
      </div>
    </div>
  );
}

interface ImagePreviewProps {
  isDisabled: boolean;
  imageSrc?: string;
  handleClick: () => void;
}

const ImagePreview = ({ isDisabled, imageSrc, handleClick }: ImagePreviewProps) => {
  return isDisabled ? (
    <div className='group relative h-[238px] w-[170px] overflow-hidden rounded-r16 border border-white600'>
      {imageSrc && (
        <img
          alt='eventDetailImage'
          className='h-full w-full rounded-xl object-cover'
          src={imageSrc}
        />
      )}
    </div>
  ) : (
    <BigImage
      text='이미지 변경'
      width={170}
      height={238}
      onClickImage={handleClick}
      onClickNoImage={handleClick}>
      {imageSrc && (
        <img
          alt='eventDetailImage'
          className='h-full w-full rounded-xl object-cover'
          src={imageSrc}
        />
      )}
    </BigImage>
  );
};
