import type { FileChattingResponse } from 'web/templates/CustomerChat/types';
import { MESSAGE_TYPE_CODE } from '../components/ChattingContent/components/ChattingMessage/constants/message';

export const replaceFileMessage = (
  prev: Array<FileChattingResponse>,
  res: FileChattingResponse,
) => {
  const existingMessageIndex = prev.findIndex(
    (item) =>
      item.type === MESSAGE_TYPE_CODE.FILE_MOCKING &&
      item.fileIDs[0].aws[0].fileName === res.fileIDs[0].aws[0].fileName,
  );

  const isExisted = existingMessageIndex !== -1;

  if (isExisted) {
    const isSent = res.isSent;
    if (isSent) {
      prev[existingMessageIndex] = res;
    } else {
      prev[existingMessageIndex] = {
        ...prev[existingMessageIndex],
        type: MESSAGE_TYPE_CODE.FILE_MOCKING,
        _id: res._id,
        isSent: res.isSent,
        status: res.status,
      };
    }
    return [...prev];
  }

  return [res, ...prev];
};
