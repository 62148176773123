import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';

interface PrevButtonProps {
  disabled?: boolean;
  onClick: () => void;
  className?: string;
}

export default function PrevButton({ disabled, onClick, className }: PrevButtonProps) {
  return (
    <div className={customTwMerge('w-full', className)}>
      <OutlinedButton btnColor='blue' className='w-full' disabled={disabled} onClick={onClick}>
        이전
      </OutlinedButton>
    </div>
  );
}
