import AutomationMessageCard from '@templates/CustomerChat/components/SupportBot/components/AlgorithmGuide/components/AutomationMessageCard';
import type { ResConsultationGuide } from '@templates/CustomerChat/components/SupportBot/components/AlgorithmGuide/hooks/use-get-algorithm-guide';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import DOMPurify from 'dompurify';
import EditorViewer from 'web/shared/components/EditorViewer/EditorViewer';
import NoSupportBotContents from '../NoSuportBotContents/NoSupportBotContents';

interface AlgorithmGuideProps {
  data: ResConsultationGuide | undefined;
}

export default function AlgorithmGuide({ data }: AlgorithmGuideProps) {
  if (!data) return <NoSupportBotContents />;

  const {
    consultationGuide = '',
    lastMarketingAlgorithmMessage: marketingMessage,
    lastConsultationAlgorithmMessage: consultationMessage,
  } = data;

  const hasContent = consultationGuide || marketingMessage || consultationMessage;

  if (!hasContent) return <NoSupportBotContents />;

  const sanitizedConsultationGuide = DOMPurify.sanitize(consultationGuide ?? '');

  return (
    <Scrollbar disabledX>
      <div className='flex h-full w-full flex-col gap-16 p-16'>
        <div className='flex flex-col gap-16'>
          {/*마케팅*/}
          <AutomationMessageCard type={'MARKETING'} message={marketingMessage} />
          {/*상담*/}
          <AutomationMessageCard type={'CONSULTATION'} message={consultationMessage} />
        </div>
        <EditorViewer htmlContent={sanitizedConsultationGuide} />
      </div>
    </Scrollbar>
  );
}
