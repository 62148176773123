import '@afterdoc-text-editor/public/styles/merged.css';
import { useEffect, useRef, useState } from 'react';
import ImageBlot from '../../formats/image';
import Quill from '../../lib/quill-registry';
import { handleNextOrderedList } from './functions/handle-next-order-list';
import { handleNoneNextOrderedList } from './functions/handle-none-next-order-list';
import { removeBrTag } from './functions/remove-br-tag';

interface AfterDocTextEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  height?: number;
  disabled?: boolean;
}

export default function AfterDocTextEditor({
  value,
  onChange,
  placeholder = '내용을 입력해 주세요.',
  disabled = false,
  height,
}: AfterDocTextEditorProps) {
  const editorRef = useRef<HTMLDivElement>(null);
  const quillRef = useRef<Quill>();
  const isInitializedRef = useRef<boolean>(false);
  const currentValueRef = useRef<string>('');

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!editorRef.current || isInitializedRef.current || !mounted) return;

    const quill = new Quill(editorRef.current, {
      modules: {
        clipboard: {
          matchVisual: false,
        },
        history: {
          delay: 500,
          maxStack: 500,
          userOnly: true,
        },
        keyboard: {
          bindings: {
            removeBlockquote: {
              key: 'Backspace',
              format: ['blockquote'],
              empty: true,
              handler() {
                quill.format('blockquote', false);
              },
            },
            removeHeader: {
              key: 'Backspace',
              format: ['header'],
              empty: true,
              handler() {
                quill.format('header', false);
              },
            },
            removeDivider: {
              key: 'Backspace',
              // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              handler(range: any) {
                const [line] = quill.getLine(range.index);
                const [prevLine] = quill.getLine(range.index - 1);

                // 현재 라인이 빈 p 태그이고, 이전 라인이 hr인 경우
                if (
                  line &&
                  line.domNode.tagName === 'P' &&
                  line.length() <= 1 &&
                  prevLine?.domNode.tagName === 'HR'
                ) {
                  // hr과 현재 빈 라인을 모두 삭제
                  quill.deleteText(range.index - 1, 2);
                  return false;
                }
                return true;
              },
            },
            handleEnterOnDivider: {
              key: 'Enter',
              // biome-ignore lint/suspicious/noExplicitAny: <explanation>
              handler(range: any) {
                const [line] = quill.getLine(range.index);

                // 현재 라인이 hr 태그인 경우
                if (line?.domNode.tagName === 'HR') {
                  // 커서를 새로운 라인으로 이동
                  quill.setSelection(range.index + 1, 0, Quill.sources.USER);
                  return false;
                }
                return true;
              },
            },
            shiftEnterContinueList: {
              key: 'Enter',
              shiftKey: true,
              handler() {
                handleNoneNextOrderedList(quill);
              },
            },
          },
        },
        toolbar: {
          container: [
            'undo',
            'redo',
            'bold',
            'underline',
            'strike',
            'italic',
            { list: 'bullet' },
            { list: 'ordered' },
            { header: 1 },
            { header: 2 },
            { header: 3 },
            'image',
            'blockquote',
            'divider',
          ],
          handlers: {
            undo: () => {
              quill.history.undo();
            },
            redo: () => {
              quill.history.redo();
            },
            image: () => {
              const input = document.createElement('input');
              input.setAttribute('type', 'file');
              input.setAttribute('accept', 'image/*');
              input.click();

              input.onchange = async () => {
                const file = input.files?.[0];
                if (!file) return;

                try {
                  // 파일을 base64로 변환
                  const reader = new FileReader();
                  const base64Promise = new Promise<string>((resolve) => {
                    reader.onload = (e) => {
                      resolve(e.target?.result as string);
                    };
                  });
                  reader.readAsDataURL(file);
                  const base64 = await base64Promise;

                  // 이미지 압축
                  const compressedImage = await ImageBlot.compressImage(base64);

                  // 에디터에 삽입
                  const range = quill.getSelection(true);
                  quill.insertEmbed(range.index, 'image', compressedImage, Quill.sources.USER);
                  quill.setSelection(range.index + 1, Quill.sources.USER);

                  // 이미지 삽입 후 onChange 호출
                  if (onChange) {
                    const content = quill.root.innerHTML;
                    onChange(content);
                  }
                } catch (error) {
                  console.error('이미지 업로드 실패:', error);
                }
              };
            },
            divider: () => {
              const range = quill.getSelection(true);

              if (range) {
                // divider 삽입
                quill.insertEmbed(range.index + 1, 'divider', true, Quill.sources.USER);

                // 다음 줄로 커서 이동
                quill.insertText(range.index + 2, '\n', Quill.sources.USER);
                quill.setSelection(range.index + 3, 0, Quill.sources.USER);

                // onChange 호출
                if (onChange) {
                  const content = quill.root.innerHTML;
                  onChange(content);
                }
              }
            },
          },
        },
      },
      theme: 'snow',
      placeholder,
      bounds: editorRef.current,
      readOnly: disabled,
    });

    // 초기 상태 설정
    quill.enable(!disabled);
    if (value) {
      quill.root.innerHTML = value;
      currentValueRef.current = value;
    }

    // 문법 검사 비활성화
    const styleTag = document.querySelector('style[data-gramm]');
    if (styleTag) {
      styleTag.remove();
    }

    // Grammarly 비활성화를 위한 추가 속성들
    quill.root.setAttribute('data-gramm', 'false');
    quill.root.setAttribute('data-gramm_editor', 'false');
    quill.root.setAttribute('data-enable-grammarly', 'false');
    quill.root.setAttribute('spellcheck', 'false');
    quill.root.setAttribute('autocorrect', 'off');
    quill.root.setAttribute('autocomplete', 'off');
    quill.root.setAttribute('data-ms-editor', 'false');

    // contentEditable 요소에도 적용
    const editableElements = quill.root.querySelectorAll('[contenteditable="true"]');
    for (const element of editableElements) {
      element.setAttribute('data-gramm', 'false');
      element.setAttribute('spellcheck', 'false');
      element.setAttribute('autocorrect', 'off');
      element.setAttribute('autocomplete', 'off');
      element.setAttribute('data-ms-editor', 'false');
    }

    // 맞춤법 검사 비활성화
    quill.root.setAttribute('spellcheck', 'false');

    // 이벤트 핸들러
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const handleTextChange = (_delta: any, _oldContents: any, source: string) => {
      if (source === 'user' && onChange) {
        const content = quill.root.innerHTML;
        currentValueRef.current = content;

        handleNextOrderedList(quill);

        const removeBrTagContent = removeBrTag(content);
        onChange(removeBrTagContent);

        // handleOrderedList(quill); // Call the ordered list handler
      }
    };

    quill.on('text-change', handleTextChange);
    quillRef.current = quill;
    isInitializedRef.current = true;

    return () => {
      quill.off('text-change', handleTextChange);
      quillRef.current?.disable();
      quillRef.current = undefined;
      isInitializedRef.current = false;
    };
  }, [mounted]);

  useEffect(() => {
    const editor = quillRef.current;
    if (!editor || !isInitializedRef.current) return;

    editor.enable(!disabled);

    // value가 현재 에디터 내용과 다르고, 이전에 저장된 값과도 다를 때만 업데이트
    if (value !== currentValueRef.current) {
      editor.root.innerHTML = value ?? '';
      currentValueRef.current = value ?? '';
    }
  }, [value, disabled]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className='afterdoc-text-editor' style={{ height: height ?? '100%' }}>
      {mounted ? (
        <div ref={editorRef} style={{ height: height ? height - 42 : '100%' }} />
      ) : (
        <div
          className='w-full rounded-10 bg-white50'
          style={{ height: height ? height - 42 : '100%' }}
        />
      )}
    </div>
  );
}
