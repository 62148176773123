import { useWindowSize } from '@shared-hooks/use-window-size';
import { Color } from '@tailwind-base/styles/color';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import DashedTag from 'afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useSetAtom } from 'jotai';
import { useLayoutEffect, useRef, useState } from 'react';
import type { AlgorithmSummary, MatchedTreatmentTag } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useOpenAutomationDialog } from 'web/shared/hooks/overlayPage/use-open-automation-dialog';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import RegisterMarketingAutomationDialogContainer from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/RegisterMarketingAutomationDialog.container';
import { MARKETING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/constants/marketing-tab-items';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import { selectedMarketingAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';
import StatisticItem from 'web/templates/Automation/containers/Main/shared/components/StatisticItem';
import { useToggleAlgorithm } from 'web/templates/Automation/containers/Main/shared/hooks/use-toggle-algorithm';
import AutomationZoomInOutViewerContainer from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/AutomationZoomInOutViewer.container';
import { useDeleteAlgorithm } from 'web/templates/Automation/containers/shared/hooks/use-delete-algorithm';
import './MarketingCardItem.scss';

interface MarketingCardItemProps {
  marketingAlgorithm: AlgorithmSummary;
}

export default function MarketingCardItem({ marketingAlgorithm }: MarketingCardItemProps) {
  const {
    _id: algorithmId,
    name,
    treatmentTags,
    isActive,
    targetCount,
    responseCount,
    readCount,
    isForNoTreatmentTags,
  } = marketingAlgorithm;

  const { authorizationTypeID } = useUserInfo();
  const containerRef = useRef<HTMLDivElement>(null);

  const canUpsertAutomation = !!authorizationTypeID?.canUpsertAutomation;
  const canDeleteAutomation = !!authorizationTypeID?.canDeleteAutomation;
  const [visibleTags, setVisibleTags] = useState<
    { tag: MatchedTreatmentTag; maxTextLength: number }[]
  >([]);
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);
  const { width: windowWidth } = useWindowSize();

  const openAutomationDialog = useOpenAutomationDialog();
  const { isChecked, onToggle } = useToggleAlgorithm({
    algorithmId,
    targetCount,
    initialState: isActive,
    type: 'MARKETING',
  });

  const onClickCard = () => {
    openAutomationDialog(
      <AutomationZoomInOutViewerContainer algorithmId={algorithmId} type='MARKETING' />,
      OVERLAY_PAGE_ID['marketing-automation-zoom-in-out-viewer'],
    );
  };

  useLayoutEffect(() => {
    if (!containerRef.current || !treatmentTags) return;

    const calculateVisibleTags = () => {
      const container = containerRef.current;
      if (!container) return;

      const containerWidth = container.offsetWidth;
      const reservedWidth = isForNoTreatmentTags ? 85 : 0;
      const availableWidth = containerWidth - reservedWidth;

      let usedWidth = 0;
      const tagsToShow: { tag: MatchedTreatmentTag; maxTextLength: number }[] = [];
      let remainingCount = 0;

      const measureDiv = document.createElement('div');
      measureDiv.style.position = 'absolute';
      measureDiv.style.visibility = 'hidden';
      measureDiv.className = 'filled-tag-class';
      document.body.appendChild(measureDiv);

      // 더보기 버튼 최소화
      const remainingTagsCount = treatmentTags.length;
      const plusButtonText = `${remainingTagsCount}+`;
      measureDiv.innerText = plusButtonText;
      const minPlusButtonWidth = measureDiv.offsetWidth + 2; // 최소 여백

      for (let i = 0; i < treatmentTags.length; i++) {
        const tag = treatmentTags[i];
        let maxTextLength = tag.categoryName.length;
        measureDiv.innerText = tag.categoryName;
        let tagWidth = measureDiv.offsetWidth;

        const needsSpaceForPlus = i < treatmentTags.length - 1;
        const availableWidthForTag = needsSpaceForPlus
          ? availableWidth - minPlusButtonWidth - (usedWidth > 0 ? 2 : 0) // 태그 간격 2px로 최소화
          : availableWidth - (usedWidth > 0 ? 2 : 0);

        // 텍스트가 너무 길 경우에만 자르기
        if (tagWidth > availableWidthForTag) {
          // 더 점진적인 감소를 위해 0.99 사용
          maxTextLength = Math.ceil(maxTextLength * (availableWidthForTag / tagWidth) * 0.99);
          maxTextLength = Math.max(4, maxTextLength); // 최소 4자

          // 미세 조정: 남은 공간 최대한 활용
          let found = false;
          while (!found && maxTextLength <= tag.categoryName.length) {
            measureDiv.innerText = tag.categoryName.slice(0, maxTextLength);
            if (measureDiv.offsetWidth > availableWidthForTag) {
              maxTextLength--;
              found = true;
            } else if (maxTextLength === tag.categoryName.length) {
              found = true;
            } else {
              maxTextLength++;
            }
          }
        }

        // 최종 태그 너비 계산
        measureDiv.innerText = tag.categoryName.slice(0, maxTextLength);
        tagWidth = measureDiv.offsetWidth;

        // 태그 너비가 사용 가능한 공간보다 큰 경우, 태그를 자르기
        if (tagWidth > availableWidth - usedWidth) {
          // 남은 공간에 맞게 텍스트 길이 조정
          const remainingWidth = availableWidth - usedWidth;
          const ratio = remainingWidth / tagWidth;
          maxTextLength = Math.max(4, Math.floor(maxTextLength * ratio));

          // 자른 텍스트로 다시 너비 계산
          measureDiv.innerText = tag.categoryName.slice(0, maxTextLength);
          tagWidth = measureDiv.offsetWidth;
        }

        // 자른 후에도 남은 공간이 있다면 태그 추가
        if (usedWidth + tagWidth <= availableWidth) {
          tagsToShow.push({ tag, maxTextLength });
          usedWidth += tagWidth + (i < treatmentTags.length - 1 ? 2 : 0);
        } else {
          remainingCount = treatmentTags.length - i;
          break;
        }
      }

      document.body.removeChild(measureDiv);

      setVisibleTags(tagsToShow);
      setHiddenTagsCount(remainingCount);
    };

    const resizeObserver = new ResizeObserver(() => {
      if (!containerRef.current) return;
      calculateVisibleTags();
    });

    resizeObserver.observe(containerRef.current);
    calculateVisibleTags();

    return () => {
      resizeObserver.disconnect();
    };
  }, [treatmentTags, isForNoTreatmentTags, windowWidth]);

  return (
    <CardItemLayout
      onClick={onClickCard}
      className='marketing-card-item flex flex-col justify-between gap-y-16'>
      <div>
        <Title
          title={name}
          titleClassName='line-clamp-1'
          trailingChildren={
            canUpsertAutomation && (
              <div onClick={(e) => e.stopPropagation()}>
                <Toggle
                  checked={isForNoTreatmentTags || !!treatmentTags?.length ? isChecked : false}
                  defaultChecked={
                    isForNoTreatmentTags && !!treatmentTags?.length ? isChecked : false
                  }
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (!isForNoTreatmentTags && !treatmentTags?.length) {
                      return modalService.defaultWarning({
                        id: 'marketing-automation-card-item-toggle-warning',
                        title: '자동화를 실행할 수 없습니다.',
                        contents: '적용된 치료태그가 없어 자동화를 실행할 수 없습니다.',
                        onConfirm: () => {
                          modalService.popById('marketing-automation-card-item-toggle-warning');
                        },
                        buttonType: 'CUSTOM',
                        bottomButtons: [
                          <ContainedButton
                            className='w-full'
                            btnColor='red'
                            key='marketing-automation-card-item-toggle-warning-confirm'
                            onClick={() => {
                              modalService.popById('marketing-automation-card-item-toggle-warning');
                            }}>
                            확인
                          </ContainedButton>,
                        ],
                      });
                    }
                    onToggle(e);
                  }}
                />
              </div>
            )
          }
          wrapperClassName='p-0 items-center gap-10'
        />
        <div
          ref={containerRef}
          className='mt-10 flex h-30 items-center rounded-r6 bg-white200 px-10 py-6'>
          <div className='flex items-center gap-4'>
            {isForNoTreatmentTags && (
              <DashedTag bgColor={Color.transparent} tagSize='small'>
                치료태그 미입력
              </DashedTag>
            )}
            {visibleTags.length > 0 && !!treatmentTags?.length && (
              <div className='flex items-center gap-4'>
                {visibleTags.map(({ tag, maxTextLength }) => (
                  <FilledTag key={tag._id} bgColor={tag.color} maxTextLength={maxTextLength}>
                    {tag.categoryName}
                  </FilledTag>
                ))}
                <HoverTooltip
                  text={
                    (isForNoTreatmentTags ? '치료태그 미입력' : '') +
                    treatmentTags.map((tag) => tag.categoryName).join(', ')
                  }
                  show={hiddenTagsCount > 0}>
                  {hiddenTagsCount > 0 && (
                    <DashedTag bgColor={Color.transparent} tagSize='small'>
                      {`${hiddenTagsCount}+`}
                    </DashedTag>
                  )}
                </HoverTooltip>
              </div>
            )}
            {!isForNoTreatmentTags && !treatmentTags?.length && (
              <span className='text-Body12'>적용된 태그가 없습니다.</span>
            )}
          </div>
        </div>
      </div>
      <div className='flex items-center'>
        <StatisticItem label='대상자' value={targetCount} unit='명' />
        <Divider type='line' direction='vertical' className='mx-10' />
        <StatisticItem label='조회수' value={readCount} unit='건' />
        <Divider type='line' direction='vertical' className='mx-10' />
        <StatisticItem label='응답수' value={responseCount} unit='건' />
      </div>
      <DeleteAndCopyButtons
        algorithmId={algorithmId}
        canUpsertAutomation={canUpsertAutomation}
        canDeleteAutomation={canDeleteAutomation}
      />
    </CardItemLayout>
  );
}

interface DeleteAndCopyButtonsProps {
  algorithmId: string;
  canUpsertAutomation: boolean;
  canDeleteAutomation: boolean;
}

const DeleteAndCopyButtons = ({
  algorithmId,
  canUpsertAutomation,
  canDeleteAutomation,
}: DeleteAndCopyButtonsProps) => {
  const { handleDeleteAlgorithmWithModal } = useDeleteAlgorithm({ type: 'MARKETING' });
  const openAutomationDialog = useOpenAutomationDialog();

  const setMessageMode = useSetAtom(algorithmModeState);
  const setSelectedAutomationTab = useSetAtom(selectedMarketingAutomationTabState);

  const onClickCopyButton = () => {
    openAutomationDialog(
      <RegisterMarketingAutomationDialogContainer />,
      OVERLAY_PAGE_ID['register-marketing-automation-dialog'],
    );

    setMessageMode({
      algorithmId,
      mode: 'COPY',
    });
    setSelectedAutomationTab(MARKETING_TAB_ITEMS[0].value);
  };

  return (
    <div className='flex w-full gap-10' onClick={(e) => e.stopPropagation()}>
      {canDeleteAutomation && (
        <button
          className='h-22 w-full rounded-r6 border border-white700 bg-white50 text-Body11 text-white700'
          type='button'
          onClick={() => {
            handleDeleteAlgorithmWithModal(algorithmId);
          }}>
          삭제
        </button>
      )}
      {canUpsertAutomation && (
        <button
          className='h-22 w-full rounded-r6 border border-blue500 bg-white50 text-Body11 text-blue500'
          type='button'
          onClick={onClickCopyButton}>
          복제
        </button>
      )}
    </div>
  );
};
