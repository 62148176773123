import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { Hospital, TreatmentTag } from '@apis/swaggers/swagger-docs';
import { useNavigationBlocker } from '@shared/hooks/use-navigation-blocker';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CustomerInfoContainer from '@templates/HospitalSetting/containers/ServiceSetting/containers/CustomerInfo/CustomerInfo.container';
import {
  displayedPatientsFieldsState,
  initialDisplayedPatientsFieldsState,
} from '@templates/HospitalSetting/containers/ServiceSetting/containers/CustomerInfo/states/displayed-patients-fields';
import TreatmentTagContainer from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/TreatmentTag.container';
import {
  initialTreatmentTagsState,
  treatmentTagsState,
} from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import { showColorPickerIndexState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/show-color-picker-index';
import { tagInputErrorMessageState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/tag-input-error-message-state';
import UsingReservationSettingContainer from '@templates/HospitalSetting/containers/ServiceSetting/containers/UsingReservationSetting/UsingReservationSetting.container';
import {
  initialUseSaasReservationState,
  useSaasReservationState,
} from '@templates/HospitalSetting/containers/ServiceSetting/containers/UsingReservationSetting/states/hospital-use-saas-reservation-state';
import { useServiceSettingUpdate } from '@templates/HospitalSetting/containers/ServiceSetting/hooks/use-service-setting-update';
import { hospitalSettingsModifiedState } from '@templates/HospitalSetting/states/hospital-settings-modified-state';
import { funcSaveButtonState } from '@templates/HospitalSetting/states/save-button-disabled-state';
import type { PatientsField } from '@templates/HospitalSetting/types/PatientsField';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtomValue, useSetAtom } from 'jotai';
import { useAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const updateHospitalInfo = async (hospital: Hospital) => {
  const response = await apiClient.v3.apiHospitalsElUpdate(hospital);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const checkDiff = <T extends PatientsField | TreatmentTag[]>(targetValue: T, initialValue: T) => {
  return isEqual(targetValue, initialValue);
};

const checkHospitalSettingsModified = (
  patientsFields: PatientsField,
  initialPatientsFields: PatientsField,
  treatmentTags: TreatmentTag[],
  initialTreatmentTags: TreatmentTag[],
  useSaasReservation: boolean,
  initialUseSaasReservation: boolean,
) => {
  const isPatientsFieldsEqual = checkDiff<PatientsField>(patientsFields, initialPatientsFields);
  const isTreatmentTagsEqual = checkDiff<TreatmentTag[]>(
    treatmentTags.slice(1),
    initialTreatmentTags,
  );
  const isSaaSReservationStateEqual = useSaasReservation === initialUseSaasReservation;
  return isPatientsFieldsEqual && isTreatmentTagsEqual && isSaaSReservationStateEqual;
};

const validateTreatmentTags = (
  treatmentTags: TreatmentTag[],
  setTagInputErrorMessage: (msg: string) => void,
  hasInvalidOrDuplicateTreatmentTags: (tags: TreatmentTag[]) => boolean,
) => {
  if (hasInvalidOrDuplicateTreatmentTags(treatmentTags)) {
    const errorMsg = '태그명이 동일하거나, 비어있는 값이 있습니다. 아래 정보를 다시 확인해 주세요.';
    setTagInputErrorMessage(errorMsg);
    throw new Error('Duplicate treatment tags found');
  }
};

export default function ServiceSettingContainer() {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const resetShowColorPickerIndex = useResetAtom(showColorPickerIndexState);
  const resetTagInputErrorMessage = useResetAtom(tagInputErrorMessageState);

  const treatmentTags = useAtomValue(treatmentTagsState);
  const initialTreatmentTags = useAtomValue(initialTreatmentTagsState);
  const useSaasReservation = useAtomValue(useSaasReservationState);
  const initialUseSaasReservation = useAtomValue(initialUseSaasReservationState);
  const patientsFields = useAtomValue(displayedPatientsFieldsState);
  const initialPatientsFields = useAtomValue(initialDisplayedPatientsFieldsState);

  const [hospitalSettingsModified, setHospitalSettingsModified] = useAtom(
    hospitalSettingsModifiedState,
  );

  const setSaveFunction = useSetAtom(funcSaveButtonState);
  const setTagInputErrorMessage = useSetAtom(tagInputErrorMessageState);

  const { hasInvalidOrDuplicateTreatmentTags, updatePatientField, syncTreatmentTags } =
    useServiceSettingUpdate();

  const updateHospitalMutation = useMutation({
    mutationFn: () =>
      updateHospitalInfo({ _id: hospitalID, usingSaaSReservation: useSaasReservation }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiHospitalsElFind] });
    },
  });

  const handleSave = useCallback(async () => {
    try {
      fullDimmedLoadingService.on();
      validateTreatmentTags(
        treatmentTags,
        setTagInputErrorMessage,
        hasInvalidOrDuplicateTreatmentTags,
      );
      await updatePatientField(initialPatientsFields, patientsFields);
      await syncTreatmentTags(initialTreatmentTags, treatmentTags);
      await updateHospitalMutation.mutateAsync();

      toastService.successMsg({ text: '저장되었습니다.' });
    } catch (err) {
      toastService.errorMsg({ text: '저장을 실패하였습니다. 정보를 다시 확인해 주세요.' });
      console.error('Error updating service setting', err);
    } finally {
      fullDimmedLoadingService.off();
    }
  }, [patientsFields, initialPatientsFields, treatmentTags, initialTreatmentTags]);

  useEffect(() => {
    setSaveFunction(handleSave);
  }, [handleSave, setSaveFunction]);

  useEffect(() => {
    if (patientsFields && initialPatientsFields && treatmentTags && initialTreatmentTags) {
      const isEquals = checkHospitalSettingsModified(
        patientsFields,
        initialPatientsFields,
        treatmentTags,
        initialTreatmentTags,
        useSaasReservation,
        initialUseSaasReservation,
      );
      setHospitalSettingsModified(!isEquals);
    }
  }, [
    treatmentTags,
    initialTreatmentTags,
    patientsFields,
    initialPatientsFields,
    useSaasReservation,
    initialUseSaasReservation,
  ]);

  useEffect(() => {
    return () => {
      resetShowColorPickerIndex();
      resetTagInputErrorMessage();
    };
  }, []);

  useNavigationBlocker({
    shouldBlock: hospitalSettingsModified,
  });

  return (
    <div className='bg-white50 pt-40'>
      <CustomerInfoContainer />
      <UsingReservationSettingContainer />
      <TreatmentTagContainer />
    </div>
  );
}
