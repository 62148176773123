const mode = import.meta.env.MODE;
const isUsingDevServer = mode === 'dev';
const isUsingDemoServer = mode === 'demo';
const isUsingProdServer = mode === 'prod';

const isUsingLocalServer = import.meta.env.VITE_ENABLE_LOCAL_BACKEND_SERVER === 'true';

const getAPIUrl = () => {
  if (isUsingLocalServer) {
    return 'http://localhost:8080';
  }

  if (isUsingDevServer) {
    return 'https://dev.api.afterdoc.net';
  }

  if (isUsingDemoServer) {
    return 'https://dev.demo.api.afterdoc.net';
  }

  if (isUsingProdServer) {
    return 'https://api.afterdoc.net';
  }

  return 'https://dev.api.afterdoc.net';
};

const getKakaoIntegrationAPIUrl = () => {
  if (isUsingLocalServer) {
    return 'http://localhost:8080';
  }

  if (isUsingDevServer) {
    return 'https://dev.ikakao.afterdoc.net';
  }

  if (isUsingDemoServer) {
    return 'https://dev.ikakao.afterdoc.net';
  }

  if (isUsingProdServer) {
    return 'https://ikakao.afterdoc.net';
  }

  return 'https://dev.ikakao.afterdoc.net';
};

const getKakaoProxyAPIUrl = () => {
  if (isUsingLocalServer) {
    return 'http://localhost:8080';
  }

  if (isUsingDevServer) {
    return 'https://dev.fileproxy.afterdoc.net/proxy/file/download';
  }

  if (isUsingDemoServer) {
    return 'https://dev.fileproxy.afterdoc.net/proxy/file/download';
  }

  if (isUsingProdServer) {
    return 'https://fileproxy.afterdoc.net/proxy/file/download';
  }

  return 'https://dev.fileproxy.afterdoc.net/proxy/file/download';
};

const getChatSocketUrl = () => {
  if (isUsingProdServer) {
    return 'https://chat.afterdoc.net';
  }

  return 'https://dev.chat.afterdoc.net';
};

export const API_URL = getAPIUrl();
export const KAKAO_INTEGRATION_API_URL = getKakaoIntegrationAPIUrl();
export const KAKAO_PROXY_URL = getKakaoProxyAPIUrl();
export const CHAT_SOCKET_URL = getChatSocketUrl();
// export const NEXT_PUBLIC_API_URL = 'https://4979-210-223-83-166.ngrok-free.app';
