import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import ContentContainer from 'web/templates/Content/Content.container';

export default function ContentPage() {
  return (
    <Scrollbar isAlwaysVisible>
      <ContentContainer />
    </Scrollbar>
  );
}
