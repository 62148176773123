export const makePlaceholder = (categoryTitle: string) => {
  switch (categoryTitle) {
    case '홈케어 상품':
      return '홈케어 상품 제목으로 검색';
    case '이벤트':
      return '이벤트 제목으로 검색';
    case '공지사항':
      return '공지사항 제목으로 검색';
    default:
      return '키워드 혹은 제목으로 검색';
  }
};
