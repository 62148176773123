import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { Suspense } from 'react';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import AddFolderAndDownloadManualBox from 'web/templates/HospitalManual/components/HospitalManualPanel/components/AddFolderAndDownloadManualBox/AddFolderAndDownloadManualBox';
import ManualFolderAndTreatmentTagsLists from 'web/templates/HospitalManual/components/HospitalManualPanel/components/ManualFolderAndTreatmentTagsLists/ManualFolderAndTreatmentTagsLists';
import TagSearchInput from 'web/templates/HospitalManual/components/HospitalManualPanel/components/TagSearchInput/TagSearchInput';

export default function HospitalManualPanel() {
  const { authorizationTypeID } = useUserInfo();
  const canUserControlManualFolderAndTagStatus =
    authorizationTypeID?.canControlManualFolderAndTagStatus;

  return (
    <div className='border-r border-r-white400 bg-white50'>
      <Title
        title='원내매뉴얼'
        subTitle={
          '폴더를 우클릭하여 폴더명 변경 및 삭제를 할 수 있으며,\n드래그하여 매뉴얼의 순서를 변경할 수 있습니다.'
        }
        wrapperClassName='border-b border-white400'
      />
      <LayoutBox noStyle>
        {canUserControlManualFolderAndTagStatus && <AddFolderAndDownloadManualBox />}
      </LayoutBox>
      <LayoutBox noStyle>
        <TagSearchInput />
      </LayoutBox>
      <Suspense
        fallback={
          <div className='h-[calc(100vh-179px)] flex-w-full-center'>
            <FullLoading />
          </div>
        }>
        <ManualFolderAndTreatmentTagsLists />
      </Suspense>
    </div>
  );
}
