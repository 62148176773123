import { apiClient } from '@apis/instances/api-client';
import type { File as MPFile } from '@apis/swaggers/swagger-docs';
import { PutObjectCommand, type PutObjectCommandInput } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import {
  AWS_FILE_BUCKET_NAME,
  AWS_FILE_REGION,
  s3,
} from '@shared/utils/fileUpload/constants/awsConfig';
import type { AwsResult, s3FileUploadParams } from '@shared/utils/fileUpload/types/types';
import dayjs from 'dayjs';

type ServerFileUploadParams = {
  userID: string;
  type: number;
  paths: string[];
  aws: AwsResult[];
};

async function uploadFiles({ files, sizes, type, userID, flags }: s3FileUploadParams) {
  let fileArr: File[] = [];

  if (Array.isArray(files)) {
    fileArr = files;
  }

  const model: ServerFileUploadParams = {
    userID,
    type,
    paths: [],
    aws: [],
  };

  const awsResults: AwsResult[] = await Promise.all(
    fileArr.map((file, i) => uploadFile(file, sizes[i], userID)),
  );

  for (const result of awsResults) {
    model.paths.push(result.Location);
    model.aws.push(result);
  }

  if (flags) {
    flags.push(true);
  }

  return await createFileToServerRequest(model);
}

async function uploadFile(file: File, size: object, userID: string): Promise<AwsResult> {
  const key = `${userID}/${dayjs().format('YYYYMMDDHHmmssSSS')}/${file.name}`;

  const params: PutObjectCommandInput = {
    Bucket: AWS_FILE_BUCKET_NAME,
    Key: key,
    ContentType: file.type,
    ACL: 'public-read',
  };

  try {
    // 1. 먼저 presigned URL을 생성
    const command = new PutObjectCommand(params);
    const signedUrl = await getSignedUrl(s3, command, { expiresIn: 3600 });

    // 2. presigned URL을 사용하여 파일 업로드
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
      },
    });

    if (!response.ok) {
      throw new Error(`Upload failed: ${response.statusText}`);
    }

    const encodedKey = encodeURIComponent(key);

    return {
      Bucket: AWS_FILE_BUCKET_NAME,
      Key: key,
      key: key,
      Location: `https://${AWS_FILE_BUCKET_NAME}.s3.${AWS_FILE_REGION}.amazonaws.com/${encodedKey}`,
      ETag: response.headers.get('ETag') ?? undefined,
      size,
      fileName: file.name,
      fileSize: file.size,
    };
  } catch (err) {
    console.error('Error uploading file to AWS-S3:', err);
    throw err;
  }
}

const createFileToServerRequest = async (params: ServerFileUploadParams) => {
  try {
    const response = await apiClient.v3.apiFiles(params);
    if (response.data.code !== 0 || !response.data.data) {
      throw new Error(response.data.message);
    }
    return response.data.data as MPFile;
  } catch (error) {
    throw new Error(`Error uploading file to server: ${error ?? ''}`);
  }
};

export const FileUploader = {
  uploadFiles,
};
