import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiManualsElFoldersParams, ManualFolderList } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import { settingFolderOpen } from '../functions/setting-folder-open';
import { settingNoFolderOpen } from '../functions/setting-no-folder-open';

const getManualsFolders = async (query: ApiManualsElFoldersParams) => {
  const { data } = await apiClient.v3.apiManualsElFolders(query);
  return SHARED_UTILS.api.checkApiResponse(data) as ManualFolderList;
};

export interface SubLayer {
  key: string;
  text: string;
  color: string;
  isOpen: boolean;
}

export interface Layer {
  title: string;
  isOpen: boolean;
  items: SubLayer[];
}

export interface ManusFolders extends Layer {
  id: string;
  type: string;
  color: string;
}

export const useManusFolders = ({
  keyword,
  chatRoomID,
}: { keyword: string; chatRoomID: string | null }) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const { treatmentTags, automationAppliedTreatmentTag } = usePatient();
  const firstOpenIndex = useRef<number>(-1);

  //cleanup 보다 훨씬 안정적
  useEffect(() => {
    firstOpenIndex.current = -1;
  }, [chatRoomID]);

  const automationTreatmentTagId = automationAppliedTreatmentTag?.tagId ?? undefined;

  const treatmentTagsIDs = useMemo(
    () => [
      ...(automationTreatmentTagId ? [automationTreatmentTagId] : []),
      ...(treatmentTags ?? []).map((tag) => tag.tagId),
    ],
    [automationTreatmentTagId, treatmentTags],
  );

  const query = useMemo(
    () => ({
      hospitalID,
      ...(keyword && { folderOrTagName: keyword }),
      onlyActive: true,
    }),
    [hospitalID, keyword],
  );

  const { data, refetch, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiManualsElFolders, query] as const,
    queryFn: ({ queryKey }) => getManualsFolders(queryKey[1]),
    enabled: !!hospitalID,
  });

  const [newData, setNewData] = useState<ManusFolders[]>([]);

  useEffect(() => {
    if (data) {
      const isSearchMode = !!keyword;
      const firstTreatmentTagID = treatmentTagsIDs[0];
      const tagsWithFolder: ManusFolders[] = settingFolderOpen({
        data,
        treatmentTagsIDs,
        isSearchMode,
        firstTreatmentTagID,
      });

      const tagsNoFolder = settingNoFolderOpen({ data, firstTreatmentTagID });
      const concatData = [...tagsWithFolder, ...tagsNoFolder];
      setNewData(concatData);

      if (treatmentTagsIDs.length === 0) return;

      let count = 0;

      for (const { id, type, items, isOpen } of concatData) {
        if (type === 'folder') {
          if (!isOpen) {
            count += 1;
          } else {
            count += 1;
            for (const { key } of items) {
              if (key === firstTreatmentTagID) {
                firstOpenIndex.current = count;

                return;
              }
              count += 1;
            }
          }
        } else {
          if (id === firstTreatmentTagID) {
            firstOpenIndex.current = count;
            return;
          }
          count += 1;
        }
      }
    }
  }, [data, treatmentTagsIDs, keyword]);

  const folderOpenMap = useMemo(() => {
    const map = new Map();
    for (const { id, isOpen, type } of newData) {
      if (type === 'tag') {
        map.set(id, isOpen);
      } else {
        map.set(id, isOpen);
        // for (const { key, isOpen } of items) {
        //   map.set(key, isOpen);
        // }
      }
    }

    return map;
  }, [newData]);

  const settingTagPanelOpenMap = useMemo(() => {
    const map = new Map();
    for (const { id, items, type, isOpen } of newData) {
      if (type === 'folder') {
        for (const { key, isOpen } of items) {
          map.set(key, isOpen);
        }
      } else {
        map.set(id, isOpen);
      }
    }

    return map;
  }, [newData]);

  return {
    data: newData,
    folderOpenMap,
    settingTagPanelOpenMap,
    firstOpenIndex: firstOpenIndex.current,
    isLoading,
    refetch,
  };
};
