import type { UserHServicePhotoAuthorizationType } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import AccountUpdateDialog from '@templates/HospitalSetting/containers/AllAccountSetting/containers/AllAccountList/components/AccountUpdateDialog/AccountUpdateDialog';
import type {
  RemoveHospitalAccountParams,
  UpdateHospitalAccountParams,
} from '@templates/HospitalSetting/containers/AllAccountSetting/hooks/use-hospital-account';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useEffect, useState } from 'react';

interface AccountListTableRowProps {
  account: UserHServicePhotoAuthorizationType;
  handleChangeAccount: (params: UpdateHospitalAccountParams) => void;
  handleRemoveAccount: (params: RemoveHospitalAccountParams) => void;
}

type AuthorizationType = Exclude<UpdateHospitalAccountParams['authorizationType'], undefined>;

const generateClassName = (width: number, additionalClasses?: string) => {
  return `w-[${String(width)}px] px-12 py-4 ${additionalClasses ? additionalClasses : ''}`;
};

export default function AccountListTableRow({
  account,
  handleChangeAccount,
  handleRemoveAccount,
}: AccountListTableRowProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const { _id, jobType, realName, id, authorizationTypeID, isDefaultCounselor } = account;
  const [items, setItems] = useState<string[]>(['']);

  const handleRemoveButtonClick = () => {
    modalService.defaultWarning({
      title: '계정을 삭제하시겠어요?',
      contents: (
        <span className='whitespace-pre-wrap '>
          <span className='font-bold'>{realName}</span> 계정을 삭제할까요?{'\n'}
          계정 삭제시 복구가 불가능합니다.
        </span>
      ),
      onConfirm: () => {
        handleRemoveAccount({ _id, hospitalID });
        modalService.pop();
      },
    });
  };

  const handleChangeAuthorizationType = (index: number) => {
    if (index < items.length) {
      const type = items[index];
      const params: UpdateHospitalAccountParams = {
        _id,
        hospitalID,
        authorizationType: type as AuthorizationType,
      };
      handleChangeAccount(params);
    }
  };

  const handleChangeCounselor = () => {
    const params: UpdateHospitalAccountParams = {
      _id,
      hospitalID,
      isDefaultCounselor: !isDefaultCounselor,
    };
    handleChangeAccount(params);
  };

  const handleOpenDialog = () => {
    dialogService.push(<AccountUpdateDialog account={account} />, {
      titleProps: {
        title: '계정 수정',
      },
      width: 500,
      wrapperClassName: 'h-fit mt-0 mb-0 overflow-visible',
    });
  };

  useEffect(() => {
    if (authorizationTypeID?.name === '마스터') {
      setItems(['마스터']);
    } else {
      setItems(['관리자', '직원']);
    }
  }, [authorizationTypeID]);

  return (
    <>
      <tr key={`${_id}`} className='min-h-[40px] border-[#C5CDE5] border-b text-Body12'>
        <td className={generateClassName(145, 'whitespace-pre-wrap break-all')}>{jobType}</td>
        <td className={generateClassName(145, 'whitespace-pre-wrap break-all')}>{realName}</td>
        <td className={generateClassName(134, 'whitespace-pre-wrap break-all')}>{id}</td>
        <td className={generateClassName(150)}>
          <TextFieldSelectBoxDropdown
            options={items}
            onSelect={handleChangeAuthorizationType}
            disabled={authorizationTypeID?.name === '마스터'}
            selectedIndex={
              items.findIndex((type) => type === authorizationTypeID?.name) !== -1
                ? items.findIndex((type) => type === authorizationTypeID?.name)
                : undefined
            }
          />
        </td>
        <td className={generateClassName(100)}>
          <Checkbox
            id={`radio-${_id}`}
            label='지정'
            gapSize={5}
            onChange={handleChangeCounselor}
            checked={isDefaultCounselor}
          />
        </td>
        <td className={generateClassName(132)}>
          <div className='flex gap-10'>
            <ContainedButton
              buttonSize='small'
              btnColor='secondary'
              onClick={handleOpenDialog}
              className='h-22 min-w-50 flex-center text-Body11 text-black200'>
              수정
            </ContainedButton>
            <ContainedButton
              buttonSize='small'
              btnColor='secondary'
              onClick={handleRemoveButtonClick}
              className='h-22 min-w-50 flex-center text-Body11 text-black200'>
              삭제
            </ContainedButton>
          </div>
        </td>
      </tr>
    </>
  );
}
