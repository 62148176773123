import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Toggle from 'afterdoc-design-system/components/Atoms/Toggle/Toggle';
import { useFormContext } from 'react-hook-form';
import type { CommonAlgorithmInput } from 'web/apis/swaggers/swagger-docs';
import { useUserInfo } from 'web/shared/hooks/use-user-info';

export default function UsingCommonAlgorithmOrNotToggle() {
  const { setValue, watch } = useFormContext<CommonAlgorithmInput>();
  const { authorizationTypeID } = useUserInfo();

  const canUpsertAutomation = !!authorizationTypeID?.canUpsertAutomation;
  const isActive = watch('isActive');

  return (
    <div className='w-70'>
      <LabelText className='mb-10'>사용여부</LabelText>
      <Toggle
        disabled={!canUpsertAutomation}
        checked={isActive}
        label={isActive ? 'ON' : 'OFF'}
        onChange={(e) => {
          setValue('isActive', e.target.checked);
        }}
      />
    </div>
  );
}
