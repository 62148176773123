import {
  eventPushEndDateAtom,
  eventPushStartDateAtom,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/event-push-request-create-param-state';
import { isUseDateRangeFilterState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/is-use-date-range-filter-state';
import {
  type ErrorType,
  signupRangeErrorTypeState,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushSelector/states/signup-range-error-type-state';
import SignupDatePicker from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PushTargetSelector/components/SignupRangeFilter/components/SignupDatePicker';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import { useAtom } from 'jotai/index';
import { useEffect } from 'react';

const signupRangeErrorMessage: { [key in ErrorType]: string } = {
  none: '',
  invalidFormat: '날짜를 다시 확인해 주세요.',
  endDateBeforeStartDate: '종료일이 시작일보다 빠릅니다.',
};

export default function SignupRangeFilter() {
  const [isUseDateRangeFilter, setIsUseDateRangeFilter] = useAtom(isUseDateRangeFilterState);

  const [startedAt, setStartedAt] = useAtom(eventPushStartDateAtom);
  const [endedAt, setEndedAt] = useAtom(eventPushEndDateAtom);

  const [errorType, setErrorType] = useAtom(signupRangeErrorTypeState);

  const handleChange = () => {
    setIsUseDateRangeFilter((prev) => !prev);
  };

  useEffect(() => {
    if (!isUseDateRangeFilter) {
      setStartedAt(null);
      setEndedAt(null);
    }
  }, [isUseDateRangeFilter]);

  useEffect(() => {
    setErrorType('none');
  }, [startedAt, endedAt]);

  return (
    <div className='mx-35 flex min-h-32 items-center gap-10'>
      <Checkbox
        checked={isUseDateRangeFilter}
        label={'가입기간'}
        labelProps={{ className: 'w-60' }}
        onChange={handleChange}
      />
      <div className='flex flex-col'>
        <div className='flex-center gap-10'>
          <SignupDatePicker type='start' isDisabled={!isUseDateRangeFilter} />
          <p className='text-Header12 text-black500'>~</p>
          <SignupDatePicker type='end' isDisabled={!isUseDateRangeFilter} />
        </div>
        {errorType !== 'none' && (
          <p className='mt-4 text-Body10 text-red500'>{signupRangeErrorMessage[errorType]}</p>
        )}
      </div>
    </div>
  );
}
