import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtomValue } from 'jotai';
import { isEqual } from 'lodash-es';
import { apiClient } from 'web/apis/instances/api-client';
import type {
  AlgorithmMessage,
  DeleteMessageInput,
  MessageInput,
  NewMessageInput,
  UpdateAlgorithmMainDataHandlerBodyRequest,
  UpdateMessageInput,
} from 'web/apis/swaggers/swagger-docs';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';

const createCounselAutomationMessages = async (params: NewMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateCounselAutomationMessages = async (params: UpdateMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesUpsertSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateAlgorithmMainData = async (params: UpdateAlgorithmMainDataHandlerBodyRequest) => {
  const response = await apiClient.v3.updateAlgorithmMainDataHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const deleteCounselAutomationMessages = async (params: DeleteMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesDeleteSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};
export const useHandleCounselAutomationMessages = () => {
  const algorithmMode = useAtomValue(algorithmModeState);
  const originMessages = useAtomValue(originMessagesState);

  const updateAlgorithmMainDataMutation = useMutation({
    mutationFn: updateAlgorithmMainData,
  });

  const handleAutomationMessages = async ({
    onSuccess,
    newMessagesValues,
    setDisabled,
  }: {
    onSuccess?: () => void;
    newMessagesValues: (MessageInput & { id: string; kakaoTemplateHistoryId?: string })[];
    setDisabled: (disabled: boolean) => void;
  }) => {
    if (algorithmMode.mode !== 'EDIT') return;
    setDisabled(true);

    try {
      for (const newMsg of newMessagesValues) {
        const isNewMessage = !originMessages.some((originMsg) => originMsg.id === newMsg.id);

        if (isNewMessage) {
          await createCounselAutomationMessages({
            algorithmId: algorithmMode.algorithmId,
            name: newMsg.name,
            sendingType: newMsg.sendingType,
            daysAfter: newMsg.daysAfter,
            timeOfDay: newMsg.timeOfDay,
            content: newMsg.content,
            showConsultationGuide: newMsg.showConsultationGuide,
            consultationGuide: newMsg.showConsultationGuide ? newMsg.consultationGuide : undefined,
          });
          continue;
        }

        const prevFilteredOriginalMsg = originMessages.find((msg) => msg.id === newMsg.id);

        const filteredOriginalMsg: Omit<AlgorithmMessage, 'content'> & {
          content: MessageInput['content'];
        } = {
          consultationGuide: prevFilteredOriginalMsg?.consultationGuide || undefined,
          content: {
            event: prevFilteredOriginalMsg?.content?.event?._id as string,
            homecareContent: prevFilteredOriginalMsg?.content?.homecareContent?._id as string,
            video: prevFilteredOriginalMsg?.content?.video?._id as string,
            workoutVideo: prevFilteredOriginalMsg?.content?.workoutVideo?._id as string,
            image: prevFilteredOriginalMsg?.content?.imageFileID as string,
            survey: prevFilteredOriginalMsg?.content?.survey?.answers?.length
              ? prevFilteredOriginalMsg?.content?.survey
              : undefined,
            text: prevFilteredOriginalMsg?.content?.text,
          },
          createdAt: prevFilteredOriginalMsg?.createdAt as string,
          daysAfter: prevFilteredOriginalMsg?.daysAfter,
          id: prevFilteredOriginalMsg?.id as string,
          kakaoTemplateHistoryId: prevFilteredOriginalMsg?.kakaoTemplateHistoryId,
          name: prevFilteredOriginalMsg?.name as string,
          sendingType: prevFilteredOriginalMsg?.sendingType as
            | 'BASIC'
            | 'REVISIT_INDUCEMENT'
            | 'SURVEY',
          showConsultationGuide: prevFilteredOriginalMsg?.showConsultationGuide ?? false,
          timeOfDay: prevFilteredOriginalMsg?.timeOfDay,
          updatedAt: prevFilteredOriginalMsg?.updatedAt as string,
        };

        if (filteredOriginalMsg && !isEqual(filteredOriginalMsg, newMsg)) {
          await updateCounselAutomationMessages({
            messageId: newMsg.id,
            algorithmId: algorithmMode.algorithmId,
            name: newMsg.name,
            sendingType: newMsg.sendingType,
            daysAfter: newMsg.daysAfter,
            timeOfDay: newMsg.timeOfDay,
            content: newMsg.content,
            showConsultationGuide: newMsg.showConsultationGuide,
            consultationGuide: newMsg.showConsultationGuide ? newMsg.consultationGuide : undefined,
          });
        }
      }

      const deletedMessages = originMessages.filter(
        (originMsg) => !newMessagesValues.some((newMsg) => newMsg.id === originMsg.id),
      );

      for (const deletedMsg of deletedMessages) {
        await deleteCounselAutomationMessages({ messageId: deletedMsg.id });
      }

      onSuccess?.();
    } catch (error) {
      console.error('Error while processing messages:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
    } finally {
      setDisabled(false);
    }
  };

  const handleUpdateAlgorithmMainData = async ({
    updatedAlgorithmValues,
  }: {
    updatedAlgorithmValues: UpdateAlgorithmMainDataHandlerBodyRequest;
  }) => {
    await updateAlgorithmMainDataMutation.mutateAsync(updatedAlgorithmValues);
  };

  return {
    handleAutomationMessages,
    handleUpdateAlgorithmMainData,
  };
};
