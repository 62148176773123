import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import Dropdown from 'afterdoc-design-system/components/Molecules/Dropdown/BaseDropdown/BaseDropdown';
import TextFieldSelectBox from 'afterdoc-design-system/components/Molecules/TextFieldSelectBox/TextFieldSelectBox';
import { type RefObject, useState } from 'react';
import type { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';

const SCALE_OPTIONS = [5, 25, 50, 75, 100];

interface ZoomInOutControllerProps {
  scale: number;
  onChangeScale: (scale: number) => void;
  transformRef: RefObject<ReactZoomPanPinchRef>;
}

export default function ZoomInOutController({
  scale,
  onChangeScale,
  transformRef,
}: ZoomInOutControllerProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleZoomIn = () => {
    if (scale < 100 && transformRef.current) {
      const newScale = Math.min(scale + 25, 100);
      onChangeScale(newScale);
      const scaleValue = newScale / 100;
      transformRef.current.setTransform(0, 0, scaleValue);
    }
  };

  const handleZoomOut = () => {
    if (scale > 5 && transformRef.current) {
      const newScale = Math.max(scale - 25, 5);
      onChangeScale(newScale);
      const scaleValue = newScale / 100;
      transformRef.current.setTransform(0, 0, scaleValue);
    } else if (scale > 0 && scale <= 5 && transformRef.current) {
      onChangeScale(5);
      transformRef.current.setTransform(0, 0, 0.05);
    }
  };

  return (
    <div className='absolute right-[20px] bottom-[100px] flex items-center gap-4'>
      <IconButton
        icon='minus'
        size={24}
        color='black200'
        className='rounded-r10 border border-white400 bg-white50 p-4'
        onClick={handleZoomOut}
        disabled={scale <= 5}
      />
      <div className='relative'>
        {isDropdownOpen && (
          <Dropdown
            wrapperClassName='bottom-[30px] -left-[40px]'
            handleToggle={() => setIsDropdownOpen(false)}
            width={115}
            options={SCALE_OPTIONS.map((option) => `${option}%`)}
            onSelect={(index) => {
              const newScale = SCALE_OPTIONS[index];
              onChangeScale(newScale);
              const scaleValue = newScale / 100;
              if (transformRef.current) {
                transformRef.current.setTransform(0, 0, scaleValue);
              }
              setIsDropdownOpen(false);
            }}
            selectedIndex={SCALE_OPTIONS.indexOf(scale)}
            focusedIndex={SCALE_OPTIONS.indexOf(scale)}
          />
        )}
        <TextFieldSelectBox
          value={`${scale}%`}
          width={80}
          onClickInput={() => setIsDropdownOpen(true)}
        />
      </div>
      <IconButton
        icon='plus'
        size={24}
        color='black200'
        className='rounded-r10 border border-white400 bg-white50 p-4'
        onClick={handleZoomIn}
        disabled={scale >= 100}
      />
    </div>
  );
}
