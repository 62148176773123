import * as React from "react";
import type { SVGProps } from "react";
const SvgCode = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M5 7 3 9l2 2M13 7l2 2-2 2"
      className="code_svg__ql-even code_svg__ql-stroke"
    />
    <path d="m10 5-2 8" className="code_svg__ql-stroke" />
  </svg>
);
export default SvgCode;
