import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiHospitalsAccountsElFindParams } from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useMutation, useQuery, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { SHARED_UTILS } from 'utils/utils';

export type UpdateHospitalAccountParams = Parameters<
  typeof apiClient.v3.apiHospitalsAccountsElUpdate
>[0];

export type RemoveHospitalAccountParams = Parameters<
  typeof apiClient.v3.apiHospitalsAccountsElUpdate
>[0];

const fetchAccounts = async (params: ApiHospitalsAccountsElFindParams) => {
  const response = await apiClient.v3.apiHospitalsAccountsElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateAccount = async (params: UpdateHospitalAccountParams) => {
  const response = await apiClient.v3.apiHospitalsAccountsElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data).isUpdatedSuccess;
};

const removeAccount = async (params: RemoveHospitalAccountParams) => {
  const response = await apiClient.v3.apiHospitalsAccountsElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(response.data).isDeletedSucess;
};

export const useHospitalAccount = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const fetchAccountList = () =>
    useSuspenseQuery({
      queryKey: [QUERY_KEY.apiHospitalsAccountsElFind, { hospitalID }] as const,
      queryFn: ({ queryKey }) => fetchAccounts(queryKey[1]),
    });
  const fetchAccountListWithOutSuspense = () =>
    useQuery({
      queryKey: [QUERY_KEY.apiHospitalsAccountsElFind, { hospitalID }] as const,
      queryFn: ({ queryKey }) => fetchAccounts(queryKey[1]),
    });

  const updateAccountMutation = useMutation({
    mutationFn: updateAccount,
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiHospitalsAccountsElFind] });
    },
  });

  const removeAccountMutation = useMutation({
    mutationFn: removeAccount,
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiHospitalsAccountsElFind] });
    },
  });

  return {
    fetchAccountList,
    fetchAccountListWithOutSuspense,
    updateAccount: updateAccountMutation.mutate,
    removeAccount: removeAccountMutation.mutate,
  };
};
