import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import type { TagProperty } from 'afterdoc-design-system/components/Molecules/Dropdown/TagDropdown/types/tag-dropdown';
import { useVirtualizedKeyboardNavigation } from 'afterdoc-design-system/components/Molecules/Dropdown/VirtualizedTagDropdown/hooks/use-virtualized-dropdown-keyboard-navigation';
import {
  type CSSProperties,
  type RefObject,
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FixedSizeList } from 'react-window';
import { v4 as uuidv4 } from 'uuid';
import './VirtualizedTagDropdown.scss';

export interface TagDropdownProps {
  tags: TagProperty[];
  isComposing?: boolean;
  isToggle: boolean;
  id?: string;
  searchText?: string;
  onSelect?: (index: number) => void;
  handleToggle: (isToggle?: boolean) => void;
  redTextIndices?: number[];
  wrapperClassName?: string;
  tagsClassName?: string;
  width?: number | string;
  wrapperStyle?: CSSProperties;
  tagStyle?: CSSProperties;
  ignoreRefs?: RefObject<HTMLElement>[];
  focusedIndex?: number;
  onFocusChange?: (index: number) => void;
  isDefaultFocus?: boolean;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
  handleClickOutside?: () => void;
}

interface VirtualizedTagItemProps {
  index: number;
  style: CSSProperties;
  data: {
    tags: TagProperty[];
    tagMaxLengths: number[];
    selectedIndex?: number;
    focusedIndex?: number;
    onSelect: (index: number) => void;
    onFocusChange?: (index: number) => void;
    redTextIndices: number[];
  };
}

const VirtualizedTagItem = memo(({ index, style, data }: VirtualizedTagItemProps) => {
  const {
    tags,
    tagMaxLengths,
    selectedIndex,
    focusedIndex,
    onSelect,
    onFocusChange,
    redTextIndices,
  } = data;
  const option = tags[index];

  return (
    <div
      style={{ ...style }}
      tabIndex={index}
      onClick={() => onSelect(index)}
      onMouseEnter={() => onFocusChange?.(index)}
      className={customTwMerge(
        `block cursor-pointer px-10 py-7 focus:bg-blue50 focus:outline-none ${
          redTextIndices.includes(index) ? 'text-red500' : 'text-black500'
        } flex items-center justify-between hover:bg-blue50`,
        focusedIndex === index ? 'bg-blue50' : '',
        selectedIndex === index ? 'text-Header12' : 'text-Body12',
      )}
      id={option.id}
      item-index={index}
      aria-labelledby='virtualized-dropdown-option'
      aria-selected={selectedIndex === index ? 'true' : undefined}>
      <FilledTag maxTextLength={tagMaxLengths[index]} bgColor={option.color ?? '#F5F5F5'}>
        {option.name}
      </FilledTag>
    </div>
  );
});

export default function VirtualizedTagDropdown({
  id,
  tags,
  isComposing,
  isToggle,
  searchText,
  onSelect,
  handleToggle,
  ignoreRefs,
  redTextIndices = [],
  wrapperClassName,
  width,
  wrapperStyle,
  onFocusChange,
}: TagDropdownProps) {
  const dropdownId = useRef(id ?? uuidv4()).current;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<FixedSizeList>(null);

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(undefined);

  const listData = useMemo(
    () => ({
      tags,
      tagMaxLengths: tags.map((tag) => tag.name.length),
      focusedIndex,
      onSelect: (index: number) => {
        onSelect?.(index);
      },
      onFocusChange,
      redTextIndices,
    }),
    [tags, focusedIndex, onSelect, onFocusChange, redTextIndices],
  );

  const handleKeyDown = useVirtualizedKeyboardNavigation({
    listRef,
    tags,
    isComposing,
    focusedIndex,
    setFocusedIndex,
    onSelect: (index) => {
      onSelect?.(index);
    },
    handleToggle,
  });

  useClickOutside({
    id: dropdownId,
    ref: dropdownRef,
    contentRef,
    onClose: () => {
      handleToggle(false);
    },
    ignoreRefs: ignoreRefs ?? [],
  });

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (!isToggle) return;
      handleKeyDown(event);
    };

    window.addEventListener('keydown', keydownListener);
    return () => {
      window.removeEventListener('keydown', keydownListener);
    };
  }, [handleKeyDown, isToggle]);

  useEffect(() => {
    if (searchText && searchText.length > 0 && tags.length > 0) {
      setFocusedIndex(0);
    }
  }, [searchText, tags]);

  useEffect(() => {
    const handleDropdownOpen = (event: CustomEvent) => {
      if (event.detail.dropdownId !== dropdownId && isToggle) {
        handleToggle(false);
      }
    };

    window.addEventListener('dropdownOpen', handleDropdownOpen as EventListener);

    return () => {
      window.removeEventListener('dropdownOpen', handleDropdownOpen as EventListener);
    };
  }, [dropdownId, isToggle, handleToggle]);

  return (
    <div ref={dropdownRef} className='relative'>
      <div
        ref={contentRef}
        className={customTwMerge(
          'absolute z-10 mt-2 select-none rounded-r6 bg-white50 py-4 shadow-modal ring-1 ring-black ring-opacity-5',
          wrapperClassName,
        )}
        data-ignore-click-outside={true}
        style={{
          height: tags.length > 0 ? 33 * Math.min(tags.length, 5) + 8 : 40,
          width: SHARED_UTILS.css.getCssSizeValue(width),
          ...wrapperStyle,
        }}>
        {tags.length > 0 ? (
          <FixedSizeList
            ref={listRef}
            height={33 * Math.min(tags.length, 5)}
            width='100%'
            itemCount={tags.length}
            itemSize={33}
            itemData={listData}
            className='virtualizedList pr-4'>
            {VirtualizedTagItem}
          </FixedSizeList>
        ) : (
          <div className='px-8 py-9 text-Caption9 text-black200'>*등록 가능 태그가 없습니다.</div>
        )}
      </div>
    </div>
  );
}
