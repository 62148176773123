import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { Suspense } from 'react';
import ReservationHistoryContainer from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/ReservationHistory.container';
import TreatmentHistoryContainer from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/TreatmentHistory/TreatmentHistory.container';

export default function ReservationManagementContainer() {
  const { usingSaaSReservation } = useSelectedHospitalInfo();

  return (
    <div className='flex h-full flex-col gap-16'>
      <div className='flex flex-col gap-16'>
        {usingSaaSReservation ? (
          <>
            <div className='text-Header16 text-black700'>예약관리</div>
            {/* 치료내역 */}
            <Suspense
              fallback={
                <div className='flex h-full w-full flex-col gap-4'>
                  <div className='mb-16 text-Header14 text-black500'>치료내역</div>
                  <div className='h-[100px] flex-center'>
                    <BaseLoading />
                  </div>
                </div>
              }>
              <TreatmentHistoryContainer />
            </Suspense>
            {/* 예약내역 */}
            <ReservationHistoryContainer />
          </>
        ) : (
          <div className='min-h-[300px] flex-full-center'>
            <NoData
              title={'예약관리를 사용하시는 경우,\n해당 고객의 예약내역을 확인할 수 있습니다.'}
              iconProps={{
                name: 'warning',
                size: 48,
                color: 'white600',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
