import { Color } from '@tailwind-base/styles/color';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import { makePatientInfoStringDisplay } from '../../functions/makePatientInfoStringDisplay';

export default function PatientDetail() {
  const { isLoading, gender, birthdate, countryCode, phoneNumber, nationalityKoreanCountryName } =
    useChatTopPatient();

  const genderAndBirthDisplay = makePatientInfoStringDisplay({
    type: 'genderAndBirthdate',
    payload: {
      gender,
      birthdate,
    },
  });
  const { internationalDialingCodes } = countryCode || {};

  // const countryDisplay = makePatientInfoStringDisplay({
  //   type: 'country',
  //   payload: {
  //     koreanCountryName,
  //     internationalDialingCodes,
  //   },
  // });

  const phoneNumberDisplay = makePatientInfoStringDisplay({
    type: 'phoneNumber',
    payload: {
      phoneNumber,
      internationalDialingCodes,
    },
  });

  const nationalityName = makePatientInfoStringDisplay({
    type: 'nationalityKoreanCountryName',
    payload: {
      nationalityKoreanCountryName,
    },
  });

  return (
    <>
      {!isLoading && (
        <div className='mt-12 mb-8 flex gap-10'>
          <FilledTag
            maxTextLength={30}
            bgColor='#F7F7F7'
            textColor={Color.black500}
            className='rounded-r6 px-6'
            tagSize='big'>
            {genderAndBirthDisplay}
          </FilledTag>
          {/* 국제 번호 스펙 아웃으로 인한 주석처리 */}
          {/* <FilledTag
            bgColor='#F7F7F7'
            textColor={Color.black500}
            className='rounded-r6 px-6'
            tagSize='big'>
            {countryDisplay}
          </FilledTag> */}
          <FilledTag
            maxTextLength={30}
            bgColor='#F7F7F7'
            className='rounded-r6 px-6'
            textColor={Color.black500}
            tagSize='big'>
            {phoneNumberDisplay}
          </FilledTag>
          <FilledTag
            maxTextLength={30}
            bgColor='#F7F7F7'
            className='rounded-r6 px-6'
            textColor={Color.black500}
            tagSize='big'>
            {nationalityName}
          </FilledTag>
        </div>
      )}
    </>
  );
}
