import { SHARED_UTILS } from '@shared-utils/utils';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { apiClient } from 'web/apis/instances/api-client';
import type { LanguageNameAndAlpha2 } from 'web/apis/swaggers/swagger-docs';
import FilePicker, { type FilePickerHandle } from 'web/shared/components/FilePicker/FilePicker';
import { useServiceSettings } from 'web/shared/hooks/use-serivce-settings';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { FileUploadController } from 'web/shared/utils/fileUpload/FileUploadController';
import { FileService } from 'web/shared/utils/fileUpload/services/FileServices';
import type { FileUploadResponse } from 'web/shared/utils/fileUpload/types/types';
import { neverCheck } from 'web/shared/utils/never-check';
import { useLanguageList } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/components/ChattingSendingBottom/hooks/use-language-list';
import { REQUEST_ROOM_TYPE_MAP } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/constants/types-codes';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { ROOM_TYPE_MAP } from 'web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import { useChatTopPatient } from 'web/templates/CustomerChat/hooks/use-chat-top-patient';
import {
  roomInfoSelector,
  roomTypeSelector,
} from 'web/templates/CustomerChat/states/selected-chat-room';
import { translateLanguageState } from 'web/templates/CustomerChat/states/translate-language';
import type { ImageChattingResponse } from 'web/templates/CustomerChat/types';
import { isActivatingChatTranslateState } from '../../../../states/is-activating-chat-translate';
import { MESSAGE_TYPE_CODE } from '../../../ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { useHandleChatSocket } from '../../../ChattingContainer/components/ChattingContent/hooks/use-handle-chat-socket';
import { chattingRequestState } from '../../../ChattingContainer/states/chatting-request';
import { isSendingMessagesState } from '../../../ChattingContainer/states/isSendingMessages';
import type { TempSendFile } from '../../types/send-file';
import { kakaoUploadFile, kakaoUploadImage } from './apis/kakao-upload';
import { createMockingFileIDs } from './functions/create-mocking-file-ids';
import { useHandleTextAreaEvents } from './hooks/use-handle-text-area-events';
interface ChattingSendingBottomProps {
  disabled: boolean;
  toggleEmojiPicker: (iconButtonRef: HTMLButtonElement | null) => void;
  onClickSend: () => void;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  languageID?: LanguageNameAndAlpha2;
  chatRoomID: string;
  isDisabledSendingBottom: boolean;
}

export default function ChattingSendingBottom({
  disabled,
  toggleEmojiPicker,
  onClickSend,
  textAreaRef,
  languageID,
  chatRoomID,
  isDisabledSendingBottom,
}: ChattingSendingBottomProps) {
  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);

  const res = useServiceSettings();
  const isUseTranslate = !!res?.usingChatTranslate;
  const { userId, userType, name, realName, jobType, photo } = useUserInfo();
  const roomInfo = useAtomValue(roomInfoSelector);
  const roomType = useAtomValue(roomTypeSelector);

  const resetSelectedMessageId = useResetAtom(selectedMessageIDState);
  const setReq = useSetAtom(chattingRequestState);
  const setIsSendingMessages = useSetAtom(isSendingMessagesState);
  const setTranslateLanguage = useSetAtom(translateLanguageState);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const filePickerRef = useRef<FilePickerHandle>(null);
  const emojiButtonRef = useRef<HTMLButtonElement>(null);

  const { iconSet } = useChatTopPatient();
  const { languageList } = useLanguageList({ languageID, setSelectedIndex });

  const { isGrayAfterDocIcon, isKakaoIcon } = iconSet;
  const isSendingButtonActive = !(isGrayAfterDocIcon && !isKakaoIcon);
  const options = languageList.map((item) => {
    const { koreanLanguageName } = item;
    return `${koreanLanguageName}로 변환`;
  });

  const { sendMessage } = useHandleChatSocket({ enableListening: false });
  useHandleTextAreaEvents({ textAreaRef, onSend: onClickSend });

  const handleClick = () => {
    filePickerRef.current?.clickMultipleFile();
  };

  const handleFileChange = async (files: File[]) => {
    if (roomType === null) throw new Error('Room type is null');

    const userName = name ?? '';
    const userRealName = realName ?? '';
    const userJobType = jobType ?? '';
    const filePath = photo as unknown as TempSendFile;
    const photoPath = filePath?.paths[0];

    const { pf_id, user_key } = roomInfo;

    const filteredFiles = files.filter((file) => {
      const isValidateFileSize = FileService.validateFileSize(file);
      const isNoZeroFileSize = FileService.isSizeNoZero(file);

      !isValidateFileSize && toastService.errorMsg({ text: '용량 초과로 전송을 실패했습니다.' });
      !isNoZeroFileSize && toastService.errorMsg({ text: '용량이 0인 파일은 전송할 수 없습니다.' });

      return isValidateFileSize && isNoZeroFileSize;
    });

    setIsSendingMessages(true);
    resetSelectedMessageId();

    switch (roomType) {
      case ROOM_TYPE_MAP.kakao: {
        const imageAndFileDataForMocking: ImageChattingResponse[] = [];

        const imageFiles = filteredFiles.filter(FileService.isImageFile);

        const mockingPayload = {
          chatRoomID,
          userType,
          userName,
          userRealName,
          userID: userId,
          jobType: userJobType,
          fileIDs: [],
          type: MESSAGE_TYPE_CODE.IMAGE_MOCKING,
          photoPath,
          createdAt: '',
          isSent: true,
          markers: [''],
          sentAt: '',
          updatedAt: '',
          videoContentIDs: [''],
          _id: '',
          _v: 0,
          isBookmark: false,
        };

        const imagesDataWithMockingType = imageFiles.map((file) => {
          return {
            ...mockingPayload,
            _id: uuidv4(),
            fileIDs: createMockingFileIDs([file]),
            images: imageFiles,
          };
        });

        imageAndFileDataForMocking.push(...imagesDataWithMockingType);

        const nonImageFiles = filteredFiles.filter((file) => !FileService.isImageFile(file));
        const mockingFileIDs = createMockingFileIDs(nonImageFiles);
        const mockingFilePayload = {
          chatRoomID,
          userType,
          userName,
          userRealName,
          userID: userId,
          jobType: userJobType,
          fileIDs: mockingFileIDs,
          type: MESSAGE_TYPE_CODE.FILE_MOCKING,
          selectedFileIndices: [],
          photoPath,
          createdAt: '',
          isSent: false,
          markers: [''],
          sentAt: '',
          updatedAt: '',
          videoContentIDs: [''],
          _v: 0,
          isBookmark: false,
        };

        const filesDataWithMockingType = [];

        for (const idx in mockingFileIDs) {
          filesDataWithMockingType.push({
            ...mockingFilePayload,
            _id: uuidv4(),
            fileIDs: [mockingFileIDs[Number(idx)]],
          });
        }

        imageAndFileDataForMocking.push(...filesDataWithMockingType);

        setReq({
          type: REQUEST_ROOM_TYPE_MAP.KAKAO.IMAGES_FILES_TYPE,
          payload: imageAndFileDataForMocking,
        });

        if (imageFiles.length !== 0) {
          const payload = {
            pf_id,
            user_key,
            userType,
            userName,
            userRealName,
            userID: userId,
            jobType: userJobType,
            type: 300,
            photoPath,
            images: imageFiles,
          };

          kakaoUploadImage(payload);
        }

        if (nonImageFiles.length !== 0) {
          const filePayload = {
            pf_id,
            user_key,
            userType,
            userName,
            userRealName,
            userID: userId,
            jobType: userJobType,
            type: 500,
            photoPath,
            files: nonImageFiles,
          };

          await kakaoUploadFile(filePayload);
        }

        break;
      }
      case ROOM_TYPE_MAP.manager:
      case ROOM_TYPE_MAP.afterdoc: {
        const imageFiles = filteredFiles.filter(FileService.isImageFile);
        if (imageFiles.length !== 0) {
          const mockingPayload = {
            chatRoomID,
            userType,
            userName,
            userRealName,
            isSent: false,
            userID: userId,
            jobType: userJobType,
            fileIDs: imageFiles.map((file) => file.name),
            type: MESSAGE_TYPE_CODE.IMAGE_MOCKING,
            photoPath,
          };

          setReq({
            type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.IMAGE_TYPE,
            payload: { ...mockingPayload, _id: uuidv4() },
          });

          FileUploadController.uploadFiles({
            files: imageFiles,
            userID: userId,
            callback: async (result: FileUploadResponse) => {
              if (result.code !== 0 || !result.data) return;

              const { data } = result;

              for (const file of data) {
                if (chatRoomID === null) return;

                const { _id: fileIDsBundleID, type } = file;
                const payload = {
                  ...mockingPayload,
                  fileIDs: [fileIDsBundleID],
                  type,
                };

                //payload 자체에 _id 값을 넣으면 문제 발생
                sendMessage({
                  type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.IMAGE_FILE_TYPE,
                  payload,
                });
              }
            },
          });
        }
        const nonImageFiles = filteredFiles.filter((file) => !FileService.isImageFile(file));
        if (nonImageFiles.length !== 0) {
          const callback = async (result: FileUploadResponse) => {
            if (result.code !== 0 || !result.data) return;

            const { data } = result;

            const mockingFileIDs = createMockingFileIDs(nonImageFiles);
            const mockingPayload = {
              chatRoomID,
              userType,
              userName,
              userRealName,
              isSent: false,
              userID: userId,
              jobType: userJobType,
              fileIDs: mockingFileIDs,
              type: MESSAGE_TYPE_CODE.FILE_MOCKING,
              photoPath,
            };
            const mockingPayloads = mockingFileIDs.map((_, index) => {
              return {
                ...mockingPayload,
                _id: uuidv4(),
                fileIDs: [mockingFileIDs[index]],
              };
            });

            setReq({
              type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.IMAGE_FILE_TYPE,
              payload: mockingPayloads,
            });

            for (const file of data) {
              const { _id: fileIDsBundleID, type } = file;
              const payload = {
                ...mockingPayload,
                fileIDs: [fileIDsBundleID],
                type,
              };
              sendMessage({
                type: REQUEST_ROOM_TYPE_MAP.AFTERDOC.IMAGE_FILE_TYPE,
                payload,
              });
            }
          };

          FileUploadController.uploadFiles({
            files: nonImageFiles,
            userID: userId,
            callback,
          });
        }

        break;
      }
      default:
        roomType satisfies never;
        neverCheck(roomType);
    }
  };

  const handleSelectLanguage = async (index: number) => {
    const newLanguageCodeISOAlpha2 = languageList[index].languageCodeISOAlpha2;
    const postChatroomTranslateLanguage = async () => {
      const { data } = await apiClient.v3.apiChatroomElUpdate({
        _id: chatRoomID,
        language: newLanguageCodeISOAlpha2,
      });
      return SHARED_UTILS.api.checkApiResponse(data);
    };

    await postChatroomTranslateLanguage();

    setSelectedIndex(index);
    setTranslateLanguage(languageList[index].languageCodeISOAlpha2);
  };

  return (
    <div className='mr-10 mb-10 ml-15 flex items-end justify-between'>
      <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
      <div className={'flex gap-8'}>
        <IconButton
          size={20}
          icon={'add-circle'}
          color={'white800'}
          disabled={isDisabledSendingBottom}
          onClick={handleClick}
        />
        <IconButton
          size={20}
          icon={'emoji-outline'}
          color={'white800'}
          onClick={() => toggleEmojiPicker(emojiButtonRef.current)}
          className='mr-12'
          disabled={isDisabledSendingBottom}
          ref={emojiButtonRef}
        />
        {isActivatingChatTranslate && (
          <TextFieldSelectBoxDropdown
            width={116}
            options={options}
            inputClassName='line-clamp-1 h-20 text-Body10 rounded-r6 border border-white400 text-black500 px-0 pl-8 py-0'
            inputWrapperClassName='[&>#text-input-right-icon-button]:pr-0'
            dropdownWrapperClassName='absolute bottom-20'
            onSelect={handleSelectLanguage}
            selectedIndex={selectedIndex}
            focusedIndex={selectedIndex}
            disabled={isDisabledSendingBottom}
          />
        )}
      </div>
      {/* 버튼이 없어졌을때도 공간을 유지하기 위해 min-h 추가 */}
      <div className='flex min-h-[36.36px] items-center'>
        {isUseTranslate && isSendingButtonActive && (
          <ContainedButton
            buttonSize='big'
            disabled={disabled}
            onClick={onClickSend}
            iconProps={{ name: 'send', color: 'white50' }}>
            전송
          </ContainedButton>
        )}
      </div>
    </div>
  );
}
