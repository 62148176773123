import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import Checkbox from 'afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom, useAtomValue } from 'jotai';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FixedSizeList, type ListChildComponentProps } from 'react-window';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  TargetableNationalitiesHandlerData,
  TargetableNationalitiesHandlerParams,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { isNationalitySelectBoxOpenedState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/states/is-nationality-select-box-opened';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/algorithm-mode';
import { useSingleAlgorithmInfo } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-single-algorithm-info';
import './NationalitySetting.scss';

const getTargetableNationalities = async (params: TargetableNationalitiesHandlerParams) => {
  const response = await apiClient.v3.targetableNationalitiesHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function NationalitySetting() {
  const { hospitalID } = useSelectedHospitalInfo();
  const { setValue, watch } = useFormContext<CounselAutomationAPIFormValues>();
  const targetTreatmentTags = watch('targetTreatmentTags');

  const [isOpened, setIsOpened] = useAtom(isNationalitySelectBoxOpenedState);

  const algorithmMode = useAtomValue(algorithmModeState);

  const { data: targetableNationalities } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.targetableNationalitiesHandler,
      {
        hospitalID,
        treatmentTagId: targetTreatmentTags?.join(','),
        exceptionTargetAlgorithmId:
          algorithmMode.mode !== 'CREATE' ? algorithmMode.algorithmId : undefined,
      },
    ] as const,
    queryFn: ({ queryKey }) => getTargetableNationalities(queryKey[1]),
    select: (data) => {
      return data.filter(
        (nationalities) =>
          nationalities._id !== '-1' && nationalities.countryCodeISOAlpha3 !== 'KOR',
      );
    },
  });

  const handleClick = useCallback(() => {
    if (!targetTreatmentTags || !targetTreatmentTags.length) {
      return;
    }
    setIsOpened((prev) => !prev);
  }, [targetTreatmentTags, setIsOpened]);

  useEffect(() => {
    if (targetTreatmentTags === undefined) return;

    if (!targetTreatmentTags.length && isOpened) {
      setIsOpened(false);
      setValue('toBeAppliedNationalityIds', undefined);
    }
  }, [targetTreatmentTags, isOpened, setIsOpened, setValue]);

  const tagContainerRef = useRef<HTMLDivElement>(null);
  const [tagMaxLengths, setTagMaxLengths] = useState<Record<string, number>>({});

  useEffect(() => {
    if (!tagContainerRef.current) return;

    const measureDiv = document.createElement('div');
    measureDiv.style.position = 'absolute';
    measureDiv.style.visibility = 'hidden';
    measureDiv.style.whiteSpace = 'nowrap';
    measureDiv.style.fontSize = '12px';
    measureDiv.style.fontFamily = 'inherit';
    measureDiv.style.padding = '2px 8px';
    measureDiv.style.boxSizing = 'border-box';
    document.body.appendChild(measureDiv);

    const containerWidth = tagContainerRef.current.clientWidth;
    const maxTagWidth = containerWidth - 40;

    const calculateMaxLengths: Record<string, number> = {};

    const processTag = (tag: { id: string; text: string }) => {
      measureDiv.innerText = tag.text;
      let maxTextLength = tag.text.length;
      let tagWidth = measureDiv.getBoundingClientRect().width;

      const adjustedMaxWidth = maxTagWidth * 1.5;

      if (tagWidth > adjustedMaxWidth) {
        while (tagWidth > adjustedMaxWidth && maxTextLength > 3) {
          maxTextLength--;
          measureDiv.innerText = `${tag.text.slice(0, maxTextLength)}...`;
          tagWidth = measureDiv.getBoundingClientRect().width;
        }
      }
      calculateMaxLengths[tag.id] = maxTextLength;
    };

    for (const nationality of targetableNationalities ?? []) {
      processTag({ id: nationality._id, text: nationality.koreanCountryName });
    }

    document.body.removeChild(measureDiv);
    setTagMaxLengths(calculateMaxLengths);
  }, [targetableNationalities]);

  const listData = {
    nationalities: targetableNationalities,
    tagMaxLengths,
  } as const;

  return (
    <div className='mt-16 rounded-r10 border border-white600 bg-white200 p-10'>
      <button
        type='button'
        className='flex w-full justify-between'
        onClick={handleClick}
        disabled={!targetTreatmentTags?.length}>
        <div className='text-Header12 text-black500'>국적 (선택)</div>
        <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} color='black200' />
      </button>

      <AnimatePresence initial={false}>
        {isOpened && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              duration: 0.2,
              ease: 'easeInOut',
            }}
            className='mt-10 overflow-hidden'>
            <LabelText textClassName='text-Body10Bold'>국적</LabelText>
            <div className='mt-4 rounded-r10 border border-black200 bg-white50'>
              <div className='mr-4 py-4' ref={tagContainerRef}>
                <FixedSizeList
                  height={278}
                  width='100%'
                  itemData={listData}
                  itemCount={targetableNationalities.length}
                  itemSize={30}
                  className='virtualizedList pr-4'>
                  {NationalityItem}
                </FixedSizeList>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

type ListData = {
  nationalities: TargetableNationalitiesHandlerData['data'];
  tagMaxLengths: Record<string, number>;
};

const NationalityItem = memo(({ index, style, data }: ListChildComponentProps<ListData>) => {
  const nationality = data.nationalities[index];

  const { watch, setValue } = useFormContext<CounselAutomationAPIFormValues>();
  const { handleWithEditWarning } = useSingleAlgorithmInfo();
  const toBeAppliedNationalityIds = watch('toBeAppliedNationalityIds') || [];

  const handleCheckboxChange = (nationalityId: string, checked: boolean) => {
    handleWithEditWarning(() => {
      setValue(
        'toBeAppliedNationalityIds',
        checked
          ? [...toBeAppliedNationalityIds, nationalityId]
          : toBeAppliedNationalityIds.filter((id) => id !== nationalityId),
        { shouldDirty: true, shouldTouch: true },
      );
    });
  };

  return (
    <div style={style} className='flex items-center gap-10 pr-4 pl-16'>
      <Checkbox
        size={20}
        checked={toBeAppliedNationalityIds.includes(nationality._id)}
        onChange={(e) => handleCheckboxChange(nationality._id, e.target.checked)}
        label={
          <FilledTag
            maxTextLength={data.tagMaxLengths[nationality._id]}
            className='cursor-pointer'
            tagSize='small'>
            {nationality.koreanCountryName}
          </FilledTag>
        }
      />
    </div>
  );
});
