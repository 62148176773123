import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { alertService } from 'afterdoc-design-system/components/Molecules/Alert/Alert.service';
import { AnimatePresence, motion } from 'framer-motion';
import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';

export interface AlertProps {
  contents: ReactNode;
  id?: string;
  titleProps?: {
    title?: string;
    adjacentChildren?: {
      position: 'left' | 'right';
      children: ReactNode;
      className?: string;
    };
    className?: string;
  };
  type?: 'default' | 'warning';
  onClickAlert?: () => void;
  onClose?: () => void;
  shouldCloseOnClick?: boolean;
  bottomButtons?: ReactNode;
  autoCloseDelay?: number;
  className?: string;
  createdAt?: number;
  children?: ReactNode;
}

const Alert = ({
  id,
  contents,
  titleProps,
  type,
  onClickAlert,
  bottomButtons,
  onClose,
  shouldCloseOnClick,
  autoCloseDelay = 4000,
  className,
  createdAt = Date.now(),
  children,
}: AlertProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [exitType, setExitType] = useState<'slide' | 'fade'>('fade');

  const modalRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const timeoutRef = useRef<NodeJS.Timeout>();

  const handleClose = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setExitType('slide');
    setIsVisible(false);
    setTimeout(() => {
      if (id) {
        alertService.popById(id);
      } else {
        alertService.popAll();
      }
      if (onClose) onClose();
    }, 100);
  }, [id, onClose]);

  useEffect(() => {
    setIsVisible(true);

    if (autoCloseDelay && autoCloseDelay > 0) {
      const elapsedTime = Date.now() - createdAt;
      const remainingTime = Math.max(0, autoCloseDelay - elapsedTime);

      timeoutRef.current = setTimeout(() => {
        setExitType('fade');
        handleClose();
      }, remainingTime);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <AnimatePresence mode='popLayout'>
      {isVisible && (
        <motion.div
          layout
          className={customTwMerge('w-[400px] select-none', className)}
          ref={modalRef}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={exitType === 'slide' ? { opacity: 0, x: 100 } : { opacity: 0 }}
          transition={{
            layout: { duration: 0.3 },
            duration: 0.2,
          }}>
          <div
            onClick={(e) => {
              e.stopPropagation();

              onClickAlert?.();
              if (shouldCloseOnClick) {
                id ? alertService.popById(id) : alertService.popAll();
              }
            }}
            className={customTwMerge(
              'relative cursor-pointer rounded-r10 pt-16 pr-20 pb-14 pl-24 shadow-spray',
              type === 'warning' ? '!bg-opacity-80 bg-red50' : 'bg-white50',
              titleProps ? '!bg-opacity-80' : '',
            )}
            id={id}
            ref={contentRef}>
            <div className='flex w-full items-center justify-between'>
              <div className='flex items-center gap-8 truncate'>
                {type === 'warning' && <Icon name='warning' color='red500' size={20} />}
                {titleProps?.adjacentChildren && titleProps.adjacentChildren.position === 'left'
                  ? titleProps.adjacentChildren.children
                  : null}
                <div className='truncate text-Header14 text-black700'>{titleProps?.title}</div>
                {titleProps?.adjacentChildren && titleProps.adjacentChildren.position === 'right'
                  ? titleProps.adjacentChildren.children
                  : null}
              </div>
              <IconButton
                icon='close'
                size={20}
                color='black500'
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              />
            </div>
            <div className='line-clamp-2 w-full max-w-[356px] break-all py-4 text-Body12 text-black700'>
              {contents}
            </div>
            {bottomButtons}
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Alert;
