import * as React from "react";
import type { SVGProps } from "react";
const SvgDb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <rect
      width={16}
      height={18}
      x={21}
      y={4}
      stroke="#999"
      strokeWidth={2}
      rx={1}
      transform="rotate(90 21 4)"
    />
    <path stroke="#999" strokeWidth={2} d="M20 13.5H4M12 21V4" />
    <path fill="#999" d="M3 4h18v4H3z" />
  </svg>
);
export default SvgDb;
