import * as React from "react";
import type { SVGProps } from "react";
const SvgContentsActivated = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 22 22"
    {...props}
  >
    <rect width={18} height={18} x={2} y={2} fill="#333" rx={2} />
    <rect width={12} height={5} x={5} y={5} fill="#fff" rx={1} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M5.8 13h10M5.8 16h7"
    />
  </svg>
);
export default SvgContentsActivated;
