import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import { useChatMessageContext } from '../ChatMessageContext';
import ContentText from './components/ContentText';
import MessageTitle from './components/MessageTitle';

export default function AlgorythmSurveyMessage() {
  const { isMine, messageWidthStyle, contentParts, algorithmMessageId, surveyResponseId, isLeft } =
    useChatMessageContext();
  const answers = algorithmMessageId?.content?.survey?.answers ?? [];
  const question = algorithmMessageId?.content?.survey?.question ?? '';
  const answerIndex = surveyResponseId?.answerIndex ?? null;
  return (
    <div
      className={` rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
      style={messageWidthStyle}>
      <MessageTitle title={isLeft ? '응답을 완료했습니다.' : '설문'} />
      <ContentText parts={contentParts} />
      <div className='mb-12 break-all text-Body12'>{question}</div>
      <Divider type='line' />
      <div className='mt-12 flex flex-col items-start justify-between gap-12'>
        {answers?.map((answer, index) => (
          <div
            key={answer}
            className={`break-all text-Body12 ${index === answerIndex ? 'text-blue500' : null}`}>
            {`${index + 1}. ${answer}`}
          </div>
        ))}
      </div>
    </div>
  );
}
