import * as React from "react";
import type { SVGProps } from "react";
const SvgClean = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="M5 3h8M6 12l3.35-9M11 11l4 4M15 11l-4 4"
      className="clean_svg__ql-stroke"
    />
    <rect
      width={7}
      height={1}
      x={2}
      y={14}
      className="clean_svg__ql-fill"
      rx={0.5}
      ry={0.5}
    />
  </svg>
);
export default SvgClean;
