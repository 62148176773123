import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { useState } from 'react';
import ImageViewer from 'web/shared/components/ImageViewer/ImageViewer';
import { extractImageObjectFromS3Url } from 'web/shared/utils/fileUpload/functions/extract-file-name';

interface ServiceManagerMemoImagesProps {
  images: string[];
}

const VISIBLE_COUNT = 10;

export default function ServiceManagerMemoImages({ images }: ServiceManagerMemoImagesProps) {
  const [startIndex, setStartIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);

  const handlePrev = () => {
    setStartIndex((prev) => Math.max(prev - VISIBLE_COUNT, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) =>
      Math.min(
        prev + VISIBLE_COUNT,
        images.length - (images.length % VISIBLE_COUNT || VISIBLE_COUNT),
      ),
    );
  };

  return (
    <>
      <div className={customTwMerge('mt-21 flex items-center gap-12 px-20')}>
        <div className='flex w-full items-center justify-between gap-12'>
          {images.length > 0 && (
            <IconButton
              icon='chevron-left'
              size={24}
              color={'black500'}
              className={customTwMerge(
                'h-24 w-24 flex-center rounded-r6 border border-white400 bg-white50 ',
                startIndex === 0 && 'cursor-not-allowed opacity-50',
              )}
              onClick={handlePrev}
              disabled={startIndex === 0 || images.length <= VISIBLE_COUNT}
            />
          )}
          <div className='flex w-full items-center justify-start gap-12'>
            {images.slice(startIndex, startIndex + VISIBLE_COUNT).map((image, index) => (
              <div
                key={index}
                className='flex cursor-pointer flex-row items-center gap-6'
                onClick={() => {
                  setSelectedIndex(index);
                  setIsImageViewerOpen(true);
                }}>
                <img src={image} alt='' className='h-40 w-40 rounded-r10' />
              </div>
            ))}
          </div>
          {images.length > 0 && (
            <IconButton
              icon='chevron-right'
              size={24}
              color='black500'
              className={customTwMerge(
                'h-24 w-24 flex-center rounded-r6 border border-white400 bg-white50',
                startIndex >= images.length - VISIBLE_COUNT && 'cursor-not-allowed opacity-50',
              )}
              onClick={handleNext}
              disabled={
                startIndex >= images.length - VISIBLE_COUNT || images.length <= VISIBLE_COUNT
              }
            />
          )}
        </div>
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          selectedIndex={selectedIndex}
          imageObjects={images.map(extractImageObjectFromS3Url)}
          imageUrls={images}
          onClose={() => setIsImageViewerOpen(false)}
        />
      )}
    </>
  );
}
