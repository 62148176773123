import * as React from "react";
import type { SVGProps } from "react";
const SvgAfterdocNon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <rect width={18} height={18} x={0.5} y={0.5} fill="#fff" rx={5.5} />
    <rect width={18} height={18} x={0.5} y={0.5} stroke="#fff" rx={5.5} />
    <path
      fill="#979797"
      fillRule="evenodd"
      d="M2.5 6.772a7.496 7.496 0 0 1 9.684-4.317h.001a7.496 7.496 0 0 1-5.222 14.052 15.8 15.8 0 0 1-3.72.376.17.17 0 0 1-.12-.288c.32-.33.877-1.008 1.193-1.723a7.5 7.5 0 0 1-1.817-8.1m7.77-2.048 3.148 1.818c.39.227.632.644.632 1.096v3.633c0 .453-.241.87-.632 1.096l-3.148 1.818c-.391.225-.874.225-1.265 0l-3.149-1.818a1.27 1.27 0 0 1-.632-1.096V7.639c0-.453.242-.87.632-1.096l3.149-1.818c.39-.225.874-.225 1.265 0m3.454 5.73a.16.16 0 0 0 .08-.137V8.946a.058.058 0 0 0-.088-.05l-1.089.59V8.052A.058.058 0 0 0 12.54 8l-1.179.685a.16.16 0 0 0-.079.137v1.267l-1.208.661a.16.16 0 0 0-.08.138v1.358c0 .045.063.086.101.063l1.2-.65-.013 1.396c0 .045.049.073.087.05l1.179-.685a.16.16 0 0 0 .08-.137v-1.192z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAfterdocNon;
