import { SHARED_UTILS } from '@shared-utils/utils';
import { apiClient } from 'web/apis/instances/api-client';

type TranslateParams = Parameters<typeof apiClient.v3.apiChatroomElUpdateTranslateToggle>[0];

export const postTranstranslate = async ({
  chatRoomID: chatRoomId,
  usingTranslation,
}: TranslateParams) => {
  const response = await apiClient.v3.apiChatroomElUpdateTranslateToggle({
    chatRoomID: chatRoomId,
    usingTranslation,
  });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};
