//메뉴얼과 컨텐츠의 아이템이 다름 그래서 그냥 40으로 고정
//기획에서 정확한 스크롤 값이 아니어도 괜찮다는 정확한 명세가 있음

export type handleScollByParentChildIndexParams = {
  container: HTMLDivElement | null;
  parentIndex: number;
  childIndex?: number;
};

const ITEM_HEIGHT = 40;

export const handleScollByParentChildIndex = ({
  container,
  parentIndex,
  childIndex,
}: handleScollByParentChildIndexParams) => {
  if (container) {
    requestAnimationFrame(() => {
      setTimeout(() => {
        const movingTopValue =
          ITEM_HEIGHT * parentIndex + (childIndex ? ITEM_HEIGHT * childIndex : 0);

        container.scrollTop = movingTopValue;
      }, 0);
    });
  }
};
