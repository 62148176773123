import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { memo, useMemo } from 'react';
import type { ApiServiceSettingsElToBeDisplayedPatientsFieldsData } from 'web/apis/swaggers/swagger-docs';
import { EDITABLE_TABLE_HEADERS } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/table';
import { customerTableDisplayModeState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/customer-management-display-mode';
import { editableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

type CustomerManagementEditableTableFooterProps = {
  handleAddRow: () => void;
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

const CustomerManagementEditableTableFooter = ({
  handleAddRow,
  ...usingColumns
}: CustomerManagementEditableTableFooterProps) => {
  const resetSearchText = useResetAtom(searchTextState);
  const resetSelectedSearchCriteria = useResetAtom(selectedSearchCriteriaState);
  const resetEditableHeaderFilter = useResetAtom(editableHeaderFilterState);
  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  const customerTableDisplayMode = useAtomValue(customerTableDisplayModeState);
  const searchText = useAtomValue(searchTextState);
  const editableHeaderFilter = useAtomValue(editableHeaderFilterState);

  const {
    usingChartNumber,
    usingFirstVisitStartDate,
    usingBirthDay,
    usingGender,
    usingTreatmentTag,
    usingNationality,
  } = usingColumns;

  const headers = useMemo(
    () =>
      EDITABLE_TABLE_HEADERS.filter((header) => {
        if (usingChartNumber && header === '차트번호') return true;
        if (usingFirstVisitStartDate && header === '초/재진') return true;
        // if (usingCountryCode && header === '국가번호') return true;
        if (usingBirthDay && header === '생년월일') return true;
        if (usingGender && header === '성별') return true;
        if (usingNationality && header === '국적') return true;
        if (usingTreatmentTag && header === '치료태그') return true;

        if (header === 'No') return true;
        if (header === '고객명') return true;
        if (header === '휴대폰번호') return true;

        return false;
      }),
    [
      usingChartNumber,
      usingFirstVisitStartDate,
      // usingCountryCode,
      usingBirthDay,
      usingGender,
      usingNationality,
      usingTreatmentTag,
    ],
  );

  const handleResetSearchAndFilter = () => {
    resetSearchText();
    resetSelectedSearchCriteria();
    resetEditableHeaderFilter();
    resetSelectedCell();
    resetFocusedCell();
  };

  return (
    <tfoot>
      <tr>
        <td colSpan={headers.length}>
          <div className='my-10 flex-w-full-center'>
            {customerTableDisplayMode === 'EDITOR' &&
              (searchText?.length ||
              Object.values(editableHeaderFilter).some((v) => v !== undefined) ? (
                <OutlinedButton onClick={handleResetSearchAndFilter}>
                  검색 및 필터 초기화
                </OutlinedButton>
              ) : (
                <OutlinedButton
                  onClick={handleAddRow}
                  iconProps={{
                    name: 'add-circle',
                    color: 'blue500',
                    size: 20,
                  }}>
                  입력칸 50개 추가
                </OutlinedButton>
              ))}
          </div>
        </td>
      </tr>
    </tfoot>
  );
};

export default memo(CustomerManagementEditableTableFooter);
