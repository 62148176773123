import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { SHARED_UTILS } from 'utils/utils';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElFindOneParams,
  ApiVideocontentcategoryElFindParams,
  ServiceSetting,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from './use-selected-hospital-info';

export type UseVideoContentCategoriesParams = {
  serviceSettings: ServiceSetting | undefined;
};

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const response = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchVideoContentCategories = async (params: ApiVideocontentcategoryElFindParams) => {
  const response = await apiClient.v3.apiVideocontentcategoryElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useVideoContentCategories = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: serviceSettings } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElFindOne,
      {
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  const uniqueParentCategoryIDs = useMemo(() => {
    return [
      ...new Set(
        serviceSettings?.usingAutoSendVideo
          ?.map((video) => video._id)
          .filter((id): id is string => id !== undefined) ?? [],
      ),
    ];
  }, [serviceSettings]);

  const { data: categories } = useQuery({
    queryKey: [QUERY_KEY.apiVideocontentcategoryElFind, uniqueParentCategoryIDs],
    queryFn: () =>
      serviceSettings
        ? fetchVideoContentCategories({
            isTop: false,
            isParent: true,
            'parentCategoryIDs[]': uniqueParentCategoryIDs,
          })
        : Promise.resolve([]),
    enabled: !!serviceSettings && uniqueParentCategoryIDs.length > 0,
  });
  return { categories, serviceSettings };
};
