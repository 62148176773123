/**
 * 전월 대비 변화율을 계산하는 함수
 * @param lastMonthData 이전 달 데이터
 * @param thisMonthData 이번 달 데이터
 * @returns 변화율 (%)
 */

export const calculateMonthOverMonthChange = (
  lastMonthData: number,
  thisMonthData: number,
): number => {
  // 이전 달이 0이고 이번 달이 0보다 큰 경우 100% 증가
  if (lastMonthData === 0 && thisMonthData > 0) {
    return 100;
  }

  // 이전 달이 0보다 크고 이번 달이 0인 경우 -100% 감소
  if (lastMonthData > 0 && thisMonthData === 0) {
    return -100;
  }

  // 그 외의 경우 정상적인 증감률 계산
  return lastMonthData ? Math.round((thisMonthData / lastMonthData) * 100) : 0;
};
