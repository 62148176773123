import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtomValue } from 'jotai';
import { isEqual } from 'lodash-es';
import { apiClient } from 'web/apis/instances/api-client';
import type {
  DeleteMessageInput,
  MessageInput,
  NewMessageInput,
  UpdateAlgorithmMainDataHandlerBodyRequest,
  UpdateMessageInput,
} from 'web/apis/swaggers/swagger-docs';
import { algorithmModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/algorithm-mode';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/origin-message';

const createMarketingAutomationMessages = async (params: NewMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateMarketingAutomationMessages = async (params: UpdateMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesUpsertSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const deleteMarketingAutomationMessages = async (params: DeleteMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesDeleteSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateAlgorithmMainData = async (params: UpdateAlgorithmMainDataHandlerBodyRequest) => {
  const response = await apiClient.v3.updateAlgorithmMainDataHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useHandleMarketingAutomationMessages = () => {
  const algorithmMode = useAtomValue(algorithmModeState);
  const originMessages = useAtomValue(originMessagesState);

  const updateAlgorithmMainDataMutation = useMutation({
    mutationFn: updateAlgorithmMainData,
  });

  const handleAutomationMessages = async ({
    onSuccess,
    newMessagesValues,
    setDisabled,
  }: {
    onSuccess?: () => void;
    newMessagesValues: (MessageInput & { id?: string })[];
    setDisabled: (disabled: boolean) => void;
  }) => {
    if (algorithmMode.mode !== 'EDIT') return;
    setDisabled(true);

    try {
      for (const newMsg of newMessagesValues) {
        const isNewMessage = !originMessages.some((originMsg) => originMsg.id === newMsg.id);

        if (isNewMessage) {
          await createMarketingAutomationMessages({
            algorithmId: algorithmMode.algorithmId,
            name: newMsg.name,
            sendingType: 'BASIC',
            content: newMsg.content,
            sendingDateTime: newMsg.sendingDateTime,
          });
          continue;
        }

        const originalMsg = originMessages.find((msg) => msg.id === newMsg.id);
        if (originalMsg && !isEqual(originalMsg, newMsg)) {
          await updateMarketingAutomationMessages({
            messageId: newMsg.id,
            algorithmId: algorithmMode.algorithmId,
            name: newMsg.name,
            sendingType: 'BASIC',
            content: newMsg.content,
            sendingDateTime: newMsg.sendingDateTime,
          });
        }
      }

      const deletedMessages = originMessages.filter(
        (originMsg) => !newMessagesValues.some((newMsg) => newMsg.id === originMsg.id),
      );

      for (const deletedMsg of deletedMessages) {
        await deleteMarketingAutomationMessages({ messageId: deletedMsg.id });
      }

      onSuccess?.();
    } catch (error) {
      console.error('Error while processing messages:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
    } finally {
      setDisabled(false);
    }
  };

  const handleUpdateAlgorithmMainData = async ({
    onSuccess,
    updatedAlgorithmValues,
  }: {
    onSuccess?: () => void;
    updatedAlgorithmValues: UpdateAlgorithmMainDataHandlerBodyRequest;
  }) => {
    await updateAlgorithmMainDataMutation.mutateAsync(updatedAlgorithmValues);
    onSuccess?.();
  };

  return {
    handleAutomationMessages,
    handleUpdateAlgorithmMainData,
  };
};
