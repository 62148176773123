import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';

export default function ExpiredImageMock({ isFirst = true }: { isFirst?: boolean }) {
  return (
    <div className='content relative flex h-[150px] max-h-[330px] w-[160px]'>
      <div className='h-full w-full rounded-r10 bg-black200 opacity-50' />
      {isFirst && (
        <div className='absolute inset-0 flex items-center justify-center'>
          <div className='flex flex-col items-center justify-center gap-6 rounded-r10 text-Body11 '>
            <Icon name='image-off-outline' color='black500' size={40} />
            <span className='text-Body13 text-black700'>만료됨</span>
          </div>
        </div>
      )}
    </div>
  );
}
