import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import ALIntegrationEditIntegratedItem from './ALIntegrationEditIntegratedItem';
import type { ALIntegrationUserData } from './hooks/type';

interface ALIntegrationEditIntegratedProps {
  tempUsers: ALIntegrationUserData[];
  updateTempUsers: (index: number) => void;
}

export default function ALIntegrationEditIntegrated({
  tempUsers,
  updateTempUsers,
}: ALIntegrationEditIntegratedProps) {
  const updateList = (index: number) => {
    updateTempUsers(index);
  };
  return (
    <div className='flex h-full flex-col rounded-r16 bg-white100 py-10' style={{ width: '332px' }}>
      <div className='px-10 pt-6 pb-10 text-Header14'>연동된 계정</div>
      <Scrollbar className=''>
        <div className='rounded-r16 bg-white100 px-10'>
          {tempUsers?.map((_, index) => (
            <ALIntegrationEditIntegratedItem
              key={index}
              list={tempUsers}
              index={index}
              update={updateList}
            />
          ))}
        </div>
      </Scrollbar>
    </div>
  );
}
