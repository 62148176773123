import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiAutomationsElOngoingParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import OnGoingConsultingAutomationCard from './components/OnGoingConsultingAutomationCard';

const fetchAutomationsData = async (params: ApiAutomationsElOngoingParams) => {
  const response = await apiClient.v3.apiAutomationsElOngoing(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function OnGoingConsultingAutomationCardsList() {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiAutomationsElOngoing, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchAutomationsData(queryKey[1]),
  });

  if (!data.length) {
    return (
      <NoData
        title='적용중인 자동화가 없습니다.'
        className='flex-full-center'
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
      />
    );
  }

  return (
    <div className='h-[calc(100vh-222px)]'>
      <Scrollbar disabledX>
        <div className='flex flex-col gap-10 px-20 py-10'>
          {data.map((automation) => {
            return <OnGoingConsultingAutomationCard key={automation.algorithmId} {...automation} />;
          })}
        </div>
      </Scrollbar>
    </div>
  );
}
