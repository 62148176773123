import type React from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';

export interface FilePickerHandle {
  clickSingle: () => void;
  clickMultiple: () => void;
  clickFile: () => void;
  clickMultipleFile: () => void;
}

interface FilePickerProps {
  onFileChange: (files: File[]) => void;
}

const args = {
  type: 'file',
  className: 'hidden',
};

const FilePicker = forwardRef<FilePickerHandle, FilePickerProps>((props, ref) => {
  const singleImageRef = useRef<HTMLInputElement>(null);
  const multipleImageRef = useRef<HTMLInputElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const multipleFileRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    clickSingle: () => {
      singleImageRef.current?.click();
    },
    clickMultiple: () => {
      multipleImageRef.current?.click();
    },
    clickFile: () => {
      fileRef.current?.click();
    },
    clickMultipleFile: () => {
      multipleFileRef.current?.click();
    },
  }));

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files) {
      const fileArray: File[] = Array.from(files);
      props.onFileChange(fileArray);

      e.currentTarget.value = '';
    }
  };

  return (
    <>
      <input ref={singleImageRef} accept='image/*' {...args} onChange={handleFileChange} />
      <input
        ref={multipleImageRef}
        accept='image/jpeg, image/png, image/gif, image/webp'
        {...args}
        multiple
        onChange={handleFileChange}
      />
      <input ref={fileRef} accept='*' {...args} onChange={handleFileChange} />
      <input ref={multipleFileRef} accept='*' {...args} multiple onChange={handleFileChange} />{' '}
    </>
  );
});

export default FilePicker;
