import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { useDataEventBusHandler } from 'web/apis/push/data-push/hooks/use-data-event-bus-handler';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElCountriesParams,
  ApiTreatmentTagsElParams,
  TemporaryPatientsLastModifierHandlerBodyRequest,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import type { DataNotificationPayload } from 'web/shared/utils/event-bus';
import { COUNTRY_LIMIT } from 'web/templates/CustomerManagement/constants/country-limit';
import CustomerManagementEditableTableHeader from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/CustomerManagementEditableTableHeader';
import CustomerManagementSaveFooter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementSaveFooter/CustomerManagementSaveFooter';
import { useResetTable } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/hooks/use-reset-table/use-reset-table';
import { modifierInfoState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/modifier-info';
import { useGetUsingColumns } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/hooks/apis/use-get-using-columns';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';
import CustomerManagementEditableTableBodyContainer from './containers/CustomerManagementEditableTableBody.container';

const fetchTreatmentTagsList = async (params: ApiTreatmentTagsElParams) => {
  const response = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchCountriesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const patchTemporaryPatientsLastModifier = async (
  params: TemporaryPatientsLastModifierHandlerBodyRequest,
) => {
  const response = await apiClient.v3.temporaryPatientsLastModifierHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function CustomerManagementEditableTableContainer() {
  const { hospitalID } = useSelectedHospitalInfo();
  const usingColumns = useGetUsingColumns();

  const methods = useForm({
    defaultValues: {
      rows: [],
    },
  });

  const {
    data: { treatmentTags },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTagsList(queryKey[1]),
  });

  const { data: countriesList } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiServiceSettingsElCountries,
      { hospitalID, limit: COUNTRY_LIMIT },
    ] as const,
    queryFn: ({ queryKey }) => fetchCountriesList(queryKey[1]),
  });

  const tableRef = useRef<HTMLDivElement>(null);

  const setSelectedCell = useSetAtom(selectedCellState);
  const setFocusedCell = useSetAtom(focusedCellState);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;

      if (tableRef.current && !tableRef.current.contains(targetElement)) {
        setSelectedCell({
          row: -1,
          col: -1,
        });
        setFocusedCell({
          row: null,
          col: null,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tableRef]);

  return (
    <FormProvider {...methods}>
      <EditableCustomerTable
        usingColumns={usingColumns}
        countriesList={countriesList}
        treatmentTags={treatmentTags}
      />
    </FormProvider>
  );
}

interface EditableCustomerTableProps {
  usingColumns: ReturnType<typeof useGetUsingColumns>;
  countriesList: Awaited<ReturnType<typeof fetchCountriesList>>;
  treatmentTags: Awaited<ReturnType<typeof fetchTreatmentTagsList>>['treatmentTags'];
}

function EditableCustomerTable({
  usingColumns,
  countriesList,
  treatmentTags,
}: EditableCustomerTableProps) {
  const isFirstRender = useRef(true);
  const handleNotificationRef = useRef<((payload: DataNotificationPayload) => void) | null>(null);

  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();
  const { userId: loggedInUser } = useUserInfo();

  const setModifierInfo = useSetAtom(modifierInfoState);

  const notificationConfigs = useMemo(
    () => [
      {
        codes: ['d_TemporaryPatientsUpdate_el'],
        handler: (payload: DataNotificationPayload) => handleNotificationRef.current?.(payload),
      },
      {
        codes: ['d_PatientsUpdate_el'],
        handler: (payload: DataNotificationPayload) => handleNotificationRef.current?.(payload),
      },
    ],
    [],
  );

  const patchTemporaryPatientsLastModifierMutation = useMutation({
    mutationFn: patchTemporaryPatientsLastModifier,
    onSuccess: (data) => {
      setModifierInfo({
        createdAt: data.createdAt,
        userID: data.userID,
      });
    },
  });

  const { revertTableData } = useResetTable();

  const refetchTableData = useCallback(async () => {
    patchTemporaryPatientsLastModifierMutation.mutate({
      hospitalID,
    });
    await revertTableData();
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.temporaryPatientsIsLockHandler, { hospitalID }],
    });
  }, [hospitalID, patchTemporaryPatientsLastModifierMutation, queryClient, revertTableData]);

  useDataEventBusHandler(notificationConfigs);

  useEffect(() => {
    handleNotificationRef.current = (payload: DataNotificationPayload) => {
      const { hospitalID: messageHospitalID, senderID } = payload.data;

      if (hospitalID === messageHospitalID && senderID !== loggedInUser) {
        refetchTableData();
      }
    };
  }, [loggedInUser, hospitalID, refetchTableData]);

  useEffect(() => {
    if (isFirstRender.current && !hospitalID) {
      isFirstRender.current = false;
      return;
    }

    patchTemporaryPatientsLastModifierMutation.mutate({
      hospitalID,
    });
  }, [hospitalID]);

  return (
    <>
      <div
        className={customTwMerge(
          'flex h-[calc(100vh-155px)] w-full justify-center border-line-table',
        )}>
        <div className='flex w-full flex-col'>
          <table className='w-full table-fixed'>
            <CustomerManagementEditableTableHeader {...usingColumns} />
          </table>
          <CustomerManagementEditableTableBodyContainer
            countriesList={countriesList}
            treatmentTags={treatmentTags}
            {...usingColumns}
          />
        </div>
      </div>
      <CustomerManagementSaveFooter />
    </>
  );
}
