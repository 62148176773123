import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';

export default function SendContentFormHeader() {
  return (
    <div className='mt-20 flex w-full items-center justify-between'>
      <LabelText width='fit-content' isRequired>
        내용
      </LabelText>
      <span className='text-Body10 text-black200'>발송할 메시지의 형태를 선택할 수 있습니다.</span>
    </div>
  );
}
