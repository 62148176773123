import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import type { BaseLoadingProps } from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { type ReactNode, Suspense, useMemo } from 'react';

interface SuspenseWithLoadingProps extends BaseLoadingProps {
  children: ReactNode;
  type?: 'default' | 'screen' | 'custom';
  className?: string;
}

export default function SuspenseWithLoading({
  children,
  type = 'default',
  className,
  ...loadingProps
}: SuspenseWithLoadingProps) {
  const loadingWrapperClassName = useMemo(() => {
    if (type === 'default') {
      return 'flex-full-center';
    }

    if (type === 'screen') {
      return 'h-screen w-full flex-center';
    }

    if (type === 'custom') {
      return '';
    }
  }, [type]);

  return (
    <Suspense
      fallback={
        <div className={customTwMerge(loadingWrapperClassName, className)}>
          <BaseLoading {...loadingProps} />
        </div>
      }>
      {children}
    </Suspense>
  );
}
