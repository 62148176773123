import * as React from "react";
import type { SVGProps } from "react";
const SvgDirectionRtl = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 18 18" {...props}>
    <path
      d="m15 12-2-2 2-2zM9 4H5"
      className="direction-rtl_svg__ql-stroke direction-rtl_svg__ql-fill"
    />
    <path d="M5 3a3 3 0 0 0 0 6h1V3z" className="direction-rtl_svg__ql-fill" />
    <path d="M5 4h1v11H5zM7 4h1v11H7z" className="direction-rtl_svg__ql-fill" />
  </svg>
);
export default SvgDirectionRtl;
