export const withLogging = ({ msg, type }: { msg: string; type: string }) => {
  if (import.meta.env.MODE !== 'prod') {
    switch (type) {
      case 'error':
        console.error(msg);
        break;
      case 'info':
        console.info(msg);
        break;
      default:
        console.info(msg);
    }
  }
};
