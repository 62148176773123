import type { EventData, NoticeData, UseDataReturnType, VideoData } from '../types';
import { useEventList } from './use-event-list';
import { useNoticeList } from './use-notice-list';
import { useVideoList } from './use-video-list';

export const useContentsData = ({
  categoryTitle,
  categoryID,
  keyword,
}: { categoryTitle: string; categoryID: string; keyword: string }) => {
  switch (categoryTitle) {
    case '홈케어 상품':
    case '이벤트':
      return useEventList({ keyword, categoryID, categoryTitle }) as UseDataReturnType<EventData>;
    case '공지사항':
      return useNoticeList({ keyword }) as UseDataReturnType<NoticeData>;
    default:
      return useVideoList({ keyword, categoryID }) as UseDataReturnType<VideoData>;
  }
};
