import { useAtomValue } from 'jotai';
import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import EditOverlay from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/EditOverlay/EditOverlay';
import { useTextAreaControl } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/components/hooks/use-text-area-control';
import { useKakaoCheckWhetherEditOrNot } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-kakao-check-whether-edit-or-not';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import ResizableTextArea from 'web/templates/Automation/containers/Dialog/shared/components/ResizableTextArea/ResizableTextArea';

interface ContentTextAreaProps {
  defaultHeight?: number;
}

export const ContentTextArea = forwardRef<HTMLTextAreaElement, ContentTextAreaProps>(
  ({ defaultHeight }, ref) => {
    const { watch } = useFormContext<CounselAutomationAPIFormValues>();

    const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

    const messageContentText = watch(`messages.${selectedMessageIndex}.content.text`);

    const { handleFormClick, showOverlay } = useKakaoCheckWhetherEditOrNot();
    const { handlePaste, handleChange } = useTextAreaControl({
      text: messageContentText ?? '',
    });

    return (
      <div className='relative w-full'>
        {showOverlay && (
          <EditOverlay
            id='content-text-area-overlay'
            onClick={(e) => {
              handleFormClick(e, () => {
                if (ref && 'current' in ref) {
                  ref.current?.focus();
                }
              });
            }}
          />
        )}
        <Controller
          key={selectedMessageIndex}
          name={`messages.${selectedMessageIndex}.content.text`}
          render={({ field }) => (
            <ResizableTextArea
              {...field}
              ref={ref}
              onPaste={handlePaste}
              onChange={(e) => handleChange(e, field.onChange)}
              placeholder='내용을 입력해 주세요.'
              style={{
                minHeight: defaultHeight,
              }}
            />
          )}
        />
      </div>
    );
  },
);
