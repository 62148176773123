import { useChatMessageContext } from '../ChatMessageContext';
import ImageMessage from './ImageMessage';
import TextContentMessage from './TextContentMessage/TextContentMessage';

export default function AutomationMarketingMessage() {
  const { content, isLeft } = useChatMessageContext();

  return (
    <div className={`flex flex-col gap-8 ${!isLeft && 'items-end'}`}>
      {content && <TextContentMessage />}
      <ImageMessage />
    </div>
  );
}
