import { useEffect } from 'react';
import { useChatMessageContext } from '../../../ChatMessageContext';
import { findChat } from '../../../components/RightMessage/apis/find-chat';
import { TRANSLATE_TEXT_MESSAGE_LIMIT_TIME } from '../../../components/RightMessage/constants/rightMessage';
import { TEXT_TYPE_GROUP } from '../../Message';

/**
 * Custom hook to handle the timeout logic for translated mocking messages.
 * @param {boolean} isTranslatedMockingMessage - State indicating if the message is being translated.
 * @param {function} setIsTranslatedMockingMessage - Function to update the translated mocking message state.
 * @param {function} setIsRightTimeError - Function to update the time error state.
 */

export const useRightTranslationTimeout = ({
  isTranslatedMockingMessage,
  setIsTranslatedMockingMessage,
  setIsRightTimeError,
}: {
  isTranslatedMockingMessage: boolean;
  setIsTranslatedMockingMessage: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRightTimeError: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { setResMessages, _id } = useChatMessageContext();

  useEffect(() => {
    let timer: NodeJS.Timeout;

    //위에 전체를 덮고 있는 타임아웃 로직이 번역 결과와 DB와 연관되면서 해당 로직에 일부가 없어도 됨.
    if (isTranslatedMockingMessage) {
      timer = setTimeout(async () => {
        const res = await findChat(_id);
        setIsTranslatedMockingMessage(false);

        if (res.isTranslated) {
          setResMessages((prev) => {
            return prev.map((message) => {
              if (message._id === res._id) {
                return { ...res, status: 'success', isTranslated: true };
              }
              return message;
            });
          });
          setIsRightTimeError(false);
        } else {
          setResMessages((prev) => {
            return prev.map((message) => {
              if (message._id === _id) {
                return {
                  ...message,
                  type: TEXT_TYPE_GROUP[0],
                  isTranslated: false,
                  status: 'failed',
                };
              }
              return message;
            });
          });
          setIsRightTimeError(true);
        }
      }, TRANSLATE_TEXT_MESSAGE_LIMIT_TIME);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isTranslatedMockingMessage]);
};
