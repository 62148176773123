import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import { AnimatePresence, motion } from 'framer-motion';
import type { MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import RenderProgressRightContent from './RenderProgressRightContent';
import { RenderingProgressDownloadIcon } from './RenderingProgressDownloadIcon';

const statusConfig = {
  complete: { backgroundColor: 'rgba(0, 122, 255, 0.8)', description: '다운로드를 완료했습니다.' },
  error: { backgroundColor: 'rgba(233, 0, 0, 0.8)', description: '다운로드를 실패했습니다.' },
  downloading: { backgroundColor: 'rgba(17, 17, 17, 0.8)', description: '파일 다운로드 중...' },
} as const;

export type Status = keyof typeof statusConfig;

export type ProgressBarProps = {
  id?: string;
  fileName: string;
  status?: Status;
  percentage: number;
  onFileOpenClickCallback: (e: MouseEvent<HTMLButtonElement>) => void;
  onReloadClickCallback: (e: MouseEvent<HTMLButtonElement>) => void;
  onCancelClickCallback?: (e: MouseEvent<HTMLButtonElement>) => void;
  onDeleteClickCallback?: (e: MouseEvent<HTMLButtonElement>) => void;
  shouldAutoHide?: boolean;
  timeout?: number;
  isPercentage?: boolean;
  isDisabledTimeout?: boolean;
};

export default function ProgressBar({
  fileName,
  status,
  percentage,
  onFileOpenClickCallback,
  onReloadClickCallback,
  onCancelClickCallback,
  onDeleteClickCallback,
  shouldAutoHide = false,
  isPercentage = true,
  timeout = 2000,
  isDisabledTimeout = false,
}: ProgressBarProps) {
  const [isVisible, setIsVisible] = useState(true);

  const onDeleteClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    setIsVisible(false);
  };

  useEffect(() => {
    if (isDisabledTimeout) return;
    let timer: NodeJS.Timeout | null = null;

    if (shouldAutoHide && status === 'complete') {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, timeout);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      setIsVisible(true);
    };
  }, [shouldAutoHide, status, timeout]);

  return (
    <AnimatePresence>
      {isVisible && !!status && (
        <motion.div
          className='flex h-50 justify-between gap-8 rounded-r16 px-20'
          style={{ backgroundColor: statusConfig[status].backgroundColor }}
          initial={{ opacity: 1, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}>
          <div className='flex-center gap-10'>
            <RenderingProgressDownloadIcon status={status} />
            <span className='text-Body11 text-white50'>{statusConfig[status].description}</span>
            {status === 'error' && (
              <span className='text-Body11' style={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {fileName}
              </span>
            )}
          </div>
          <div className='flex-center gap-10'>
            {
              <RenderProgressRightContent
                status={status}
                percentage={percentage}
                onReloadClickCallback={onReloadClickCallback}
                onFileOpenClickCallback={onFileOpenClickCallback}
                isPercentage={isPercentage}
              />
            }
            <IconButton
              onClick={(e) => {
                if (status === 'complete' || status === 'error') {
                  onDeleteClick(e);
                  onDeleteClickCallback?.(e);
                } else {
                  //중간에 취소하는 경우에도 똑같이 onDeleteClick 필요하다고 생각
                  onDeleteClick(e);
                  onCancelClickCallback?.(e);
                }
              }}
              icon='close'
              size={24}
              color='white50'
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
