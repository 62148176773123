import { useAtomValue } from 'jotai';
import { type RefObject, useLayoutEffect } from 'react';
import { selectedMessageIDState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { chatRoomIDSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { chattingRequestState } from '../states/chatting-request';

type UseForcedMoveToBottomProps = {
  chatContainerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  isBottomButtonVisible: boolean;
  resMessagesLength: number;
};

export const useForcedMoveToBottom = ({
  chatContainerRef,
  isLoading,
  isBottomButtonVisible,
  resMessagesLength,
}: UseForcedMoveToBottomProps) => {
  const chatRoomID = useAtomValue(chatRoomIDSelector);
  const selectedMessageID = useAtomValue(selectedMessageIDState);
  const req = useAtomValue(chattingRequestState);

  const scrollToTop = () => {
    const container = chatContainerRef.current;
    if (container) {
      const isNotAtTop = container.scrollTop !== 0;
      if (isNotAtTop) {
        requestAnimationFrame(() => {
          setTimeout(() => {
            container.scrollTop = 0;
          }, 0);
        });
      }
    }
  };

  //use-bottom-scroll-observer.ts의 hook과 연관성이 있어서 함께 봐야함
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    scrollToTop();
  }, [isLoading, chatRoomID]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (req) {
      scrollToTop();
    }
  }, [req]);

  //데이터 발생시 밑으로 끌어올리는 로직
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useLayoutEffect(() => {
    if (resMessagesLength && !isBottomButtonVisible && selectedMessageID === null) {
      scrollToTop();
    }
  }, [resMessagesLength]);
};
