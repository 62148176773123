import { useSuspenseQuery } from '@tanstack/react-query';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { getPatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { patientIdState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/state/show-customer-detail-info';

export default function ApplyingAutomation() {
  return (
    <div className='flex h-full flex-col gap-4'>
      <div className='text-Header14 text-black700'>상담자동화</div>
      <div className='mb-16 text-Body12 text-black500'>
        고객에게 적용된 상담자동화가 표시됩니다.
      </div>
      <Suspense>
        <ApplyingAutomationTagsWithTitle />
      </Suspense>
    </div>
  );
}

const ApplyingAutomationTagsWithTitle = () => {
  const patientId = useAtomValue(patientIdState);

  const {
    data: { appliedAutomation },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.appliedAutomation, { patientId: patientId || '' }] as const,
    queryFn: ({ queryKey }) => getPatientDetailInfo(queryKey[1]),
    refetchOnMount: 'always',
  });

  if (!appliedAutomation) {
    return <NoApplyingAutomationData />;
  }

  const { tagsHaveFolder, tagsHaveNoFolder, algorithmName, appliedNationalityNames } =
    appliedAutomation;

  return (
    <>
      <div className='rounded-r16 border border-white600 bg-white50 pb-16'>
        <div className='border-b border-b-white600 px-20 pt-16 pb-10'>
          {/* 적용 중인 상담자동화명 */}
          <div className='text-Header14'>{algorithmName}</div>
          {/* 적용 중인 국적 */}
          <div className='mt-4 text-Body13 text-black300'>{appliedNationalityNames}</div>
        </div>
        <div className='h-[112px] w-full px-16 pt-10'>
          <Scrollbar>
            <div className='flex flex-col gap-12'>
              {tagsHaveFolder.map(({ folderID, folderName, tags }) => {
                return (
                  <div key={folderID} className='flex flex-col gap-8'>
                    <div className='text-Header12 text-black500'>{folderName}</div>
                    <div className='flex flex-wrap gap-8'>
                      {tags.map((tag) => {
                        return (
                          <FilledTag
                            key={tag.tagId}
                            bgColor={tag.tagColor}
                            className='rounded-8 bg-blue50 px-12 py-4 text-Body13 text-black500'>
                            {tag.tagName}
                          </FilledTag>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {tagsHaveNoFolder.length > 0 && (
                <>
                  <div className='mt-8 text-Header12'>폴더없음</div>
                  <div className='flex flex-wrap gap-8'>
                    {tagsHaveNoFolder.map((tag) => {
                      return (
                        <FilledTag
                          key={tag.tagId}
                          bgColor={tag.tagColor}
                          className='rounded-8 bg-blue50 px-12 py-4 text-Body13 text-black500'>
                          {tag.tagName}
                        </FilledTag>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </Scrollbar>
        </div>
      </div>
    </>
  );
};

const NoApplyingAutomationData = () => {
  return (
    <div className='flex-full-center'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        title='진행중인 상담자동화가 없습니다.'
      />
    </div>
  );
};
