import { atom } from 'jotai';

// sessionStorage용 유틸리티 함수
const getSessionStorageValue = <T>(key: string, initialValue: T): T => {
  try {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  } catch {
    return initialValue;
  }
};

const setSessionStorageValue = <T>(key: string, value: T): void => {
  try {
    sessionStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error('Error saving to sessionStorage:', error);
  }
};

export const createSessionAtom = <T>(key: string, initialValue: T) => {
  const baseAtom = atom<T>(getSessionStorageValue(key, initialValue));

  const sessionAtom = atom(
    (get) => get(baseAtom),
    (_get, set, newValue: T) => {
      set(baseAtom, newValue);
      setSessionStorageValue(key, newValue);
    },
  );

  return sessionAtom;
};
