import Block from '../../../blots/block';
import type Quill from '../../../lib/quill-registry';

// 이전 space 공백을 확인하는 함수
const getLeadingSpacesLength = (textContent: string) => {
  const leadingSpacesMatch = textContent.match(/^\s*/); // Match leading spaces
  return leadingSpacesMatch ? leadingSpacesMatch[0].length : 0;
};

const calculateTitleNumber = (parentList: ParentNode | null, lineDomNode: HTMLElement) => {
  if (!(parentList instanceof Element)) return 0;

  const counterTitle = parentList.getAttribute('data-counter-title');
  if (counterTitle && Number(counterTitle) !== 1) {
    return Number(counterTitle);
  }

  const siblings = Array.from(parentList.children);
  return siblings.indexOf(lineDomNode) + 1;
};

// 교묘하게 4자리 숫자일 때 공백 길이가 하나 더 들어가야 함
const calculateSpaces = (titleNum: number) => {
  return ' '.repeat(
    titleNum > 9999
      ? 18
      : // 5자리 숫자에 대해 공백 길이
        titleNum > 999
        ? 16
        : // 4자리 숫자에 대해 공백 길이
          titleNum > 99
          ? 13
          : // 3자리 숫자에 대해 공백 길이
            titleNum > 9
            ? 11
            : 8, // 2자리 및 1자리 숫자에 대해 공백 길이
  );
};

export const handleNoneNextOrderedList = (quill: Quill) => {
  setTimeout(() => {
    const selection = quill.getSelection();
    if (!selection) return null; // Exit if no selection

    const [line] = quill.getLine(selection.index); // Get the current line
    if (!line) return null; // Exit if no line

    const lineDomNode = line.domNode;

    const isOrderedListItem =
      lineDomNode instanceof HTMLElement &&
      lineDomNode.tagName === 'LI' &&
      lineDomNode.dataset.list === 'ordered';

    const isOrderedParagraph =
      lineDomNode instanceof HTMLElement &&
      lineDomNode.tagName === 'P' &&
      lineDomNode.dataset.list === 'ordered';

    if (isOrderedListItem || isOrderedParagraph) {
      if (isOrderedParagraph) {
        const previousSpace = lineDomNode.getAttribute('data-space');
        const newSpace = getLeadingSpacesLength(lineDomNode.textContent || '');

        if (Number(previousSpace) !== newSpace) return; // Exit if spaces don't match
      }

      const parentList = lineDomNode.parentNode;
      const titleNum = calculateTitleNumber(parentList, lineDomNode);
      const spaces = calculateSpaces(titleNum);

      // Create a new paragraph with the calculated spaces
      const newP = Block.create('p') as HTMLElement;
      newP.setAttribute('data-list', 'ordered');
      newP.setAttribute('data-space', spaces.length.toString());
      newP.textContent = spaces;

      // Append the new paragraph to the current line
      lineDomNode.appendChild(newP);

      setTimeout(() => {
        const newIndex = selection.index + 1 + newP.innerHTML.length;
        quill.setSelection(newIndex, 0); // Set cursor position
      }, 0);
    }
  }, 0);
};
