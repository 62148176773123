import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from 'afterdoc-design-system/components/Atoms/Tag/FilledTag';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useTagDropdownKeyboardEvent } from 'afterdoc-design-system/components/Molecules/Dropdown/TagDropdown/hooks/use-tag-dropdown-keyboard-events';
import {
  type CSSProperties,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import type { TagProperty } from './types/tag-dropdown';

export interface TagDropdownProps {
  tags: TagProperty[];
  isToggle: boolean;
  id?: string;
  searchText?: string;
  selectedIndex?: number;
  onSelect?: (index: number) => void;
  handleToggle: (isToggle?: boolean) => void;
  redTextIndices?: number[];
  wrapperClassName?: string;
  tagsClassName?: string;
  width?: number | string;
  wrapperStyle?: CSSProperties;
  tagStyle?: CSSProperties;
  ignoreRefs?: RefObject<HTMLElement>[];
  focusedIndex?: number;
  onFocusChange?: (index: number) => void;
  isDefaultFocus?: boolean;
  customFocusScrollHandler?: (focusedIndex: number) => number;
  customSelectedScrollHandler?: (selectedIndex: number) => number;
  handleClickOutside?: () => void;
  isComposing?: boolean;
}

export default function TagDropdown({
  tags,
  isToggle,
  id,
  searchText,
  selectedIndex,
  onSelect,
  handleToggle,
  redTextIndices = [],
  wrapperClassName,
  tagsClassName,
  width,
  wrapperStyle,
  tagStyle,
  ignoreRefs,
  focusedIndex: initialFocusedIndex,
  onFocusChange,
  isDefaultFocus = false,
  customFocusScrollHandler,
  customSelectedScrollHandler,
  handleClickOutside,
  isComposing,
}: TagDropdownProps) {
  const dropdownId = useRef(id ?? uuidv4()).current;
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollbarRef = useRef<{
    scrollTo: (index: number, behavior?: ScrollBehavior, height?: number) => void;
  } | null>(null);

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(initialFocusedIndex);
  const [tagMaxLengths, setTagMaxLengths] = useState<number[]>([]);
  const itemContainerRef = useRef<HTMLDivElement>(null);

  const handleEscape = useCallback(() => {
    handleToggle(false);
  }, [handleToggle]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();

      if (!contentRef.current) return;

      useTagDropdownKeyboardEvent({
        keycode: event.key,
        listEl: contentRef.current,
        onEnter: handleEnter,
        onEsc: handleEscape,
        focusedIndex,
        setFocusedIndex,
        isComposing,
      });
    },
    [handleEscape, focusedIndex, isComposing],
  );

  const handleEnter = (focusedItemId: string) => {
    if (!tags.length) return;

    const selectedIndex = tags.findIndex(
      (item, index) => item.id === focusedItemId && index === focusedIndex,
    );

    if (selectedIndex !== -1) {
      onSelect?.(selectedIndex);
      setFocusedIndex(0);
    }
  };

  const openDropdown = () => {
    const event = new CustomEvent('dropdownOpen', { detail: { dropdownId } });
    window.dispatchEvent(event);
    handleToggle(true);
  };

  const defaultFocusScrollHandler = useCallback(() => {
    if (focusedIndex !== undefined && scrollbarRef.current) {
      scrollbarRef.current.scrollTo(focusedIndex, 'auto', 33);
    }
  }, [focusedIndex]);

  const defaultSelectedScrollHandler = useCallback(() => {
    const selectedElement = contentRef.current?.querySelector(
      `[aria-selected='true']`,
    ) as HTMLElement;
    if (selectedElement && scrollbarRef.current) {
      const index = Number(selectedElement.getAttribute('item-index'));
      scrollbarRef.current.scrollTo(index, 'auto', 33);
    }
  }, [selectedIndex]);

  useClickOutside({
    id: dropdownId,
    ref: dropdownRef,
    contentRef,
    onClose: () => {
      handleClickOutside?.();
      handleToggle(false);
    },
    ignoreRefs: ignoreRefs ?? [],
  });

  useEffect(() => {
    if (isToggle) {
      openDropdown();
    }
  }, [isToggle]);

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if ((event.target as HTMLElement).tagName === 'INPUT') return;

      if (
        event.key === 'Escape' ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown' ||
        event.key === 'Enter'
      ) {
        handleKeyDown(event);
      }
    };

    window.addEventListener('keydown', keydownListener, true);
    return () => {
      window.removeEventListener('keydown', keydownListener, true);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    if (!focusedIndex) return;

    const scrollPosition = customFocusScrollHandler
      ? customFocusScrollHandler(focusedIndex)
      : focusedIndex;

    if (scrollbarRef.current && scrollPosition !== undefined) {
      scrollbarRef.current.scrollTo(scrollPosition, 'auto', 33);
    } else {
      defaultFocusScrollHandler();
    }
  }, [focusedIndex, customFocusScrollHandler, defaultFocusScrollHandler]);

  useEffect(() => {
    if (!selectedIndex) return;

    const scrollPosition = customSelectedScrollHandler
      ? customSelectedScrollHandler(selectedIndex)
      : selectedIndex;

    if (scrollbarRef.current && scrollPosition !== undefined) {
      scrollbarRef.current.scrollTo(scrollPosition, 'auto', 33);
    } else {
      defaultSelectedScrollHandler();
    }
  }, [selectedIndex, customSelectedScrollHandler, defaultSelectedScrollHandler]);

  useEffect(() => {
    if (focusedIndex === undefined && tags.length > 0) {
      const newFocusedIndex =
        selectedIndex !== undefined ? selectedIndex : isDefaultFocus ? 0 : undefined;
      setFocusedIndex(newFocusedIndex);
    }
  }, [tags.length, selectedIndex, focusedIndex]);

  useEffect(() => {
    if (!tags.length) return;

    const selectedElement = contentRef.current?.querySelector(
      `[aria-selected='true']`,
    ) as HTMLElement;
    if (selectedElement && scrollbarRef.current) {
      const index = Number(selectedElement.getAttribute('item-index'));

      if (tags.length >= 5) {
        scrollbarRef.current.scrollTo(index - 2, 'auto');
      } else {
        scrollbarRef.current.scrollTo(index, 'auto');
      }
    }
  }, [tags]);

  useEffect(() => {
    const handleDropdownOpen = (event: CustomEvent) => {
      if (event.detail.dropdownId !== dropdownId && isToggle) {
        handleToggle(false);
      }
    };

    window.addEventListener('dropdownOpen', handleDropdownOpen as EventListener);

    return () => {
      window.removeEventListener('dropdownOpen', handleDropdownOpen as EventListener);
    };
  }, [dropdownId, isToggle, handleToggle]);

  useEffect(() => {
    if (!itemContainerRef.current || !tags.length) return;

    const measureDiv = document.createElement('div');
    measureDiv.style.position = 'absolute';
    measureDiv.style.visibility = 'hidden';
    measureDiv.style.whiteSpace = 'nowrap';
    measureDiv.style.fontSize = '14px';
    measureDiv.style.fontFamily = 'inherit';
    measureDiv.style.padding = '4px 8px';
    measureDiv.style.border = '1px solid transparent';
    measureDiv.style.boxSizing = 'border-box';
    measureDiv.style.display = 'inline-block';
    document.body.appendChild(measureDiv);

    const containerWidth = itemContainerRef.current.clientWidth;
    const sidePadding = 24;
    const iconSpace = selectedIndex !== undefined ? 24 : 0;
    const maxTagWidth = containerWidth - sidePadding - iconSpace;

    const calculatedMaxLengths = tags.map((tag) => {
      measureDiv.innerText = tag.name;
      let maxTextLength = tag.name.length;
      let tagWidth = measureDiv.getBoundingClientRect().width;

      const adjustedMaxWidth = maxTagWidth * 1.5;

      if (tagWidth > adjustedMaxWidth) {
        while (tagWidth > adjustedMaxWidth && maxTextLength > 3) {
          maxTextLength--;
          measureDiv.innerText = tag.name.slice(0, maxTextLength);
          tagWidth = measureDiv.getBoundingClientRect().width;
        }
        return maxTextLength;
      }

      return tag.name.length;
    });

    document.body.removeChild(measureDiv);
    setTagMaxLengths(calculatedMaxLengths);
  }, [tags, width, selectedIndex]);

  useEffect(() => {
    if (searchText && tags.length > 0) {
      setFocusedIndex(0);
      onFocusChange?.(0);
    }
  }, [searchText, tags.length]);

  return (
    <div ref={dropdownRef} className='relative'>
      <div
        ref={contentRef}
        className={customTwMerge(
          'absolute z-10 mt-2 select-none rounded-r6 bg-white50 py-4 shadow-modal ring-1 ring-black ring-opacity-5',
          wrapperClassName,
        )}
        data-ignore-click-outside={true}
        style={{
          height: tags.length > 0 ? 33 * Math.min(tags.length, 5) + 8 : 40,
          width: SHARED_UTILS.css.getCssSizeValue(width),
          ...wrapperStyle,
        }}>
        {tags.length > 0 ? (
          <Scrollbar ref={scrollbarRef}>
            <div ref={itemContainerRef} aria-orientation='vertical' aria-labelledby='tags-menu'>
              {tags.map((option, index) => (
                <div
                  style={tagStyle}
                  key={option.id}
                  tabIndex={index}
                  onClick={() => {
                    onSelect?.(index);
                  }}
                  onMouseEnter={() => onFocusChange?.(index)}
                  className={customTwMerge(
                    `block cursor-pointer px-10 py-7 focus:bg-blue50 focus:outline-none ${
                      redTextIndices.includes(index) ? 'text-red500' : 'text-black500'
                    } flex items-center justify-between hover:bg-blue50`,
                    focusedIndex === index ? 'bg-blue50' : '',
                    selectedIndex === index ? 'text-Header12' : 'text-Body12',
                    tagsClassName,
                  )}
                  id={option.id}
                  item-index={index}
                  aria-labelledby='dropdown-option'
                  aria-selected={selectedIndex === index ? 'true' : undefined}>
                  <FilledTag
                    maxTextLength={tagMaxLengths[index]}
                    bgColor={option.color ?? '#F5F5F5'}>
                    {option.name}
                  </FilledTag>
                  {selectedIndex === index && <Icon name='done' color='blue500' size={16} />}
                </div>
              ))}
            </div>
          </Scrollbar>
        ) : (
          <div className='px-8 py-9 text-Caption9 text-black200'>*등록 가능 태그가 없습니다.</div>
        )}
      </div>
    </div>
  );
}
