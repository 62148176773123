import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  KakaoAlimFriendTalkLinkageHandlerBodyRequest,
  KakaoProfileCategoryHandlerResponse,
} from '@apis/swaggers/swagger-docs';
import { useSuspenseQuery } from '@tanstack/react-query';
import { historyCategoryDataState } from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/KakaoAlimTalkForm/states/category-data-state';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import { categoryCodeErrorAtom } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import { useAtom, useAtomValue } from 'jotai/index';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SHARED_UTILS } from 'utils/utils';

type Categories = {
  [broadCategoryName: string]: {
    [mediumCategoryName: string]: {
      name: string;
      code: string;
    }[];
  };
};

type ResCategoriesType = KakaoProfileCategoryHandlerResponse['data'];

function transformToCategories(data: ResCategoriesType): Categories {
  const result: Categories = {};

  for (const broadCategory of data) {
    const { broadCategoryName, subCategory } = broadCategory;

    if (!result[broadCategoryName]) {
      result[broadCategoryName] = {};
    }

    for (const mediumCategory of subCategory) {
      const { mediumCategoryName, smallCategory } = mediumCategory;

      result[broadCategoryName][mediumCategoryName] = smallCategory.map((item) => ({
        name: item.smallCategoryName,
        code: item.code,
      }));
    }
  }

  return result;
}

const findSelectedIndex = <T,>(list: T[], selectedItem: T) => {
  const index = list.findIndex((item) => item === selectedItem);
  return index !== -1 ? index : undefined;
};

const fetchKakaoProfileCategories = async () => {
  const { data } = await apiClient.v3.kakaoProfileCategoryHandler();
  const result = SHARED_UTILS.api.checkApiResponse<ResCategoriesType>(data);
  return transformToCategories(result);
};

export default function CategorySelector() {
  const { setValue } = useFormContext<KakaoAlimFriendTalkLinkageHandlerBodyRequest>();

  const [categoryCodeError, setCategoryCodeError] = useAtom(categoryCodeErrorAtom);
  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);
  const [historyCategoryData, setHistoryCategoryData] = useAtom(historyCategoryDataState);

  const [selectedBroadCategory, setSelectedBroadCategory] = useState('');
  const [selectedMediumCategory, setSelectedMediumCategory] = useState('');
  const [selectedSmallCategory, setSelectedSmallCategory] = useState('');

  const { data: categories } = useSuspenseQuery({
    queryKey: [QUERY_KEY.kakaoProfileCategoryHandler],
    queryFn: fetchKakaoProfileCategories,
  });

  const broadCategories = useMemo(() => Object.keys(categories), [categories]);

  const mediumCategories = useMemo(
    () => (selectedBroadCategory ? Object.keys(categories[selectedBroadCategory]) : []),
    [categories, selectedBroadCategory],
  );
  const smallCategories = useMemo(
    () =>
      selectedBroadCategory && selectedMediumCategory
        ? categories[selectedBroadCategory][selectedMediumCategory]
        : [],
    [categories, selectedBroadCategory, selectedMediumCategory],
  );

  const selectedBroadCategoryIndex = useMemo(
    () => findSelectedIndex(broadCategories, selectedBroadCategory),
    [broadCategories, selectedBroadCategory],
  );

  const selectedMediumCategoryIndex = useMemo(
    () => findSelectedIndex(mediumCategories, selectedMediumCategory),
    [mediumCategories, selectedMediumCategory],
  );

  const selectedSmallCategoryIndex = useMemo(
    () =>
      findSelectedIndex(
        smallCategories.map((category) => category.name),
        selectedSmallCategory,
      ),
    [smallCategories, selectedSmallCategory],
  );

  const isNotHistory = useMemo(
    () => hasLinkageOrRequestHistory === 'NotLinked',
    [hasLinkageOrRequestHistory],
  );

  const handleBroadCategoryChange = (index: number) => {
    setCategoryCodeError(false);
    setSelectedBroadCategory(broadCategories[index]);
    setSelectedMediumCategory('');
    setSelectedSmallCategory('');
  };

  const handleMediumCategoryChange = (index: number) => {
    setCategoryCodeError(false);
    setSelectedMediumCategory(mediumCategories[index]);
    setSelectedSmallCategory('');
  };

  const handleSmallCategoryChange = (index: number) => {
    setCategoryCodeError(false);
    setSelectedSmallCategory(smallCategories[index].name);
  };

  useEffect(() => {
    if (selectedSmallCategoryIndex !== undefined && selectedSmallCategoryIndex >= 0) {
      setValue('categoryCode', smallCategories[selectedSmallCategoryIndex].code);
    } else {
      setValue('categoryCode', '');
    }
  }, [selectedSmallCategoryIndex]);

  useEffect(() => {
    if (categories) {
      const { broadCategory, mediumCategory, smallCategory } = historyCategoryData.history;

      const selectCategory = (
        categoryValue: string | undefined,
        options: string[] | { name: string }[],
        handleChange: (index: number) => void,
      ) => {
        if (!categoryValue) return;
        const index = options.findIndex((option) =>
          typeof option === 'string' ? option === categoryValue : option.name === categoryValue,
        );
        if (index >= 0) {
          handleChange(index);
        }
      };

      selectCategory(broadCategory, broadCategories, handleBroadCategoryChange);
      selectCategory(mediumCategory, mediumCategories, handleMediumCategoryChange);
      selectCategory(smallCategory, smallCategories, handleSmallCategoryChange);
    }
  }, [historyCategoryData.history, categories, broadCategories, mediumCategories, smallCategories]);

  useEffect(() => {
    setHistoryCategoryData((prev) => ({
      ...prev,
      current: {
        broadCategory: selectedBroadCategory,
        mediumCategory: selectedMediumCategory,
        smallCategory: selectedSmallCategory,
      },
    }));
  }, [selectedBroadCategory, selectedMediumCategory, selectedSmallCategory]);

  return (
    <>
      <div className='flex gap-10'>
        <div className='flex h-32 w-[140px] flex-shrink-0 items-center gap-6'>
          <LabelText isRequired width={'w-fit'} className='flex-center'>
            카테고리
          </LabelText>
          <HoverTooltip
            position='topRight'
            text={'채널의 성격을 잘 나타낼 수 있는 정보로 입력해 주세요.'}>
            <Icon name={'info-circle-outline'} color={'black200'} size={16} />
          </HoverTooltip>
        </div>
        <div className='flex w-full flex-col gap-4'>
          <div className='flex gap-10'>
            <TextFieldSelectBoxDropdown
              options={broadCategories}
              disabled={!isNotHistory}
              onSelect={handleBroadCategoryChange}
              placeholder={'대분류'}
              selectedIndex={selectedBroadCategoryIndex}
            />
            <TextFieldSelectBoxDropdown
              options={mediumCategories}
              onSelect={handleMediumCategoryChange}
              disabled={!isNotHistory || selectedBroadCategoryIndex === undefined}
              placeholder={'중분류'}
              selectedIndex={selectedMediumCategoryIndex}
            />
            <TextFieldSelectBoxDropdown
              options={smallCategories.map((category) => category.name)}
              placeholder={'소분류'}
              onSelect={handleSmallCategoryChange}
              disabled={!isNotHistory || selectedMediumCategoryIndex === undefined}
              selectedIndex={selectedSmallCategoryIndex}
            />
          </div>
          {categoryCodeError && (
            <span className='text-Body10 text-red500'>카테고리를 선택해 주세요.</span>
          )}
        </div>
      </div>
    </>
  );
}
