import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import ImageViewer from 'web/shared/components/ImageViewer/ImageViewer';
import OptimizedImage from 'web/shared/components/OptimizedImage/OptimizedImage';
import { roomTypeSelector } from 'web/templates/CustomerChat/states/selected-chat-room';
import { useChatMessageContext } from '../ChatMessageContext';
import TextContentMessage from './TextContentMessage/TextContentMessage';
import ExpiredImageMock from './components/ImageList/components/ExpiredImageMock';
import { useImageProxyCallback } from './components/ImageList/hooks/use-image-proxy-callback';

export default function MarketingImageMessage() {
  const { fileIDs, isMine, content, chattingWidth, sentAt, userRealName, jobType, userName } =
    useChatMessageContext();
  if (fileIDs === undefined) return null;
  const roomType = useAtomValue(roomTypeSelector);
  const { switchProxyCallback } = useImageProxyCallback();

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const handleImageOpen = (index: number) => {
    setIsImageViewerOpen(true);
    setSelectedIndex(index);
  };
  const { aws } = fileIDs[0];

  const images =
    aws?.map((o) => {
      return {
        id: o.Key,
        url: o.Location,
        fileName: o.fileName,
        sentAt,
        name: (userRealName || userName) ?? '',
        jobType,
      };
    }) ?? [];

  return (
    <div className='flex flex-col items-end gap-10'>
      {content && <TextContentMessage />}
      <div
        className={`w-fit rounded-r10 bg-white50 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)] ' : 'bg-white50 '}`}
        style={{ maxWidth: `${chattingWidth / 2}px` }}>
        <div className='mb-10 flex-center rounded-r6 bg-purple500 px-10 py-4 text-Header12 text-white50'>
          이벤트 알림
        </div>
        {fileIDs?.map(({ aws, paths, createdAt, expiredAt }, index) => {
          const isExpired = expiredAt ? new Date(expiredAt) < new Date() : false;

          const { Key, Location } = aws?.[index] || {};
          if (isExpired) return <ExpiredImageMock />;
          return (
            paths && (
              <div
                key={createdAt}
                className='relative max-h-[330px] max-w-[330px] flex-center overflow-hidden rounded-r16'>
                <OptimizedImage
                  image={{ id: Key, url: Location }}
                  alt='marketing'
                  loadingComponent={
                    <div className='h-[330px] w-[330px] flex-center rounded-r16 shadow-modal'>
                      <BaseLoading />
                    </div>
                  }
                  onClick={() => {
                    handleImageOpen(0);
                  }}
                />
              </div>
            )
          );
        })}
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          imageObjects={images}
          selectedIndex={selectedIndex}
          imageUrls={images.map((image) => image.url)}
          onClose={(e) => {
            setIsImageViewerOpen(false);
            e?.stopPropagation();
            e?.preventDefault();
          }}
          proxyCallback={switchProxyCallback(roomType)}
        />
      )}
    </div>
  );
}
