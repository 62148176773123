import type {
  PanelListProps,
  SubLayer,
} from 'afterdoc-design-system/components/Atoms/PanelList/PanelList';

export type CustomerManagementPanelTitle = '고객정보 입력' | '치료태그 미입력';

export const DEFAULT_PANEL_ITEMS: PanelListProps['layers'] = [
  {
    title: '고객정보 입력',
    items: [],
    state: 'default',
  },
  {
    title: '치료태그 미입력',
    items: [],
    state: 'default',
  },
] satisfies Array<{
  title: CustomerManagementPanelTitle;
  items: SubLayer[];
  state?: 'default' | 'focus';
}>;
