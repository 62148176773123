import { Color } from '@tailwind-base/styles/color';
import { useQuery } from '@tanstack/react-query';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import TranslateTextMessage from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/Message/TextContentMessage/TranslateTextMessage';
import { ROOM_TYPE_MAP } from 'web/templates/CustomerChat/constants/ROOM_TYPE_MAP';
import { fetchMetadata } from 'web/templates/CustomerChat/functions/fetchMetadata';
import { isActivatingChatTranslateState } from '../../../../../../../../states/is-activating-chat-translate';
import { useChatMessageContext } from '../../ChatMessageContext';
import { MESSAGE_TYPE_CODE, urlRegex } from '../../constants/message';
import ContentText from '../components/ContentText';
import { MessageDialogContent } from '../components/MessageDialogContent';
import IsTranslatingMessage from './IsTranslatingMessage';
import { useLeftTranslationTimeout } from './hooks/use-left-translate-timeout';
import { useRightTranslationTimeout } from './hooks/use-right-translate-timeout';

export default function TextContentMessage() {
  const {
    status,
    isMine,
    contentParts,
    isTranslated,
    isLeft,
    type,
    isOverLength,
    error,
    messageWidthStyle,
  } = useChatMessageContext();

  const isActivatingChatTranslate = useAtomValue(isActivatingChatTranslateState);
  const messageWidthStyleWithFitContent = {
    ...messageWidthStyle,
    width: 'fit-content',
  };
  const cardWidthStyle = messageWidthStyle;
  const isTranslatedMockingMessageInitial =
    isActivatingChatTranslate &&
    isTranslated === undefined &&
    type === MESSAGE_TYPE_CODE.TEXT_MOCKING;

  const [isTranslatedMockingMessage, setIsTranslatedMockingMessage] = useState(
    isTranslatedMockingMessageInitial,
  );

  useEffect(() => {
    setIsTranslatedMockingMessage(isTranslatedMockingMessageInitial);
  }, [isTranslatedMockingMessageInitial]);

  const isLeftMockingMessageInitial =
    isLeft && isActivatingChatTranslate && isTranslated === undefined;

  const [isLeftMockingMessage, setIsLeftMockingMessage] = useState(isLeftMockingMessageInitial);

  useEffect(() => {
    setIsLeftMockingMessage(isLeftMockingMessageInitial);
  }, [isLeftMockingMessageInitial]);

  const [isRightTimeError, setIsRightTimeError] = useState(false);

  const firstUrl = contentParts.find((part) => urlRegex.test(part));

  const { data: metadata } = useQuery({
    queryKey: ['chat-metadata', firstUrl] as const,
    queryFn: ({ queryKey }) => fetchMetadata(queryKey[1]),
    enabled: !!firstUrl,
    staleTime: 1000 * 60 * 60,
    retry: 1,
  });

  //번역&&오른쪽채팅 일 경우에 적용되는 timeout 로직
  useRightTranslationTimeout({
    isTranslatedMockingMessage,
    setIsTranslatedMockingMessage,
    setIsRightTimeError,
  });

  //번역&&왼쪽채팅 일 경우에 적용되는 timeout 로직
  const { isLeftTimeout, restartTimer } = useLeftTranslationTimeout({
    isLeftMockingMessage,
    setIsLeftMockingMessage,
  });

  const handleModalOpen = () => {
    dialogService.push(
      <MessageDialogContent>
        <ContentText parts={contentParts} isTruncate={false} />
      </MessageDialogContent>,
      {
        hasCloseButton: true,
        titleProps: {
          title: '전체보기',
        },
      },
    );
  };

  if (isLeftMockingMessage) return <IsTranslatingMessage />;

  if (isTranslatedMockingMessage) return <IsTranslatingMessage />;

  const isRightError = isRightTimeError || (!isLeft && status === 'failed');
  const isTextType = type === 100;

  //error가 있고 error type이 'translate'이고 messager가 '1000'일 경우 isSendOverOneThousandStringToKakao를 true로 설정
  const isSendOverOneThousandStringToKakao =
    error &&
    error.type === ROOM_TYPE_MAP.kakao &&
    error.rawErrorMessage ===
      'IllegalArgumentException. message length is limited in length 1000.' &&
    !isLeft;
  const messageOverOneThousandStringToKakao = error
    ? `번역된 ${error.message}`
    : '번역된 내용이 1,000자를 초과하여 전송을 실패했습니다.';

  const isTextMessageErrorHandle =
    isTextType && !isRightError && (isLeftTimeout || isTranslated !== undefined);
  return (
    <div className={`flex flex-col items-${isLeft ? 'start' : 'end'} gap-10`}>
      {/* 텍스트 타입 && 오른쪽 에러 아니여야하고 && (왼쪽 타임에러 || 번역완료) 일 경우 */}
      {isTextMessageErrorHandle ? (
        <>
          <TranslateTextMessage isLeftTimeout={isLeftTimeout} restartTimer={restartTimer} />
        </>
      ) : (
        <div
          className={`flex-row space-y-10 rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
          style={messageWidthStyleWithFitContent}>
          <ContentText parts={contentParts} />
          {!error && isOverLength && (
            <OutlinedButton
              className='w-full border-black200 px-20 py-6 text-black200'
              btnColor='secondary'
              onClick={handleModalOpen}>
              전체보기
            </OutlinedButton>
          )}
          {isSendOverOneThousandStringToKakao && (
            <ContentText
              textColor={Color.red500}
              parts={[messageOverOneThousandStringToKakao]}
              isTruncate={false}
            />
          )}
        </div>
      )}
      {metadata && (
        <div
          className={`h-[108px] overflow-hidden rounded-r10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
          style={cardWidthStyle}>
          <div className='flex h-full'>
            {metadata?.image && (
              <img
                width={108}
                height={108}
                src={metadata.image}
                alt='Preview'
                className='object-cover'
              />
            )}
            <div className='m-10 flex flex-center flex-col-center flex-grow items-start gap-5'>
              <p className='line-clamp-1 overflow-hidden break-all text-Body13 text-black700'>
                {metadata?.title}
              </p>
              <p className='line-clamp-2 overflow-hidden break-all text-Body12 text-black500'>
                {metadata?.description}
              </p>
              <p className='line-clamp-1 overflow-hidden break-all text-Body12 text-black500'>
                {firstUrl}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
