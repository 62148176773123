import { useSetAtom } from 'jotai';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { chattingWidthAtom } from 'web/templates/CustomerChat/states/chattingWidth';

export default function useChatRoomResizeObserver(
  divRef: React.RefObject<HTMLDivElement>,
  isManualOpen: boolean,
) {
  const setChattingWidth = useSetAtom(chattingWidthAtom);

  const isMounted = useRef(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
    } else {
      if (divRef.current) {
        const currentWidth = divRef.current.offsetWidth;
        divRef.current.style.width = isManualOpen
          ? `${currentWidth - 400}px`
          : `${currentWidth + 400}px`;
      }
    }
  }, [isManualOpen]);

  const updateWidth = () => {
    if (divRef.current) {
      setChattingWidth(divRef.current.offsetWidth);
    }
  };

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      updateWidth();
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current);
      }
      observer.disconnect();
    };
  }, [divRef.current]);
}
