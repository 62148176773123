import * as React from "react";
import type { SVGProps } from "react";
const SvgLogoWhiteSymbol = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M.666 7.377C2.64 2.222 8.422-.355 13.578 1.622h.002c5.155 1.976 7.732 7.756 5.755 12.912-1.952 5.09-7.612 7.664-12.718 5.824-1.474.331-3.132.532-4.96.502a.227.227 0 0 1-.159-.384c.426-.44 1.169-1.345 1.59-2.297A10 10 0 0 1 .665 7.377m10.36-2.73 4.198 2.424c.52.303.843.859.843 1.46v4.846c0 .604-.322 1.16-.844 1.461l-4.197 2.424a1.69 1.69 0 0 1-1.687 0l-4.198-2.424a1.69 1.69 0 0 1-.843-1.46V8.532c0-.604.322-1.16.843-1.461L9.34 4.647a1.69 1.69 0 0 1 1.687 0m4.606 7.641a.21.21 0 0 0 .105-.183v-1.829a.077.077 0 0 0-.116-.067l-1.452.789V9.083a.077.077 0 0 0-.116-.067l-1.572.913a.21.21 0 0 0-.105.184v1.69l-1.61.881a.21.21 0 0 0-.107.183v1.811c0 .06.083.115.134.085l1.6-.866-.017 1.86c0 .06.065.097.116.067l1.572-.913a.21.21 0 0 0 .105-.184v-1.589z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLogoWhiteSymbol;
