import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';

type HandleOpenElectronReservationParams = {
  isDisabled: boolean;
  chatRoomParentHServiceID: string;
};

export const handleOpenElectronReservation = async ({
  isDisabled,
  chatRoomParentHServiceID,
}: HandleOpenElectronReservationParams) => {
  if (isDisabled) {
    toastService.errorMsg({ text: '고객 등록 후 예약할 수 있습니다.' });
    return;
  }
  if (window.electron === undefined) {
    alert('앱에서 실행해야 하는 기능입니다.');
  }

  window.electron?.ipcRenderer.send('Application.showReservationWindow', {
    value: { hserviceID: chatRoomParentHServiceID },
  });
};
