import { ISO_DATE_TIME_TIME_FORMAT } from '@shared/constants/iso-date-time-format';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import TextFieldSelectBoxDropdown from 'afterdoc-design-system/components/Organisms/Dropdown/TextFieldSelectBoxDropdown/TextFieldSelectBoxDropdown';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai/index';
import { memo, useEffect, useState } from 'react';

interface IntegrationStartTimePickerProps {
  currentDate: string;
  onHandleChangeTime: (value: string) => void;
}

const hourOptions = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
const minuteOptions = Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, '0'));

function IntegrationStartTimePicker({
  currentDate,
  onHandleChangeTime,
}: IntegrationStartTimePickerProps) {
  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  const [selectedHourIndex, setSelectedHourIndex] = useState<number | undefined>();
  const [selectedMinuteIndex, setSelectedMinuteIndex] = useState<number | undefined>();

  const handleSelect = (index: number, type: 'hour' | 'minute') => {
    const updatedHour = type === 'hour' ? hourOptions[index] : hourOptions[selectedHourIndex ?? 0];
    const updatedMinute =
      type === 'minute' ? minuteOptions[index] : minuteOptions[selectedMinuteIndex ?? 0];

    if (type === 'hour') {
      setSelectedHourIndex(index);
    } else {
      setSelectedMinuteIndex(index);
    }

    const formattedTime = dayjs()
      .set('hour', Number.parseInt(updatedHour, 10))
      .set('minute', Number.parseInt(updatedMinute, 10))
      .set('second', 0)
      .set('millisecond', 0)
      .format(ISO_DATE_TIME_TIME_FORMAT);
    onHandleChangeTime(formattedTime);
  };

  useEffect(() => {
    const hourIndex = hourOptions.findIndex((hour) => hour === dayjs(currentDate).format('HH'));
    const minuteIndex = minuteOptions.findIndex(
      (minute) => minute === dayjs(currentDate).format('mm'),
    );

    setSelectedHourIndex(hourIndex !== -1 ? hourIndex : 0);
    setSelectedMinuteIndex(minuteIndex !== -1 ? minuteIndex : 0);
  }, [currentDate]);

  return (
    <div className='h-full flex-center gap-4'>
      <TextFieldSelectBoxDropdown
        options={hourOptions}
        onSelect={(index) => handleSelect(index, 'hour')}
        width={62}
        selectedIndex={selectedHourIndex}
        disabled={hasLinkageOrRequestHistory !== 'NotLinked'}
      />

      <span className='text-Body12 text-black500'>:</span>

      <TextFieldSelectBoxDropdown
        options={minuteOptions}
        onSelect={(index) => handleSelect(index, 'minute')}
        width={62}
        selectedIndex={selectedMinuteIndex}
        disabled={hasLinkageOrRequestHistory !== 'NotLinked'}
      />
    </div>
  );
}

export default memo(IntegrationStartTimePicker);
