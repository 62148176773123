import * as Sentry from '@sentry/react';
import { type PropsWithChildren, Suspense, lazy } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import AppContainer from 'web/App.container';
import ErrorBoundary from 'web/shared/components/ErrorBoundary/ErrorBoundary';
import { ROUTES_PATH } from 'web/shared/components/NavigationBar/constants/menu-items';
import FullLoading from '../components/FullLoading/FullLoading';

// Error Page
const ErrorPage = lazy(() =>
  import('../../pages/ErrorPage').then((module) => ({ default: module.default })),
);

/** Main Routes Pages */
// 고객상담
const CustomerChatPage = lazy(() =>
  import('../../pages/CustomerChatPage').then((module) => ({ default: module.default })),
);
// 고객입력
const CustomerManagementPage = lazy(() =>
  import('../../pages/CustomerManagementPage').then((module) => ({ default: module.default })),
);
// 자동화
const AutomationPage = lazy(() =>
  import('../../pages/AutomationPage').then((module) => ({ default: module.default })),
);
// 콘텐츠
const ContentPage = lazy(() =>
  import('../../pages/ContentPage').then((module) => ({ default: module.default })),
);
// 원내매뉴얼
const HospitalManualPage = lazy(() =>
  import('../../pages/HospitalManualPage').then((module) => ({ default: module.default })),
);
// 병원설정
const HospitalSettingPage = lazy(() =>
  import('../../pages/HospitalSettingPage').then((module) => ({ default: module.default })),
);
// 내정보설정
const UserInfoSettingPage = lazy(() =>
  import('../../pages/UserInfoSettingPage').then((module) => ({ default: module.default })),
);

// 테스트
const TaewoongTestPageDev = lazy(() =>
  import('../../pages/tests/TaewoongTestPage.dev').then((module) => ({ default: module.default })),
);
const JunsuTestPageDev = lazy(() =>
  import('../../pages/tests/JunsuTestPage.dev').then((module) => ({ default: module.default })),
);
const TaesungTestPageDev = lazy(() =>
  import('../../pages/tests/TaesungTestPage.dev').then((module) => ({ default: module.default })),
);

// 에러 페이지 테스트
const ErrorPageTest = lazy(() =>
  import('../../pages/tests/ErrorTestPage.dev').then((module) => ({ default: module.default })),
);

const SuspenseWithErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<FullLoading />}>{children}</Suspense>
    </ErrorBoundary>
  );
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

const routes = [
  {
    path: ROUTES_PATH.HOME,
    element: <AppContainer />,
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES_PATH.CUSTOMER_CHAT} replace />,
      },
      {
        path: ROUTES_PATH.RESERVATION_MANAGEMENT,
      },
      {
        path: ROUTES_PATH.ADMIN_CHAT,
      },
      {
        path: ROUTES_PATH.CUSTOMER_CHAT,
        element: (
          <SuspenseWithErrorBoundary>
            <CustomerChatPage />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.CUSTOMER_MANAGEMENT,
        element: (
          <SuspenseWithErrorBoundary>
            <CustomerManagementPage />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.AUTOMATION,
        element: (
          <SuspenseWithErrorBoundary>
            <AutomationPage />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.CONTENT,
        element: (
          <SuspenseWithErrorBoundary>
            <ContentPage />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.HOSPITAL_MANUAL,
        element: (
          <SuspenseWithErrorBoundary>
            <HospitalManualPage />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.HOSPITAL_SETTING,
        element: (
          <SuspenseWithErrorBoundary>
            <HospitalSettingPage />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.USER_INFO_SETTINGS,
        element: (
          <SuspenseWithErrorBoundary>
            <UserInfoSettingPage />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.ERROR,
        element: (
          <SuspenseWithErrorBoundary>
            <ErrorPage />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.TAEWOONG_TEST,
        element: (
          <SuspenseWithErrorBoundary>
            <TaewoongTestPageDev />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.JUNSU_TEST,
        element: (
          <SuspenseWithErrorBoundary>
            <JunsuTestPageDev />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.TAESUNG_TEST,
        element: (
          <SuspenseWithErrorBoundary>
            <TaesungTestPageDev />
          </SuspenseWithErrorBoundary>
        ),
      },
      {
        path: ROUTES_PATH.ERROR_TEST,
        element: (
          <SuspenseWithErrorBoundary>
            <ErrorPageTest />
          </SuspenseWithErrorBoundary>
        ),
      },
    ],
  },
];

// createBrowserRouter 대신 sentryCreateBrowserRouter 사용
const AppRoutes: ReturnType<typeof createBrowserRouter> = sentryCreateBrowserRouter(routes);

export default AppRoutes;
